import {
  InvoiceCategoryFilter,
  RateType,
  SettlementState,
} from '@treadinc/horizon-api-spec';
import { makeObservable, observable, runInAction } from 'mobx';

import { AddOnCharge } from '~hooks/useAddOns/models';
import { Settlement } from '~hooks/useSettlements';
import { Pagination, PaginationLink } from '~services/pagination';
import { getPageNumber } from '~utils/pagination';

export enum SettlementSearchParamName {
  SETTLEMENT_STATES = 'filter[states]',
  SETTLEMENT_CATEGORY = 'filter[category]',
  END_DATE = 'filter[end_date]',
  START_DATE = 'filter[start_date]',
  PROJECTS = 'filter[project_ids]',
  CUSTOMERS = 'filter[customer_account_ids]',
  VENDORS = 'filter[vendor_account_ids]',
  TRUCKS = 'filter[truck_ids]',
  DRIVERS = 'filter[driver_ids]',
  DROPOFF_SITE = 'filter[dropoff_site_ids]',
  PICKUP_SITE = 'filter[pickup_site_ids]',
  RATE_TYPES = 'filter[rate_types]',
}

export interface SettlementSearchParams {
  [SettlementSearchParamName.SETTLEMENT_CATEGORY]?: InvoiceCategoryFilter;
  [SettlementSearchParamName.END_DATE]?: string;
  [SettlementSearchParamName.START_DATE]?: string;
  [SettlementSearchParamName.PROJECTS]?: string[];
  [SettlementSearchParamName.CUSTOMERS]?: string[];
  [SettlementSearchParamName.VENDORS]?: string[];
  [SettlementSearchParamName.TRUCKS]?: string[];
  [SettlementSearchParamName.DRIVERS]?: string[];
  [SettlementSearchParamName.DROPOFF_SITE]?: string[];
  [SettlementSearchParamName.PICKUP_SITE]?: string[];
  [SettlementSearchParamName.SETTLEMENT_STATES]?: Array<SettlementState>;
  [SettlementSearchParamName.RATE_TYPES]?: RateType[];
}

export default class SettlementsStore {
  settlements: Settlement[] = [];
  addOnChargesBySettlementId: Record<string, AddOnCharge[]> = {};
  pagination: Pagination = {
    limit: 100,
    after: '',
    before: '',
    page: 1,
  };
  searchParams: SettlementSearchParams = {};

  constructor() {
    makeObservable(this, {
      settlements: observable,
      addOnChargesBySettlementId: observable,
      pagination: observable,
      searchParams: observable,
    });
  }

  addSettlement(settlement: Settlement) {
    runInAction(() => {
      this.setSettlements([...this.settlements, settlement]);
    });
  }

  updateSettlement(settlement: Settlement, upsert?: boolean) {
    runInAction(() => {
      const settlementIndex = this.settlements.findIndex(
        ({ id }) => id === settlement.id,
      );

      if (settlementIndex > -1) {
        const newList = this.settlements.slice();

        newList.splice(settlementIndex, 1, settlement);
        this.setSettlements(newList);
      } else if (upsert) {
        this.addSettlement(settlement);
      }
    });
  }

  setAddOnChargesBySettlementId(settlementId: string, addOnCharges: AddOnCharge[]) {
    runInAction(() => {
      this.addOnChargesBySettlementId = {
        ...this.addOnChargesBySettlementId,
        [settlementId]: addOnCharges,
      };
    });
  }

  updateAddOnChargeBySettlementId(settlementId: string, addOnCharge: AddOnCharge) {
    runInAction(() => {
      const updateInd = this.addOnChargesBySettlementId[settlementId].findIndex(
        (charge) => charge.id === addOnCharge.id,
      );
      if (updateInd >= 0) {
        this.addOnChargesBySettlementId[settlementId][updateInd] = addOnCharge;
      }
      this.addOnChargesBySettlementId[settlementId].push(addOnCharge);
    });
  }

  setPagination(pagination: Pagination) {
    runInAction(() => {
      this.pagination = {
        ...this.pagination,
        before: pagination.before || '',
        after: pagination.after || '',
      };
    });
  }

  updatePageNumber(link: PaginationLink) {
    runInAction(() => {
      this.pagination = {
        ...this.pagination,
        page: getPageNumber(this.pagination.page, link),
      };
    });
  }

  setSearchParams(searchParams: SettlementSearchParams) {
    runInAction(() => {
      this.searchParams = searchParams;
    });
  }

  setSettlements(settlements: Settlement[]) {
    runInAction(() => {
      this.settlements = [...settlements];
    });
  }
}
