import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { AddOnRateType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { AddOn } from '~hooks/useAddOns';
import useAddOns from '~hooks/useAddOns/useAddOns';
import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import {
  SettlementLineItem,
  useSettlementLineItems,
} from '~hooks/useSettlementLineItems';
import { useSettlements } from '~hooks/useSettlements';
import { useStores } from '~store';
import theme from '~theme/AppTheme';

import { appendAddOnRateTypeToName } from '../../helpers';
import { COMMON_GRID_CONFIG } from './constants';
import { SettlementAddOnCharges } from './SettlementAddOnCharges';
import { SettlementLineItems } from './SettlementLineItems';
import { SettlementPricing } from './SettlementPricing';

function LoadingPlaceholder() {
  return (
    <Box
      sx={{
        gridColumn: '1 / -1',
        display: 'grid',
        justifyContent: 'center',
        alignContent: 'center',
        height: '100px',
        bgcolor: 'common.white',
      }}
    >
      <CircularProgress />
    </Box>
  );
}

type AddOnSelectOption = {
  id: string;
  label: string;
};

type AddOnFormData = {
  addOns: {
    addOnSelection: AddOnSelectOption | null;
    quantity: number | null;
    rate: number | null;
  }[];
};

export const SettlementDetails = observer(
  ({ settlementId }: { settlementId: string }) => {
    const [lineItems, setLineItems] = useState<SettlementLineItem[]>([]);
    const [isEditing, setIsEditing] = useState(false);

    const { addOnsStore, settlementsStore, userStore } = useStores();

    const companyId = userStore.userCompany.id;
    const settlement = useMemo(() => {
      return settlementsStore.settlements.find(
        (settlement) => settlement.settlementId === settlementId,
      );
    }, [settlementId, settlementsStore.settlements]);
    const settlementAddOnCharges = useMemo(() => {
      return settlementsStore.addOnChargesBySettlementId[settlementId] ?? [];
    }, [settlementId, settlementsStore.addOnChargesBySettlementId]);

    const {
      register,
      setValue,
      watch,
      handleSubmit,
      formState: { errors, isValid, isDirty },
      reset,
      trigger,
      control,
    } = useForm<AddOnFormData>({
      defaultValues: {
        addOns: [{ addOnSelection: null, quantity: null, rate: null }],
      },
      mode: 'all',
      delayError: 500,
    });

    const { fields, append, remove } = useFieldArray({
      control,
      name: 'addOns',
    });

    const addOns = watch('addOns');

    const {
      getAllAddOns,
      isLoading: isLoadingAllAdOns,
      createSettlementAddOnCharges,
      isCreating: isCreatingSettlementAddOnCharges,
    } = useAddOns();
    const {
      getAllSettlementAddOnCharges,
      isLoading: isLoadingAllSettlementAddOnCharges,
    } = useSettlements();
    const { getAllSettlementLineItems, isLoading: isLoadingAllSettlementLineItems } =
      useSettlementLineItems();
    const { currencyFormatter } = useCompanyCurrency();

    // Hydrate line items and add on charges on mount
    useEffect(() => {
      const getSettlementLineItems = async () => {
        const lineItemsRequest = await getAllSettlementLineItems(String(settlementId));
        const lineItems = lineItemsRequest.map((group) => {
          return group.lineItems;
        });
        setLineItems(lineItems.flat());
      };

      getSettlementLineItems();
      getAllSettlementAddOnCharges(String(settlementId));
    }, [settlementId]);

    // Hydrate select options on mount
    useEffect(() => {
      if (!addOnsStore.addOns.length) {
        getAllAddOns(companyId);
      }
    }, [companyId, addOnsStore.addOns]);

    const isLoadingAddOnArea = useMemo(
      () =>
        isLoadingAllAdOns ||
        isLoadingAllSettlementAddOnCharges ||
        isLoadingAllSettlementLineItems,
      [
        isLoadingAllAdOns,
        isLoadingAllSettlementAddOnCharges,
        isLoadingAllSettlementLineItems,
      ],
    );

    const addOnByAddOnId = useMemo(() => {
      const addOnByAddOnId = addOnsStore.addOns.reduce(
        (acc, addOn) => {
          acc[addOn.id ?? ''] = addOn;
          return acc;
        },
        {} as Record<string, AddOn>,
      );
      return addOnByAddOnId;
    }, [settlementId, addOnsStore.addOns]);

    const addOnSelectOptions = useMemo(() => {
      const options = addOnsStore.addOns.reduce((options, addOn) => {
        options.push({
          id: String(addOn.id),
          label: appendAddOnRateTypeToName(addOn.name, addOn.addOnRateType),
        });

        return options;
      }, [] as AddOnSelectOption[]);

      return options;
    }, [settlementId, addOnsStore.addOns]);

    const isReadyForSubmission = useMemo(
      () => addOns.length > 0 && isDirty && isValid,
      [isValid, isDirty, addOns],
    );

    const onSubmit = useCallback(
      handleSubmit((data) => {
        const addOnCharges = data.addOns
          .filter((addOnFormData) => !!addOnFormData.addOnSelection)
          .map<Parameters<typeof createSettlementAddOnCharges>[1][number]>(
            (addOnFormData) => {
              const addOnOption = addOnFormData.addOnSelection;
              const addOn = addOnByAddOnId[addOnOption?.id ?? ''];
              const rateType = addOn.addOnRateType;
              if (rateType === AddOnRateType.RATE_FOR_EACH) {
                return {
                  addOnId: addOn.id ?? '',
                  addOnRateType: AddOnRateType.RATE_FOR_EACH,
                  name: addOn.name,
                  quantity: addOnFormData.quantity ?? 0,
                  rate: addOnFormData.rate ?? 0,
                };
              } else {
                return {
                  addOnId: addOn.id ?? '',
                  addOnRateType: AddOnRateType.RATE_PERCENT_OF_TOTAL,
                  name: addOn.name,
                  percentage: addOnFormData.rate ?? 0,
                };
              }
            },
          );
        if (addOnCharges.length) {
          createSettlementAddOnCharges(settlementId, addOnCharges);
        }
        setIsEditing(false);
        reset();
      }),
      [setIsEditing, reset, createSettlementAddOnCharges, settlementId, addOnByAddOnId],
    );

    return (
      <Box
        sx={{
          border: 'none',
          px: 1,
          py: 2,
          backgroundColor: theme.brandV2.colors.treadGray8,
          gap: 2,
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
        }}
      >
        {isEditing ? (
          <Box
            component="form"
            onSubmit={onSubmit}
            sx={{ gridColumn: '1 / -1', justifySelf: 'end', display: 'flex', gap: 2 }}
          >
            <Button
              type="button"
              color="inherit"
              variant="contained"
              startIcon={<CloseIcon />}
              onClick={() => {
                setIsEditing(false);
                reset();
              }}
              sx={{ fontWeight: 500, bgcolor: 'common.white' }}
            >
              {t('approvals.driver_pay.settlements.cancel')}
            </Button>
            <LoadingButton
              type="submit"
              color="success"
              loading={isCreatingSettlementAddOnCharges}
              disabled={!isReadyForSubmission}
              startIcon={<CheckIcon />}
              sx={{ fontWeight: 500 }}
            >
              {t('approvals.driver_pay.settlements.save')}
            </LoadingButton>
          </Box>
        ) : (
          <Button
            type="button"
            startIcon={<EditOutlinedIcon />}
            sx={{ gridColumn: '1 / -1', justifySelf: 'end', fontWeight: 500 }}
            onClick={() => setIsEditing(true)}
          >
            {t('approvals.driver_pay.settlements.edit_add_ons')}
          </Button>
        )}
        <Box sx={{ gridColumn: '1 / -1' }}>
          <Box
            sx={{
              ...COMMON_GRID_CONFIG,
              gridTemplateRows: '36px',
              bgcolor: 'secondary.light',
            }}
          >
            {[
              t('approvals.driver_pay.settlements.pdf.date'),
              t('approvals.driver_pay.settlements.pdf.truck_num'),
              t('approvals.driver_pay.settlements.pdf.equipment_type'),
              t('approvals.driver_pay.settlements.pdf.material'),
              t('approvals.driver_pay.settlements.pdf.ids_jobs'),
              t('approvals.driver_pay.settlements.pdf.qty'),
              t('approvals.driver_pay.settlements.pdf.description'),
              t('approvals.driver_pay.settlements.adjustments'),
              t('approvals.driver_pay.settlements.pdf.billing_qty'),
              t('approvals.driver_pay.settlements.pay_rate'),
              t('approvals.driver_pay.settlements.pdf.total'),
            ].map((header, index, arr) => (
              <Typography
                key={header}
                variant="body2"
                sx={{
                  fontWeight: 600,
                  ...(index === arr.length - 1 ? { gridColumn: 'span 2' } : {}),
                }}
              >
                {header}
              </Typography>
            ))}
          </Box>

          {isLoadingAddOnArea ? (
            <LoadingPlaceholder />
          ) : (
            <>
              <SettlementLineItems
                lineItems={lineItems}
                settlement={settlement}
                currencyFormatter={currencyFormatter}
              />
              <SettlementAddOnCharges
                settlementAddOnCharges={settlementAddOnCharges}
                currencyFormatter={currencyFormatter}
              />
            </>
          )}

          {isCreatingSettlementAddOnCharges && <LoadingPlaceholder />}
          {isEditing &&
            fields.map((field, index) => {
              const selection = watch(`addOns.${index}.addOnSelection` as const);
              const quantity = watch(`addOns.${index}.quantity` as const);
              const rate = watch(`addOns.${index}.rate` as const);
              return (
                <Box
                  key={field.id}
                  sx={{
                    ...COMMON_GRID_CONFIG,
                    borderBottom: '1px solid #E2E2E2',
                  }}
                >
                  <Autocomplete
                    {...register(`addOns.${index}.addOnSelection` as const, {
                      required: 'Selection is required',
                    })}
                    onChange={(e, value) => {
                      setValue(`addOns.${index}` as const, {
                        addOnSelection: value,
                        quantity: null,
                        rate: null,
                      });
                      trigger();
                    }}
                    options={addOnSelectOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={`${t(
                          'approvals.driver_pay.settlements.add_on_select_placeholder',
                        )}`}
                        value={selection ?? ''}
                        sx={{
                          '& .MuiInputBase-input': {
                            fontSize: '12px',
                          },
                        }}
                      />
                    )}
                    ListboxProps={{ sx: { fontSize: '12px' } }}
                    sx={{
                      gridColumn: '1 / 4',
                    }}
                  />
                  {addOnByAddOnId[selection?.id ?? '']?.addOnRateType ===
                    AddOnRateType.RATE_FOR_EACH && (
                    <TextField
                      size="small"
                      placeholder={`${t(
                        'approvals.driver_pay.settlements.quantity_placeholder',
                      )}`}
                      type="number"
                      {...register(`addOns.${index}.quantity` as const, {
                        min: 0,
                        setValueAs: (v) => (isNaN(v) ? null : Math.abs(parseInt(v))),
                        validate: {
                          required: (v) => {
                            if (
                              // We only need this field/validation if the add on is a rate for each
                              addOnByAddOnId[selection?.id ?? '']?.addOnRateType ===
                              AddOnRateType.RATE_FOR_EACH
                            ) {
                              return !!v;
                            }
                            return true;
                          },
                        },
                      })}
                      value={quantity === null || isNaN(quantity) ? '' : quantity}
                      error={!!errors.addOns?.[index]?.quantity && !!quantity}
                      sx={{
                        gridArea: 'qty',
                        '& .MuiInputBase-input': {
                          fontSize: '12px',
                        },
                      }}
                    />
                  )}
                  {selection && (
                    <TextField
                      placeholder={`${t(
                        'approvals.driver_pay.settlements.rate_placeholder',
                      )}`}
                      size="small"
                      type="number"
                      {...register(`addOns.${index}.rate` as const, {
                        setValueAs: (v) =>
                          isNaN(v) ? null : parseFloat(parseFloat(v).toFixed(2)),
                        required: 'Rate is required',
                      })}
                      value={rate === null || isNaN(rate) ? '' : rate}
                      error={!!errors.addOns?.[index]?.rate && !!rate}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {addOnByAddOnId[selection?.id ?? '']?.percentage ? '%' : '$'}
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        gridArea: 'rate',
                        '& .MuiInputBase-input': {
                          fontSize: '12px',
                        },
                      }}
                    />
                  )}
                  <Box sx={{ gridArea: 'total', fontSize: '12px' }}>
                    {quantity &&
                    rate &&
                    addOnByAddOnId[selection?.id ?? ''].addOnRateType ===
                      AddOnRateType.RATE_FOR_EACH
                      ? currencyFormatter(quantity * rate)
                      : '$-'}
                  </Box>
                  <IconButton
                    type="button"
                    onClick={() => {
                      remove(index);
                    }}
                    sx={{ gridArea: 'action', justifySelf: 'end' }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              );
            })}
        </Box>
        {isEditing && (
          <Button
            type="button"
            startIcon={<AddIcon />}
            sx={{ fontWeight: 500 }}
            onClick={() => {
              append({ addOnSelection: null, quantity: null, rate: null });
            }}
          >
            {t('approvals.driver_pay.settlements.add_on')}
          </Button>
        )}
        <SettlementPricing settlementId={settlementId} />
      </Box>
    );
  },
);
