import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { LngLat } from '@nbai/nbmap-gl';
import { AuthMethod } from '@treadinc/horizon-api-spec';
import { Polygon, polygon, Position } from '@turf/helpers';
import { t as $t } from 'i18next';
import { capitalize, get, startCase } from 'lodash';
import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { ControllerProps, FieldErrors, useForm } from 'react-hook-form';
import { v4 as UUID } from 'uuid';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { PhoneCodeFlagInput } from '~components/FormFields/PhoneCodeFlagInput';
import { RadioGroupFormField } from '~components/FormFields/RadioGroupFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import HybridBaseMap from '~components/Maps/HybridBaseMap';
import { MarkerProps } from '~components/Maps/interfaces';
import { AllowedAuthMethod, countries, data as enums } from '~constants/enums';
import { TORONTO_OFFICE_COORDINATES } from '~constants/mapConsts';
import { FormStateChangeProps } from '~formsShared';
import { AdminCompany } from '~hooks/admin/useCompaniesAdmin/models';
import { AddressItem } from '~hooks/useAddress';
import { Company, CompanyBasic } from '~hooks/useCompany/models';
import { useGeocode } from '~hooks/useGeocode';
import {
  LIVE_MAP_GEOFENCE_FILL_LAYER_ID,
  LIVE_MAP_PINS_LAYER_ID,
  LIVE_MAP_SITES_LAYER_ID,
  LIVE_MAP_TRUCK_LAYER_ID,
} from '~hooks/useLiveMap/constants';
import { companyCreateSchema } from '~pages/Admin/Companies/companyValidationSchema';
import { ImageUpload } from '~pages/Admin/Companies/ImageUpload';
import { allowedTimezones } from '~utils/dateTime';

const authMethodsOptions: Record<AllowedAuthMethod, string> = {
  magic_link: $t('auth_methods.magic_link'),
  otp_sms: $t('auth_methods.otp_sms'),
};

const extractNonSelectableAuthMethods = (list: AuthMethod[]) => {
  return list.filter((method) => Object.keys(authMethodsOptions).includes(method));
};

const extractNames = (fullName: string) => {
  const parts = fullName.trim().split(/\s+/);
  const lastName = parts.pop();
  const firstName = parts.join(' ');
  return { firstName, lastName };
};

interface CreateCompanyFormProps {
  defaultCompany: Company | AdminCompany | null | undefined;
  onFormStateChange: ({ isValid, isDirty }: FormStateChangeProps) => void;
  onChangeLogo: (logo: any) => void;
  editable?: boolean;
  companyOptions?: CompanyBasic[];
  isAdminPage?: boolean;
}

const CompanyForm = forwardRef(function CompanyForm(
  {
    defaultCompany,
    onFormStateChange,
    onChangeLogo,
    editable = true,
    companyOptions,
    isAdminPage = false,
  }: CreateCompanyFormProps,
  ref: Ref<any>,
) {
  const [defaultSiteGeoFence, setDefaultSiteGeoFence] = useState<Polygon[]>([]);
  const [marker, setMarker] = useState<MarkerProps>();
  const [mapId, setMapId] = useState<string>(UUID());

  const { getPlaces, encodeLngLat } = useGeocode({ addressProvider: 'google_maps' });

  const isEditing = Boolean(defaultCompany?.id);
  const primaryNames = extractNames(get(defaultCompany, 'primaryContact.name', ''));
  const billingNames = extractNames(get(defaultCompany, 'billingContact.name', ''));
  const mapCenter = useMemo(() => {
    if (marker?.lng && marker?.lat)
      return {
        lng: marker.lng,
        lat: marker.lat,
      };
    return TORONTO_OFFICE_COORDINATES;
  }, [marker, TORONTO_OFFICE_COORDINATES]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(companyCreateSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      parentCompany: defaultCompany?.parentCompany || undefined,
      id: defaultCompany?.id || '',
      legalName: defaultCompany?.legalName || '',
      doingBusinessName: defaultCompany?.dbaName || '',
      companyType: get(defaultCompany, 'companyType', null),
      address: {
        streetAddress: get(defaultCompany, 'address.streetAddress', ''),
        premise: get(defaultCompany, 'address.premise', ''),
        locality: get(defaultCompany, 'address.locality', ''),
        administrativeArea: get(defaultCompany, 'address.administrativeArea', ''),
        postalCode: get(defaultCompany, 'address.postalCode', ''),
        country: get(defaultCompany, 'address.country', countries.default),
      },

      defaultLat: defaultCompany?.defaultLat ?? TORONTO_OFFICE_COORDINATES.lat,
      defaultLon: defaultCompany?.defaultLon ?? TORONTO_OFFICE_COORDINATES.lng,

      primaryContact: {
        firstName: primaryNames.firstName,
        lastName: primaryNames.lastName,
        email: get(defaultCompany, 'primaryContact.email', ''),
        phone: get(defaultCompany, 'primaryContact.phone', ''),
      },

      billingContact: {
        firstName: billingNames.firstName,
        lastName: billingNames.lastName,
        email: get(defaultCompany, 'billingContact.email', ''),
        phone: get(defaultCompany, 'billingContact.phone', ''),
      },

      usageStatus: get(defaultCompany, 'usageStatus', enums.usage_status.default),
      billingStatus: get(defaultCompany, 'billingStatus', enums.billing_status.default),
      saasBillingPlan: get(
        defaultCompany,
        'saasBillingPlan',
        enums.saas_billing_plan.default,
      ),

      systemOfMeasure: get(
        defaultCompany,
        'systemOfMeasure',
        enums.system_of_measure.default,
      ),
      unitOfDistance: get(
        defaultCompany,
        'unitOfDistance',
        enums.unit_of_distance.default,
      ),
      language: get(defaultCompany, 'language', enums.language.default),
      dateFormat: get(defaultCompany, 'dateFormat', enums.date_format.default),
      timeFormat: get(defaultCompany, 'timeFormat', enums.time_format.default),
      currency: get(defaultCompany, 'currency', enums.currency.default),
      timeZone: get(defaultCompany, 'timeZone', enums.time_zone.default),
      authMethods: extractNonSelectableAuthMethods(defaultCompany?.authMethods ?? []),
    },
  });

  const [defaultLatLon, setDefaultLatLon] = useState<string>(
    `${getValues('defaultLat')}, ${getValues('defaultLon')}`,
  );
  const [defaultAddress, setDefaultAddress] = useState<AddressItem | string>('');

  useEffect(() => {
    onFormStateChange({ isDirty, isValid });
  }, [isValid, isDirty]);

  // Sets the initial marker
  useEffect(() => {
    handleCoordinateChange(getValues('defaultLat'), getValues('defaultLon'));
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      submit(callBack: any) {
        handleSubmit(callBack)();
      },
      resetForm(callBack?: () => void) {
        reset();
        setDefaultLatLon(`${getValues('defaultLat')}, ${getValues('defaultLon')}`);
        handleEncodeLatLon(getValues('defaultLat'), getValues('defaultLon'));
        handleCoordinateChange(getValues('defaultLat'), getValues('defaultLon'));
        callBack?.();
      },
    }),
    [],
  );

  const handleSelectAddress = (item: AddressItem) => {
    const {
      lat,
      lng,
      streetAddress,
      premise,
      locality,
      administrativeArea,
      postalCode,
      country,
    } = item;

    if (isNaN(lat) || isNaN(lng)) return;

    handleAddressChange(item);
    handleCoordinateChange(item.lat, item.lng);
    setValue('address.streetAddress', streetAddress.split(',')[0], {
      shouldDirty: true,
    });
    setValue('address.premise', premise);
    setValue('address.locality', locality);
    setValue('address.administrativeArea', administrativeArea);
    setValue('address.postalCode', postalCode);
    // @ts-ignore, type is safe, identical even — unsure why it's giving me a type error.
    setValue('address.country', country);

    return streetAddress.split(',')[0];
  };
  // Sets the initial marker
  useEffect(() => {
    handleCoordinateChange(getValues('defaultLat'), getValues('defaultLon'));
  }, []);

  // Sets a new market according to the new coordinates, and updates the form values accordingly
  // This is also called when the user drags the marker
  const handleCoordinateChange = (lat: number, lon: number) => {
    if (isNaN(lat) || isNaN(lon)) return;

    const coordinates: Position[] = Array(5).fill([lon, lat]);

    const polygonFromFenceCoords = polygon([coordinates], {}, { id: defaultCompany?.id });

    setDefaultSiteGeoFence(
      (prev) => [...prev, polygonFromFenceCoords] as unknown as Polygon[],
    );

    const newMarker = {
      id: 'default',
      lng: lon,
      lat: lat,
      draggable: true,
      onDragMarker: (e: LngLat) => {
        handleLatLonChange(`${e.lat},${e.lng}`);
        setValue('defaultLat', lat, { shouldDirty: true });
        setValue('defaultLon', lon, { shouldDirty: true });
        handleCoordinateChange(e.lat, e.lng);
      },
    };

    setDefaultLatLon(`${lat}, ${lon}`);
    setValue('defaultLat', lat);
    setValue('defaultLon', lon);

    setMarker(newMarker);
    setMapId(UUID());
  };

  // Parse the input value to get the lat and lon if the user types them manually
  // Enables the user to input coordinates for their map center and address simultaneously
  const handleInputChange = async (
    e: React.SyntheticEvent<Element, Event> | null,
    value: string,
  ) => {
    const [latStr, lonStr] = value.split(',').map((coord: string) => coord.trim());
    const lat = parseFloat(latStr);
    const lon = parseFloat(lonStr);

    if (isNaN(lat) || isNaN(lon)) return;

    try {
      handleCoordinateChange(lat, lon);
      const newAddress = await encodeLngLat(`${lat},${lon}`);
      setDefaultAddress(newAddress);
      handleSelectAddress(newAddress);
    } catch (error) {
      console.error('Error encoding coordinates:', error);
    }
  };

  useEffect(() => {
    handleEncodeLatLon(getValues('defaultLat'), getValues('defaultLon'));
  }, []);

  const handleEncodeLatLon = async (lat: number, lon: number) => {
    const newAddress = await encodeLngLat(`${lat},${lon}`);
    setDefaultAddress(newAddress);
  };

  const handleAddressChange = (address: AddressItem | string) => {
    setDefaultAddress(address);
    if (typeof address !== 'string') {
      handleCoordinateChange(address.lat, address.lng);
      setDefaultLatLon(`${address.lat}, ${address.lng}`);
    }
  };

  const handleLatLonChange = async (latLon: string) => {
    setDefaultLatLon(latLon);
    const [latStr, lonStr] = latLon.split(',').map((coord: string) => coord.trim());
    const lat = parseFloat(latStr);
    const lon = parseFloat(lonStr);
    if (!isNaN(lat) && !isNaN(lon)) {
      const newAddress = await encodeLngLat(`${lat},${lon}`);
      setDefaultAddress(newAddress);
      handleCoordinateChange(lat, lon);
    }
  };

  return (
    <Box component="form" data-test-id="company-form">
      {companyOptions && (
        <Box display={'flex'} flexDirection={'column'} sx={{ mt: 2 }}>
          <AutocompleteFormField
            control={control}
            name="parentCompany"
            errors={errors}
            list={companyOptions}
            label={`${$t('form_fields.parent_company')}`}
            isRequired={true}
            getValue={(item) => item.id}
            getLabel={(item) => item.legalName || ''}
          />
        </Box>
      )}
      <Box display={'flex'} flexDirection={'column'} sx={{ mt: 4 }}>
        <Typography variant={'h6'} sx={{ mb: 1 }}>
          {$t('company.form.account_information')}
        </Typography>

        <Box display={'flex'} sx={{ mb: 2 }}>
          <TextFormField
            control={control}
            errors={errors}
            disabled={!editable}
            name="legalName"
            label={`${$t('form_fields.legal_name')}`}
            isRequired={true}
          />
        </Box>

        <Box display={'flex'} sx={{ mb: 1 }}>
          <Box display={'flex'} width={'50%'}>
            <TextFormField
              control={control}
              errors={errors}
              disabled={!editable}
              name={'doingBusinessName'}
              label={`${$t('form_fields.company_name')}`}
              isRequired={false}
              sx={{ mr: 2 }}
            />
          </Box>
          <Box width={'50%'}>
            <AutocompleteFormField
              control={control}
              name="companyType"
              errors={errors}
              disabled={!editable}
              list={enums.company_type.values}
              label={`${$t('form_fields.company_type')}`}
              isRequired={true}
              getValue={(item) => item}
              getLabel={(item) => startCase(item)}
            />
          </Box>
        </Box>

        <Box sx={{ mb: 2 }}>
          <AutocompleteAsyncFormField
            control={control}
            errors={errors}
            disabled={!editable}
            name="address.streetAddress"
            getLabel={(item) => get(item, 'streetAddress', '')}
            getValue={(item) => get(item, 'placeId', '')}
            label={`${$t('form_fields.address')}`}
            hint={`${$t('common.address_autocomplete_hint')}`}
            asyncCallback={getPlaces}
            onSelect={handleSelectAddress}
            onInput={(e, val) => {
              handleInputChange(e, val);
            }}
            isRequired={true}
            debounceTime={500}
          />
        </Box>

        <Box sx={{ mb: 2 }}>
          <TextFormField
            control={control}
            errors={errors}
            disabled={!editable}
            name="address.premise"
            label={`${$t('form_fields.address_2')}`}
            isRequired={false}
          />
        </Box>

        <Box sx={{ mb: 2 }}>
          <TextFormField
            control={control}
            errors={errors}
            disabled={!editable}
            name="address.locality"
            label={`${$t('form_fields.city')}`}
            isRequired={true}
          />
        </Box>

        <Box display={'flex'} sx={{ mb: 2 }}>
          <Box display={'flex'} width={'50%'}>
            <TextFormField
              control={control}
              errors={errors}
              disabled={!editable}
              name="address.administrativeArea"
              label={`${$t('form_fields.state')}`}
              isRequired={false}
              sx={{ mr: 2 }}
            />
          </Box>
          <Box display={'flex'} width={'50%'}>
            <TextFormField
              control={control}
              errors={errors}
              disabled={!editable}
              name="address.postalCode"
              label={`${$t('form_fields.zip')}`}
              isRequired={true}
            />
          </Box>
        </Box>

        <Box sx={{ mb: 1 }}>
          <AutocompleteFormField
            control={control}
            name="address.country"
            errors={errors}
            disabled={!editable}
            list={countries.values}
            label={`${$t('form_fields.country')}`}
            isRequired={true}
            getValue={(item) => item.code}
            getLabel={(item) => item.name}
          />
        </Box>

        <Box display={'flex'} sx={{ mb: 2 }}>
          <AutocompleteFormField
            control={control}
            name="timeZone"
            errors={errors}
            disabled={!editable}
            list={allowedTimezones}
            label={`${$t('form_fields.time_zone')}`}
            isRequired={true}
            clearable={false}
            getValue={(item) => item}
            getLabel={(item) => item}
          />
        </Box>

        <Box display={'flex'} flexDirection={'column'} sx={{ mb: 2 }}>
          <Box display={'flex'} sx={{ mb: 2 }} gap={2}>
            <AutocompleteAsyncFormField
              control={control}
              errors={errors}
              disabled={!editable}
              name="defaultAddress"
              getValue={(item) => item}
              getLabel={(item) => item.streetAddress}
              label={`${$t('navigation.default_map_center')}`}
              hint={`${$t('common.address_autocomplete_hint')}`}
              asyncCallback={getPlaces}
              onSelect={(e: AddressItem) => handleAddressChange(e)}
              isRequired={true}
              inputProps={{
                value:
                  typeof defaultAddress === 'string'
                    ? defaultAddress
                    : defaultAddress?.streetAddress || '',
                onInput: (e: React.ChangeEvent<HTMLInputElement>) =>
                  handleAddressChange(e.currentTarget.value),
              }}
            />
            <TextFormField
              control={control}
              name="defaultLatLon"
              errors={errors}
              label={`${$t('navigation.default_map_center')}`}
              inputProps={{
                value: defaultLatLon,
                onInput: (e) => handleLatLonChange(e.currentTarget.value),
              }}
              disabled={!editable}
            />
          </Box>
          <HybridBaseMap
            containerId={mapId}
            center={mapCenter as LngLat}
            layers={[]}
            sources={[]}
            controlsToAdd={['navigation']}
            defaultGeoFences={defaultSiteGeoFence}
            isDrawPolygonControlEnabled={false}
            isTrashDrawControlEnabled={false}
            markers={[marker] as MarkerProps[]}
            clickable={[
              LIVE_MAP_PINS_LAYER_ID,
              LIVE_MAP_TRUCK_LAYER_ID,
              LIVE_MAP_SITES_LAYER_ID,
              LIVE_MAP_GEOFENCE_FILL_LAYER_ID,
            ]}
            zoom={15}
            sx={{ height: '300px' }}
            overlay={
              marker
                ? !editable
                  ? $t('navigation.click_edit_to_change_marker')
                  : null
                : $t('navigation.choose_address')
            }
          />
        </Box>
      </Box>

      <Divider sx={{ my: 2 }} />

      <Box display={'flex'} sx={{ flexDirection: 'column', mb: 2 }}>
        <Typography variant={'h6'} sx={{ mb: 1 }}>
          {$t('company.form.contact_information')}
        </Typography>

        <Box display={'flex'}>
          <Box display={'flex'} width={'50%'}>
            <TextFormField
              control={control}
              errors={errors}
              disabled={!editable}
              name="primaryContact.firstName"
              label={`${$t('form_fields.first_name')}`}
              isRequired={true}
              sx={{ mr: 2 }}
            />
          </Box>
          <Box display={'flex'} width={'50%'}>
            <TextFormField
              control={control}
              errors={errors}
              disabled={!editable}
              name="primaryContact.lastName"
              label={`${$t('form_fields.last_name')}`}
              isRequired={true}
            />
          </Box>
        </Box>

        <Box display={'flex'}>
          <Box display={'flex'} width={'50%'}>
            <TextFormField
              control={control}
              errors={errors}
              disabled={!editable}
              name="primaryContact.email"
              label={`${$t('form_fields.email')}`}
              isRequired={true}
              sx={{ mr: 2 }}
            />
          </Box>
          <Box display={'flex'} width={'50%'}>
            <PhoneCodeFlagInput
              control={control as unknown as ControllerProps['control']}
              errors={errors as unknown as FieldErrors}
              disabled={!editable}
              name="primaryContact.phone"
              label={`${$t('form_fields.phone')}`}
              isRequired={true}
            />
          </Box>
        </Box>
      </Box>

      <Divider sx={{ my: 2 }} />

      <Box display={'flex'} sx={{ flexDirection: 'column', mb: 2 }}>
        <Typography variant={'h6'} sx={{ mb: 1 }}>
          {$t('company.form.billing_contact')}
        </Typography>

        <Box display={'flex'}>
          <Box display={'flex'} width={'50%'}>
            <TextFormField
              control={control}
              errors={errors}
              disabled={!editable}
              name="billingContact.firstName"
              label={`${$t('form_fields.first_name')}`}
              isRequired={true}
              sx={{ mr: 2 }}
            />
          </Box>
          <Box display={'flex'} width={'50%'}>
            <TextFormField
              control={control}
              errors={errors}
              disabled={!editable}
              name="billingContact.lastName"
              label={`${$t('form_fields.last_name')}`}
              isRequired={true}
            />
          </Box>
        </Box>
        <Box display={'flex'} sx={{ mb: 2 }}>
          <Box display={'flex'} width={'50%'}>
            <TextFormField
              control={control}
              errors={errors}
              disabled={!editable}
              name="billingContact.email"
              label={`${$t('form_fields.email')}`}
              isRequired={true}
              sx={{ mr: 2 }}
            />
          </Box>
          <Box display={'flex'} width={'50%'}>
            <PhoneCodeFlagInput
              control={control as unknown as ControllerProps['control']}
              errors={errors as unknown as FieldErrors}
              disabled={!editable}
              name="billingContact.phone"
              label={`${$t('form_fields.phone')}`}
              isRequired={true}
            />
          </Box>
        </Box>
      </Box>

      <Divider sx={{ my: 2 }} />

      <Box display={'flex'} sx={{ flexDirection: 'column', mb: 3 }}>
        <Typography variant={'h6'}>{$t('company.form.company_logo')}</Typography>
        <ImageUpload
          url={defaultCompany?.logoUrl}
          onChange={onChangeLogo}
          sx={{ mt: 1, mb: 1 }}
        />
      </Box>

      {isAdminPage && (
        <>
          <Divider sx={{ my: 2 }} />

          <Box display={'flex'} sx={{ flexDirection: 'column', mb: 2 }}>
            <Typography variant={'h6'} sx={{ mb: 1 }}>
              {$t('company.form.billing_settings')}
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Box display={'flex'} sx={{ mb: 2 }}>
                <Box width={'33.3%'} sx={{ mr: 2 }}>
                  <AutocompleteFormField
                    control={control}
                    name="usageStatus"
                    errors={errors}
                    disabled={!editable}
                    list={enums.usage_status.values}
                    label={`${$t('form_fields.usage_status')}`}
                    isRequired={true}
                    clearable={false}
                    getValue={(item) => item}
                    getLabel={(item) => capitalize(item)}
                  />
                </Box>

                <Box width={'33.3%'} sx={{ mr: 2 }}>
                  <AutocompleteFormField
                    control={control}
                    name="billingStatus"
                    errors={errors}
                    disabled={!editable}
                    list={enums.billing_status.values}
                    label={`${$t('form_fields.billing_status')}`}
                    isRequired={true}
                    clearable={false}
                    getValue={(item) => item}
                    getLabel={(item) => capitalize(item)}
                  />
                </Box>

                <Box width={'33.3%'}>
                  <AutocompleteFormField
                    control={control}
                    name="saasBillingPlan"
                    errors={errors}
                    disabled={!editable}
                    list={enums.saas_billing_plan.values}
                    label={`${$t('form_fields.saas_billing_plan')}`}
                    isRequired={true}
                    clearable={false}
                    getValue={(item) => item}
                    getLabel={(item) => capitalize(item)}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          {isEditing && (
            <>
              <Divider sx={{ my: 2 }} />

              <Box display="flex" flexDirection="column" mb={2}>
                <Typography variant={'h6'} sx={{ mb: 1 }}>
                  {$t('company.form.authentication_settings')}
                </Typography>

                <Box mb={2}>
                  <AutocompleteFormField
                    control={control}
                    name="authMethods"
                    errors={errors}
                    disabled={!editable}
                    list={Object.keys(authMethodsOptions)}
                    label={`${$t('company.form.available_auth_methods')}`}
                    isRequired
                    multiple
                    limitTags={3}
                    clearable={false}
                    getValue={(item) => item}
                    getLabel={(item: AllowedAuthMethod) => authMethodsOptions[item]}
                  />
                </Box>
              </Box>
            </>
          )}
        </>
      )}

      <Box display={'flex'} sx={{ flexDirection: 'column', mb: 3, display: 'none' }}>
        <Typography variant={'h6'}>{$t('company.form.region_settings')}</Typography>
        <Box sx={{ mt: 2 }}>
          <Box display={'flex'} sx={{ mb: 2 }}>
            <Box width={'50%'} sx={{ mr: 2 }}>
              <AutocompleteFormField
                control={control}
                name="timeZone"
                errors={errors}
                disabled={!editable}
                list={enums.time_zone.values}
                label={`${$t('form_fields.time_zone')}`}
                isRequired={true}
                clearable={false}
                getValue={(item) => item}
                getLabel={(item) => item}
              />
            </Box>

            <Box width={'50%'}>
              <AutocompleteFormField
                control={control}
                name="dateFormat"
                errors={errors}
                disabled={!editable}
                list={enums.date_format.values}
                label={`${$t('form_fields.date_format')}`}
                isRequired={true}
                clearable={false}
                getValue={(item) => item}
                getLabel={(item) => item}
              />
            </Box>
          </Box>
        </Box>

        <Box>
          <Box display={'flex'}>
            <Box width={'50%'} sx={{ mr: 2 }}>
              <RadioGroupFormField
                control={control}
                errors={errors}
                disabled={!editable}
                name="timeFormat"
                label={`${$t('form_fields.time_format')}`}
                list={enums.time_format.values}
              />
            </Box>
            <Box width={'50%'} sx={{ mt: 2 }}>
              <AutocompleteFormField
                control={control}
                name="currency"
                errors={errors}
                disabled={!editable}
                list={enums.currency.values}
                label={`${$t('form_fields.currency')}`}
                isRequired={true}
                clearable={false}
                getValue={(item) => item}
                getLabel={(item) => item.toUpperCase()}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box display={'flex'} sx={{ flexDirection: 'column', display: 'none' }}>
        <Typography variant={'h6'}>{$t('company.form.unit_settings')}</Typography>
        <Box display={'flex'} sx={{ mt: 2 }}>
          <Box width={'50%'} sx={{ mr: 2 }}>
            <RadioGroupFormField
              control={control}
              errors={errors}
              disabled={!editable}
              name="unitOfDistance"
              label={`${$t('form_fields.distance')}`}
              list={enums.unit_of_distance.values}
            />
          </Box>
          <Box width={'50%'}>
            <RadioGroupFormField
              control={control}
              errors={errors}
              disabled={!editable}
              name="systemOfMeasure"
              label={`${$t('form_fields.system_of_measure')}`}
              list={enums.system_of_measure.values}
            />
          </Box>
        </Box>
      </Box>

      {/*Submit buttons are moved to the parent component*/}
    </Box>
  );
});
export { CompanyForm };
