import {
  AddOn_Create,
  AddOn_Read,
  AddOn_Update,
  AddOnCharge_Create,
  AddOnCharge_Read,
  AddOnCharge_Update_Destroyable,
  AddOnRateType,
  AddOnType,
  Destroyable,
} from '@treadinc/horizon-api-spec';

import { CompanyBasic } from '~hooks/useCompany';
import {
  AddOnChargeDTO,
  removeAddOnRateTypeFromName,
} from '~pages/Approvals/DriverPay/helpers';
import { AddOnDTO } from '~pages/Settings/RatesManagement/AddOnCharges/components/addOnFormSchema';
import { Nullable } from '~types/Nullable';

export class AddOn {
  constructor(
    private _id: Nullable<string>,
    private _name: string,
    private _add_on_rate_type: AddOnRateType,
    private _add_on_types: Array<AddOnType>,
    private _quantity: number,
    private _rate: number,
    private _external_id: Nullable<string>,
    private _percentage: number,
    private _company: CompanyBasic,
  ) {}

  public get id() {
    return this._id;
  }

  public get name() {
    return this._name;
  }

  public get addOnRateType() {
    return this._add_on_rate_type;
  }

  public get addOnTypes() {
    return this._add_on_types;
  }

  public get quantity() {
    return this._quantity;
  }

  public get rate() {
    return this._rate;
  }

  public get externalId() {
    return this._external_id;
  }

  public get percentage() {
    return this._percentage;
  }

  public get company() {
    return this._company;
  }

  public static deparse(proto: AddOnDTO) {
    const data: AddOn_Create = {
      name: proto.name,
      add_on_rate_type: proto.addOnRateType.id as AddOnRateType,
      add_on_types: proto.addOnTypes.map((i) => i.id as AddOnType),
      quantity: proto.quantity ?? undefined,
      ...(proto.addOnRateType.id === AddOnRateType.RATE_FOR_EACH
        ? { rate: proto.rate ?? undefined }
        : { percentage: proto.rate ?? undefined }),
      external_id: proto.externalId || null,
    };

    return data;
  }

  public static deparseUpdate(proto: AddOnDTO) {
    const data: AddOn_Update = {
      name: proto.name,
      add_on_rate_type: proto.addOnRateType.id as AddOnRateType,
      add_on_types: proto.addOnTypes.map((i) => i.id as AddOnType),
      quantity: proto.quantity ?? undefined,
      ...(proto.addOnRateType.id === AddOnRateType.RATE_FOR_EACH
        ? { rate: proto.rate ?? undefined }
        : { percentage: proto.rate ?? undefined }),
      external_id: proto.externalId || null,
    };

    return data;
  }

  public static parse(proto: AddOn_Read) {
    return new AddOn(
      proto.id,
      proto.name,
      proto.add_on_rate_type,
      proto.add_on_types,
      Number(proto.quantity),
      Number(proto.rate),
      proto.external_id,
      Number(proto.percentage),
      CompanyBasic.parse(proto.company),
    );
  }
}

export class AddOnCharge {
  constructor(
    private _id: Nullable<string>,
    private _name: string,
    private _percentage: number,
    private _quantity: number,
    private _rate: number,
    private _total_amount: number,
    private _add_on_rate_type: AddOnRateType,
    private _external_id: Nullable<string>,
    private _add_on: { id: string },
  ) {}

  public get id() {
    return this._id;
  }

  public get name() {
    return this._name;
  }

  public get percentage() {
    return this._percentage;
  }

  public get quantity() {
    return this._quantity;
  }

  public get rate() {
    return this._rate;
  }

  public get totalAmount() {
    return this._total_amount;
  }

  public get addOnRateType() {
    return this._add_on_rate_type;
  }

  public get externalId() {
    return this._external_id;
  }

  public get addOnId() {
    return this._add_on.id;
  }

  public static parse(proto: AddOnCharge_Read) {
    return new AddOnCharge(
      proto.id ?? null,
      proto.name,
      Number(proto.percentage),
      Number(proto.quantity),
      Number(proto.rate),
      Number(proto.total_amount),
      proto.add_on_rate_type,
      proto.external_id ?? null,
      proto.add_on,
    );
  }

  public static deparse(proto: AddOnChargeDTO & { invoiceId: string }) {
    const rateType = proto.addOnRateType as AddOnRateType;
    const isRateForEachAddOn = rateType === AddOnRateType.RATE_FOR_EACH;

    if (proto.id && proto._destroy === 1) {
      const data: Destroyable = {
        id: proto.id,
        _destroy: 1,
      };

      return data;
    }

    const name = removeAddOnRateTypeFromName(proto.addOn.name);

    if (proto.id) {
      const data: AddOnCharge_Update_Destroyable = {
        id: proto.id,
        add_on_rate_type: rateType,
        name,
        percentage: isRateForEachAddOn ? undefined : proto.value,
        rate: isRateForEachAddOn ? proto.value : undefined,
        quantity: proto.quantity,
      };

      return data;
    }

    const data: AddOnCharge_Create = {
      add_on_id: proto.addOn.id,
      add_on_rate_type: rateType,
      invoice_id: proto.invoiceId,
      name,
      percentage: isRateForEachAddOn ? undefined : proto.value,
      rate: isRateForEachAddOn ? proto.value : undefined,
      quantity: proto.quantity,
    };

    return data;
  }
}
