import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { FileAttachment_Read, JobState } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { LoadCard } from '~components/Cards/LoadCard';
import { Drawer } from '~components/Drawer/src/ui';
import { NotSpecified } from '~components/Helpers/NotSpecified';
import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';
import { OrderUnitOfMeasure } from '~constants/enums';
import { useFileAttachment } from '~hooks/useFileAttachment';
import { Job, useJob } from '~hooks/useJob';
import { JobLoadCycle, JobLoadSummary } from '~hooks/useJob/models';
import { Ticket, TicketEventType, useTickets } from '~hooks/useTickets';
import { TicketFormDTO } from '~pages/Approvals/ApprovalsComponents/ticketFormSchema';
import { TicketDetails } from '~pages/Approvals/TicketsReviewDataGrid/TicketDetails';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { ItemNameAndId } from '~types/ItemNameAndId';

interface Props {
  details: Job;
  sx?: SxProps<Theme>; // Style
  reload: () => void;
}

const Loads = ({ details, sx = {}, reload }: Props) => {
  const { toasterStore, userStore } = useStores();
  const {
    createTicket,
    deleteTicketImageById,
    getAllTicketsByJob,
    updateState,
    updateTicketById,
    uploadTicketImageById,
  } = useTickets();
  const theme = useTheme();
  const [selectedTicket, setSelectedTicket] = useState<{
    isDrawerOpen: boolean;
    loadId?: string;
    ticket?: Ticket;
  }>({ isDrawerOpen: false });
  const imagePendingToBeUploaded = useRef<File>();

  const userPermissions = userStore.getPermissions();

  const [isLoading, setIsLoading] = useState(true);
  const [jobLoads, setJobLoads] = useState<JobLoadSummary[]>([]);
  const [jobTickets, setJobTickets] = useState<Ticket[]>([]);
  const { removeLoad, addLoad, getJobById, getJobLoadCycles, getJobLoads, approveLoad } =
    useJob();
  const { getAllFileAttachmentsById, deleteFileAttachmentById } = useFileAttachment();
  const [cycles, setCycles] = useState<Array<JobLoadCycle>>([]);

  const doAction = async (id: string, action: TicketEventType) => {
    const res = await updateState(id, action);
    toasterStore.push(alert(t('approvals.ticket_updated'), AlertTypes.success));
    return res;
  };

  const saveTicket = async (ticketDto: TicketFormDTO) => {
    let ticket: Ticket;
    const isUpdate = Boolean(ticketDto.id);

    if (isUpdate) {
      ticket = await updateTicketById({
        id: String(ticketDto.id),
        data: {
          quantity: ticketDto.quantity,
          unitOfMeasure: ticketDto.unitOfMeasure,
          material: ticketDto.material.id ? { id: ticketDto.material.id } : undefined,
          ticketNumber: ticketDto.ticketNumber,
          imageUrl: ticketDto.imageUrl,
        },
      });
    } else {
      ticket = await createTicket({
        data: {
          job_id: ticketDto.jobId,
          load_id: selectedTicket.loadId,
          material_id: ticketDto.material.id || undefined,
          quantity: ticketDto.quantity,
          ticket_number: ticketDto.ticketNumber,
          unit_of_measure: ticketDto.unitOfMeasure.id,
        },
      });

      if (imagePendingToBeUploaded.current) {
        ticket = await uploadTicketImageById({
          id: ticket.id,
          file: imagePendingToBeUploaded.current,
        });
      }
    }

    if (ticket) {
      const message = isUpdate ? 'approvals.ticket_updated' : 'approvals.ticket_created';

      toasterStore.push(alert(t(message), AlertTypes.success), false, true);
      setSelectedTicket((state) => ({ ...state, ticket }));
      reload();
    }

    return ticket;
  };

  const handleFlagTicket = async (ticket: Ticket) => {
    ticket.flagged = !ticket.flagged;

    const updatedTicket = await updateTicketById({ id: ticket.id, data: ticket });

    toasterStore.push(
      alert(t('approvals.ticket_updated'), AlertTypes.success),
      false,
      true,
    );
    setSelectedTicket((state) => ({ ...state, ticket: updatedTicket }));
    reload();

    return updatedTicket;
  };

  const onRemoveLoad = async (loadId: string) => {
    await removeLoad(loadId);
    await getJobById(details.id);
    reload();
    toasterStore.push(alert(t('loads.load_removed'), AlertTypes.success));
  };

  const onAddNew = () => {
    addLoad(details.id, {
      quantity: 1,
      unitOfMeasure: {
        id: OrderUnitOfMeasure.LOAD,
        name: OrderUnitOfMeasure.LOAD,
      } as ItemNameAndId,
    })
      .then(() => {
        toasterStore.push(alert(t('loads.load_added'), AlertTypes.success));
      })
      .then(() => {
        getJobById(details.id);
      })
      .then(() => {
        reload();
      });
  };

  const getCycleTime = (load: JobLoadSummary): number => {
    let res = 0;

    if (cycles.length) {
      const cycle = cycles.find((cycle) => cycle.fromLoadId === load.id);
      if (cycle) {
        res = cycle.timeMinutes || 0;
      }
    }

    return res;
  };

  useEffect(() => {
    function getInitialData() {
      const loadCyclesRequest = getJobLoadCycles(details.id).then((res) => {
        setCycles(res);
      });

      const jobLoadsRequest = getJobLoads(details.id).then((response) => {
        setJobLoads(response);
      });

      const jobTicketsRequest = getAllTicketsByJob(details.id).then((response) => {
        setJobTickets(response);
      });

      return Promise.all([loadCyclesRequest, jobLoadsRequest, jobTicketsRequest]);
    }

    setIsLoading(true);
    getInitialData().then(() => setIsLoading(false));
  }, [details]);

  useEffect(() => {
    if (!selectedTicket.isDrawerOpen) {
      imagePendingToBeUploaded.current = undefined;

      // remove added attachments if the drawer was closed without saving the ticket
      const shouldRemoveAttachments = Boolean(
        !selectedTicket.ticket?.id && selectedTicket.loadId,
      );

      if (shouldRemoveAttachments) {
        getAllFileAttachmentsById(
          String(selectedTicket.loadId),
          FileAttachment_Read.file_attachable_type.LOAD,
        ).then((attachments) => {
          attachments.map((attachment) => deleteFileAttachmentById(attachment.id));
        });
      }
    }
  }, [selectedTicket.isDrawerOpen, selectedTicket.loadId, selectedTicket.ticket?.id]);

  const allLoadsApprovable = useMemo(() => {
    return !jobLoads.length
      ? false
      : jobLoads.every((load) => {
          return load.approvable;
        });
  }, [jobLoads]);

  const onApproveAllLoads = () => {
    Promise.all(
      jobLoads.map((load) => {
        if (load.approvable) {
          return approveLoad(load.id);
        }
        return null;
      }),
    ).then(() => {
      toasterStore.push(alert(t('approvals.loads_approved'), AlertTypes.success));
      reload();
    });
  };

  return (
    <>
      <Box
        sx={{
          px: 1.5,
          mt: 1.5,
        }}
      >
        {/* header */}

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            mb: 1,
          }}
        >
          {' '}
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 600,
            }}
          >
            {t('approvals.driver_day.loads')}
          </Typography>
          {allLoadsApprovable && (
            <Button
              variant="contained"
              size="small"
              sx={{
                fontSize: '12px',
              }}
              onClick={onApproveAllLoads}
            >
              {t('approvals.driver_day.approve_loads')}
            </Button>
          )}
        </Box>

        {jobLoads.length ? (
          <>
            <Grid
              container
              columnSpacing={1}
              rowSpacing={0}
              sx={{
                pb: '4px',
                my: 0.5,
                fontWeight: 'bold',
                borderBottom: `1px solid ${theme.brandV2.colors.treadGray7}`,
              }}
            >
              <Grid item xs={1} sx={{ pl: 1 }}>
                <Typography variant={'body2'} sx={{ fontWeight: 600 }}>
                  {t('loads.number')}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant={'body2'} sx={{ fontWeight: 600 }}>
                  {t('loads.status_and_cycle')}
                </Typography>
              </Grid>
              <Grid item xs={1} sx={{ '&.MuiGrid-item': { pl: 0.5 } }}>
                <Typography variant={'body2'} sx={{ fontWeight: 600 }}>
                  {t('loads.alerts')}
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ '&.MuiGrid-item': { pl: 0.5 } }}>
                <Typography variant={'body2'} sx={{ fontWeight: 600 }}>
                  {t('loads.ticket')}
                </Typography>
              </Grid>
              <Grid item xs={1.5}>
                <Typography variant={'body2'} sx={{ fontWeight: 600 }}>
                  {t('loads.pickup_entry')}
                </Typography>
              </Grid>
              <Grid item xs={1.5}>
                <Typography variant={'body2'} sx={{ fontWeight: 600 }}>
                  {t('loads.dropoff_entry')}
                </Typography>
              </Grid>
              <Grid item xs={1.5} sx={{ '&.MuiGrid-item': { pl: 0 } }} />
              <Grid item xs={0.5} sx={{ '&.MuiGrid-item': { pl: 0 } }} />
            </Grid>

            {jobLoads.map((load, idx) => {
              const currentTicket = jobTickets.find((ticket) => {
                return ticket.loadId === load.id;
              });

              return (
                <LoadCard
                  key={load.id}
                  index={idx}
                  cycleTime={getCycleTime(load)}
                  deletable={userPermissions.canDeleteLoad}
                  load={load}
                  onClick={() => {
                    setSelectedTicket({
                      isDrawerOpen: true,
                      loadId: load.id,
                      ticket: currentTicket,
                    });
                  }}
                  onRemoveLoad={onRemoveLoad}
                  reload={reload}
                  ticket={currentTicket || null}
                  isLast={idx === jobLoads.length - 1}
                />
              );
            })}
          </>
        ) : (
          <NotSpecified />
        )}
        {userPermissions.canCreateLoad &&
          ![JobState.REJECTED, JobState.COMPLETED, JobState.CANCELED].includes(
            details.status,
          ) && (
            <Box mt={2}>
              <SmallButton
                onClick={onAddNew}
                color={'brandV2Yellow'}
                disabled={isLoading}
              >
                {`+ ${t('loads.add')}`}
              </SmallButton>
            </Box>
          )}
      </Box>

      <Drawer
        anchor="right"
        onClose={() => setSelectedTicket((state) => ({ ...state, isDrawerOpen: false }))}
        open={selectedTicket.isDrawerOpen}
        variant="temporary"
      >
        <TicketDetails
          job={details}
          loadId={selectedTicket.loadId}
          ticket={selectedTicket.ticket}
          onFlag={handleFlagTicket}
          onApprove={async (ticket) => {
            const approvedTicket = await doAction(ticket.id, 'approve');

            if (approvedTicket) {
              setSelectedTicket((state) => ({ ...state, ticket: approvedTicket }));
              reload();
            }
          }}
          onRemoveImage={async (ticket) => {
            await deleteTicketImageById({ id: ticket.id });
            const newTicket = ticket;
            newTicket.imageUrl = '';
            setSelectedTicket((state) => ({ ...state, ticket: newTicket }));
          }}
          onUploadImage={async (ticket, image) => {
            if (ticket) {
              const updatedTicket = await uploadTicketImageById({
                id: ticket.id,
                file: image,
              });

              if (updatedTicket) {
                setSelectedTicket((state) => ({ ...state, ticket: updatedTicket }));
              }
            } else {
              imagePendingToBeUploaded.current = image;
            }
          }}
          onChange={saveTicket}
          onClose={() => {
            setSelectedTicket((state) => ({ ...state, isDrawerOpen: false }));
          }}
        />
      </Drawer>
    </>
  );
};

export { Loads };
