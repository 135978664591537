import { DndContext, DragOverlay } from '@dnd-kit/core';
import ContentCopyOutlined from '@mui/icons-material/ContentCopyOutlined';
import PersonAddOutlined from '@mui/icons-material/PersonAddOutlined';
import SendOutlined from '@mui/icons-material/SendOutlined';
import SmsOutlined from '@mui/icons-material/SmsOutlined';
import { SxProps, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import {
  OrderState,
  UserBulkAssignJobsChannel_Read,
  UserBulkCopyAssignmentsChannel_Read,
  UserBulkSendJobsChannel_Read,
} from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';

import { ModalDialog, ModalDialogHandler } from '~components/Dialog/ModalDialog';
import {
  BulkAssignJobsDialog,
  BulkSendJobsDialog,
} from '~components/Job/JobsDataGridBulkActions';
import SendTextDialog from '~components/Job/SendTextDialog';
import { CommonLoader } from '~components/Loaders/CommonLoader';
import {
  makeOrderDispatchCardTemplateColumns,
  orderDispatchColumns,
  OrdersDispatchColumnKey,
} from '~components/Order/ordersDispatchColumns';
import {
  Column,
  HeaderPanel,
  LoadingSpinner,
  OrdersViewBulkActionsBarButton,
  OverflowAwareText,
  SmallButton,
} from '~components/Order/ordersDispatchStyledComponents';
import { FetchReason } from '~constants/enums';
import { FeatureFlags } from '~constants/featureFlags';
import { Order, useOrdersDispatch } from '~hooks/useOrders';
import { useStores } from '~store';
import { Filters as OrdersDispatchStoreFilters } from '~store/OrdersDispatchStore';
import theme from '~theme/AppTheme';
import { alert, AlertTypes } from '~types/AlertTypes';
import { useAwaitableFeatureFlag } from '~utils/hooks/useFeatureFlag';
import useInfiniteScroll from '~utils/hooks/useInfiniteScroll';
import { usePrevious } from '~utils/hooks/usePrevious';

import { INewDispatchViewComponent } from '../NewDispatch';
import BulkAssignMultipleJobsDialog from './BulkAssignMultipleJobsDialog';
import CopyVendorAssignmentsDialog from './CopyVendorAssignmentsDialog';
import DriverPanelAssigneeCard from './DriverPanelAssigneeCard';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from './drivers/constants';
import OrderDispatchCard, {
  ExpandedState,
  ORDER_DISPATCH_CARD_BORDER_WIDTH_IN_PX,
  ORDER_DISPATCH_CARD_DEFAULT_EXPANDED_STATE,
  ORDER_DISPATCH_CARD_INNER_GUTTER_SIZE,
} from './OrderDispatchCard';
import OrderForm from './OrderForm';
import OrdersDispatchFiltersBar from './OrdersDispatchFiltersBar';
import OrdersViewBulkActionsBar, {
  useOrdersViewBulkActionsBar,
} from './OrdersViewBulkActionsBar';
import OrdersViewDriverPanel, {
  DRIVER_PANEL_SIDEBAR_ANIMATION_IN_MS,
} from './OrdersViewDriverPanel';

const infiniteScrollOptions: IntersectionObserverInit = {
  threshold: 0.1,
  rootMargin: '500px',
} as const;

enum OrderActionDialog {
  BULK_ASSIGN_MULTIPLE = 'bulk_assign_multiple',
  CANCEL_ORDER = 'cancel_order',
  CLONE_ORDER = 'clone_order',
  FORM = 'form',
  TEXT_ALL_DRIVERS = 'text_all_drivers',
}

type OrderActionDialogState = {
  dialog?: OrderActionDialog;
  isOpen: boolean;
  order?: Order;
};

export const ORDERS_DISPATCH_HEADER_ROW_HEIGHT_IN_PX = 35;
export const ORDER_DISPATCH_CARDS_CONTAINER_GUTTER_SIZE = 1.5;
export const LEFT_COLUMN_WIDTH_IN_PX = 240;
const LEFT_COLUMN_COLLAPSED_WIDTH_IN_PX = 42;
const FETCH_ORDERS_REQUIRED_FILTERS: Array<keyof OrdersDispatchStoreFilters> = [
  'orderStates',
  'startDate',
  'endDate',
  'search',
];

const areAllRequiredFiltersInPlace = (currentFilters: OrdersDispatchStoreFilters) => {
  const isSearching = Boolean(currentFilters.search?.length);

  if (isSearching) {
    return true;
  }

  return FETCH_ORDERS_REQUIRED_FILTERS.every((filter) => filter in currentFilters);
};

interface OrdersViewProviderProps extends INewDispatchViewComponent {}

function OrdersViewProvider(props: OrdersViewProviderProps) {
  const nonRoutableAlertsFeatureFlag = useAwaitableFeatureFlag({
    featureFlagKey: FeatureFlags.nonRoutableAlerts,
  });
  const newOrderFormFeatureFlag = useAwaitableFeatureFlag({
    featureFlagKey: FeatureFlags.newOrderForm,
  });
  const bulkAssignMultipleFeatureFlag = useAwaitableFeatureFlag({
    featureFlagKey: FeatureFlags.bulkAssignMultiple,
  });

  const isLoadingFeatureFlags =
    !nonRoutableAlertsFeatureFlag.isReady ||
    !newOrderFormFeatureFlag.isReady ||
    !bulkAssignMultipleFeatureFlag.isReady;

  if (isLoadingFeatureFlags) {
    return props.children(null, <CommonLoader />);
  }

  return (
    <OrdersView
      {...props}
      newOrderFormFeatureFlagEnabled={newOrderFormFeatureFlag.isFeatureFlagEnabled}
      nonRoutableAlertsFeatureFlagEnabled={
        nonRoutableAlertsFeatureFlag.isFeatureFlagEnabled
      }
      bulkAssignMultipleFeatureFlagEnabled={
        bulkAssignMultipleFeatureFlag.isFeatureFlagEnabled
      }
    />
  );
}

interface OrdersViewProps extends INewDispatchViewComponent {
  bulkAssignMultipleFeatureFlagEnabled?: boolean;
  newOrderFormFeatureFlagEnabled?: boolean;
  nonRoutableAlertsFeatureFlagEnabled?: boolean;
}

const OrdersView = observer(
  ({
    bulkAssignMultipleFeatureFlagEnabled,
    children,
    newOrderFormFeatureFlagEnabled,
    nonRoutableAlertsFeatureFlagEnabled,
  }: OrdersViewProps) => {
    const infiniteScroll = useInfiniteScroll(infiniteScrollOptions);
    const { ordersDispatchStore, userStore, toasterStore } = useStores();
    const {
      acceptOrder,
      bulkAssignJobs,
      bulkCopyAssignments,
      bulkSendJobs,
      cancelOrder,
      cloneOrder,
      completeOrder,
      createJobFromOrder,
      getCompanyOrders,
      handleAssigneeDragCancel,
      handleAssigneeDragEnd,
      handleAssigneeDragStart,
      rejectOrder,
      subscribeToBulkAssignJobsRTU,
      subscribeToBulkCopyAssignmentsRTU,
      subscribeToBulkSendJobsRTU,
      subscribeToJobsRTU,
      subscribeToOrdersRTU,
    } = useOrdersDispatch();
    const [orderCardsExpandedState, setOrderCardsExpandedState] = useState({
      allCards: ORDER_DISPATCH_CARD_DEFAULT_EXPANDED_STATE,
      cardsByOrder: new Map<string, ExpandedState>(),
    });
    const [ordersFetchState, setOrdersFetchState] = useState({
      initialFetchComplete: false,
      reason: FetchReason.INITIAL_FETCH,
      shouldFetch: false,
    });
    const [orderActionDialog, setOrderActionDialog] = useState<OrderActionDialogState>({
      isOpen: false,
    });

    const cloneOrderDialogRef = useRef<ModalDialogHandler>(null);
    const cancelOrderDialogRef = useRef<ModalDialogHandler>(null);

    const bulkActions = useOrdersViewBulkActionsBar(ordersDispatchStore.orderJobs);
    const isBulkActionsBarVisible = bulkActions.jobsCount > 0;
    const hasChildCompanies = userStore.userCompanies.length > 1;

    const companyId = userStore.currentCompanies[0].id || userStore.userCompany?.id;
    const isReadyToFetchOrders = areAllRequiredFiltersInPlace(
      ordersDispatchStore.filters,
    );

    const currentFilters = JSON.stringify(
      ordersDispatchStore.filters,
      // this is to presever end & start dates when "All Dates" filters is selected, as
      // we want to keep track of the whole set of filters applied
      (_, value) => value ?? null,
    );
    const previousFilters = usePrevious(currentFilters);

    const openOrderActionDialog = useCallback(
      (dialog: OrderActionDialog, order?: Order) => {
        setOrderActionDialog((state) => ({ ...state, isOpen: true, dialog, order }));

        if (dialog === OrderActionDialog.CLONE_ORDER) {
          cloneOrderDialogRef.current?.open();
        } else if (dialog === OrderActionDialog.CANCEL_ORDER) {
          cancelOrderDialogRef.current?.open();
        }
      },
      [],
    );

    const closeOrderActionDialog = useCallback(() => {
      setOrderActionDialog((state) => ({ ...state, isOpen: false }));
      cloneOrderDialogRef.current?.close();
      cancelOrderDialogRef.current?.close();
      bulkActions.closeDialog('bulkTextAllDrivers');
    }, []);

    const handleAcceptOrder = useCallback(async (orderId: string) => {
      const acceptedOrder = await acceptOrder(orderId);

      toasterStore.push(
        alert(
          t('dispatch.order.updated', { name: acceptedOrder.orderId }),
          AlertTypes.success,
        ),
        false,
        true,
      );
    }, []);

    const handleCardExpandedStateChange = useCallback((orderId: string) => {
      setOrderCardsExpandedState((state) => {
        const { allCards, cardsByOrder } = state;
        const currentExpandedState = cardsByOrder.get(orderId) ?? allCards;
        let newExpandedState = ExpandedState.CLOSED;

        if (currentExpandedState === ExpandedState.CLOSED) {
          newExpandedState = ExpandedState.EXPANDED;
        } else if (currentExpandedState === ExpandedState.EXPANDED) {
          newExpandedState = ExpandedState.OPEN;
        }

        const newCardsByOrder = new Map(cardsByOrder);
        newCardsByOrder.set(orderId, newExpandedState);

        return { ...state, cardsByOrder: newCardsByOrder };
      });
    }, []);

    const handleAllCardsExpandedStateChange = useCallback(
      (expandedState: ExpandedState) => {
        setOrderCardsExpandedState({ allCards: expandedState, cardsByOrder: new Map() });
      },
      [],
    );

    const handleBulkAssignJobsUpdateReceived = useCallback(
      (data: UserBulkAssignJobsChannel_Read) => {
        toasterStore.push(
          alert(
            t('dispatch.order.bulk_jobs_sent', {
              success_num: data.assigned_job_friendly_ids?.length,
              failed_num: data.failed_job_friendly_ids?.length,
              failed_ids: ` ${data.failed_job_friendly_ids?.map((v) => `TRD-${v}`).join(', ')}`,
            }),
            data.failed_job_friendly_ids?.length || 0 > 0
              ? AlertTypes.error
              : AlertTypes.success,
          ),
          false,
          true,
        );
      },
      [],
    );

    const handleBulkCopyAssignmentsUpdateReceived = useCallback(
      (data: UserBulkCopyAssignmentsChannel_Read) => {
        toasterStore.push(
          alert(
            t('dispatch.order.bulk_jobs_sent', {
              success_num: data.copied_job_friendly_ids?.length,
              failed_num: data.failed_job_friendly_ids?.length,
              failed_ids: ` ${data.failed_job_friendly_ids?.map((v) => `TRD-${v}`).join(', ')}`,
            }),
            data.failed_job_friendly_ids?.length || 0 > 0
              ? AlertTypes.error
              : AlertTypes.success,
          ),
          false,
          true,
        );
      },
      [],
    );

    const handleBulkSendJobsUpdateReceived = useCallback(
      (data: UserBulkSendJobsChannel_Read) => {
        toasterStore.push(
          alert(
            t('dispatch.order.bulk_jobs_sent', {
              success_num: data.sent_job_friendly_ids?.length,
              failed_num: data.failed_job_friendly_ids?.length,
              failed_ids: ` ${data.failed_job_friendly_ids?.map((v) => `TRD-${v}`).join(', ')}`,
            }),
            data.failed_job_friendly_ids?.length || 0 > 0
              ? AlertTypes.error
              : AlertTypes.success,
          ),
          false,
          true,
        );
      },
      [],
    );

    const handleCancelOrder = useCallback(
      async (orderId: string) => {
        try {
          const cancelledOrder = await cancelOrder(orderId);

          toasterStore.push(
            alert(
              t('dispatch.order.canceled', { name: cancelledOrder.orderId }),
              AlertTypes.success,
            ),
            false,
            true,
          );
        } finally {
          closeOrderActionDialog();
        }
      },
      [closeOrderActionDialog],
    );

    const handleCloneOrder = useCallback(
      async (orderId: string, includeAssignees: boolean) => {
        let clonedOrder: Order | null = null;

        try {
          clonedOrder = await cloneOrder(orderId, includeAssignees);

          toasterStore.push(
            alert(
              t('dispatch.order.copied', { name: clonedOrder.orderId }),
              AlertTypes.success,
            ),
            false,
            true,
          );
        } finally {
          closeOrderActionDialog();

          if (clonedOrder?.id) {
            openOrderActionDialog(OrderActionDialog.FORM, clonedOrder);
          }
        }
      },
      [closeOrderActionDialog, openOrderActionDialog],
    );

    const handleCompleteOrder = useCallback(async (orderId: string) => {
      const completedOrder = await completeOrder(orderId);

      toasterStore.push(
        alert(
          t('dispatch.order.completed', { name: completedOrder.orderId }),
          AlertTypes.success,
        ),
        false,
        true,
      );
    }, []);

    const handleCreateJobFromOrder = useCallback(async (orderId: string) => {
      const createdJob = await createJobFromOrder(orderId);

      toasterStore.push(
        alert(
          t('dispatch.order.added_job', { name: createdJob.jobId }),
          AlertTypes.success,
        ),
      );
    }, []);

    const handleRefreshOrdersOnScreenClick = useCallback(() => {
      ordersDispatchStore.dismissOrdersPendingToBeShown();
    }, []);

    const handleRejectOrder = useCallback(async (orderId: string) => {
      const rejectedOrder = await rejectOrder(orderId);

      toasterStore.push(
        alert(
          t('dispatch.order.updated', { name: rejectedOrder.orderId }),
          AlertTypes.success,
        ),
        false,
        true,
      );
    }, []);

    const requestInFlight = useRef(false);
    const fetchOrders = () => {
      if (requestInFlight.current) {
        return;
      }

      requestInFlight.current = true;

      if (ordersFetchState.reason === FetchReason.FILTERS_CHANGED) {
        handleAllCardsExpandedStateChange(ORDER_DISPATCH_CARD_DEFAULT_EXPANDED_STATE);
      }

      getCompanyOrders().then(() => {
        infiniteScroll.notify();
        requestInFlight.current = false;

        setOrdersFetchState((state) => ({
          ...state,
          shouldFetch: false,
          initialFetchComplete: true,
        }));
      });
    };

    useEffect(() => {
      let ordersRTUSubscription: ReturnType<typeof subscribeToOrdersRTU> | null = null;
      let jobsRTUSubscription: ReturnType<typeof subscribeToJobsRTU> | null = null;
      let bulkSendJobsRTUSubscription: ReturnType<
        typeof subscribeToBulkSendJobsRTU
      > | null = null;
      let bulkAssignJobsRTUSubscription: ReturnType<
        typeof subscribeToBulkAssignJobsRTU
      > | null = null;
      let bulkCopyAssignmentsRTUSubscription: ReturnType<
        typeof subscribeToBulkCopyAssignmentsRTU
      > | null = null;

      if (companyId) {
        ordersRTUSubscription = subscribeToOrdersRTU(companyId);
        jobsRTUSubscription = subscribeToJobsRTU(companyId);
        bulkSendJobsRTUSubscription = subscribeToBulkSendJobsRTU(
          companyId,
          handleBulkSendJobsUpdateReceived,
        );
        bulkAssignJobsRTUSubscription = subscribeToBulkAssignJobsRTU(
          companyId,
          handleBulkAssignJobsUpdateReceived,
        );
        bulkCopyAssignmentsRTUSubscription = subscribeToBulkCopyAssignmentsRTU(
          companyId,
          handleBulkCopyAssignmentsUpdateReceived,
        );
      }

      return () => {
        ordersRTUSubscription?.unsubscribe?.();
        jobsRTUSubscription?.unsubscribe?.();
        bulkSendJobsRTUSubscription?.unsubscribe?.();
        bulkAssignJobsRTUSubscription?.unsubscribe?.();
        bulkCopyAssignmentsRTUSubscription?.unsubscribe?.();
      };
    }, [companyId]);

    useEffect(() => {
      if (companyId) {
        setOrdersFetchState({
          initialFetchComplete: false,
          reason: FetchReason.INITIAL_FETCH,
          shouldFetch: true,
        });

        fetchOrders();
      }
    }, [companyId]);

    useEffect(() => {
      if (ordersFetchState.shouldFetch) {
        if (ordersFetchState.reason === FetchReason.FILTERS_CHANGED) {
          bulkActions.clearAllCheckedOrdersAndJobs();
        }

        fetchOrders();
      }
    }, [ordersFetchState.shouldFetch, ordersFetchState.reason]);

    useEffect(() => {
      if (!isReadyToFetchOrders) {
        return;
      }

      setOrdersFetchState((state) => {
        if (!state.initialFetchComplete) {
          return { ...state, shouldFetch: true, reason: FetchReason.INITIAL_FETCH };
        }

        if (infiniteScroll.endReached && ordersDispatchStore.hasMore) {
          return { ...state, shouldFetch: true, reason: FetchReason.INFINITE_SCROLL };
        }

        if (currentFilters !== previousFilters) {
          return { ...state, shouldFetch: true, reason: FetchReason.FILTERS_CHANGED };
        }

        return { ...state, shouldFetch: false };
      });
    }, [
      isReadyToFetchOrders,
      currentFilters,
      infiniteScroll.endReached,
      ordersDispatchStore.hasMore,
      previousFilters,
    ]);

    const isSidebarCollapsed = ordersDispatchStore.isSidebarCollapsed;
    const driversColumnSx: SxProps = {
      '&.MuiBox-root': {
        borderRight: isSidebarCollapsed
          ? 'none'
          : `solid 1px ${theme.brandV2.colors.treadGray7}`,
        gridRow: isSidebarCollapsed ? 'span 1' : 'span 2',
        transition: `all ${DRIVER_PANEL_SIDEBAR_ANIMATION_IN_MS}ms`,
        width: `${isSidebarCollapsed ? LEFT_COLUMN_COLLAPSED_WIDTH_IN_PX : LEFT_COLUMN_WIDTH_IN_PX}px`,
      },
    };
    const columnsHeaderGutter = `calc(${theme.spacing(ORDER_DISPATCH_CARDS_CONTAINER_GUTTER_SIZE)} + ${theme.spacing(ORDER_DISPATCH_CARD_INNER_GUTTER_SIZE)} + ${ORDER_DISPATCH_CARD_BORDER_WIDTH_IN_PX}px)`;

    const isLargeViewport = useMediaQuery(theme.breakpoints.up('md'));

    // derive sidebar collapsed state from viewport size on first render
    useEffect(() => {
      if (_.isNil(isSidebarCollapsed)) {
        ordersDispatchStore.toggleSidebar(!isLargeViewport);
      }
    }, [isSidebarCollapsed, isLargeViewport]);

    return children(
      <OrdersDispatchFiltersBar
        expandedState={orderCardsExpandedState.allCards}
        onExpandedStateChange={handleAllCardsExpandedStateChange}
        onNewOrderClick={() => openOrderActionDialog(OrderActionDialog.FORM)}
      />,
      <>
        <DndContext
          onDragCancel={handleAssigneeDragCancel}
          onDragEnd={handleAssigneeDragEnd}
          onDragStart={handleAssigneeDragStart}
        >
          <Box
            display="grid"
            gridAutoRows={`${ORDERS_DISPATCH_HEADER_ROW_HEIGHT_IN_PX}px 1fr`}
            gridTemplateColumns="auto 1fr"
            height="100%"
            maxHeight={`calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px)`}
            overflow="hidden"
          >
            <Box sx={driversColumnSx}>
              <OrdersViewDriverPanel />
            </Box>

            <HeaderPanel
              display="grid"
              gridTemplateColumns={makeOrderDispatchCardTemplateColumns(
                isSidebarCollapsed,
              )}
              sx={
                isSidebarCollapsed
                  ? { pr: columnsHeaderGutter, pl: 0 }
                  : { px: columnsHeaderGutter }
              }
            >
              {Object.entries(orderDispatchColumns)
                .filter(([, column]) => !column.condition || column.condition())
                .map(([key, column]) => (
                  <Column key={key} columnKey={key as OrdersDispatchColumnKey} isHeader>
                    <OverflowAwareText fontWeight={600}>{column.title}</OverflowAwareText>
                  </Column>
                ))}
            </HeaderPanel>

            <Box
              columnGap={ORDER_DISPATCH_CARDS_CONTAINER_GUTTER_SIZE}
              display="flex"
              flexDirection="column"
              gridColumn={isSidebarCollapsed ? 'span 2' : undefined}
              overflow="auto"
              p={ORDER_DISPATCH_CARDS_CONTAINER_GUTTER_SIZE}
              rowGap={2}
            >
              <LoadingSpinner
                isVisible={
                  ordersDispatchStore.orders.length === 0 &&
                  ordersDispatchStore.isLoadingOrders
                }
              />

              {ordersDispatchStore.orders.map((order) => (
                <OrderDispatchCard
                  key={order.id}
                  order={order}
                  companyHasChildCompanies={hasChildCompanies}
                  expandedState={
                    orderCardsExpandedState.cardsByOrder.get(order.id) ??
                    orderCardsExpandedState.allCards
                  }
                  checkedJobs={bulkActions.checkedOrdersAndJobs[order.id]}
                  isChecked={order.id in bulkActions.checkedOrdersAndJobs}
                  nonRoutableAlertsFeatureFlagEnabled={
                    nonRoutableAlertsFeatureFlagEnabled
                  }
                  onBulkAssignMultipleClick={
                    bulkAssignMultipleFeatureFlagEnabled
                      ? () => {
                          openOrderActionDialog(
                            OrderActionDialog.BULK_ASSIGN_MULTIPLE,
                            order,
                          );
                        }
                      : undefined
                  }
                  onAcceptOrder={() => handleAcceptOrder(order.id)}
                  onCheckedStateChange={bulkActions.updateCheckedOrdersAndJobs}
                  onCloneOrderClick={() => {
                    openOrderActionDialog(OrderActionDialog.CLONE_ORDER, order);
                  }}
                  onCreateJobFromOrderClick={() => handleCreateJobFromOrder(order.id)}
                  onEditClick={() => openOrderActionDialog(OrderActionDialog.FORM, order)}
                  onExpandedStateChange={handleCardExpandedStateChange}
                  onOrderStateChange={(nextOrderState) => {
                    if (nextOrderState === OrderState.CANCELED) {
                      openOrderActionDialog(OrderActionDialog.CANCEL_ORDER, order);
                    } else if (nextOrderState === OrderState.COMPLETED) {
                      handleCompleteOrder(order.id);
                    }

                    return Promise.resolve();
                  }}
                  onRejectOrder={() => handleRejectOrder(order.id)}
                  onTextAllDriversClick={() => {
                    openOrderActionDialog(OrderActionDialog.TEXT_ALL_DRIVERS, order);
                  }}
                />
              ))}

              {ordersDispatchStore.hasMore ? (
                <Box ref={infiniteScroll.nodeRef}>
                  <LoadingSpinner isVisible={ordersDispatchStore.isLoadingOrders} />
                </Box>
              ) : ordersDispatchStore.isLoadingOrders ? null : (
                <Box
                  display="flex"
                  justifyContent="center"
                  position="relative"
                  sx={{
                    '&::before': {
                      backgroundColor: theme.brandV2.colors.treadGray6,
                      content: "''",
                      display: 'block',
                      height: '1px',
                      left: 0,
                      position: 'absolute',
                      right: 0,
                      top: '50%',
                      transform: 'translateY(-50%)',
                    },
                  }}
                >
                  <Typography
                    bgcolor={theme.brandV2.colors.treadGray8}
                    color={theme.brandV2.colors.treadGray6}
                    fontSize="12px"
                    fontWeight="700"
                    position="relative"
                    px={1}
                    textAlign="center"
                    textTransform="uppercase"
                  >
                    {ordersDispatchStore.orders.length === 0
                      ? t('common.no_data')
                      : t('dispatch.dispatch_v2.all_orders_loaded')}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>

          <DragOverlay>
            {ordersDispatchStore.draggedAssignee ? (
              <DriverPanelAssigneeCard
                assignee={ordersDispatchStore.draggedAssignee}
                placement="dragging"
              />
            ) : null}
          </DragOverlay>
        </DndContext>

        {isBulkActionsBarVisible && (
          <OrdersViewBulkActionsBar
            jobsCount={bulkActions.jobsCount}
            onClearAll={bulkActions.clearAllCheckedOrdersAndJobs}
          >
            <OrdersViewBulkActionsBarButton
              color="inherit"
              onClick={() => bulkActions.openDialog('copyVendorAssignments')}
              startIcon={<ContentCopyOutlined />}
            >
              {t('actions.copy_to')}
            </OrdersViewBulkActionsBarButton>

            <OrdersViewBulkActionsBarButton
              color="inherit"
              disabled={bulkActions.jobsWithTextableDrivers.length === 0}
              onClick={() => bulkActions.openDialog('bulkTextAllDrivers')}
              startIcon={<SmsOutlined />}
            >
              {t('actions.text')}
            </OrdersViewBulkActionsBarButton>

            <OrdersViewBulkActionsBarButton
              color="inherit"
              disabled={bulkActions.assignableJobs.length === 0}
              onClick={() => bulkActions.openDialog('assign')}
              startIcon={<PersonAddOutlined />}
            >
              {t('actions.assign')}
            </OrdersViewBulkActionsBarButton>

            <OrdersViewBulkActionsBarButton
              color="brandV2Green"
              disabled={bulkActions.sendableJobs.length === 0}
              onClick={() => bulkActions.openDialog('send')}
              startIcon={<SendOutlined />}
            >
              {t('actions.send')}
            </OrdersViewBulkActionsBarButton>
          </OrdersViewBulkActionsBar>
        )}

        <OrderForm
          isOpen={
            orderActionDialog.isOpen &&
            orderActionDialog.dialog === OrderActionDialog.FORM
          }
          newOrderFormFeatureFlagEnabled={newOrderFormFeatureFlagEnabled}
          onClose={closeOrderActionDialog}
          order={orderActionDialog.order}
        />

        <BulkSendJobsDialog
          isOpen={bulkActions.dialogsState.send.isOpen}
          onClose={() => bulkActions.closeDialog('send')}
          onConfirm={() => {
            if (bulkActions.sendableJobs.length) {
              bulkSendJobs(bulkActions.sendableJobs, []);
            }

            bulkActions.closeDialog('send');
          }}
        />

        <BulkAssignJobsDialog
          accountId={bulkActions.dialogsState.assign.selectedVendorAccountId ?? null}
          companyId={companyId}
          isOpen={bulkActions.dialogsState.assign.isOpen}
          onClose={() => bulkActions.closeDialog('assign')}
          onConfirm={() => {
            const { assignableJobs, dialogsState } = bulkActions;
            const { selectedVendorAccountId } = dialogsState.assign;

            if (selectedVendorAccountId && assignableJobs.length) {
              bulkAssignJobs(assignableJobs, selectedVendorAccountId);
            }

            bulkActions.closeDialog('assign');
          }}
          onSelectedAccountChange={bulkActions.updateBulkAssignJobsSelectedAccount}
        />

        <BulkAssignMultipleJobsDialog
          isOpen={
            orderActionDialog.isOpen &&
            orderActionDialog.dialog === OrderActionDialog.BULK_ASSIGN_MULTIPLE
          }
          onClose={closeOrderActionDialog}
          onConfirm={() => console.log('confirm!')}
          order={orderActionDialog.order}
        />

        <CopyVendorAssignmentsDialog
          companyId={companyId}
          isOpen={bulkActions.dialogsState.copyVendorAssignments.isOpen}
          orderId={bulkActions.dialogsState.copyVendorAssignments.selectedOrderId}
          onClose={() => bulkActions.closeDialog('copyVendorAssignments')}
          onConfirm={() => {
            const { copyableJobs, dialogsState } = bulkActions;
            const { selectedOrderId } = dialogsState.copyVendorAssignments;

            if (selectedOrderId && copyableJobs.length) {
              bulkCopyAssignments(copyableJobs, selectedOrderId);
            }

            bulkActions.closeDialog('copyVendorAssignments');
          }}
          onSelectedOrderChange={bulkActions.updateBulkCopyAssignmentsSelectedOrder}
        />

        <ModalDialog
          callBack={() => handleCloneOrder(`${orderActionDialog.order?.id}`, true)}
          cancelButtonText={`${t('dispatch.order.copy_without_assignments')}`}
          confirmButtonColor="primary"
          confirmButtonText={`${t('dispatch.order.copy_with_assignments')}`}
          loading={ordersDispatchStore.isCloningOrder}
          onCancel={() => handleCloneOrder(`${orderActionDialog.order?.id}`, false)}
          ref={cloneOrderDialogRef}
          content={t('dispatch.order.clone_description')}
          title={t('dispatch.order.clone_order')}
        />

        <SendTextDialog
          isOpen={
            bulkActions.dialogsState.bulkTextAllDrivers.isOpen ||
            (orderActionDialog.isOpen &&
              orderActionDialog.dialog === OrderActionDialog.TEXT_ALL_DRIVERS)
          }
          onClose={closeOrderActionDialog}
          mode={
            bulkActions.dialogsState.bulkTextAllDrivers.isOpen
              ? 'bulk_drivers'
              : 'order_drivers'
          }
          source={
            bulkActions.dialogsState.bulkTextAllDrivers.isOpen
              ? bulkActions.jobsWithTextableDrivers
              : orderActionDialog.order
          }
        />

        <ModalDialog
          callBack={() => handleCancelOrder(`${orderActionDialog.order?.id}`)}
          confirmButtonColor="error"
          confirmButtonText={`${t('actions.confirm')}`}
          loading={ordersDispatchStore.isCancellingOrder}
          ref={cancelOrderDialogRef}
          content={t('dispatch.order.cancel_description')}
          title={t('dispatch.order.cancel_order')}
        />

        <Snackbar
          open={ordersDispatchStore.ordersPendingToShown.some((order) => {
            return ordersDispatchStore.orderMatchesCurrentFilteringCriteria(order);
          })}
          message={t('order.new_orders_available')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          ContentProps={{
            sx: {
              backgroundColor: theme.palette.brandV2Yellow.main,
              color: theme.brandV2.colors.treadBlack,
              fontSize: '12px',
            },
          }}
          action={
            <SmallButton
              color="brandV2Black"
              onClick={handleRefreshOrdersOnScreenClick}
              size="small"
              variant="text"
            >
              {t('order.refresh_data').toUpperCase()}
            </SmallButton>
          }
        />
      </>,
    );
  },
);

export default OrdersViewProvider;
