import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SearchParams } from '~constants/enums';

import { DateFilter } from '../DriverDay/DriverDayFilters/DateFilter';

type NullableDate = dayjs.Dayjs | null;

export const DriverDayDateFilter = function () {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialStartDate = searchParams.get(SearchParams.START_DATE)
    ? dayjs(searchParams.get(SearchParams.START_DATE))
    : null;

  const initialEndDate = searchParams.get(SearchParams.END_DATE)
    ? dayjs(searchParams.get(SearchParams.END_DATE))
    : null;

  const [startDate, setStartDate] = useState<NullableDate>(initialStartDate);
  const [endDate, setEndDate] = useState<NullableDate>(initialEndDate);

  const handleDateChange = useCallback((start: NullableDate, end: NullableDate) => {
    setStartDate(start);
    setEndDate(end);
  }, []);

  const onFilterClosed = useCallback(() => {
    setSearchParams((params) => {
      if (startDate) {
        params.set(SearchParams.START_DATE, startDate.format('YYYY-MM-DD'));
      } else {
        params.delete(SearchParams.START_DATE);
      }
      if (endDate) {
        params.set(SearchParams.END_DATE, endDate.format('YYYY-MM-DD'));
      } else {
        params.delete(SearchParams.END_DATE);
      }
      return params;
    });
  }, [setSearchParams, startDate, endDate]);

  return (
    <DateFilter
      startDate={startDate}
      endDate={endDate}
      onDateSelect={handleDateChange}
      onAnchorClose={onFilterClosed}
    />
  );
};
