import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { t } from 'i18next';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';

export interface PhaseOptions {
  id: string;
  name: string;
  code: string | undefined;
  primary?: boolean;
  _destroy?: number;
}

interface PhaseSelectionProps {
  phaseOptions?: PhaseOptions[];
  selectedPhase?: PhaseOptions | null;
  canEdit: boolean;
  onChange?: (option: PhaseOptions | null) => void;
  onPhaseOptionsChange?: (options: PhaseOptions[]) => void;
}
export const PhaseSelection = ({
  phaseOptions = [],
  canEdit,
  selectedPhase,
  onChange = () => {},
  onPhaseOptionsChange = () => {},
}: PhaseSelectionProps) => {
  const [localPhaseOptions, setLocalPhaseOptions] = useState<PhaseOptions[]>(
    phaseOptions || [],
  );
  const [phaseName, setPhaseName] = useState('');
  const [phaseCode, setPhaseCode] = useState('');

  useEffect(() => {
    setLocalPhaseOptions(phaseOptions);
    setPhaseName('');
    setPhaseCode('');
    setValue('name', '');
    setValue('code', '');
    onPhaseOptionsChange(phaseOptions);
  }, [phaseOptions]);

  const {
    control: phaseControl,
    watch: watchPhase,
    setValue,
    getValues,
    formState: { errors: phaseErrors },
  } = useForm<PhaseOptions>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      code: '',
    },
  });
  const watchPhaseCode = watchPhase('code');
  const watchPhaseName = watchPhase('name');

  const filteredPhaseOptions = useMemo(() => {
    return localPhaseOptions
      .filter((item) => item.code)
      .filter((item) => !(item._destroy && item.id === ''));
  }, [localPhaseOptions]);

  useEffect(() => {
    if (watchPhaseName && watchPhaseCode) {
      // set the primary phase amongst the phase options
      setLocalPhaseOptions((prev) =>
        prev.map((item) =>
          item.code === watchPhaseCode && item.name === watchPhaseName
            ? { ...item, primary: true }
            : { ...item, primary: undefined },
        ),
      );
    }
  }, [watchPhaseCode, watchPhaseName]);

  useEffect(() => {
    onPhaseOptionsChange(filteredPhaseOptions);
  }, [filteredPhaseOptions]);

  // only show distinct phase names.
  const phaseNameOptions = useMemo(() => {
    return _.unionBy(
      localPhaseOptions.filter((item) => !item._destroy),
      (item) => item.name,
    ).map((item) => item.name);
  }, [localPhaseOptions]);

  // only show phases with the same name as the selected phase.
  // don't show deleted phases.
  const phaseCodeOptions = useMemo(() => {
    return localPhaseOptions
      .filter((phase) => phase.name === watchPhaseName && phase.code)
      .filter((item) => !item._destroy)
      .map((item) => item.code);
  }, [localPhaseOptions, watchPhaseName]);

  useEffect(() => {
    if (selectedPhase) {
      setValue('name', selectedPhase.name);
      setValue('code', selectedPhase.code);
    } else {
      setValue('name', '');
      setValue('code', '');
    }
  }, [selectedPhase]);

  return (
    <>
      <Grid item xs={6}>
        <AutocompleteFormField
          control={phaseControl}
          name="name"
          errors={phaseErrors}
          list={phaseNameOptions}
          label={`${t('project.form.project_phase_name')}`}
          isRequired={false}
          clearable={true}
          getValue={(item) => item}
          getLabel={(item) => item || ''}
          clearDefaultValue={''}
          onSelect={(value) => {
            setValue('code', '');
            setPhaseCode('');
            if (!value) {
              onChange(null);
            }
          }}
          onDelete={
            canEdit
              ? (option) => {
                  if (option === watchPhaseName) {
                    setValue('name', '');
                  }
                  setLocalPhaseOptions((prev) =>
                    prev.map((item) =>
                      item.name === option ? { ...item, _destroy: 1 } : item,
                    ),
                  );
                  setValue('code', '');
                  setPhaseCode('');
                }
              : undefined
          }
          onInput={(event, value) => {
            setPhaseName(value);
          }}
          noOptionsText={
            <NoOptionsText
              phaseName={phaseName}
              allowCreate={canEdit}
              createLabel={t('project.form.create_name', {
                name: phaseName,
              })}
              emptyTextLabel={t('project.form.enter_phase_name')}
              createNewClick={() => {
                setLocalPhaseOptions((prev) => [
                  ...prev,
                  {
                    id: '',
                    name: phaseName,
                    code: undefined,
                  },
                ]);
                setValue('name', phaseName);
                setValue('code', '');
              }}
            />
          }
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteFormField
          control={phaseControl}
          name="code"
          list={phaseCodeOptions}
          disabled={!watchPhaseName}
          clearable={true}
          onDelete={
            canEdit
              ? (option) => {
                  if (option === watchPhaseCode) {
                    setValue('code', '');
                  }
                  setLocalPhaseOptions((prev) =>
                    prev.map((item) =>
                      _.isEqual(item.code, option) && _.isEqual(item.name, watchPhaseName)
                        ? { ...item, _destroy: 1 }
                        : item,
                    ),
                  );
                }
              : undefined
          }
          clearDefaultValue={''}
          errors={phaseErrors}
          label={`${t('project.form.project_phase_code')}`}
          isRequired={!watchPhaseCode && !!watchPhaseName}
          getValue={(item) => item}
          getLabel={(item) => item || ''}
          onInput={(event, value) => {
            setPhaseCode(value);
          }}
          onSelect={(value) => {
            if (value) {
              const selectedPhase = localPhaseOptions.find(
                (item) => item.code === value && item.name === watchPhaseName,
              );
              if (selectedPhase) {
                onChange(selectedPhase);
              }
            }
          }}
          noOptionsText={
            <NoOptionsText
              phaseName={phaseCode}
              allowCreate={canEdit}
              createLabel={t('project.form.create_code', {
                code: phaseCode,
              })}
              emptyTextLabel={t('project.form.enter_phase_code')}
              createNewClick={() => {
                setLocalPhaseOptions((prev) => [
                  ...prev,
                  {
                    id: '',
                    name: phaseName,
                    code: phaseCode,
                  },
                ]);
                setValue('code', phaseCode);
              }}
            />
          }
        />
      </Grid>
    </>
  );
};

export const NoOptionsText = ({
  phaseName,
  allowCreate,
  createLabel,
  emptyTextLabel,
  createNewClick,
}: {
  phaseName: string;
  allowCreate: boolean;
  createLabel: string;
  emptyTextLabel: string;
  createNewClick: () => void;
}) => {
  if (!allowCreate) {
    return (
      <Chip
        size={'small'}
        variant={'filled'}
        color={'primary'}
        sx={{ cursor: 'pointer' }}
        label={t('project.form.edit_in_project')}
      />
    );
  }
  return phaseName ? (
    <Chip
      size={'small'}
      variant={'filled'}
      color={'primary'}
      onClick={createNewClick}
      sx={{ cursor: 'pointer' }}
      label={createLabel}
    />
  ) : (
    <Chip
      size={'small'}
      variant={'filled'}
      color={'primary'}
      sx={{ cursor: 'pointer' }}
      label={emptyTextLabel}
    />
  );
};
