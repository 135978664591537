import {
  Agave_LinkedAccount_Read,
  Agave_LinkToken_Read,
} from '@treadinc/horizon-api-spec';
import { AxiosError } from 'axios';
import { t } from 'i18next';

import { API_VERSION } from '~constants/consts';
import connection from '~services/connectionModule';
import { Pagination, PaginationQuery } from '~services/pagination';

import { AgaveLinkedAccount } from './models';

export default function useAgave() {
  const createLinkedAccount = async (publicToken: string) => {
    try {
      const response = await connection.post<Agave_LinkedAccount_Read>(
        `${API_VERSION}/agave/linked_accounts`,
        { public_token: publicToken },
        {},
        t('error_messages.agave.failed_to_create_linked_account') as string,
      );

      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const deleteLinkedAccount = async (
    linkedAccountId: string,
    errorCallback?: (error: AxiosError) => void,
  ) => {
    try {
      await connection.delete<Agave_LinkedAccount_Read>(
        `${API_VERSION}/agave/linked_accounts/${linkedAccountId}`,
        {},
        t('error_messages.agave.failed_to_delete_linked_account') as string,
        [500],
      );
    } catch (error) {
      console.error(error);
      errorCallback?.(error as AxiosError);
      throw error;
    }
  };

  const getLinkToken = async () => {
    try {
      const response = await connection.post<Agave_LinkToken_Read>(
        `${API_VERSION}/agave/link_tokens`,
        {},
        {},
        t('error_messages.agave.failed_to_fetch_link_token') as string,
      );

      return response.link_token;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getLinkedAccounts = async (pagination: Pagination) => {
    try {
      const params: PaginationQuery = {
        'page[limit]': pagination.limit,
      };

      if (pagination.after) {
        params['page[after]'] = pagination.after;
      } else if (pagination.before) {
        params['page[before]'] = pagination.before;
      }

      const response = await connection.getPaginated<Agave_LinkedAccount_Read>(
        `${API_VERSION}/agave/linked_accounts`,
        { params },
        t('error_messages.agave.failed_to_fetch_linked_accounts') as string,
      );
      const linkedAccounts = response.data.map((account) => {
        return AgaveLinkedAccount.parse(account);
      });

      return { data: linkedAccounts, pagination: response.pagination };
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  return { createLinkedAccount, deleteLinkedAccount, getLinkToken, getLinkedAccounts };
}
