import {
  Account_Read_Typeahead,
  AccountType,
  Driver_Read,
  Driver_Read_Typeahead,
  Job_Read,
  JobState,
  UserBulkSendJobsChannel_Read,
} from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import { t as $t } from 'i18next';
import _ from 'lodash';

import { API_VERSION } from '~constants/consts';
import { AccountTypeahead } from '~hooks/useAccount';
import { DriverBasic } from '~hooks/useDrivers';
import { Job } from '~hooks/useJob';
import connection from '~services/connectionModule';
import { realTimeChannels } from '~services/realTimeChannels';
import { useStores } from '~store';
import { JobsFilters } from '~store/DriverSchedulerStore';
import { Nullable } from '~types/Nullable';

import {
  DUMMY_COLUMN_INCREMENTS,
  INCREMENTS_PER_HOUR,
  MINUTES_PER_INCREMENT,
} from '../components/drivers/constants';

interface FetchAssigneesParams {
  companyId: string;
}

interface GetDriversQueryProps {
  'page[limit]'?: number;
  'page[before]'?: string;
  'page[after]'?: string;
  'filter[shared]'?: boolean;
  'search[query]'?: string;
}

interface GetVendorsQueryProps {
  'filter[account_types]'?: AccountType[];
  'page[after]'?: string;
  'page[before]'?: string;
  'page[limit]'?: number;
  'search[query]'?: string;
}

interface FetchJobsParams {
  startDate?: string;
  endDate?: string;
  assigneeIds?: string[];
  states?: JobState[];
  callback?: (jobs: Job[]) => void;
  companyId?: string;
}

interface GetJobsQueryProps {
  'page[limit]'?: number;
  'page[before]'?: string;
  'page[after]'?: string;
  'filter[customer_account_ids]'?: string[];
  'filter[dispatch_numbers]'?: string[];
  'filter[driver_ids]'?: string[];
  'filter[dropoff_site_ids]'?: string[];
  'filter[end_date]'?: string;
  'filter[external_ids]'?: string[];
  'filter[pickup_site_ids]'?: string[];
  'filter[project_ids]'?: string[];
  'filter[start_date]'?: string;
  'filter[states]'?: JobState[];
  'filter[vendor_account_ids]'?: string[];
  'search[datagrid]'?: string;
}

export const useDriverSchedulerFetch = () => {
  const { driverSchedulerStore } = useStores();

  const fetchUnassignedJobs = async (options: FetchJobsParams) => {
    driverSchedulerStore.unassignedJobsFetchStart();

    const { unassignedJobsPagination, unassignedJobsFilters, dateFilters } =
      driverSchedulerStore;

    const params: GetJobsQueryProps = {
      'page[limit]': unassignedJobsPagination.limit,
    };

    if (unassignedJobsPagination.before) {
      params['page[before]'] = unassignedJobsPagination.before;
    } else if (unassignedJobsPagination.after) {
      params['page[after]'] = unassignedJobsPagination.after;
    }

    if (dateFilters.startDate) {
      params['filter[start_date]'] = dateFilters.startDate;
    }

    if (dateFilters.endDate) {
      params['filter[end_date]'] = dateFilters.endDate;
    }

    if (options.states) {
      params['filter[states]'] = options.states;
    }

    if (unassignedJobsFilters?.search?.trim().length) {
      params['search[datagrid]'] = unassignedJobsFilters.search.trim();
    }

    if (unassignedJobsFilters?.customers?.length) {
      params['filter[customer_account_ids]'] = unassignedJobsFilters.customers;
    }

    if (unassignedJobsFilters?.dispatchNumbers?.length) {
      params['filter[dispatch_numbers]'] = unassignedJobsFilters.dispatchNumbers;
    }

    if (unassignedJobsFilters?.dropOffSites?.length) {
      params['filter[dropoff_site_ids]'] = unassignedJobsFilters.dropOffSites;
    }

    if (unassignedJobsFilters?.pickUpSites?.length) {
      params['filter[pickup_site_ids]'] = unassignedJobsFilters.pickUpSites;
    }

    if (unassignedJobsFilters?.projects?.length) {
      params['filter[project_ids]'] = unassignedJobsFilters.projects;
    }

    if (unassignedJobsFilters?.projectsExternalIds?.length) {
      params['filter[external_ids]'] = unassignedJobsFilters.projectsExternalIds;
    }

    try {
      const response = await connection.getPaginated<Job_Read>(
        `${API_VERSION}/jobs`,
        { params },
        $t('error_messages.jobs.failed_to_fetch') as string,
      );
      const { data, pagination } = response;
      const parsedJobs = data.map(Job.parse);
      driverSchedulerStore.fetchUnassignedJobsEnd(
        parsedJobs,
        pagination,
        options.companyId,
      );
      options.callback?.(parsedJobs);
    } catch (error) {
      driverSchedulerStore.fetchUnassignedJobsEnd([]);
      console.error(error);
      throw new Error('Unable to fetch unassigned jobs');
    }
  };

  const fetchAssignedJobs = async (options: FetchJobsParams) => {
    if (!options.assigneeIds || options.assigneeIds.length === 0) {
      return;
    }

    driverSchedulerStore.assignedJobsFetchStart();

    const { assignedJobsPagination, assignedJobsFilters, dateFilters } =
      driverSchedulerStore;

    const isTargetingVendor =
      driverSchedulerStore.assigneesFilters.assigneeType === 'vendor';
    const params: GetJobsQueryProps = {
      'page[limit]': assignedJobsPagination.limit,
    };

    if (assignedJobsPagination.before) {
      params['page[before]'] = assignedJobsPagination.before;
    } else if (assignedJobsPagination.after) {
      params['page[after]'] = assignedJobsPagination.after;
    }

    if (dateFilters.startDate) {
      params['filter[start_date]'] = dateFilters.startDate;
    }

    if (dateFilters.endDate) {
      params['filter[end_date]'] = dateFilters.endDate;
    }

    if (assignedJobsFilters?.search?.trim().length) {
      params['search[datagrid]'] = assignedJobsFilters.search.trim();
    }

    if (assignedJobsFilters?.customers?.length) {
      params['filter[customer_account_ids]'] = assignedJobsFilters.customers;
    }

    if (assignedJobsFilters?.dispatchNumbers?.length) {
      params['filter[dispatch_numbers]'] = assignedJobsFilters.dispatchNumbers;
    }

    if (assignedJobsFilters?.dropOffSites?.length) {
      params['filter[dropoff_site_ids]'] = assignedJobsFilters.dropOffSites;
    }

    if (assignedJobsFilters?.pickUpSites?.length) {
      params['filter[pickup_site_ids]'] = assignedJobsFilters.pickUpSites;
    }

    if (assignedJobsFilters?.projects?.length) {
      params['filter[project_ids]'] = assignedJobsFilters.projects;
    }

    if (assignedJobsFilters?.projectsExternalIds?.length) {
      params['filter[external_ids]'] = assignedJobsFilters.projectsExternalIds;
    }

    // Chunk assignee IDs into groups of 20
    const assigneeIdChunks = _.chunk(options.assigneeIds || [], 20);
    const allFetchedJobs: Job[] = [];
    const newAssignments: Record<string, Nullable<Job>> = {};
    const assigneeJobCount: Record<string, number> = {};

    try {
      for (const chunk of assigneeIdChunks) {
        if (isTargetingVendor) {
          params['filter[vendor_account_ids]'] = chunk.length > 0 ? chunk : undefined;
        } else {
          params['filter[driver_ids]'] = chunk.length > 0 ? chunk : undefined;
        }

        const response = await connection.getPaginated<Job_Read>(
          `${API_VERSION}/jobs`,
          { params },
          $t('error_messages.jobs.failed_to_fetch') as string,
        );

        const { data, pagination } = response;
        const parsedJobs = data.map(Job.parse);

        // Collect jobs from each fetch
        allFetchedJobs.push(...parsedJobs);
        driverSchedulerStore.updateAssignedJobsPagination(pagination);
      }

      // Assign jobs to drivers
      allFetchedJobs.forEach((job) => {
        const assigneeId = isTargetingVendor
          ? job.vendorJobAssignment?.vendorAccount?.id
          : job.driver?.id;

        if (assigneeId) {
          const startDate = dayjs.tz(job.jobStartAt);
          const columnIndex =
            DUMMY_COLUMN_INCREMENTS +
            Math.floor(
              startDate.hour() * INCREMENTS_PER_HOUR +
                startDate.minute() / MINUTES_PER_INCREMENT,
            );
          newAssignments[`${assigneeId}:${columnIndex}`] = job;

          assigneeJobCount[assigneeId] = (assigneeJobCount[assigneeId] ?? 0) + 1;
        }
      });

      // Determine if there are active jobs filters
      const hasActiveAssignedJobsFilters = Object.keys(assignedJobsFilters).some(
        (key) => assignedJobsFilters[key as keyof JobsFilters],
      );

      // Update job assignments and filter drivers
      const jobAssignments = {
        ...driverSchedulerStore.jobAssignments,
        ...newAssignments,
      };

      let filteredAssignees = driverSchedulerStore.assignees;
      if (hasActiveAssignedJobsFilters) {
        filteredAssignees = driverSchedulerStore.assignees.filter(
          (assignee) => assigneeJobCount[assignee.id],
        );
      }

      driverSchedulerStore.setJobAssignments(jobAssignments);
      driverSchedulerStore.setFilteredAssignees(filteredAssignees);
      driverSchedulerStore.fetchAssignedJobsEnd(allFetchedJobs);
    } catch (error) {
      driverSchedulerStore.fetchAssignedJobsEnd([]);
      console.error(error);
      throw new Error('Unable to fetch assigned jobs');
    }
  };

  const fetchDrivers = async (options: FetchAssigneesParams) => {
    driverSchedulerStore.assigneesFetchStart();

    const { assigneesPagination, assigneesFilters } = driverSchedulerStore;
    const params: GetDriversQueryProps = {
      'page[limit]': assigneesPagination.limit,
    };
    if (assigneesPagination.before) {
      params['page[before]'] = assigneesPagination.before;
    } else if (assigneesPagination.after) {
      params['page[after]'] = assigneesPagination.after;
    }

    if (assigneesFilters.search) {
      params['search[query]'] = assigneesFilters.search;
    }

    params['filter[shared]'] = assigneesFilters.assigneeType === 'external';

    try {
      const response = await connection.getPaginated<Driver_Read_Typeahead>(
        `${API_VERSION}/companies/${options.companyId}/drivers/typeahead`,
        { params },
        $t('error_messages.drivers.failed_to_fetch_drivers') as string,
      );
      const { data, pagination } = response;
      driverSchedulerStore.assigneesFetchEnd(
        (data as Driver_Read[]).map(DriverBasic.parse),
        pagination,
      );
    } catch (error) {
      driverSchedulerStore.assigneesFetchEnd([]);
      console.error(error);
      throw new Error('Unable to fetch drivers');
    }
  };

  const fetchVendors = async (options: FetchAssigneesParams) => {
    driverSchedulerStore.assigneesFetchStart();

    const { assigneesPagination, assigneesFilters } = driverSchedulerStore;
    const params: GetVendorsQueryProps = {
      'page[limit]': assigneesPagination.limit,
      'filter[account_types]': [AccountType.VENDOR],
    };

    if (assigneesPagination.before) {
      params['page[before]'] = assigneesPagination.before;
    } else if (assigneesPagination.after) {
      params['page[after]'] = assigneesPagination.after;
    }

    if (assigneesFilters.search) {
      params['search[query]'] = assigneesFilters.search;
    }

    try {
      const response = await connection.getPaginated<Account_Read_Typeahead>(
        `${API_VERSION}/companies/${options.companyId}/accounts/typeahead`,
        { params },
        $t('error_messages.account.failed_to_fetch_company_accounts') as string,
      );
      const { data, pagination } = response;
      driverSchedulerStore.assigneesFetchEnd(
        data.map((account) => AccountTypeahead.parse(account)),
        pagination,
      );
    } catch (error) {
      driverSchedulerStore.assigneesFetchEnd([]);
      console.error(error);
      throw new Error('Unable to fetch vendors');
    }
  };

  const assignDriver = async (id: string, driverId: string) => {
    driverSchedulerStore.updateJobStart();

    try {
      const response = await connection.patch<Job_Read>(
        `${API_VERSION}/jobs/${id}`,
        {
          driver_id: driverId,
        },
        {},
        $t('error_messages.jobs.failed_to_assign_driver') as string,
      );
      const formatted = Job.parse(response);
      driverSchedulerStore.updateJob(formatted);
      return formatted;
    } catch (error) {
      console.error('Failed to assign driver:', error);
      throw new Error('Failed to assign driver');
    } finally {
      driverSchedulerStore.updateJobEnd();
    }
  };

  const assignVendor = async (id: string, vendorId: string) => {
    driverSchedulerStore.updateJobStart();

    try {
      const response = await connection.put<Job_Read>(
        `${API_VERSION}/jobs/${id}/assign_vendor`,
        { vendor_id: vendorId },
        {},
        $t('error_messages.jobs.failed_to_assign_vendor') as string,
      );
      const formatted = Job.parse(response);
      driverSchedulerStore.updateJob(formatted);
      return formatted;
    } catch (error) {
      console.error('Failed to assign vendor:', error);
      throw new Error('Failed to assign vendor');
    } finally {
      driverSchedulerStore.updateJobEnd();
    }
  };

  const unassignDriver = async (id: string) => {
    driverSchedulerStore.updateJobStart();

    try {
      const response = await connection.patch<Job_Read>(
        `${API_VERSION}/jobs/${id}`,
        {
          driver_id: null,
        },
        {},
        $t('error_messages.jobs.failed_to_unassign_driver') as string,
      );
      const formatted = Job.parse(response);
      driverSchedulerStore.updateJob(formatted);
    } catch (error) {
      console.error('Failed to unassign driver:', error);
      throw new Error('Failed to unassign driver');
    } finally {
      driverSchedulerStore.updateJobEnd();
    }
  };

  const unassignVendor = async (id: string) => {
    driverSchedulerStore.updateJobStart();

    try {
      const response = await connection.put<Job_Read>(
        `${API_VERSION}/jobs/${id}/unassign`,
        {},
        {},
        $t('error_messages.jobs.failed_to_unassign_vendor') as string,
      );
      const formatted = Job.parse(response);
      driverSchedulerStore.updateJob(formatted);
    } catch (error) {
      console.error('Failed to unassign vendor:', error);
      throw new Error('Failed to unassign vendor');
    } finally {
      driverSchedulerStore.updateJobEnd();
    }
  };

  const updateJob = async (id: string, data: any, inline?: boolean) => {
    driverSchedulerStore.updateJobStart();
    const deparsedModel = inline
      ? Job.deparseUpdateFromInlineMode(data)
      : Job.deparseUpdate(data);

    try {
      const response = await connection.patch<Job_Read>(
        `${API_VERSION}/jobs/${id}`,
        deparsedModel,
        {},
        $t('error_messages.jobs.failed_to_update') as string,
      );
      const formatted = Job.parse(response);
      driverSchedulerStore.updateJob(formatted);
      return formatted;
    } catch (error) {
      console.error('Failed to update job:', error);
      throw new Error('Failed to update job');
    } finally {
      driverSchedulerStore.updateJobEnd();
    }
  };

  const subscribeToJobsRTU = (companyId: string) => {
    const cable = connection.realTimeConnection;
    const channel = realTimeChannels.CompanyJobUpdateChannel;

    // The subscriptions object from ActionCable does track its own subscriptions in a subscriptions array.
    // For some reason, the subscriptions array is not exposed in the type definition hence the ts-ignore.
    // @ts-ignore
    let subscription = cable?.subscriptions.subscriptions.find((sub) => {
      return sub.identifier.includes(channel);
    });

    if (!subscription) {
      subscription = cable?.subscriptions?.create?.(
        { channel, company_id: companyId },
        {
          connected() {},
          disconnected() {},
          received: ({ data }: { data: Job_Read }) => {
            const job = Job.parse(data);
            driverSchedulerStore.updateJob(job);
          },
        },
      );
    }

    return subscription;
  };

  const bulkSendJobs = async (jobIds: string[]) => {
    try {
      await connection.put(
        `${API_VERSION}/jobs/bulk_send`,
        {
          job_ids: jobIds,
        },
        {},
        $t('error_messages.jobs.failed_to_bulk_send_jobs') as string,
      );
    } catch (error) {
      console.error(error);
      throw new Error('Unable to bulk send jobs');
    }
  };

  const subscribeToBulkSendJobsRTU = (
    companyId: string,
    onUpdateReceived: (data: UserBulkSendJobsChannel_Read) => void,
  ) => {
    const cable = connection.realTimeConnection;
    const channel = realTimeChannels.UserBulkSendJobsChannel;

    // The subscriptions object from ActionCable does track its own subscriptions in a subscriptions array.
    // For some reason, the subscriptions array is not exposed in the type definition hence the ts-ignore.
    // @ts-ignore
    let subscription = cable?.subscriptions.subscriptions.find((sub) => {
      return sub.identifier.includes(channel);
    });

    if (!subscription) {
      subscription = cable?.subscriptions?.create?.(
        { channel, company_id: companyId },
        {
          connected() {},
          disconnected() {},
          received: onUpdateReceived,
        },
      );
    }

    return subscription;
  };

  return {
    assignDriver,
    assignVendor,
    bulkSendJobs,
    fetchAssignedJobs,
    fetchDrivers,
    fetchUnassignedJobs,
    fetchVendors,
    subscribeToBulkSendJobsRTU,
    subscribeToJobsRTU,
    unassignDriver,
    unassignVendor,
    updateJob,
  };
};
