import Check from '@mui/icons-material/Check';
import FlagOutlined from '@mui/icons-material/FlagOutlined';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import WarningAmber from '@mui/icons-material/WarningAmber';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { TicketState } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef, useState } from 'react';

import DataGrid from '~components/DataGrid/DataGrid';
import { HeaderNavigation } from '~components/DataGrid/HeaderNavigation';
import { Drawer } from '~components/Drawer/src/ui';
import { SimpleMenu } from '~components/Menu/SimpleMenu';
import { Job, useJob } from '~hooks/useJob';
import { Ticket, TicketEventType, useTickets } from '~hooks/useTickets';
import { DateRangeFilter } from '~pages/Approvals/ApprovalsComponents/DateRangeFilter';
import { PaginationLink } from '~services/pagination';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { dateFormat } from '~utils/dateTime';
import { canEditTicket } from '~utils/tickets/ticket-utils';

import { TicketFormDTO } from '../ApprovalsComponents/ticketFormSchema';
import { TicketDetails } from './TicketDetails';
import { TicketTabFilter } from './TicketTabFilter';
import { TabFilter } from './types';
import { FilterTickets } from './utils';

export const TicketsReviewDataGrid = observer(() => {
  const { ticketsStore, toasterStore, jobStore } = useStores();
  const {
    isLoading,
    getTickets,
    updateState,
    updateTicketById,
    deleteTicketImageById,
    uploadTicketImageById,
  } = useTickets();
  const [selectedTicket, setSelectedTicket] = useState<{
    isDrawerOpen: boolean;
    job?: Job;
    ticket?: Ticket;
  }>({ isDrawerOpen: false });
  const { getJobById } = useJob();

  // Store the current isLoading state in a ref for HeaderNavigation since datagrid headers does not get re-rendered on those updates
  const loadingRef = useRef(isLoading);
  useEffect(() => {
    loadingRef.current = isLoading;
  }, [isLoading]);

  // This is to make sure the selected ticket is always upto date from the
  // Store to prevent using a stale ticket data in the drawer.
  // Example: if a user approves a ticket from the datagrid and immediately opens the drawer,
  // We want to make sure the new states is reflected in the drawer.
  // Only way to show the updated ticket is to always fetch it from the store.
  useEffect(() => {
    if (selectedTicket.ticket?.id) {
      const newSelectedTicket = ticketsStore.tickets.find((storedTicket) => {
        return storedTicket.id === selectedTicket.ticket?.id;
      });

      if (newSelectedTicket) {
        setSelectedTicket((state) => ({ ...state, ticket: newSelectedTicket }));
      }
    }
  }, [ticketsStore.tickets, selectedTicket.ticket?.id]);

  useEffect(() => {
    if (selectedTicket.isDrawerOpen) {
      const job = jobStore.jobs.find((job) => {
        return selectedTicket.ticket?.jobId === job.id;
      });

      if (job) {
        setSelectedTicket((state) => ({ ...state, job }));
      } else if (selectedTicket.ticket?.jobId) {
        getJobById(selectedTicket.ticket.jobId).then((associatedJob) => {
          setSelectedTicket((state) => ({ ...state, job: associatedJob }));
        });
      }
    }
  }, [selectedTicket.isDrawerOpen, selectedTicket.ticket?.jobId, jobStore.jobs]);

  const doAction = async (id: string, action: TicketEventType) => {
    const res = await updateState(id, action);
    toasterStore.push(alert($t('approvals.ticket_updated'), AlertTypes.success));
    return res;
  };

  const doUpdateAction = async (id: string, data: any) => {
    const res = await updateTicketById({
      id,
      data,
    });
    toasterStore.push(alert($t('approvals.ticket_updated'), AlertTypes.success));
    return res;
  };

  const saveTicket = async (ticketDto: TicketFormDTO) => {
    let ticket: Ticket | undefined = undefined;
    const isUpdate = Boolean(ticketDto.id);

    if (isUpdate) {
      ticket = await updateTicketById({
        id: String(ticketDto.id),
        data: {
          quantity: ticketDto.quantity,
          unitOfMeasure: ticketDto.unitOfMeasure,
          material: ticketDto.material.id ? { id: ticketDto.material.id } : undefined,
          ticketNumber: ticketDto.ticketNumber,
          imageUrl: ticketDto.imageUrl,
        },
      });
    }

    if (ticket) {
      toasterStore.push(
        alert($t('approvals.ticket_updated'), AlertTypes.success),
        false,
        true,
      );
      setSelectedTicket((state) => ({ ...state, ticket }));
    } else {
      throw new Error('Failed to update ticket.');
    }

    return ticket;
  };

  const handleFlagTicket = async (ticket: Ticket) => {
    ticket.flagged = !ticket.flagged;

    const updatedTicket = await updateTicketById({ id: ticket.id, data: ticket });

    toasterStore.push(
      alert($t('approvals.ticket_updated'), AlertTypes.success),
      false,
      true,
    );
    setSelectedTicket((state) => ({ ...state, ticket: updatedTicket }));

    return updatedTicket;
  };

  /**
   * Retrieves filtered tickets based on the provided link and filter query.
   * If no link is provided, it uses an empty object as the default link.
   * @param link - The pagination link to retrieve tickets from.
   */
  const getFilteredTickets = (link?: PaginationLink) => {
    getTickets(link || ({} as PaginationLink), '', filterQuery);
  };

  // Handle date range filter changes
  const [dateRangeFilterValue, setDateRangeFilterValue] = useState<string[] | null[]>([
    null,
    null,
  ]);
  const [filterQuery, setFilterQuery] = useState<Record<string, any>>({});
  useEffect(() => {
    // TODO: Look into a way to abstract this into date range filter component
    const filters = {} as Record<string, string>;

    if (dateRangeFilterValue[0]) {
      filters['from_date'] = new Date(dateRangeFilterValue[0]).toISOString();
    }

    if (dateRangeFilterValue[1]) {
      filters['to_date'] = new Date(dateRangeFilterValue[1]).toISOString();
    }

    setFilterQuery(filters);
  }, [dateRangeFilterValue]);

  // Refetch tickets when filter query changes
  useEffect(() => {
    getFilteredTickets({} as PaginationLink);
  }, [JSON.stringify(filterQuery)]);

  // DataGrid rows
  const [tabFilter, setTabFilter] = useState<TabFilter>(TabFilter.MISSING);
  const rows = useMemo(
    () => FilterTickets(tabFilter, ticketsStore.tickets),
    [ticketsStore.tickets, tabFilter],
  );

  // DataGrid columns
  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'ticketNumber',
          headerName: `${$t('form_fields.ticket')}`,
          flex: 1,
        },
        {
          field: 'ticketWarning',
          headerName: `${$t('approvals.alert')}`,
          headerAlign: 'center',
          align: 'center',
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            const t: Ticket = params.row;

            if (!t.missingInfo) {
              return null;
            }

            const nodes = [];
            // TODO: shouldn't all of this text be i18n?
            if (!t.imageUrl) {
              nodes.push(<div key="missing-imageUrl"> {'* Missing image'}</div>);
            }
            if (!t.ticketNumber) {
              nodes.push(
                <div key="missing-ticketNumber">{'* Missing ticket number'}</div>,
              );
            }
            if (!t.jobId) {
              nodes.push(<div key="missing-jobId">{'* Missing job'}</div>);
            }
            if (!t.quantity) {
              nodes.push(<div key="missing-quantify">{'* Missing quantity'}</div>);
            }

            return (
              <Tooltip title={<>{nodes}</>}>
                <WarningAmber color="primary" />
              </Tooltip>
            );
          },
        },
        {
          field: 'ticketImage',
          headerName: `${$t('approvals.image')}`,
          flex: 1,
          renderCell: (params: GridValueGetterParams) => {
            const t: Ticket = params.row;

            return (
              <>
                {t.imageUrl && (
                  <a
                    href={t.imageUrl}
                    target="_blank"
                    className="hover:underline"
                    rel="noreferrer"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {$t('approvals.view_image')}
                  </a>
                )}
              </>
            );
          },
        },
        {
          field: 'createdAtDate',
          headerName: `${$t('form_fields.date')}`,
          type: 'date',
          flex: 1,
          valueGetter: (params) => new Date(params.row.createdAt),
        },
        {
          field: 'createdAtTime',
          headerName: `${$t('form_fields.time')}`,
          type: 'dateTime',
          flex: 1,
          valueGetter: (params) => new Date(params.row.createdAt),
          valueFormatter: (params) => dateFormat(params.value, 'hh:mm A'),
        },
        {
          field: 'customerName',
          headerName: `${$t('form_fields.customer')}`,
          flex: 1,
          type: 'singleSelect',
        },
        {
          field: 'projectName',
          headerName: `${$t('form_fields.project')}`,
          flex: 1,
          type: 'singleSelect',
        },
        {
          field: 'orderId',
          headerName: `${$t('form_fields.order')}`,
          flex: 1,
          type: 'singleSelect',
        },
        {
          field: 'friendlyJobId',
          headerName: `${$t('form_fields.job')}`,
          flex: 1,
          type: 'singleSelect',
        },
        {
          field: 'materialName',
          headerName: `${$t('form_fields.material')}`,
          flex: 1,
          type: 'singleSelect',
        },
        {
          field: 'quantity',
          headerName: `${$t('form_fields.quantity')}`,
          flex: 1,
          type: 'singleSelect',
        },
        {
          field: 'pickupSiteName',
          headerName: `${$t('form_fields.site')} A`,
          flex: 1,
        },
        {
          field: 'dropoffSiteName',
          headerName: `${$t('form_fields.site')} B`,
          flex: 1,
        },
        {
          field: 'vendorName',
          headerName: `${$t('form_fields.vendor')}`,
          flex: 1,
        },
        {
          field: 'equipmentName',
          headerName: `${$t('form_fields.equipment')}`,
          flex: 1,
        },
        {
          field: 'source',
          headerName: `${$t('form_fields.source')}`,
          flex: 1,
        },
        {
          field: 'flagged',
          headerName: `${$t('form_fields.flagged')}`,
          flex: 1,
          headerAlign: 'center',
          align: 'center',
          valueGetter: (params: GridValueGetterParams) => {
            return params.value?.flagged || '';
          },
          renderCell: (params: GridValueGetterParams) => {
            const isFlagged = params.row?.flagged;
            return <>{isFlagged && <FlagOutlined color="primary" />}</>;
          },
        },
        {
          field: 'status',
          headerName: `${$t('form_fields.status')}`,
          flex: 1,
          headerAlign: 'center',
          align: 'center',
          minWidth: 125,
          valueGetter: (params: GridValueGetterParams) => {
            return params.value?.state || '';
          },
          renderCell: (params: GridValueGetterParams) => {
            const showApproval = params.row?.state && canEditTicket(params.row.state);
            return (
              <>
                {showApproval && (
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={<Check />}
                    onClick={(e) => {
                      e.stopPropagation();
                      doAction(params.row.id, 'approve');
                    }}
                  >
                    {$t('approvals.approve')}
                  </Button>
                )}
                {!showApproval && (
                  <Chip
                    label={params.row?.state}
                    color={GetStateColor(params.row.state)}
                    variant="outlined"
                  />
                )}
              </>
            );
          },
        },
        // Only renders for pagination, but will be used once ticket actions are implemented
        {
          field: 'actions',
          headerName: '',
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          hideable: false,
          headerAlign: 'center',
          align: 'center',
          renderHeader: () => (
            <HeaderNavigation
              count={ticketsStore.tickets?.length || 0}
              loading={loadingRef.current}
              pagination={ticketsStore.ticketsReviewPagination}
              callback={(ref: PaginationLink, searchQuery?: string) =>
                getTickets(ref, searchQuery, filterQuery)
              }
              altText={''}
            />
          ),
          renderCell: (params: GridValueGetterParams) => {
            const menuItems = [];
            if (canEditTicket(params.row.state)) {
              menuItems.push({
                title: `${params.row.flagged ? $t('approvals.remove_flag') : $t('approvals.add_flag')}`,
                callBack: () =>
                  doUpdateAction(params.row.id, { flagged: !params.row.flagged }),
              });
            }
            menuItems.push({
              title: `${$t('approvals.void_ticket')}`,
              callBack: () => doAction(params.row.id, 'void'),
            });
            return (
              <>
                {canEditTicket(params.row.state) && (
                  <SimpleMenu options={menuItems}>
                    <MoreHoriz />
                  </SimpleMenu>
                )}
              </>
            );
          },
        },
      ] as GridColDef[],
    [filterQuery],
  );

  return (
    <Box>
      <div className="pb-4">
        <TicketTabFilter
          value={tabFilter}
          onChange={(tab) => {
            setTabFilter(tab);
          }}
        />
      </div>
      <DataGrid
        id="tickets-review-grid"
        rows={rows as unknown as Record<string, Ticket>[]}
        columns={columns}
        loading={isLoading}
        disableColumnFilter
        hideQuickFilter
        headerSidebarComponent={
          <Box display={'flex'} alignItems={'center'} width={'100%'}>
            <DateRangeFilter
              sx={{ minWidth: '220px' }}
              name={'dates'}
              values={dateRangeFilterValue}
              callBack={setDateRangeFilterValue}
            ></DateRangeFilter>
          </Box>
        }
        onRowClick={(params) => {
          setSelectedTicket((state) => ({
            ...state,
            isDrawerOpen: true,
            ticket: params.row as Ticket,
          }));
        }}
      ></DataGrid>

      <Drawer
        anchor="right"
        onClose={() => setSelectedTicket((state) => ({ ...state, isDrawerOpen: false }))}
        open={selectedTicket.isDrawerOpen}
        variant="temporary"
      >
        <>
          {selectedTicket.job && (
            <TicketDetails
              job={selectedTicket.job}
              loadId={selectedTicket.ticket?.loadId}
              ticket={selectedTicket.ticket}
              onFlag={handleFlagTicket}
              onApprove={async (ticket) => {
                const approvedTicket = await doAction(ticket.id, 'approve');

                if (approvedTicket) {
                  setSelectedTicket((state) => ({ ...state, ticket: approvedTicket }));
                }
              }}
              onRemoveImage={async (ticket) => {
                await deleteTicketImageById({ id: ticket.id });
                const newTicket = ticket;
                newTicket.imageUrl = '';
                setSelectedTicket((state) => ({ ...state, ticket: newTicket }));
              }}
              onUploadImage={async (ticket, image) => {
                if (ticket) {
                  const updatedTicket = await uploadTicketImageById({
                    id: ticket.id,
                    file: image,
                  });

                  if (updatedTicket) {
                    setSelectedTicket((state) => ({ ...state, ticket: updatedTicket }));
                  }
                }
              }}
              onChange={saveTicket}
              onClose={() => {
                setSelectedTicket((state) => ({ ...state, isDrawerOpen: false }));
              }}
            />
          )}
        </>
      </Drawer>
    </Box>
  );
});

const GetStateColor = (state: TicketState) => {
  switch (state) {
    case TicketState.VOIDED:
      return 'error';
    case TicketState.APPROVED:
      return 'success';
    default:
      return 'info';
  }
};
