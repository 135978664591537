import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { LayerSpecification, LngLat, LngLatBoundsLike, NBMap } from '@nbai/nbmap-gl';
import { Equipment_Read_Nested } from '@treadinc/horizon-api-spec';
import bbox from '@turf/bbox';
import circle from '@turf/circle';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { flatten, unionBy, uniqueId } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';

import { BaseMap } from '~components/Maps/BaseMap';
import GoogleBaseMap from '~components/Maps/GoogleBaseMap';
import HybridBaseMap from '~components/Maps/HybridBaseMap';
import { SourceItem } from '~components/Maps/interfaces';
import pinImage from '~components/Maps/pin_blue.png';
import { LoadingSpinner } from '~components/Order/ordersDispatchStyledComponents';
import { TORONTO_OFFICE_COORDINATES } from '~constants/mapConsts';
import { Job } from '~hooks/useJob';
import {
  CLEAR_OLD_TRUCK_LOCATIONS_INTERVAL_IN_MS,
  EMPTY_FEATURE_COLLECTION,
  INITIAL_ZOOM_LEVEL,
  LIVE_MAP_GEOFENCE_FILL_LAYER_ID,
  LIVE_MAP_GEOFENCE_SOURCE_ID,
  LIVE_MAP_HIGHLIGHT_SOURCE_ID,
  LIVE_MAP_MOVING_SITE_GEOFENCE_FILL_SOURCE_ID,
  LIVE_MAP_MOVING_SITE_SOURCE_ID,
  LIVE_MAP_PINS_LAYER_ID,
  LIVE_MAP_ROUTE_SOURCE_ID,
  LIVE_MAP_SITES_LAYER_ID,
  LIVE_MAP_SITES_SOURCE_ID,
  LIVE_MAP_TRUCK_LAYER_ID,
  LIVE_MAP_TRUCK_SOURCE_ID,
  MAX_PING_TTL,
  SOURCE_DATA_IDS,
  VERTICES_IN_CIRCLE,
} from '~hooks/useLiveMap/constants';
import { JobFeatureProperties, LiveMapFeature } from '~hooks/useLiveMap/models';
import TruckMarkerFactory from '~hooks/useLiveMap/TruckMarkerFactory';
import { useLiveMap } from '~hooks/useLiveMap/useLiveMap';
import { useTruckLocations } from '~hooks/useLiveMap/useTruckLocations';
import { NextBillionAssetLocation } from '~hooks/useNextBillionAssetLocationHistories/models';
import { useNextBillionAssetLocationHistories } from '~hooks/useNextBillionAssetLocationHistories/useNextBillionAssetLocationHistories';
import { Order } from '~hooks/useOrders';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { Nullable } from '~types/Nullable';

import LivePopUp from './PopUp/LivePopUp';
import { TimeIndicatorContextProvider } from './Timeline/components/hooks/useTimeIndicatorContext';
import { Timeline } from './Timeline/Timeline';
import { AssetLocationType } from './types';

const defaultSources = Object.fromEntries(
  SOURCE_DATA_IDS.map((sourceId) => [
    sourceId,
    {
      id: sourceId,
      sourceData: EMPTY_FEATURE_COLLECTION,
    },
  ]),
);

const getEquipmentLabel = (equipment: Nullable<Equipment_Read_Nested>) => {
  return equipment
    ? `${equipment.company_share?.external_id ?? equipment.external_id ?? ''}${equipment.company_share?.external_id || equipment.external_id ? ' - ' : ''}${equipment.name ?? ''}`
    : '';
};

const buildTruckLocationFeature = (truckLocation: NextBillionAssetLocation) => {
  return {
    type: 'image',
    properties: {
      jobId: truckLocation.jobId,
      equipmentTrimmed: getEquipmentLabel(truckLocation.equipment),
      bearing: Number(truckLocation.bearing),
      state: truckLocation.jobState,
      id: truckLocation.nextBillionAssetId,
      startedAt: truckLocation.createdAt.toISOString(),
      type: truckLocation.radii?.length
        ? AssetLocationType.MOVING_SITE
        : AssetLocationType.TRUCK,
    } as JobFeatureProperties,
    geometry: {
      type: 'Point',
      coordinates: [Number(truckLocation.lon), Number(truckLocation.lat)],
    },
  } as LiveMapFeature;
};

const controlsToAdd = ['navigation'];

/*
 * TreadLivePage
 *
 * The TreadLivePage component is responsible for rendering the live map view using only location data pulled from Tread APIs.
 */
const TreadLiveMap = ({
  order,
  job,
  defaultFetchRoutePings = true,
  isSidebarCollapsed,
}: {
  order?: Order;
  job?: Job;
  defaultFetchRoutePings?: boolean;
  isSidebarCollapsed?: boolean;
}) => {
  const { userStore } = useStores();
  const {
    createLiveMapRouteSource,
    fetchTreadRoute,
    createLiveMapTrucksLayer,
    createLiveMapRouteLayer,
    createLiveMapSitesLayer,
    createLiveMapGeofenceLayer,
    liveMapHighlightLayer,
    createLiveMapSitesSource,
    createLiveMapMovingSiteLayer,
    createLiveMapMovingPinLayer,
  } = useLiveMap();

  const { subscription, subscribeToLastLocationUpdates } = useTruckLocations();
  const { getLatestLocations } = useNextBillionAssetLocationHistories();
  const [isScrubberVisible, setIsScrubberVisible] = useState(defaultFetchRoutePings);
  const [routePings, setRoutePings] = useState<NextBillionAssetLocation[]>([]);
  const [sources, setSources] = useState(defaultSources);
  const [mapInstance, setMapInstance] = useState<NBMap | null>(null);
  const [isFinishedLoadingAllActiveTrucks, setIsFinishedLoadingAllActiveTrucks] =
    useState(true);
  const [loadingRoutePings, setLoadingRoutePings] = useState(false);
  /**
   * LIVE MAP STORE MIMIC BEGIN
   */
  const [shouldFitMapFeatures, setShouldFitMapFeatures] = useState(false);
  const [truckLocations, setTruckLocations] = useState<NextBillionAssetLocation[]>([]);
  const [driverRoutePageNumber, setDriverRoutePageNumber] = useState(1);
  const [showGoogleMap, setShowGoogleMap] = useState(false);
  /**
   * LIVE MAP STORE MIMIC END
   */
  const sourceList = useMemo(() => Object.values(sources), [sources]);
  const layers = useMemo(() => {
    return [
      ...createLiveMapGeofenceLayer(),
      ...createLiveMapRouteLayer(),
      ...liveMapHighlightLayer(),
      ...createLiveMapSitesLayer(),
      ...createLiveMapMovingSiteLayer(),
      ...createLiveMapMovingPinLayer(),
      ...createLiveMapTrucksLayer(),
    ];
  }, []);
  const mapCenter = useMemo(() => {
    return {
      lng: userStore.userCompany?.defaultLon || TORONTO_OFFICE_COORDINATES.lng,
      lat: userStore.userCompany?.defaultLat || TORONTO_OFFICE_COORDINATES.lat,
    };
  }, []);
  const TreadLiveMapContainerID = useMemo(
    () => uniqueId('tread-live-map-container-'),
    [],
  );

  /**
   *
   * HELPER FUNCTIONS –––– BEGIN
   *
   */

  useEffect(() => {
    if (mapInstance) {
      const timeoutId = setTimeout(() => {
        mapInstance.map.resize();
      }, 300); // Accommodates the sidebar animation duration
      return () => clearTimeout(timeoutId);
    }
  }, [isSidebarCollapsed, mapInstance]);

  const animateFeatureMovement = (
    featureProperties: JobFeatureProperties,
    fromCoordinates: number[],
    toCoordinates: number[],
    sourceId: string,
  ) => {
    const animationDuration = 600000; // Animation duration in milliseconds
    const startTime = Date.now();

    const animateFrame = () => {
      const currentTime = Date.now();
      const progress = Math.min(1, (currentTime - startTime) / animationDuration);

      // Interpolate coordinates based on progress
      const interpolatedCoordinates = [
        fromCoordinates[0] + (toCoordinates[0] - fromCoordinates[0]) * progress,
        fromCoordinates[1] + (toCoordinates[1] - fromCoordinates[1]) * progress,
      ];

      // Update the feature's state with interpolated coordinates
      mapInstance?.map.setFeatureState(
        { source: sourceId, id: featureProperties.id },
        { coordinates: interpolatedCoordinates, bearing: featureProperties.bearing },
      );

      if (progress < 1) {
        // Continue the animation
        requestAnimationFrame(animateFrame);
      }
    };

    // Start the animation
    animateFrame();
  };

  /**
   * This function is called when the map instance is mounted, which triggers the rest of the logic.
   */
  const setMapInstanceCallBack = (map: NBMap) => {
    if (map) {
      setMapInstance(() => map);
    }
  };

  const getSource = (sourceId: string) => {
    return mapInstance?.map.getSource(sourceId) || null;
  };

  const updateSource = (sourceId: string, sourceData: any) => {
    setSources((oldSource) => {
      oldSource[sourceId] = {
        id: sourceId,
        sourceData: sourceData,
      };
      return { ...oldSource };
    });
  };

  const fitToAllFeatureBounds = (features: any[]) => {
    const boundsBox = bbox({
      type: 'FeatureCollection',
      features: features,
    });

    mapInstance?.map.fitBounds(boundsBox as LngLatBoundsLike, {
      padding: { top: 100, bottom: 100, left: 100, right: 100 },
      duration: 3000,
    });
    setShouldFitMapFeatures(false);
  };
  /**
   *
   * HELPER FUNCTIONS –––– END
   *
   */

  /**
   *
   *  TRUCK RELATED FUNCTIONS ———— BEGIN
   *
   * */
  const hasRunForJob = useRef(false);
  const hasRunForOrder = useRef(false);

  const updateSourcesAndLoadTrucks = () => {
    updateSource(LIVE_MAP_TRUCK_SOURCE_ID, EMPTY_FEATURE_COLLECTION);
    updateSource(LIVE_MAP_MOVING_SITE_SOURCE_ID, EMPTY_FEATURE_COLLECTION);
    updateSource(LIVE_MAP_MOVING_SITE_GEOFENCE_FILL_SOURCE_ID, EMPTY_FEATURE_COLLECTION);
    loadTruckLocations();
    setShouldFitMapFeatures(true);
  };

  useEffect(() => {
    if (!mapInstance) return;

    if (mapInstance && job && !hasRunForJob.current) {
      hasRunForJob.current = true;
    } else if (mapInstance && order && !hasRunForOrder.current) {
      hasRunForOrder.current = true;
    }

    updateSourcesAndLoadTrucks();
  }, [mapInstance, job, order]);

  /**
   * Subscribes to the last location updates for the user's company and updates the truck location on the map.
   */
  useEffect(() => {
    if (mapInstance && !subscription && userStore.userCompany?.id) {
      subscribeToLastLocationUpdates({
        onMessageCallBack: (data: NextBillionAssetLocation) => {
          // If an order is selected, only update the truck location if the order id matches the incoming data
          if (order && data.orderId !== order.id) return;
          // If a job is selected, only update the truck location if the job id matches the incoming data
          if (job && data.jobId !== job.id) return;

          updateTruckRealtimeSourceCallBack([data]);
        },
      });
    }

    return () => {
      subscription?.unsubscribe?.();
    };
  }, [subscription, mapInstance, userStore.userCompany?.id]);

  useEffect(() => {
    if (mapInstance && isFinishedLoadingAllActiveTrucks && truckLocations.length > 0) {
      updateTruckRealtimeSourceCallBack(truckLocations);
    }
  }, [isFinishedLoadingAllActiveTrucks, mapInstance, truckLocations.length]);

  useEffect(() => {
    if (!mapInstance) return;

    const intervalId = setInterval(
      clearOldTruckLocations,
      CLEAR_OLD_TRUCK_LOCATIONS_INTERVAL_IN_MS,
    );
    clearOldTruckLocations();

    return () => {
      setTruckLocations([]);
      clearInterval(intervalId);
    };
  }, [mapInstance]);

  const loadTruckLocations = () => {
    getLatestTruckLocations().then((data) => {
      setTruckLocations(data);
    });
  };

  const getLatestTruckLocations = async () => {
    //@todo, only fetch the truck location for this given job, if a job is present
    const truckLocations = [];
    let afterLink = null;
    let hasMore = true;

    setIsFinishedLoadingAllActiveTrucks(false);
    while (hasMore) {
      const { data, pagination } = await getLatestLocations({
        job_id: job?.id,
        order_id: order?.id,
        linkType: 'after',
        link: afterLink,
      });

      truckLocations.push(data);
      if (pagination?.after && data.length !== 0) {
        afterLink = pagination.after;
      } else {
        hasMore = false;
      }
    }
    setIsFinishedLoadingAllActiveTrucks(true);

    return flatten(truckLocations).flatMap((item) => item);
  };

  const updateTruckRealtimeSourceCallBack = (
    locationData: NextBillionAssetLocation[],
  ) => {
    const truckPins = locationData.filter((data) => !data.radii?.length);
    const truckLocationFeatures = truckPins.map(buildTruckLocationFeature);
    updateLocationSource(truckLocationFeatures, LIVE_MAP_TRUCK_SOURCE_ID);

    const movingSitePins = locationData.filter((data) => data.radii?.length);
    const movingSiteFeatures = movingSitePins.map(buildTruckLocationFeature);
    updateLocationSource(movingSiteFeatures, LIVE_MAP_MOVING_SITE_SOURCE_ID);

    const movingSiteGeofences = movingSitePins.map((data) => {
      const radii = data.radii || [];
      return radii
        .map((r) => r.radiusMeters)
        .sort()
        .map((radius, idx) => {
          const circleGeofence = circle([Number(data.lon), Number(data.lat)], radius, {
            steps: VERTICES_IN_CIRCLE,
            units: 'meters',
          });
          return {
            type: 'Feature',
            properties: {
              id: data.nextBillionAssetId + `_geofence-${idx}`,
              opacity: 0.25 - idx * 0.05,
              type: AssetLocationType.MOVING_SITE,
            } as JobFeatureProperties,
            geometry: circleGeofence.geometry,
          };
        });
    });

    updateLocationSource(
      flatten(movingSiteGeofences),
      LIVE_MAP_MOVING_SITE_GEOFENCE_FILL_SOURCE_ID,
    );
  };

  const updateLocationSource = (data: LiveMapFeature[], sourceId: string) => {
    if (!mapInstance) return;

    // Get the MapboxGL source
    const source = getSource(sourceId);
    if (!source) return;

    // Get the current features from the source
    // Not ideal but the current type defined from NB does not include the _data property
    // Even though it is available on the source object from mapbox.
    //@ts-ignore
    const currentFeatures = source?._data?.features || [];

    // Filter out features with invalid coordinates (null/undefined or [0, 0])
    const filteredNewFeatures = data.filter(
      (newFeature) =>
        !!newFeature.geometry.coordinates &&
        newFeature.geometry.coordinates[0] !== 0 &&
        newFeature.geometry.coordinates[1] !== 0,
    );

    // Create a new array of features with updated coordinates
    const mergedFeatures = unionBy(filteredNewFeatures, currentFeatures, 'properties.id');

    // Animate the movement of the existing truck features
    filteredNewFeatures.map((newFeature: LiveMapFeature) => {
      const existingFeature: LiveMapFeature = currentFeatures.find(
        (feature: LiveMapFeature) =>
          (feature.properties as JobFeatureProperties).id ===
          (newFeature.properties as JobFeatureProperties).id,
      );

      if (existingFeature) {
        const fromCoordinates = existingFeature.geometry.coordinates;
        const toCoordinates = newFeature.geometry.coordinates;

        // Animate the movement of the feature
        animateFeatureMovement(
          existingFeature.properties as JobFeatureProperties,
          // Locations feature will always have coordinates since we filter out features with invalid coordinates above.
          fromCoordinates as number[],
          toCoordinates as number[],
          sourceId,
        );
      }
    });

    // Update the source with the new FeatureCollection
    const sourceData = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: mergedFeatures,
      },
    };

    updateSource(sourceId, sourceData);

    // Fit map to bounds if needed
    if (mergedFeatures.length && shouldFitMapFeatures) {
      fitToAllFeatureBounds(mergedFeatures);
    }
  };

  /**
   * Clears old truck locations from the LiveMap.
   * If viewing a route and a selected job is present, the function returns early since we are not showing additional truck markers.
   * Retrieves the MapboxGL source and filters out features that are older than the MAX_PING_TTL.
   * Updates the source data for LIVE_MAP_TRUCK_SOURCE_ID with the filtered features.
   */
  const clearOldTruckLocations = () => {
    if (job) return;

    // Get the MapboxGL source
    const source = getSource(LIVE_MAP_TRUCK_SOURCE_ID);

    if (source) {
      // Get the current features from the source
      // Not ideal but the current type defined from NB does not include the _data property
      // Even though it is available on the source object from mapbox.
      //@ts-ignore
      const currentFeatures = source?._data?.features || [];

      const filteredFeatures = currentFeatures.filter((feature: LiveMapFeature) => {
        const startedAt = dayjs((feature.properties as JobFeatureProperties).startedAt);
        const tenMinutesAgo = dayjs().subtract(MAX_PING_TTL, 'minute');

        return startedAt.isAfter(tenMinutesAgo);
      });

      const sourceData = {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: filteredFeatures,
        },
      };
      updateSource(LIVE_MAP_TRUCK_SOURCE_ID, sourceData);
    }
  };
  /**
   *
   *  TRUCK RELATED FUNCTIONS ———— END
   *
   * */

  /**
   *
   * ROUTE RELATED FUNCTIONS ———— BEGIN
   *
   * */

  useEffect(() => {
    if (!(mapInstance && isScrubberVisible && job)) return;

    renderDriverRouteForJob(job);
  }, [isScrubberVisible, mapInstance, job]);

  useEffect(() => {
    if (!mapInstance) return;
    clearRouteView();
    if (order) {
      createLiveMapSitesSource(order).then((sourceData) => {
        updateSource(LIVE_MAP_SITES_SOURCE_ID, sourceData.pinFeaturesData);
        updateSource(LIVE_MAP_GEOFENCE_SOURCE_ID, sourceData.geofenceFeaturesData);
        fitToAllFeatureBounds(sourceData.pinFeaturesData.data.features);
      });
    }
    if (!job) {
      updateSource(LIVE_MAP_SITES_SOURCE_ID, EMPTY_FEATURE_COLLECTION);
      updateSource(LIVE_MAP_GEOFENCE_SOURCE_ID, EMPTY_FEATURE_COLLECTION);
    } else {
      createLiveMapSitesSource(job).then((sourceData) => {
        updateSource(LIVE_MAP_SITES_SOURCE_ID, sourceData.pinFeaturesData);
        updateSource(LIVE_MAP_GEOFENCE_SOURCE_ID, sourceData.geofenceFeaturesData);
        fitToAllFeatureBounds(sourceData.pinFeaturesData.data.features);
      });
    }
  }, [job, order, mapInstance]);

  useEffect(() => {
    if (!job || !mapInstance) return;
    const driverRouteSource = getSource(LIVE_MAP_ROUTE_SOURCE_ID);

    if (driverRouteSource) {
      // Not ideal but the current type defined from NB does not include the _data property
      // Even though it is avalible on the source object from mapbox.
      //@ts-ignore
      const currentRouteFeatures = driverRouteSource._data?.features || [];

      updateSource(
        LIVE_MAP_ROUTE_SOURCE_ID,
        createLiveMapRouteSource(routePings, currentRouteFeatures),
      );
    }
  }, [job, routePings, mapInstance]);

  /**
   * Fetches the the driver route for a given job through NB api and updates to route source to show that data.
   *
   * @param job - The job to fetch the driver route for.
   */
  const renderDriverRouteForJob = (job: Job) => {
    setLoadingRoutePings(true);
    fetchTreadRoute(job.id).then((data) => {
      updateRoutePings(data);
      setLoadingRoutePings(false);
    });
  };

  const clearRouteView = () => {
    // Clear the route pings while fetching the new route, which also hides the scrubber
    setRoutePings([]);

    // Clear the driver route and highlight source data
    updateSource(LIVE_MAP_ROUTE_SOURCE_ID, EMPTY_FEATURE_COLLECTION);
    updateSource(LIVE_MAP_HIGHLIGHT_SOURCE_ID, EMPTY_FEATURE_COLLECTION);
  };

  /**
   * Updates the route pings based on the provided route data if the highlight route source is visble.
   *
   * @param routeData The route data containing the pings.
   */
  const updateRoutePings = (routeData: NextBillionAssetLocation[]) => {
    const highlightSource = getSource(LIVE_MAP_HIGHLIGHT_SOURCE_ID);

    if (highlightSource && mapInstance) {
      setRoutePings([
        // Since the last page is received, replaced the last page (pages start at 1) of pings with the new pings
        ...routePings.slice(0, (driverRoutePageNumber - 1) * 100),
        ...routeData,
      ]);
    }
  };

  /**
   * Sets the highlight ping on the live map based on the given time index.
   *
   * @param timeIdx - The index of the time for which to set the highlight ping.
   */
  const setHightlightPingByTime = (timeIdx: number) => {
    if (routePings[timeIdx]) {
      const coordinate = [routePings[timeIdx].lon, routePings[timeIdx].lat];

      const sourceData = {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [
            {
              properties: {
                color: theme.palette.success.main,
                size: 8,
                haloColor: 'rgba(46, 125, 50, 0.4)',
                haloSize: 12,
              },
              geometry: {
                type: 'Point',
                coordinates: coordinate,
              },
            },
          ],
        },
      };

      updateSource(LIVE_MAP_HIGHLIGHT_SOURCE_ID, sourceData);
    }
  };
  /**
   *
   * ROUTE RELATED FUNCTIONS ———— END
   *
   * */

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        position: 'relative',
      }}
      display="flex"
      flexDirection="column"
    >
      <HybridBaseMap
        isPopUpCustom={true}
        PopUpComponent={LivePopUp}
        containerId={TreadLiveMapContainerID}
        imagesToAdd={[
          { name: 'pin_icon', path: pinImage },
          ...TruckMarkerFactory.registerAssets(),
        ]}
        center={mapCenter as LngLat}
        layers={layers as LayerSpecification[]}
        clickable={[
          LIVE_MAP_PINS_LAYER_ID,
          LIVE_MAP_SITES_LAYER_ID,
          LIVE_MAP_GEOFENCE_FILL_LAYER_ID,
          LIVE_MAP_TRUCK_LAYER_ID,
        ]}
        sources={sourceList as unknown as SourceItem[]}
        controlsToAdd={controlsToAdd}
        markers={[]}
        zoom={INITIAL_ZOOM_LEVEL}
        minZoom={1}
        sx={{
          height: '100%',
          width: '100%',
        }}
        mapLoadCallBack={setMapInstanceCallBack}
      />

      {!isScrubberVisible ? (
        <Box m={1} position={'absolute'} right={0} bottom={0} zIndex={50}>
          <Button onClick={() => setIsScrubberVisible(true)}>
            {t('live_map.actions.view_route_history')}
          </Button>
        </Box>
      ) : (
        <Box position="relative">
          <Box
            m={2}
            sx={{ display: loadingRoutePings ? 'flex' : 'none' }}
            alignItems={'center'}
            gap={2}
          >
            <Typography noWrap textOverflow={'visible'} variant="body1">
              {t('live_map.actions.loading_route_history')}
            </Typography>
            <LoadingSpinner isVisible={loadingRoutePings} sx={{ width: 'fit-content' }} />
          </Box>
          <TimeIndicatorContextProvider>
            <Timeline
              pings={routePings}
              onHover={(timeIdx: number) => setHightlightPingByTime(timeIdx)}
            />
          </TimeIndicatorContextProvider>
          {!defaultFetchRoutePings && isScrubberVisible && routePings.length ? (
            <Box m={1} position={'absolute'} right={0} bottom={0} zIndex={50}>
              <Button onClick={() => setIsScrubberVisible(false)}>
                {t('live_map.actions.close_route_history')}
              </Button>
            </Box>
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default TreadLiveMap;
