import {
  Contact_Create,
  Contact_Update,
  Phase_Create,
  Phase_Read,
  Phase_Update,
  Project_Create,
  Project_Read as ProjectProto,
  Project_Read_Nested,
  Project_Update,
  ProjectMaterialTotal_Create,
  ProjectMaterialTotal_Read,
  RateType,
  User_Me_Read,
  WaypointType,
} from '@treadinc/horizon-api-spec';
import dayjs, { Dayjs } from 'dayjs';
import { cloneDeep, compact } from 'lodash';
import _ from 'lodash';

import { ContactTypes, contactTypesOptions, RateTypes } from '~constants/enums';
import { AccountBasic } from '~hooks/useAccount';
import { CompanyBasic } from '~hooks/useCompany';
import { ContactItem } from '~hooks/useContact';
import { BasicDepartment } from '~hooks/useDepartment';
import { EquipmentTypeBasic } from '~hooks/useEquipment';
import { BasicMaterial } from '~hooks/useMaterials';
import { RateBasicWithValue } from '~hooks/useRates';
import { BasicServiceClass } from '~hooks/useServiceClass';
import { Service } from '~hooks/useServices';
import { SiteBasic, WayPoint, WayPointFormProps } from '~hooks/useSites';
import {
  deparseRateDetailsDTO,
  deparseUpdateRateDetailsDTO,
} from '~pages/Sales/Orders/NewOrderFormComponents/schema';
import { ProjectFormSchemaInterface } from '~pages/Sales/Projects/projectFormSchema';
import { ItemNameAndId } from '~types/ItemNameAndId';
import { Nullable } from '~types/Nullable';

import { User } from '../useUsers';

export enum ProjectState {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export class ExtendedContactItem extends ContactItem {
  public static parse(proto: any): ExtendedContactItem {
    return new ExtendedContactItem(
      proto.id ?? '',
      proto.name ?? '',
      proto.email ?? '',
      proto.phone ?? '',
      proto.type ? contactTypesOptions.filter((item) => item.id === proto.type)[0] : null,
    );
  }
  public get type(): Nullable<ItemNameAndId> {
    return this._type;
  }
  public set type(value: ItemNameAndId) {
    this._type = value;
  }
  constructor(
    _id: string,
    _name: string,
    _email: string,
    _phone: string,
    private _type: Nullable<ItemNameAndId>,
  ) {
    super(_id, _name, _email, _phone);
  }
}

export class Phase extends ItemNameAndId {
  public static parse(proto: Phase_Read | Record<string, any>): Phase {
    return new Phase(
      proto.id ?? '',
      proto.name ?? '',
      proto.code ?? '',
      proto.primary ?? false,
    );
  }
  public static deparseUpdate(phase: Phase): Phase_Update {
    return {
      id: phase.id === '' ? undefined : phase.id,
      name: phase.name || '',
      code: phase.code || undefined,
      primary: phase.primary || false,
      _destroy: phase._destroy || undefined,
    };
  }
  public static deparse(phase: Phase): Phase_Create {
    return {
      name: phase.name || '',
      code: phase.code || undefined,
      primary: phase.primary || false,
    };
  }
  public get code(): string | undefined {
    return this._code;
  }
  public set code(value: string | undefined) {
    this._code = value;
  }
  public get primary(): boolean {
    return this._primary;
  }

  constructor(
    _id: string,
    _name: string,
    private _code: string | undefined,
    private _primary: boolean,
    private _destroy: number | undefined = undefined,
  ) {
    super(_name, _id);
  }
}
export class ProjectBasic extends ItemNameAndId {
  public static parse(proto: Project_Read_Nested): ProjectBasic {
    return new ProjectBasic(
      proto.id ?? '',
      proto.name ?? '',
      proto.external_id ?? '',
      proto.notes ?? '',
      proto.order_notes ?? null,
      proto.job_notes ?? null,
      proto.internal_notes ?? null,
    );
  }
  public get externalId(): string {
    return this._external_id;
  }
  public get notes(): string {
    return this._notes;
  }
  public get orderNotes(): Nullable<string> {
    return this._order_notes;
  }
  public get jobNotes(): Nullable<string> {
    return this._job_notes;
  }
  public get internalNotes(): Nullable<string> {
    return this._internal_notes;
  }
  constructor(
    _id: string,
    _name: string,
    private _external_id: string,
    private _notes: string,
    private _order_notes: Nullable<string>,
    private _job_notes: Nullable<string>,
    private _internal_notes: Nullable<string>,
  ) {
    super(_name, _id);
  }
}
export class ProjectMaterialTotal {
  public static parse(proto: ProjectMaterialTotal_Read): ProjectMaterialTotal {
    return new ProjectMaterialTotal(
      proto.id ?? '',
      proto.total_quantity ?? '',
      proto.unit_of_measure
        ? ItemNameAndId.parse({
            name: proto.unit_of_measure,
            id: proto.unit_of_measure,
          })
        : null,
      proto.material ? BasicMaterial.parse(proto.material) : null,
    );
  }
  public get material(): Nullable<BasicMaterial> {
    return this._material;
  }
  public set material(value: Nullable<BasicMaterial>) {
    this._material = value;
  }
  public get unitOfMeasure(): Nullable<ItemNameAndId> {
    return this._unit_of_measure;
  }
  public set unitOfMeasure(value: Nullable<ItemNameAndId>) {
    this._unit_of_measure = value;
  }
  public get totalQuantity(): string {
    return this._total_quantity;
  }
  public set totalQuantity(value: string) {
    this._total_quantity = value;
  }
  public get id(): string {
    return this._id;
  }
  constructor(
    private _id: string,
    private _total_quantity: string,
    private _unit_of_measure: Nullable<ItemNameAndId>,
    private _material: Nullable<BasicMaterial>,
  ) {}
}
interface ContactTypeMap {
  [key: string]: ContactTypes;
}

export const contactTypeMap: ContactTypeMap = {
  formenContact: ContactTypes.FOREMAN,
  salesContact: ContactTypes.SALES,
  collaboratorContact: ContactTypes.COLLABORATOR,
  supervisorContact: ContactTypes.SUPERVISOR,
};

export const filterContact = (
  project: Project | any,
  contactType: ContactTypes,
): ExtendedContactItem | null => {
  return (
    project?.allContacts
      ?.filter((contact: ExtendedContactItem) => contact?.type?.id === contactType)
      ?.find(Boolean) || null
  );
};

export const transformContact = (contact: ExtendedContactItem | null | any) => {
  if (contact?.isDeleted) {
    return { id: contact.id, _destroy: 1 };
  }
  if (!contact) return null;
  return ContactItem.deparse(contact);
};

export class Project extends ProjectBasic {
  public static parse(proto: ProjectProto): Project {
    return new Project(
      proto.id ?? '',
      proto.name ?? '',
      proto.notes ?? '',
      proto.created_at ?? '',
      proto.updated_at ?? '',
      proto.expires_at ? dayjs(proto.expires_at) : null,
      proto.effective_at ? dayjs(proto.effective_at) : null,
      proto.po_job_number ?? '',
      proto.external_id ?? '',
      proto.project_id ?? '',
      proto.company ? CompanyBasic.parse(proto.company) : null,
      proto.state ? proto.state : null,
      proto.contact ? ContactItem.parse(proto.contact) : null,
      proto.account ? AccountBasic.parse(proto.account) : null,
      proto.site ? SiteBasic.parse(proto.site) : null,
      proto.phases ? proto.phases.map((item) => Phase.parse(item)) : [],
      proto.project_material_totals
        ? proto.project_material_totals.map((item) => ProjectMaterialTotal.parse(item))
        : [],
      proto.sales_contact
        ? ExtendedContactItem.parse({ ...proto.sales_contact, type: ContactTypes.SALES })
        : null,
      proto.foreman_contact
        ? ExtendedContactItem.parse({
            ...proto.foreman_contact,
            type: ContactTypes.FOREMAN,
          })
        : null,
      proto.collaborator_contact
        ? ExtendedContactItem.parse({
            ...proto.collaborator_contact,
            type: ContactTypes.COLLABORATOR,
          })
        : null,
      proto.supervisor_contact
        ? ExtendedContactItem.parse({
            ...proto.supervisor_contact,
            type: ContactTypes.SUPERVISOR,
          })
        : null,
      proto.department ? BasicDepartment.parse(proto.department) : null,
      proto.foremen?.length
        ? proto?.foremen.map((item) => User.parse(item as User_Me_Read))
        : [],
      proto.hauler_rate_type ? (proto.hauler_rate_type as RateTypes) : null,
      proto.service ? Service.parse(proto.service) : null,
      proto.service_class ? BasicServiceClass.parse(proto.service_class) : null,
      proto.job_time ?? null,
      proto.order_quantity ?? null,
      proto.equipment_type ? EquipmentTypeBasic.parse(proto.equipment_type) : null,
      proto.loads_per_truck ?? null,
      proto.truck_count ?? null,
      proto.units_per_hour ?? null,
      proto.job_quantity ?? null,
      proto.order_notes ?? '',
      proto.job_notes ?? '',
      proto.internal_notes ?? '',
      proto?.waypoints?.length ? proto.waypoints.map(WayPoint.parse) : null,
      proto?.waypoints?.length
        ? proto.waypoints
            .filter((point) => {
              return point.type === WaypointType.PICKUP;
            })
            .map(WayPoint.parse)
        : null,
      proto?.waypoints?.length
        ? proto.waypoints
            .filter((point) => {
              return point.type === WaypointType.DROP_OFF;
            })
            .map(WayPoint.parse)
        : null,
      proto.customer_rate ? RateBasicWithValue.parse(proto.customer_rate) : null,
      proto.customer_rate_value ? Number(proto.customer_rate_value) : null,
      proto.customer_rate_type,
      proto.vendor_rate ? RateBasicWithValue.parse(proto.vendor_rate) : null,
      proto.vendor_rate_value ? Number(proto.vendor_rate_value) : null,
      proto.vendor_rate_type,
      proto.driver_rate ? RateBasicWithValue.parse(proto.driver_rate) : null,
      proto.driver_rate_value ? Number(proto.driver_rate_value) : null,
      proto.driver_rate_type,
    );
  }
  //Sergio & veni define interface based on form structure, not necearily the same as the Project
  public static deparse(project: Project | any): Project_Create {
    const { formenContact, salesContact, collaboratorContact, supervisorContact } =
      this.getProjectContacts(project);

    const customerRate = deparseRateDetailsDTO('customer', {
      targetRate: project.customerRate,
      targetRateType: project.customerRateType,
      targetRateValue: project.customerRateValue,
    });
    const vendorRate = deparseRateDetailsDTO('vendor', {
      targetRate: project.vendorRate,
      targetRateType: project.vendorRateType,
      targetRateValue: project.vendorRateValue,
    });
    const driverRate = deparseRateDetailsDTO('driver', {
      targetRate: project.driverRate,
      targetRateType: project.driverRateType,
      targetRateValue: project.driverRateValue,
    });

    return {
      name: project.name,
      company_id: project?.company?.id,
      effective_at: project.effectiveAt
        ? dayjs(project.effectiveAt).toISOString()
        : undefined,
      expires_at: project.expiresAt ? dayjs(project.expiresAt).toISOString() : undefined,
      notes: project.projectNotes || '',
      po_job_number: project.poJobNumber || '',
      external_id: project.externalId || '',
      contact: project.contact ? ContactItem.deparse(project.contact) : undefined,
      account_id: project?.account?.id || undefined,
      site_id: project?.site?.id || undefined,
      phases: project?.phases?.length ? project?.phases?.map(Phase.deparse) : [],
      sales_contact: salesContact ? (salesContact as Contact_Create) : undefined,
      foreman_contact: formenContact ? (formenContact as Contact_Create) : undefined,
      collaborator_contact: collaboratorContact
        ? (collaboratorContact as Contact_Create)
        : undefined,
      supervisor_contact: supervisorContact
        ? (supervisorContact as Contact_Create)
        : undefined,
      //Keep this for now, material is not required but is visible in the form, so we need to validate there are values there
      project_material_totals:
        project?.projectMaterialTotals?.length &&
        project?.projectMaterialTotals?.[0]?.material?.id
          ? project?.projectMaterialTotals?.map(
              (singleTotal: {
                material: BasicMaterial;
                totalQuantity: string | number;
                unitOfMeasure: ItemNameAndId;
              }) => {
                return {
                  material_id: singleTotal?.material?.id || '',
                  total_quantity: singleTotal?.totalQuantity || 0,
                  unit_of_measure: singleTotal?.unitOfMeasure?.id || '',
                };
              },
            )
          : undefined,
      department_id: project?.department?.id || null,
      foreman_ids: project?.foremen?.map((foreman: User_Me_Read) => foreman.id) || null,
      service_id: project?.service?.id || null,
      service_class_id: project?.serviceClass?.id || null,
      job_time: project?.jobTime || null,
      order_quantity: project?.quantity || null,
      equipment_type_id: project?.equipmentType?.id || null,
      loads_per_truck: project?.loadsPerTruck || null,
      truck_count: project?.truckCount || null,
      units_per_hour: project?.unitsPerHour || null,
      job_quantity: project?.jobQuantity || null,
      order_notes: project?.orderNotes || '',
      job_notes: project?.jobNotes || '',
      internal_notes: project?.internalNotes || '',
      waypoints: compact([
        project?.dropOffWayPoint
          ? WayPoint.deparse(project?.dropOffWayPoint as WayPointFormProps)
          : null,
        project?.pickUpWayPoint
          ? WayPoint.deparse(project?.pickUpWayPoint as WayPointFormProps)
          : null,
      ]),
      ...customerRate,
      ...vendorRate,
      ...driverRate,
    };
  }
  public static getProjectContacts(project: Project | any) {
    const contacts: Record<string, any> = {};
    Object.keys(contactTypeMap).forEach((key) => {
      const contactType = contactTypeMap[key];
      const contact = filterContact(project, contactType);
      contacts[key] = transformContact(contact);
    });
    return contacts;
  }
  public static deparseUpdate(project: ProjectFormSchemaInterface): Project_Update {
    const { formenContact, salesContact, collaboratorContact, supervisorContact } =
      this.getProjectContacts(project);

    const customerRate = deparseUpdateRateDetailsDTO('customer', {
      targetRate: project.customerRate,
      targetRateType: project.customerRateType,
      targetRateValue: project.customerRateValue,
    });
    const vendorRate = deparseUpdateRateDetailsDTO('vendor', {
      targetRate: project.vendorRate,
      targetRateType: project.vendorRateType,
      targetRateValue: project.vendorRateValue,
    });
    const driverRate = deparseUpdateRateDetailsDTO('driver', {
      targetRate: project.driverRate,
      targetRateType: project.driverRateType,
      targetRateValue: project.driverRateValue,
    });

    return {
      name: project.name,
      effective_at: project.effectiveAt
        ? dayjs(project.effectiveAt).toISOString()
        : undefined,
      expires_at: project.expiresAt ? dayjs(project.expiresAt).toISOString() : undefined,
      notes: project.projectNotes || '',
      po_job_number: project.poJobNumber || '',
      external_id: project.externalId || '',
      site_id: project?.site?.id || undefined,
      phases: project?.phases?.length
        ? (project?.phases?.map((phase) => {
            return phase.isDeleted
              ? {
                  id: phase.id,
                  _destroy: 1,
                }
              : {
                  id: phase.id || undefined,
                  name: phase.name || '',
                  code: phase.code || undefined,
                };
          }) as Phase_Create[])
        : [],

      sales_contact: salesContact ? (salesContact as Contact_Update) : undefined,
      foreman_contact: formenContact ? formenContact : undefined,
      collaborator_contact: collaboratorContact
        ? (collaboratorContact as Contact_Update)
        : undefined,
      supervisor_contact: supervisorContact ? supervisorContact : undefined,
      project_material_totals:
        project?.projectMaterialTotals?.length &&
        project?.projectMaterialTotals?.[0]?.material?.id
          ? (project?.projectMaterialTotals?.map((singleTotal) => {
              return singleTotal?.isDeleted
                ? {
                    id: singleTotal?.id || undefined,
                    _destroy: 1,
                  }
                : {
                    id: singleTotal?.id || undefined,
                    material_id: singleTotal?.material?.id || '',
                    total_quantity: singleTotal?.totalQuantity || 0,
                    unit_of_measure: singleTotal?.unitOfMeasure?.id || '',
                  };
            }) as ProjectMaterialTotal_Create[])
          : [],
      department_id: project?.department?.id || null,
      foreman_ids: project?.foremen?.map((foreman) => foreman.id) || [],
      service_id: project?.service?.id || null,
      service_class_id: project?.serviceClass?.id || null,
      job_time: project?.jobTime || null,
      order_quantity: Number(project?.quantity) || null,
      equipment_type_id: project?.equipmentType?.id || null,
      loads_per_truck: project?.loadsPerTruck || null,
      truck_count: project?.truckCount || null,
      units_per_hour: project?.unitsPerHour || null,
      job_quantity: Number(project?.jobQuantity) || null,
      order_notes: project?.orderNotes || '',
      job_notes: project?.jobNotes || '',
      internal_notes: project?.internalNotes || '',
      waypoints: compact([
        project?.dropOffWayPoint
          ? WayPoint.deparse(project?.dropOffWayPoint as WayPointFormProps)
          : null,
        project?.pickUpWayPoint
          ? WayPoint.deparse(project?.pickUpWayPoint as WayPointFormProps)
          : null,
      ]),
      ...customerRate,
      ...vendorRate,
      ...driverRate,
    };
  }
  public get phases(): Phase[] {
    return this._phases;
  }
  public set phases(value: Phase[]) {
    this._phases = value;
  }
  public get projectMaterialTotals(): ProjectMaterialTotal[] {
    return this._project_material_totals;
  }
  public set projectMaterialTotals(value: ProjectMaterialTotal[]) {
    this._project_material_totals = value;
  }
  public get salesContact(): Nullable<ExtendedContactItem> {
    return this._sales_contact;
  }
  public set salesContact(value: Nullable<ExtendedContactItem>) {
    this._sales_contact = value;
  }
  public get foremanContact(): Nullable<ExtendedContactItem> {
    return this._foreman_contact;
  }
  public set foremanContact(value: Nullable<ExtendedContactItem>) {
    this._foreman_contact = value;
  }
  public get collaboratorContact(): Nullable<ExtendedContactItem> {
    return this._collaborator_contact;
  }
  public set collaboratorContact(value: Nullable<ExtendedContactItem>) {
    this._collaborator_contact = value;
  }
  public get supervisorContact(): Nullable<ExtendedContactItem> {
    return this._supervisor_contact;
  }
  public set supervisorContact(value: Nullable<ExtendedContactItem>) {
    this._supervisor_contact = value;
  }
  public get allContacts(): ExtendedContactItem[] {
    const allContacts = [];
    if (this._sales_contact) {
      allContacts.push(this._sales_contact);
    }
    if (this._foreman_contact) {
      allContacts.push(this._foreman_contact);
    }
    if (this._collaborator_contact) {
      allContacts.push(this._collaborator_contact);
    }
    if (this._supervisor_contact) {
      allContacts.push(this._supervisor_contact);
    }
    return allContacts;
  }

  // Public set allContacts(value: any) {
  //   This._sales_contact = value.filter((item: any) => item.type === 'sales')[0];
  //   This._foreman_contact = value.filter((item: any) => item.type === 'sales')[0];
  //   This._collaborator_contact = value.filter((item: any) => item.type === 'sales')[0];
  // }
  public get createdAt(): string {
    return this._created_at;
  }
  public get updatedAt(): string {
    return this._updated_at;
  }
  public get expiresAt(): Nullable<Dayjs> {
    return this._expires_at;
  }
  public get effectiveAt(): Nullable<Dayjs> {
    return this._effective_at;
  }
  public get poJobNumber(): string {
    return this._po_job_number;
  }
  public get projectId(): string {
    return this._project_id;
  }
  public get company(): Nullable<CompanyBasic> {
    return this._company;
  }
  public get isSalesContact(): boolean {
    return Boolean(this._sales_contact?.id.length);
  }
  public get isForemanContact(): boolean {
    return Boolean(this._foreman_contact?.id.length);
  }
  public get isSupervisorContact(): boolean {
    return Boolean(this._supervisor_contact?.id.length);
  }
  public get isCollaboratorContact(): boolean {
    return Boolean(this._collaborator_contact?.id.length);
  }
  public get isActive(): boolean {
    return this._state === ProjectState.ACTIVE;
  }
  public get state(): Nullable<ProjectState> {
    return this._state;
  }
  public get contact(): Nullable<ContactItem> {
    return this._contact;
  }
  public get account(): Nullable<AccountBasic> {
    return this._account;
  }
  public get site(): Nullable<SiteBasic> {
    return this._site;
  }
  public get department(): Nullable<BasicDepartment> {
    return this._department;
  }
  public get foremen(): Array<User> {
    return this._foremen || [];
  }
  public get haulerRateType(): Nullable<RateTypes> {
    return this._hauler_rate_type;
  }
  public get service(): Nullable<Service> {
    return this._service;
  }
  public get serviceClass(): Nullable<BasicServiceClass> {
    return this._service_class;
  }
  public get jobTime(): Nullable<number> {
    return this._job_time;
  }
  public get orderQuantity(): Nullable<string> {
    return this._order_quantity;
  }
  public get equipmentType(): Nullable<EquipmentTypeBasic> {
    return this._equipment_type;
  }
  public get loadsPerTruck(): Nullable<number> {
    return this._loads_per_truck;
  }
  public get truckCount(): Nullable<number> {
    return this._truck_count;
  }
  public get unitsPerHour(): Nullable<number> {
    return this._units_per_hour;
  }
  public get jobQuantity(): Nullable<string> {
    return this._job_quantity;
  }
  public get quantity(): Nullable<string> {
    return this.orderQuantity;
  }
  public get projectNotes(): string {
    return this.notes;
  }
  public get waypoints(): Nullable<WayPoint[]> {
    return this._waypoints;
  }
  public set pickUpWayPoints(waypoints: Nullable<WayPoint[]>) {
    this._waypoints = cloneDeep(waypoints);
  }
  public get pickUpWayPoints(): Nullable<WayPoint[]> {
    return this._pickUpWayPoints;
  }
  public get dropOffWayPoints(): Nullable<WayPoint[]> {
    return this._dropOffWayPoints;
  }
  public set dropOffWayPoints(waypoints: Nullable<WayPoint[]>) {
    this._waypoints = cloneDeep(waypoints);
  }
  public get customerRate() {
    return this._customer_rate;
  }

  public get customerRateValue() {
    return this._customer_rate_value;
  }

  public get customerRateType() {
    return this._customer_rate_type;
  }

  public get vendorRate() {
    return this._vendor_rate;
  }

  public get vendorRateValue() {
    return this._vendor_rate_value;
  }

  public get vendorRateType() {
    return this._vendor_rate_type;
  }

  public get driverRate() {
    return this._driver_rate;
  }

  public get driverRateValue() {
    return this._driver_rate_value;
  }

  public get driverRateType() {
    return this._driver_rate_type;
  }

  constructor(
    _id: string,
    _name: string,
    _notes: string,
    private _created_at: string,
    private _updated_at: string,
    private _expires_at: Nullable<Dayjs>,
    private _effective_at: Nullable<Dayjs>,
    private _po_job_number: string,
    _external_id: string,
    private _project_id: string,
    private _company: Nullable<CompanyBasic>,
    private _state: Nullable<ProjectState>,
    private _contact: Nullable<ContactItem>,
    private _account: Nullable<AccountBasic>,
    private _site: Nullable<SiteBasic>,
    private _phases: Phase[],
    private _project_material_totals: ProjectMaterialTotal[],
    private _sales_contact: Nullable<ExtendedContactItem>,
    private _foreman_contact: Nullable<ExtendedContactItem>,
    private _collaborator_contact: Nullable<ExtendedContactItem>,
    private _supervisor_contact: Nullable<ExtendedContactItem>,
    private _department: Nullable<BasicDepartment>,
    private _foremen: Array<User> | null,
    private _hauler_rate_type: Nullable<RateTypes>,
    private _service: Nullable<Service>,
    private _service_class: Nullable<BasicServiceClass>,
    private _job_time: Nullable<number>,
    private _order_quantity: Nullable<string>,
    private _equipment_type: Nullable<EquipmentTypeBasic>,
    private _loads_per_truck: Nullable<number>,
    private _truck_count: Nullable<number>,
    private _units_per_hour: Nullable<number>,
    private _job_quantity: Nullable<string>,
    _order_notes: Nullable<string>,
    _job_notes: Nullable<string>,
    _internal_notes: Nullable<string>,
    private _waypoints: Nullable<WayPoint[]>,
    private _pickUpWayPoints: Nullable<WayPoint[]>,
    private _dropOffWayPoints: Nullable<WayPoint[]>,
    private _customer_rate: Nullable<RateBasicWithValue>,
    private _customer_rate_value: Nullable<number>,
    private _customer_rate_type: Nullable<RateType>,
    private _vendor_rate: Nullable<RateBasicWithValue>,
    private _vendor_rate_value: Nullable<number>,
    private _vendor_rate_type: Nullable<RateType>,
    private _driver_rate: Nullable<RateBasicWithValue>,
    private _driver_rate_value: Nullable<number>,
    private _driver_rate_type: Nullable<RateType>,
  ) {
    super(_id, _name, _external_id, _notes, _order_notes, _job_notes, _internal_notes);
  }
}
