// eslint-disable @typescript-eslint/no-empty-function

import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import {
  RateOwnerType,
  RateType,
  Site_Read_Nested,
  WaypointType,
} from '@treadinc/horizon-api-spec';
import { t as $t, t } from 'i18next';
import { find } from 'lodash';
import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ControllerProps, FieldErrors, useFieldArray, useForm } from 'react-hook-form';

import { AutocompleteAsyncFormField } from '~components/FormFields/AutocompleteAsyncFormField';
import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { CompanySelectorFormField } from '~components/FormFields/CompanySelectorFormField';
import { PhoneCodeFlagInput } from '~components/FormFields/PhoneCodeFlagInput';
import { SingleDateTimeFormField } from '~components/FormFields/SingleDateTimeFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { PseudoLink } from '~components/Helpers/PseudoLink';
import { SiteSelection } from '~components/Order/SiteSelection';
import { FormSectionTitle } from '~components/typographyTitles/FormSectionTitle';
import {
  contactTypesOptions,
  SystemRoles,
  unitOfMeasureOptions,
  WaypointTypes,
} from '~constants/enums';
import { haulerRateTypeField } from '~constants/featureFlags';
import { Account, useAccount } from '~hooks/useAccount';
import { CompanyBasic } from '~hooks/useCompany';
import { ContactItem } from '~hooks/useContact';
import { useDepartment } from '~hooks/useDepartment';
import { EquipmentTypeItem, useEquipment } from '~hooks/useEquipment';
import { Material, useMaterials } from '~hooks/useMaterials';
import { Order } from '~hooks/useOrders';
import { Project, useProjects } from '~hooks/useProjects';
import { ExtendedContactItem, ProjectBasic } from '~hooks/useProjects/models';
import {
  Rate,
  RateBasicWithValue,
  RatePerUnitInputs,
  RateTimeInputs,
  useRates,
} from '~hooks/useRates';
import { Site, SiteBasic, useSites, WayPoint } from '~hooks/useSites';
import { User, useUsers } from '~hooks/useUsers';
import {
  orderFormSchema,
  setDefaultOrderValues,
} from '~pages/Sales/Orders/orderFormSchema';
import { AccountInlineCreateForm } from '~pages/Sales/Projects/AccountInlineCreateForm';
import { EmptyContactItem } from '~pages/Sales/Projects/projectFormSchema';
import { SiteForm } from '~pages/Settings/Administration/Sites/SiteForm';
import { DepartmentInlineForm } from '~pages/Settings/DepartmentManagement/DepartmentInlineForm';
import {
  getRateTypeLabel,
  getRateTypeOptions,
  rateTypeLabelColor,
} from '~pages/Settings/RatesManagement/rateUtils';
import { Paginated } from '~services/pagination';
import { FormStateChangeProps } from '~src/formsShared';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { ItemNameAndId } from '~types/ItemNameAndId';
import { Nullable } from '~types/Nullable';

import { ContactArrayItem } from '../sharedSales/ContactArrayItem';

interface AddDefaultSiteParams {
  siteId: string;
  waypointType: WaypointType;
}

type NewOrderFormStateChange = FormStateChangeProps & Partial<AddDefaultSiteParams>;

interface CreateUpdateOrderFormFormProps {
  defaultOrder: Nullable<Order>;
  onFormStateChange: ({
    isValid,
    isDirty,
    siteId,
    waypointType,
  }: NewOrderFormStateChange) => void;
  company?: Nullable<CompanyBasic>;
}

const formatRatesToBasic = (rates: Rate[]) => {
  return rates.map((rate) => {
    return {
      id: `${rate.id}`,
      name: rate.name,
      type: rate?.type.label || '',
      //@ts-ignore
      rate: rate.isTimeBased
        ? //@ts-ignore
          rate.rateInputs.timeRate
        : rate.isCommission
          ? //@ts-ignore
            rate.rateInputs.commissionPercentage
          : //@ts-ignore
            rate.rateInputs.perUnitRate,
    } as RateBasicWithValue;
  });
};
//Keep till we have way points in UI and not sites only wrapped into way point
export const createWaypoint = (
  site: SiteBasic | Site,
  type: string,
  ordinality: number,
  waypointID?: string,
) =>
  ({
    id: waypointID,
    type,
    ordinality,
    contact: {
      name: '',
      phone: '',
    } as Nullable<ContactItem>,
    site: {
      id: site.id,
      name: site.name,
      lat: site.lat,
      lon: site.lon,
      address: (site as Site).address,
    },
  }) as unknown as WayPoint;
const getContactFormData = (contact: ExtendedContactItem) => {
  return {
    id: contact.id,
    name: contact.name,
    phone: contact.phone,
    type: contact.type as ItemNameAndId,
    isDeleted: false,
  };
};
const OrderForm = forwardRef(function ProjectForm(
  { defaultOrder, onFormStateChange }: CreateUpdateOrderFormFormProps,
  ref: Ref<any>,
) {
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [showCreateMaterial, setShowCreateMaterial] = useState(false);
  const [showCreateDepartment, setShowCreateDepartment] = useState(false);
  const [waypointToUpdate, setWaypointToUpdate] = useState<Nullable<WaypointTypes>>(null);
  const [showCreateSite, setShowCreateSite] = useState(false);
  const [siteFormIsDirty, setSiteFormIsDirty] = useState(false);
  const [accountsByCompanyId, setAccountsByCompanyId] = useState<Account[]>([]);
  const [sites, setSites] = useState<Site[]>([]);
  const [customerRatesOptions, setCustomerRatesOptions] = useState<RateBasicWithValue[]>(
    [],
  );
  const [companyOptions, setCompanyOptions] = useState<Nullable<CompanyBasic[]>>(null);
  const [equipmentTypesOptions, setEquipmentTypesOptions] = useState<EquipmentTypeItem[]>(
    [],
  );

  const [contactTypesOptionsFiltered, setContactTypesOptionsFiltered] =
    useState(contactTypesOptions);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    trigger,
    getValues,
    watch,
    formState: { errors, isValid, isDirty, isSubmitted },
  } = useForm({
    resolver: yupResolver(orderFormSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: setDefaultOrderValues(defaultOrder as Order),
  });
  const {
    fields: contactFields,
    append: appendContact,
    prepend: prependContact,
    remove: removeContact,
    update: updateAllContacts,
  } = useFieldArray({
    control,
    name: 'allContacts',
  });

  const { toasterStore, companyAssetsStore, userStore } = useStores();
  const { getAccountsByCompanyId } = useAccount();
  const {
    getEquipmentTypesByCompanyId,
    createEquipmentTypeByCompanyId,
    isLoading: isEquipmentTypeLoading,
  } = useEquipment();
  const { getDepartmentByCompanyId } = useDepartment();
  const { createMaterialByCompanyId, isLoading: isMaterialLoading } = useMaterials();
  const { getCompanyUsersTypeahead } = useUsers();

  const materialsOptions = companyAssetsStore.allMaterials;
  const servicesOptions = companyAssetsStore.services;
  const departmentOptions = companyAssetsStore.departments;

  // Feature flags
  const { treatments, isReady } = useSplitTreatments({
    names: [haulerRateTypeField],
  });
  const haulerRateTypeFlag = treatments[haulerRateTypeField];

  const {
    project,
    company,
    service,
    account,
    customerRate,
    equipmentType,
    phase,
    allContacts: watchedContactFields,
  } = watch();

  // Set 'company' value to enable project, sites, accounts selection etc.//
  useEffect(() => {
    if (defaultOrder?.company) {
      setValue('company', defaultOrder?.company);
      trigger('company');
    }
  }, [defaultOrder?.company]);

  const watchedPickUpWaypointSite = watch('pickUpWayPoint.site');
  const watchedDropOffWaypointSite = watch('dropOffWayPoint.site');

  // Necessary for mimicking 'onChange' revalidation
  useEffect(() => {
    if (isSubmitted) {
      trigger('pickUpWayPoint');
      trigger('dropOffWayPoint');
    }
  }, [watchedPickUpWaypointSite?.id, watchedDropOffWaypointSite?.id, isSubmitted]);

  const siteFormRef = useRef<any>(null);
  const { isUpdating, createNewSite, getCompanySitesTypeahead } = useSites();
  const { getAccountRatesUsingOwnerType } = useRates();

  const {
    companyRelatedProjects,
    getProjectsByCompanyId,
    isLoadingProjects,
    getCompanyProjectsTypeahead,
  } = useProjects();
  const { isLoading, getCompanySites } = useSites();

  const projectsOptions = useMemo(() => {
    return companyRelatedProjects.filter((project: Project) => {
      return project.isActive;
    });
  }, [companyRelatedProjects]);
  const getProjectDataByProjectId = (projectId: string) => {
    return (
      companyRelatedProjects.filter((project: Project) => {
        return project.id === projectId;
      })?.[0] || null
    );
  };
  const projectPhases = useMemo(() => {
    const projectData = getProjectDataByProjectId(project?.id || '');
    return projectData?.phases || [];
  }, [project, companyRelatedProjects]);

  const { salesContact, foremanContact, collaboratorContact, supervisorContact } =
    useMemo(() => {
      const projectData = getProjectDataByProjectId(project?.id || '');
      return {
        salesContact: projectData?.isSalesContact ? projectData.salesContact : null,
        foremanContact: projectData?.isForemanContact ? projectData.foremanContact : null,
        collaboratorContact: projectData?.isCollaboratorContact
          ? projectData.collaboratorContact
          : null,
        supervisorContact: projectData?.isSupervisorContact
          ? projectData.supervisorContact
          : null,
      };
    }, [project, companyRelatedProjects]);

  const projectNotes = useMemo(() => {
    const projectData = getProjectDataByProjectId(project?.id || '');
    return projectData?.notes || '';
  }, [project, companyRelatedProjects]);
  const projectAccount = useMemo(() => {
    const projectData = getProjectDataByProjectId(project?.id || '');
    return projectData?.account || null;
  }, [project, companyRelatedProjects]);
  const projectDepartment = useMemo(() => {
    const projectData = getProjectDataByProjectId(project?.id || '');
    return projectData?.department || null;
  }, [project, companyRelatedProjects]);
  const projectHaulerRateType = useMemo(() => {
    const projectData = getProjectDataByProjectId(project?.id || '');
    return projectData?.haulerRateType || null;
  }, [project, companyRelatedProjects]);
  const accountsOptions = useMemo(() => {
    return accountsByCompanyId.filter((account) => {
      return account.isCustomerAccount;
    });
  }, [accountsByCompanyId]);

  const getPrimaryRate = (rates: Rate[]) => {
    const found = find(rates, { isPrimary: true });
    if (found)
      return RateBasicWithValue.parse({
        id: `${found.id}`,
        name: found.name,
        type: found?.type.label || '',
        rate:
          found?.rateInputs instanceof RateTimeInputs
            ? found.rateInputs.timeRate
            : found?.rateInputs instanceof RatePerUnitInputs
              ? found.rateInputs.perUnitRate
              : found.rateInputs.commissionPercentage,
      });
  };
  const removeContactItem = (index: number) => {
    //New item, not sored in DB yet, just remove it
    if (!getValues('allContacts')?.[index].id) {
      removeContact(index);
    } else {
      // Previously saved item, mark it as deleted, in order to have their ids in the api call with destroy:1
      updateAllContacts(index, {
        ...contactFields[index],
        id: getValues('allContacts')?.[index]?.id as string,
        isDeleted: true,
      });
    }
  };
  const getRatesCustomerCallBack = ({ data, pagination }: Paginated<Rate>) => {
    setCustomerRatesOptions(formatRatesToBasic(data));
    const primary = getPrimaryRate(data);
    if (!primary) return;
    setValue('customerRate', {
      id: `${primary.id}`,
      name: primary.name,
      type: primary?.type || '',
      rate: primary.rate,
    } as RateBasicWithValue);
  };
  //Will request on create order all the info based on selected company
  useEffect(() => {
    if (company?.id) {
      // Set default account value if it's not specified
      if (!defaultOrder?.account?.id) {
        setValue('account', { name: '', id: '' });
      }

      // Set default project value if it's not specified
      if (!defaultOrder?.project?.id) {
        setValue('project', { name: '', id: '', externalId: '' } as ProjectBasic);
      }

      getAccountsByCompanyId({
        companyId: company?.id,
        callBack: setAccountsByCompanyId,
      });
      getProjectsByCompanyId(company?.id || '');
      getCompanySites({ companyId: company.id, callBack: setSites });

      getEquipmentTypesByCompanyId({
        companyId: company.id,
        callBack: setEquipmentTypesOptions,
      });
      getDepartmentByCompanyId(company?.id);
    }
  }, [company]);

  useEffect(() => {
    if (company?.id) {
      const params: Parameters<typeof getAccountRatesUsingOwnerType>[0] = {
        ownerType: RateOwnerType.CUSTOMER,
        accountId: account?.id || '',
        projectId: project?.id || '',
        serviceId: service?.id || '',
        includeDefaultRates: true,
        // TODO: update as soon as BE be ready
        // EquipmentId: equipmentType?.id || '',
      };

      getAccountRatesUsingOwnerType(params).then((response) => {
        getRatesCustomerCallBack(response);
      });
    }
  }, [project, company, service, equipmentType, account]);

  useEffect(() => {
    if (customerRate) {
      setValue('customerRateValue', customerRate.rate);
      setValue(
        'customerRateType',
        customerRate?.readableTypeValue ||
          rateTypeLabelColor?.(customerRate?.type as RateType)?.label ||
          customerRate?.type ||
          '',
      );
    } else {
      setValue('customerRateValue', null);
      setValue('customerRateType', null);
    }
  }, [customerRate]);

  useEffect(() => {
    if (defaultOrder?.orderId) {
      if (defaultOrder?.pickUpWayPoints?.[0].site?.id !== watchedPickUpWaypointSite?.id) {
        setValue('pickUpWayPoint.id', `${defaultOrder?.pickUpWayPoints?.[0]?.id}`);
      }
      if (
        defaultOrder?.dropOffWayPoints?.[0].site?.id !== watchedDropOffWaypointSite?.id
      ) {
        setValue('dropOffWayPoint.id', `${defaultOrder?.dropOffWayPoints?.[0]?.id}`);
      }
    }
  }, [watchedPickUpWaypointSite, watchedDropOffWaypointSite]);

  useEffect(() => {
    setValue('externalId', project?.externalId || '');
    !defaultOrder?.notes?.length && projectNotes?.length
      ? setValue('notes', projectNotes || '')
      : null;
    !defaultOrder?.account?.id?.length && projectAccount?.id?.length
      ? setValue('account', {
          id: projectAccount.id,
          name: projectAccount.name,
        })
      : null;

    // If there is a new order, then we can preload the department from the selected project if none was previously selected
    !defaultOrder?.orderId &&
    !defaultOrder?.department?.id?.length &&
    projectDepartment?.id?.length
      ? setValue('department', {
          id: projectDepartment.id,
          name: projectDepartment.name,
        })
      : null;

    !defaultOrder?.haulerRateType && projectHaulerRateType
      ? setValue('haulerRateType', projectHaulerRateType)
      : null;

    //@ts-ignore
    collaboratorContact && !defaultOrder?.isCollaboratorContact
      ? prependContact(getContactFormData(collaboratorContact))
      : null;
    foremanContact && !defaultOrder?.isForemanContact
      ? prependContact(getContactFormData(foremanContact))
      : null;
    salesContact && !defaultOrder?.isSalesContact
      ? prependContact(getContactFormData(salesContact))
      : null;
    supervisorContact && !defaultOrder?.isSupervisorContact
      ? prependContact(getContactFormData(supervisorContact))
      : null;
  }, [
    collaboratorContact,
    foremanContact,
    salesContact,
    projectNotes,
    projectAccount,
    projectDepartment,
    projectHaulerRateType,
  ]);

  useEffect(() => {
    setValue('phaseCode', phase?.code || '');
  }, [phase]);

  useEffect(() => {
    const res = contactTypesOptions.filter((singleType) => {
      // Check if any element in contactFields has the same type.id as singleType
      const found = watchedContactFields.some(
        (field) => field?.type?.id === singleType?.id,
      );
      return !found;
    });
    setContactTypesOptionsFiltered(res);
  }, [watchedContactFields]);

  useEffect(() => {
    if (project?.id) {
      const foremen = (project as Project).foremen || [];
      const currentlySelectedForemen = getValues('foremen');
      const newForemen = [...currentlySelectedForemen];

      foremen.forEach((foreman) => {
        const foremanIsAlreadySelected = currentlySelectedForemen.some(
          (f) => f.id === foreman.id,
        );

        if (!foremanIsAlreadySelected) {
          newForemen.push(foreman);
        }
      });

      setValue('foremen', newForemen);
    }
  }, [project]);

  const onSuccess = (site?: Site) => {
    setShowCreateSite(false);
    const name = `${site?.name}`;
    setSites((prevState) => [...prevState, site as Site]);
    toasterStore.push(
      alert($t('administration.site.site_created', { name }), AlertTypes.success),
    );
  };

  const onSubmitCallback = (data: any) => {
    createNewSite(data)
      .then(onSuccess)
      .catch(() => {});
  };
  const showCreateAccountForm = () => {
    setShowCreateAccount(true);
  };
  const onSubmitForm = () => {
    siteFormRef.current?.submit(onSubmitCallback);
  };
  const onSiteFormStateChange = ({ isDirty }: FormStateChangeProps) => {
    setSiteFormIsDirty(isDirty);
  };
  const closeSiteForm = () => {
    setShowCreateSite(false);
  };
  const showCreateSiteForm = (wayPointType: WaypointTypes) => {
    if (!showCreateSite) {
      setWaypointToUpdate(wayPointType);
      setShowCreateSite(true);
    }
  };
  useImperativeHandle(ref, () => ({
    submit(callBack: () => void) {
      handleSubmit(callBack)();
    },
    resetForm(callBack?: () => void) {
      reset();
      callBack?.();
    },
  }));

  useEffect(() => {
    onFormStateChange({ isDirty });
  }, [isValid, isDirty]);

  const [showPickUpContact, setShowPickUpContact] = useState<boolean>(false);
  const [showDropOffContact, setShowDropOffContact] = useState<boolean>(false);

  const toggleOnPickUpContact = () => {
    setShowPickUpContact(true);
  };
  const toggleOffPickUpContact = () => {
    setValue('pickUpWayPoint.contact', {
      name: '',
      phone: '',
    });

    trigger('pickUpWayPoint.contact');
    setShowPickUpContact(false);
  };

  const toggleOnDropOffContact = () => {
    setShowDropOffContact(true);
  };

  const toggleOffDropOffContact = () => {
    setValue('dropOffWayPoint.contact', {
      name: '',
      phone: '',
    });
    trigger('dropOffWayPoint.contact');
    setShowDropOffContact(false);
  };

  useEffect(() => {
    if (defaultOrder?.pickUpWayPoints?.[0]?.contact?.name) {
      setShowPickUpContact(true);
    }
    if (defaultOrder?.dropOffWayPoints?.[0]?.contact?.name) {
      setShowDropOffContact(true);
    }
  }, []);

  const [tmpEquipmentType, setTmpEquipmentType] = useState<string>('');
  const onInputEquipmentType = (event: React.SyntheticEvent, value: string) => {
    setTmpEquipmentType(value);
  };

  const createEquipmentType = () => {
    if (!userStore.userCompany?.id) {
      toasterStore.push(
        alert(t('form_validation_errors.no_user_company'), AlertTypes.error),
      );
    }

    createEquipmentTypeByCompanyId({
      companyId: userStore.userCompany?.id || '',
      equipmentType: {
        name: tmpEquipmentType,
      } as unknown as EquipmentTypeItem,
    }).then((equipmentType) => {
      setEquipmentTypesOptions((prevState) => [...prevState, equipmentType]);
      setValue('equipmentType', equipmentType);
      trigger('equipmentType');
      toasterStore.push(
        alert(
          t('administration.equipment.equipment_created', {
            name: equipmentType.name,
          }),
          AlertTypes.success,
        ),
      );
    });
  };

  const [tmpMaterial, setTmpMaterial] = useState<string>('');
  const onInputMaterial = (event: React.SyntheticEvent, value: string) => {
    setTmpMaterial(value);
  };
  const createMaterial = () => {
    if (!userStore.userCompany?.id) {
      toasterStore.push(
        alert(t('form_validation_errors.no_user_company'), AlertTypes.error),
      );
    }

    createMaterialByCompanyId({
      companyId: userStore.userCompany?.id || '',
      material: {
        name: tmpMaterial,
      } as unknown as Material,
    }).then((material: Material) => {
      toasterStore.push(
        alert(
          t('administration.material.material_created', {
            name: material.name,
          }),
          AlertTypes.success,
        ),
      );
      setValue('material', material);
      trigger('material');
    });
  };

  const rateTypeOptions = getRateTypeOptions();

  return (
    <Box component="div" sx={{ mt: 0 }} data-test-id="order-form">
      <Grid container spacing={2} sx={{ my: 1 }}>
        {/*Left side*/}
        <Grid item xs={6}>
          {/*Project details*/}
          <Card sx={{ p: 2, mb: 2 }}>
            <FormSectionTitle sx={{ mb: 2 }} title={t('order.form.project_details')} />
            {!defaultOrder?.orderId && (
              <Box sx={{ mb: 2 }}>
                <CompanySelectorFormField
                  name={'company'}
                  defaultValue={defaultOrder?.company}
                  errors={errors}
                  control={control}
                  isRequired={true}
                />
              </Box>
            )}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <AutocompleteAsyncFormField
                  disabled={!company?.id}
                  control={control}
                  name="project"
                  errors={errors}
                  label={`${$t('order.form.associated_project')}`}
                  defaultValue={undefined}
                  clearable={true}
                  getValue={(item: Project) => item.id}
                  getLabel={(item: Project) => item.name || ''}
                  asyncCallback={getCompanyProjectsTypeahead}
                  extraRequestOptions={{
                    companyId: company?.id,
                    accountId: defaultOrder?.account?.id,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFormField
                  control={control}
                  errors={errors}
                  disabled={true}
                  name="externalId"
                  label={`${$t('form_fields.project_id')}`}
                  isRequired={false}
                />
              </Grid>
              <Grid item xs={12}>
                <AutocompleteFormField
                  control={control}
                  disabled={!!defaultOrder?.company?.id || !company?.id}
                  name="account"
                  errors={errors}
                  list={accountsOptions}
                  label={`${$t('dispatch.order.customer_account')}`}
                  isRequired={true}
                  getValue={(item) => item.id}
                  getLabel={(item) => item.name || ''}
                  clearOnBlur={true}
                  blurOnSelect={true}
                  noOptionsText={
                    <Chip
                      size={'small'}
                      variant={'filled'}
                      color={'primary'}
                      onClick={showCreateAccountForm}
                      sx={{ cursor: 'pointer' }}
                      label={t('account.create_account')}
                    />
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <AutocompleteFormField
                  disabled={!company?.id || !project?.id}
                  control={control}
                  name="phase"
                  errors={errors}
                  list={projectPhases}
                  label={`${$t('form_fields.phase_name')}`}
                  isRequired={false}
                  defaultValue={undefined}
                  getValue={(item) => item.id}
                  getLabel={(item) => item.name || ''}
                  clearOnBlur={true}
                  blurOnSelect={true}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFormField
                  control={control}
                  errors={errors}
                  disabled={true}
                  name="phaseCode"
                  label={`${$t('form_fields.phase_code')}`}
                  isRequired={false}
                />
              </Grid>
            </Grid>
          </Card>

          {/*New account creating form*/}
          <Collapse in={showCreateAccount}>
            <Card sx={{ p: 2, mt: 2 }}>
              <AccountInlineCreateForm
                connectedCompany={company}
                callBackCreateAccount={(account) => {
                  setAccountsByCompanyId((prevState) => [...prevState, account]);
                  setShowCreateAccount(false);
                  setValue('account', account);
                  toasterStore.push(
                    alert(
                      t('account.account_created', { name: account.name }),
                      AlertTypes.success,
                    ),
                  );
                }}
                callBackCloseClicked={() => {
                  setShowCreateAccount(false);
                }}
                defaultAccount={
                  Account.parse({
                    account_types: [],
                    created_at: '',
                    dba_name: null,
                    department: null,
                    external_id: null,
                    name: '',
                    notes: '',
                    updated_at: '',
                  }) as Account
                }
                sx={{ mb: -0.5 }}
              />
            </Card>
          </Collapse>

          {/*Order details*/}
          <Card sx={{ p: 2, mb: 2 }}>
            <FormSectionTitle sx={{ mb: 2 }} title={$t('order.form.order_details')} />
            <Grid item xs={12}>
              <AutocompleteFormField
                control={control}
                name="department"
                groupBy={(item) => item?.typeName}
                errors={errors}
                sx={{ mr: 2 }}
                list={departmentOptions}
                label={`${$t('project.form.department')}`}
                getValue={(item) => item.id}
                getLabel={(item) => item.name || ''}
                clearOnBlur={true}
                blurOnSelect={true}
                clearable={true}
                noOptionsText={
                  <Chip
                    size={'small'}
                    variant={'filled'}
                    color={'primary'}
                    onClick={() => {
                      setShowCreateDepartment(true);
                    }}
                    disabled={!company?.id}
                    sx={{ cursor: 'pointer' }}
                    label={$t('administration.department.create')}
                  />
                }
              />
            </Grid>
            {/*New department creating form*/}
            <Collapse in={showCreateDepartment} sx={{ width: '100%' }}>
              <Grid sx={{ ml: 2 }}>
                <DepartmentInlineForm
                  connectedCompany={company as Nullable<CompanyBasic>}
                  callBackCreated={(department) => {
                    setShowCreateDepartment(false);
                    setValue('department', department);
                    trigger('department');
                    toasterStore.push(
                      alert(
                        t('administration.department.created', {
                          name: department.name,
                        }),
                        AlertTypes.success,
                      ),
                    );
                  }}
                  callBackCloseClicked={() => {
                    setShowCreateDepartment(false);
                  }}
                  sx={{ mb: 2 }}
                  defaultDepartment={null}
                />
              </Grid>
            </Collapse>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <AutocompleteFormField
                  control={control}
                  name="service"
                  errors={errors}
                  list={servicesOptions}
                  label={`${$t('form_fields.service')}`}
                  isRequired={true}
                  getValue={(item) => item.id}
                  getLabel={(item) => item.name || ''}
                  clearOnBlur={true}
                  blurOnSelect={true}
                />
              </Grid>
              <Grid item xs={6}>
                <SingleDateTimeFormField
                  dateLabel={$t('order.form.start_date') || ''}
                  timeLabel={$t('order.form.start_time') || ''}
                  control={control}
                  errors={errors}
                  name={'loadAt'}
                  isRequired={true}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={6}>
                <AutocompleteFormField
                  control={control}
                  name="material"
                  groupBy={(item) => item?.typeName}
                  errors={errors}
                  list={materialsOptions}
                  label={`${$t('form_fields.materials')}`}
                  loading={isMaterialLoading}
                  disabled={isMaterialLoading}
                  isRequired={true}
                  getValue={(item) => item.id}
                  getLabel={(item) => item.name || ''}
                  clearOnBlur={true}
                  blurOnSelect={true}
                  onInput={onInputMaterial}
                  noOptionsText={
                    tmpMaterial ? (
                      <Chip
                        size={'small'}
                        variant={'filled'}
                        color={'primary'}
                        onClick={createMaterial}
                        disabled={isMaterialLoading}
                        sx={{ cursor: 'pointer' }}
                        label={$t('common.add_new_name', {
                          name: tmpMaterial || '',
                        })}
                      />
                    ) : (
                      $t('form_fields.no_options')
                    )
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextFormField
                  control={control}
                  errors={errors}
                  name="quantity"
                  label={`${$t('form_fields.quantity')}`}
                  isRequired={true}
                />
              </Grid>
              <Grid item xs={3}>
                <AutocompleteFormField
                  control={control}
                  name="unitOfMeasure"
                  errors={errors}
                  list={unitOfMeasureOptions}
                  label={`${$t('form_fields.unit')}`}
                  isRequired={true}
                  getValue={(item) => item.id}
                  getLabel={(item) => item.name || ''}
                  clearOnBlur={true}
                  blurOnSelect={true}
                />
              </Grid>

              <Grid item xs={6}>
                <AutocompleteFormField
                  control={control}
                  name="equipmentType"
                  errors={errors}
                  list={equipmentTypesOptions}
                  label={`${$t('form_fields.equipment_type')}`}
                  disabled={isEquipmentTypeLoading}
                  loading={isEquipmentTypeLoading}
                  getValue={(item) => item.id}
                  getLabel={(item) => item.name || ''}
                  clearOnBlur={true}
                  blurOnSelect={true}
                  onInput={onInputEquipmentType}
                  noOptionsText={
                    tmpEquipmentType ? (
                      <Chip
                        size={'small'}
                        variant={'filled'}
                        color={'primary'}
                        onClick={createEquipmentType}
                        disabled={isEquipmentTypeLoading}
                        sx={{ cursor: 'pointer' }}
                        label={$t('common.add_new_name', {
                          name: tmpEquipmentType || '',
                        })}
                      />
                    ) : (
                      $t('form_fields.no_options')
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextFormField
                  control={control}
                  errors={errors}
                  name="truckCount"
                  label={`${$t('form_fields.truck_count')}`}
                  isRequired={true}
                  disabled={!!defaultOrder?.id}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFormField
                  control={control}
                  errors={errors}
                  name="loadsPerTruck"
                  label={`${$t('form_fields.loads_per_truck')}`}
                  isRequired={false}
                  disabled={!!defaultOrder?.id}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFormField
                  control={control}
                  errors={errors}
                  name="truckDelay"
                  label={`${$t('form_fields.truck_delay')}`}
                  isRequired={false}
                />
              </Grid>
            </Grid>
          </Card>

          <Card sx={{ mb: 2, p: 2, overflow: 'visible' }}>
            <FormSectionTitle title={$t('order.form.order_contacts')} />
            <Grid container sx={{ mt: 1 }}>
              <AutocompleteAsyncFormField
                control={control}
                errors={errors}
                disabled={!company?.id}
                multiple
                filterSelectedOptions={false}
                limitTags={2}
                name="foremen"
                getValue={(user: User) => user.id}
                getLabel={(user: User) => `${user.firstName} ${user.lastName}`}
                label={`${$t('form_fields.assigned_foreman')}`}
                asyncCallback={getCompanyUsersTypeahead}
                extraRequestOptions={{
                  companyId: company?.id,
                  roleName: SystemRoles.FOREMAN,
                }}
              />
            </Grid>

            <Grid container sx={{ mt: 1 }}>
              {contactFields.map((contact, index) => {
                return contact.isDeleted ? null : (
                  <ContactArrayItem
                    key={contact.id}
                    index={index}
                    arrName={'allContacts'}
                    errors={errors}
                    control={control}
                    removeAction={removeContactItem}
                    typeOptions={contactTypesOptionsFiltered}
                  />
                );
              })}
              <Grid item xs={12}>
                <PseudoLink
                  disabled={contactFields.length === contactTypesOptions.length}
                  title={$t('project.form.add_contact')}
                  sx={{ mt: 1, display: 'inline-flex' }}
                  action={
                    contactFields.length !== contactTypesOptions.length
                      ? () => {
                          appendContact(EmptyContactItem);
                        }
                      : () => {}
                  }
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>

        {/*Right side*/}
        <Grid item xs={6}>
          {/*Locations*/}
          <Card sx={{ p: 2, mb: 2 }}>
            <FormSectionTitle sx={{ mb: 2 }} title={$t('order.form.location_details')} />
            <SiteSelection
              errors={errors}
              companyId={company?.id}
              allowSuggestions={true}
              isRequired={true}
              projectId={project?.id}
              pickUpSite={defaultOrder?.pickUpWayPoints?.[0]?.site}
              dropOffSite={defaultOrder?.dropOffWayPoints?.[0]?.site}
              onDefaultSiteAdded={({ siteId, waypointType }: AddDefaultSiteParams) =>
                onFormStateChange({ siteId, waypointType, isDirty: true })
              }
              onPickUpSiteChange={(site) => {
                if (site) {
                  const pickUpWayPoint = createWaypoint(
                    site,
                    WaypointType.PICKUP,
                    10,
                    defaultOrder?.pickUpWayPoints?.[0]?.id,
                  );
                  setValue('pickUpWayPoint', pickUpWayPoint as any, {
                    shouldDirty: true,
                  });
                } else {
                  setValue('pickUpWayPoint', null, { shouldDirty: true });
                }
              }}
              onDropOffSiteChange={(site) => {
                if (site) {
                  setValue(
                    'dropOffWayPoint',
                    createWaypoint(
                      site,
                      WaypointType.DROP_OFF,
                      20,
                      defaultOrder?.dropOffWayPoints?.[0]?.id,
                    ) as any,
                    { shouldDirty: true },
                  );
                } else {
                  setValue('dropOffWayPoint', null, { shouldDirty: true });
                }
              }}
            />
            {!showPickUpContact && (
              <PseudoLink
                title={`+ ${$t('order.form.add_pick_up_site_contact')}`}
                action={toggleOnPickUpContact}
              />
            )}
            <Collapse in={showPickUpContact}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextFormField
                    control={control}
                    errors={errors}
                    name="pickUpWayPoint.contact.name"
                    label={`${$t('form_fields.name')}`}
                    disabled={!company?.id}
                    isRequired={false}
                  />
                </Grid>
                <Grid item xs={6}>
                  <PhoneCodeFlagInput
                    control={control as unknown as ControllerProps['control']}
                    errors={errors as unknown as FieldErrors}
                    name="pickUpWayPoint.contact.phone"
                    label={`${$t('form_fields.phone')}`}
                    disabled={!company?.id}
                    isRequired={false}
                  />
                </Grid>
              </Grid>
              <PseudoLink
                title={$t('order.form.remove_pick_up_site_contact')}
                sx={{ mt: 1 }}
                action={toggleOffPickUpContact}
              />
            </Collapse>
            {!showDropOffContact && (
              <PseudoLink
                title={`+ ${$t('order.form.add_drop_off_site_contact')}`}
                action={toggleOnDropOffContact}
              />
            )}
            <Collapse in={showDropOffContact}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextFormField
                    control={control}
                    errors={errors}
                    name="dropOffWayPoint.contact.name"
                    label={`${$t('form_fields.name')}`}
                    isRequired={false}
                    disabled={!company?.id}
                  />
                </Grid>
                <Grid item xs={6}>
                  <PhoneCodeFlagInput
                    control={control as unknown as ControllerProps['control']}
                    errors={errors as unknown as FieldErrors}
                    name="dropOffWayPoint.contact.phone"
                    label={`${$t('form_fields.phone')}`}
                    isRequired={false}
                    disabled={!company?.id}
                  />
                </Grid>
              </Grid>
              <PseudoLink
                title={$t('order.form.remove_drop_off_site_contact')}
                sx={{ mt: 1 }}
                action={toggleOffDropOffContact}
              />
            </Collapse>
          </Card>

          {/*Create new site form*/}
          <Collapse in={showCreateSite}>
            <Card sx={{ p: 2, mb: 2 }}>
              <FormSectionTitle sx={{ mb: 2 }} title={t('administration.site.add_new')} />
              {showCreateSite && (
                <SiteForm
                  company={company as CompanyBasic}
                  onFormStateChange={onSiteFormStateChange}
                  ref={siteFormRef}
                  defaultSite={null}
                  isInline={true}
                />
              )}

              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'end'}
                sx={{ mt: 1 }}
              >
                <LoadingButton
                  disabled={!isDirty || isUpdating}
                  loading={isUpdating}
                  loadingPosition="start"
                  startIcon={<></>}
                  onClick={onSubmitForm}
                  type="button"
                  variant="contained"
                  color="primary"
                  sx={isUpdating ? { pl: 5, pr: 2 } : { pr: 2 }}
                >
                  {$t(`actions.create`)}
                </LoadingButton>
                <Button
                  onClick={closeSiteForm}
                  sx={{ ml: 2, px: 2 }}
                  disabled={isUpdating}
                  color="secondary"
                  variant="outlined"
                >
                  {$t('actions.cancel')}
                </Button>
              </Box>
            </Card>
          </Collapse>
          {/*Customer details*/}
          <Card sx={{ p: 2 }}>
            <FormSectionTitle sx={{ mb: 2 }} title={$t('order.form.customer_details')} />

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <AutocompleteFormField
                  control={control}
                  disabled={!company?.id}
                  name="customerRate"
                  errors={errors}
                  list={customerRatesOptions}
                  label={`${$t('order.form.customer_rate_card')}`}
                  isRequired={false}
                  getValue={(item) => item.id}
                  getLabel={(item) => item.name || ''}
                  clearOnBlur={true}
                  blurOnSelect={true}
                  clearable={defaultOrder ? false : true}
                />
              </Grid>
              <Grid item xs={3}>
                <TextFormField
                  control={control}
                  errors={errors}
                  name="customerRateType"
                  disabled={true}
                  label={`${$t('administration.rates.rate_type')}`}
                  isRequired={false}
                />
              </Grid>
              <Grid item xs={3}>
                <TextFormField
                  control={control}
                  errors={errors}
                  name="customerRateValue"
                  disabled={true}
                  label={`${$t('administration.rates.rate_value_label')}`}
                  isRequired={false}
                />
              </Grid>
              {isReady && haulerRateTypeFlag.treatment === 'on' && (
                <Grid item xs={6}>
                  <AutocompleteFormField
                    control={control}
                    name="haulerRateType"
                    errors={errors}
                    multiple={false}
                    isCheckEnabled={false}
                    list={rateTypeOptions}
                    label={`${$t('form_fields.hauler_rate_type')}`}
                    getValue={(item) => item.value || item}
                    getLabel={(item) => item?.label || getRateTypeLabel(item)}
                  />
                </Grid>
              )}
            </Grid>
          </Card>

          {/*Notes*/}
          <Card sx={{ mt: 2, p: 2 }}>
            <FormSectionTitle sx={{ mb: 0 }} title={`${$t('form_fields.notes')}`} />
            <TextFormField
              control={control}
              errors={errors}
              name="notes"
              label={''}
              sx={{ width: 'auto' }}
              multiline={true}
              rows={3}
              isRequired={false}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
});
export { OrderForm };
