import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import { t } from 'i18next';

import { TabValue as AssigneeType } from '~components/Job/AssignJobToView/hooks/useAssignDriverMenu';
import { SearchBar } from '~components/Search/SearchBar';

interface SearchProps {
  assigneeType: AssigneeType;
  onChange: (searchValue: string) => void;
  sx?: SxProps;
}

export default function Search({ assigneeType, onChange, sx }: SearchProps) {
  return (
    <Box sx={sx}>
      <SearchBar
        alwaysExpanded
        setFilter={onChange}
        placeHolder={
          assigneeType === AssigneeType.VENDOR
            ? t('dispatch.job.assignment_popover.search_for_vendor') || ''
            : t('dispatch.job.assignment_popover.search_for_driver') || ''
        }
      />
    </Box>
  );
}
