import { SxProps } from '@mui/system';
import { t } from 'i18next';

import { TabValue as AssigneeType } from '~components/Job/AssignJobToView/hooks/useAssignDriverMenu';
import Tabs, { TabProps } from '~components/Tabs/Tabs';

const assigneeTypeTabs: TabProps<AssigneeType>[] = [
  {
    label: t('dispatch.job.assignment_popover.internal'),
    value: AssigneeType.INTERNAL,
  },
  {
    label: t('dispatch.job.assignment_popover.external'),
    value: AssigneeType.EXTERNAL,
  },
  {
    label: t('dispatch.job.assignment_popover.vendor'),
    value: AssigneeType.VENDOR,
  },
];

interface AssigneeTabsProps {
  onChange: (tab: AssigneeType) => void;
  selectedTab: AssigneeType;
  sx?: SxProps;
}

export default function AssigneeTabs({ onChange, selectedTab, sx }: AssigneeTabsProps) {
  return (
    <Tabs onChange={onChange} selected={selectedTab} tabs={assigneeTypeTabs} sx={sx} />
  );
}
