import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ShiftAdjustmentType } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { ModalDialogHandler } from '~components/Dialog/ModalDialog';
import { DriverDay } from '~hooks/useDriverDays/models';
import { useDriverDays } from '~hooks/useDriverDays/useDriverDays';
import theme from '~theme/AppTheme';
import { dateFormat } from '~utils/dateTime';

import ApprovalActionsMenu from './ApprovalActionsMenu';

const emDash = '-';

// We assume hours could be 0.01 and need to round this to an integer
const convertHoursToMinutes = (hours: number): number => {
  return Math.round(hours * 60);
};
const convertMinutesToHours = (minutes: number): number => {
  return minutes / 60;
};
const formatMinutesToHoursString = (minutes: number): string =>
  `${convertMinutesToHours(minutes).toFixed(2)}h`;

const calculateAdjustmentMinutes = (
  currentTotalShiftMinutes: number,
  currentShiftAdjustmentMinutes: number,
  newTotalShiftMinutes: number,
) => {
  const baseMinutes = currentTotalShiftMinutes - currentShiftAdjustmentMinutes;
  return newTotalShiftMinutes - baseMinutes;
};

type FormData = {
  shiftTimeInHours: number;
  overrideReason: ShiftAdjustmentType | null;
};

export function ShiftDetails({ driverDay }: { driverDay: DriverDay }) {
  const [isEditing, setIsEditing] = useState(false);
  const reasonDialogRef = useRef<ModalDialogHandler>(null);

  const { updateDriverDay, approveDriverDay, unapproveDriverDay } = useDriverDays();

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    defaultValues: {
      shiftTimeInHours: parseFloat(
        convertMinutesToHours(driverDay.totalShiftMinutes ?? 0).toFixed(2),
      ),
      overrideReason: null,
    },
    mode: 'all',
    delayError: 500,
  });

  useEffect(() => {
    setValue(
      'shiftTimeInHours',
      parseFloat(convertMinutesToHours(driverDay.totalShiftMinutes ?? 0).toFixed(2)),
    );
    driverDay.shiftAdjustmentType &&
      setValue('overrideReason', driverDay.shiftAdjustmentType);
  }, [driverDay.totalShiftMinutes, driverDay.shiftAdjustmentType]);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    // Handle submit early when only one field is full, by opening the modal
    if (!data.overrideReason) {
      reasonDialogRef.current?.open();
      return;
    }

    await updateDriverDay(
      driverDay.id,
      calculateAdjustmentMinutes(
        driverDay.totalShiftMinutes ?? 0,
        driverDay.shiftAdjustmentMinutes,
        convertHoursToMinutes(data.shiftTimeInHours),
      ),
      data.overrideReason,
    );
    reasonDialogRef.current?.close();
    setIsEditing(false);
    reset();
  };

  const handleApprove = () => {
    approveDriverDay(driverDay.id);
  };

  const handleUnapprove = () => {
    if (!driverDay.unapprovable) return undefined;
    unapproveDriverDay(driverDay.id);
  };

  const ApprovalCTA = () => {
    const approved = driverDay.approved;
    const approvable = driverDay.approvable;

    if (approved) {
      return (
        <ApprovalActionsMenu onMenuItemClick={handleUnapprove} approved={approved} />
      );
    }
    if (approvable) {
      return (
        <Button
          startIcon={<CheckIcon />}
          variant="contained"
          color="success"
          onClick={() => {
            handleApprove();
          }}
          sx={{
            backgroundColor: theme.brandV2.colors.treadGreen,
          }}
          size="small"
        >
          {t('actions.approve')}
        </Button>
      );
    }

    return null;
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      aria-label="shift details"
      sx={{
        backgroundColor: 'white',
        p: 1.5,
        borderRadius: 2,
        border: `1px solid ${theme.brandV2.colors.treadGray7}`,
        height: 'fit-content',
      }}
    >
      <Box
        aria-label="shift summary"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 3,
          pb: 2,
        }}
      >
        <Box sx={{ flex: 1, display: 'grid', alignContent: 'start' }}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.brandV2.colors.treadGray2 }}
          >
            {t('approvals.driver_day.shift_start_details')}
          </Typography>
          <Typography variant="h6">
            {driverDay?.startedShiftAt
              ? dateFormat(driverDay.startedShiftAt, 'hh:mm A')
              : emDash}
          </Typography>
          <Typography
            variant="caption"
            sx={{ color: (theme) => theme.brandV2.colors.treadGray2, fontWeight: 500 }}
          >
            {t('approvals.driver_day.first_geofence_entry')} :
            {driverDay?.firstGeofenceEntryAt
              ? dateFormat(driverDay?.firstGeofenceEntryAt, 'hh:mm A')
              : emDash}
          </Typography>

          <Typography
            variant="caption"
            sx={{ color: (theme) => theme.brandV2.colors.treadGray2, fontWeight: 500 }}
          >
            {t('approvals.driver_day.first_ticket')} :{' '}
            {driverDay?.firstTicketAt
              ? dateFormat(driverDay?.firstTicketAt, 'hh:mm A')
              : emDash}
          </Typography>
        </Box>

        <Box sx={{ flex: 1, display: 'grid', alignContent: 'start' }}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.brandV2.colors.treadGray2 }}
          >
            {t('approvals.driver_day.shift_end_details')}
          </Typography>
          <Typography variant="h6">
            {driverDay?.endedShiftAt
              ? dateFormat(driverDay.endedShiftAt, 'hh:mm A')
              : emDash}
          </Typography>

          <Typography
            variant="caption"
            sx={{ color: (theme) => theme.brandV2.colors.treadGray2, fontWeight: 500 }}
          >
            {t('approvals.driver_day.last_geofence_entry')} :{' '}
            {driverDay?.lastGeofenceExitAt
              ? dateFormat(driverDay?.lastGeofenceExitAt, 'hh:mm A')
              : emDash}
          </Typography>

          <Typography
            variant="caption"
            sx={{ color: (theme) => theme.brandV2.colors.treadGray2, fontWeight: 500 }}
          >
            {t('approvals.driver_day.last_ticket')} :{' '}
            {driverDay?.lastTicketAt
              ? dateFormat(driverDay?.lastTicketAt, 'hh:mm A')
              : emDash}
          </Typography>
        </Box>

        <Box sx={{ flex: 1, display: 'grid', alignContent: 'start' }}>
          <Typography
            variant="body2"
            sx={{ color: (theme) => theme.brandV2.colors.treadGray2 }}
          >
            {t('approvals.driver_day.total_hours')}
          </Typography>
          {isEditing ? (
            <TextField
              {...register('shiftTimeInHours', {
                min: 0,
                setValueAs: (v) => (!isNaN(v) ? parseFloat(parseFloat(v).toFixed(2)) : v),
                validate: {
                  number: (v) => !isNaN(v),
                  positive: (v) => v >= 0,
                },
              })}
              error={!!errors.shiftTimeInHours}
            />
          ) : (
            <Typography variant="h6">
              {driverDay.totalShiftMinutes
                ? formatMinutesToHoursString(driverDay.totalShiftMinutes)
                : emDash}
            </Typography>
          )}
          <Typography
            variant="caption"
            sx={{ color: (theme) => theme.brandV2.colors.treadGray2, fontWeight: 500 }}
          >
            {`${t('approvals.driver_day.breaks')}: ${driverDay?.totalBreakMinutes ? `${driverDay?.totalBreakMinutes}min` : emDash}`}
          </Typography>
        </Box>
        <ApprovalCTA />
      </Box>
    </Box>
  );
}
