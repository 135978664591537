import { useAgaveLink } from '@agave-api/react-agave-link';
import Add from '@mui/icons-material/Add';
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';
import Save from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import DataGrid from '~components/DataGrid/DataGrid';
import { HeaderNavigation } from '~components/DataGrid/HeaderNavigation';
import { ModalDialog, ModalDialogHandler } from '~components/Dialog/ModalDialog';
import { FeatureFlags } from '~constants/featureFlags';
import { AgaveLinkedAccount } from '~hooks/useAgave/models';
import useAgave from '~hooks/useAgave/useAgave';
import * as colsDef from '~pages/Settings/Integrations/agaveManagementDataGridColumnsDefinition';
import { DisconnectIntegrationErrorComponent } from '~pages/Settings/Integrations/DisconnectIntegrationErrorComponent';
import { routes } from '~router';
import { Pagination, PaginationLink } from '~services/pagination';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { Nullable } from '~types/Nullable';
import { useAwaitableFeatureFlag } from '~utils/hooks/useFeatureFlag';

enum AgeveSourceSystemSlug {
  QUICKBOOKS_ONLINE = 'quick-books-online',
  QUICKBOOKS_DESKTOP = 'quick-books-desktop',
}

const AGAVE_INITIAL_SOURCE_SYSTEMS: AgeveSourceSystemSlug[] = [
  AgeveSourceSystemSlug.QUICKBOOKS_ONLINE,
  AgeveSourceSystemSlug.QUICKBOOKS_DESKTOP,
];

const AgaveManagement = observer(() => {
  const { userStore } = useStores();
  const navigate = useNavigate();

  const userCanManageAccountingIntegration =
    userStore.getPermissions().canManageAccountingIntegration;

  const agaveIntegrationEnabledFeatureFlag = useAwaitableFeatureFlag({
    featureFlagKey: FeatureFlags.agaveIntegrationEnabled,
  });

  const hasAccess =
    userCanManageAccountingIntegration &&
    agaveIntegrationEnabledFeatureFlag.isReady &&
    agaveIntegrationEnabledFeatureFlag.isFeatureFlagEnabled;

  if (!hasAccess) {
    navigate(routes.base);
  }

  return <AgaveManagementGrid />;
});

const AgaveManagementGrid = observer(() => {
  const theme = useTheme();
  const { toasterStore, agaveIntegrationStore } = useStores();
  const { getLinkedAccounts, deleteLinkedAccount } = useAgave();
  const pagination = useRef<Pagination>({ limit: 25, page: 1 });
  const modalDialogRef = useRef<ModalDialogHandler>(null);

  const [alreadyLinkedSourceSystems, setAlreadyLinkedSourceSystems] = useState<
    AgeveSourceSystemSlug[]
  >([]);
  const [selectedLinkedAccount, setSelectedLinkedAccount] =
    useState<Nullable<AgaveLinkedAccount>>(null);
  const { createAgaveLinkedAccount, allAvailableSourceSystemsLinked } =
    useCreateAgaveLinkedAccount(alreadyLinkedSourceSystems);

  const isDeletingLinkedAccount = JSON.stringify(
    Object.fromEntries(agaveIntegrationStore.isDeletingLinkedAccount),
  );

  const handleDeleteLinkedAccountClick = useCallback(async (linkedAccountId: string) => {
    try {
      agaveIntegrationStore.deleteLinkedAccountStart(linkedAccountId);
      await deleteLinkedAccount(linkedAccountId, (error: AxiosError) => {
        modalDialogRef.current?.setError({
          code: error.response?.status || null,
          message: error.message || 'An unexpected error occurred',
        });
      });
      agaveIntegrationStore.deleteLinkedAccountEnd(linkedAccountId, true);
      modalDialogRef.current?.close();
      toasterStore.push(
        alert(
          t('administration.integration.agave.grid.linked_account_deleted'),
          AlertTypes.success,
        ),
        false,
        true,
      );
    } catch (e) {
      agaveIntegrationStore.deleteLinkedAccountEnd(linkedAccountId);
    }
  }, []);

  const fetchLinkedAccounts = async () => {
    try {
      agaveIntegrationStore.fetchLinkedAccountsStart();

      const response = await getLinkedAccounts(pagination.current);

      if (response) {
        agaveIntegrationStore.fetchLinkedAccountsEnd(response.data);

        pagination.current = {
          ...pagination.current,
          before: '',
          after: '',
          ...response.pagination,
        };
      } else {
        throw new Error();
      }
    } catch {
      agaveIntegrationStore.fetchLinkedAccountsEnd();
      toasterStore.push(
        alert(
          t('error_messages.agave.failed_to_fetch_linked_accounts'),
          AlertTypes.error,
        ),
        false,
        true,
      );
    }
  };

  const fetchPreviousPage = () => {
    pagination.current = _.pick(pagination.current, ['page', 'limit', 'before']);
    pagination.current.page -= 1;

    fetchLinkedAccounts();
  };

  const fetchNextPage = () => {
    pagination.current = _.pick(pagination.current, ['page', 'limit', 'after']);
    pagination.current.page += 1;

    fetchLinkedAccounts();
  };

  const columns = useMemo(
    () => [
      colsDef.logoColDef,
      colsDef.sourceSystemNameColDef,
      colsDef.statusColDef,
      colsDef.lastSyncColDef,
      colsDef.actionsColDef(() => (
        <HeaderNavigation
          count={(agaveIntegrationStore.linkedAccounts ?? []).length}
          loading={agaveIntegrationStore.isLoadingLinkedAccounts}
          pagination={pagination.current}
          callback={(ref: PaginationLink) => {
            if (ref === 'before') {
              fetchPreviousPage();
            } else {
              fetchNextPage();
            }
          }}
        />
      )),
    ],
    [
      agaveIntegrationStore.linkedAccounts?.length,
      agaveIntegrationStore.isLoadingLinkedAccounts,
    ],
  );

  const rows = useMemo(() => {
    return (agaveIntegrationStore.linkedAccounts ?? []).map((linkedAccount) => {
      const rows: colsDef.AgaveManagementRowDef['row'] = {
        id: linkedAccount.id,
        isDeleting: agaveIntegrationStore.isDeletingLinkedAccount.get(linkedAccount.id),
        linkedAccount,
        onDelete: () => {
          modalDialogRef.current?.open();
          setSelectedLinkedAccount(linkedAccount);
        },
      };

      return rows;
    });
  }, [
    JSON.stringify(agaveIntegrationStore.linkedAccounts),
    handleDeleteLinkedAccountClick,
    isDeletingLinkedAccount,
  ]);

  useEffect(() => {
    fetchLinkedAccounts();
  }, []);

  useEffect(() => {
    const slugs = (agaveIntegrationStore.linkedAccounts ?? []).map((account) => {
      return account.agaveSourceSystem.slug as AgeveSourceSystemSlug;
    });

    setAlreadyLinkedSourceSystems(slugs);
  }, [JSON.stringify(agaveIntegrationStore.linkedAccounts)]);

  return (
    <Box maxWidth="1000px">
      <Button
        color="secondary"
        component={Link}
        startIcon={<ArrowBackIosNew fontSize="small" />}
        to={`/${routes.settings.base}/${routes.settings.integrations.base}`}
        variant="text"
      >
        {t('administration.integration.title')}
      </Button>

      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h4">
            {t(`administration.integration.agave.name`)}
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {allAvailableSourceSystemsLinked && (
              <Typography
                variant="body1"
                sx={{
                  color: theme.brandV2.colors.treadGray3,
                  mr: 1,
                }}
              >
                {t('administration.integration.agave.cta.unable_to_link')}
              </Typography>
            )}
            <LoadingButton
              color="primary"
              disabled={allAvailableSourceSystemsLinked}
              loading={agaveIntegrationStore.isSavingLinkedAccount}
              loadingPosition="start"
              onClick={createAgaveLinkedAccount}
              size="medium"
              startIcon={agaveIntegrationStore.isSavingLinkedAccount ? <Save /> : <Add />}
            >
              {t('administration.integration.agave.cta.link_an_account')}
            </LoadingButton>
          </Box>
        </Box>
      </CardContent>

      <DataGrid
        id="agave-management-grid"
        columns={columns}
        disableColumnFilter
        hideQuickFilter
        loading={agaveIntegrationStore.isLoadingLinkedAccounts}
        rows={rows}
      />

      <ModalDialog
        ref={modalDialogRef}
        loading={
          !!selectedLinkedAccount &&
          agaveIntegrationStore.isDeletingLinkedAccount.get(selectedLinkedAccount.id)
        }
        title={t('administration.integration.agave.cta.disconnect_integration', {
          integrationName: selectedLinkedAccount?.agaveSourceSystem.name,
        })}
        content={t(
          'administration.integration.agave.cta.some_features_may_no_longer_work',
        )}
        confirmButtonText={`${t('actions.disconnect')}`}
        callBack={() => {
          if (selectedLinkedAccount) {
            handleDeleteLinkedAccountClick(selectedLinkedAccount.id);
          }
        }}
        onClose={() => {
          setSelectedLinkedAccount(null);
        }}
        handleErrorsInline={true}
        errorComponent={DisconnectIntegrationErrorComponent}
      />
    </Box>
  );
});

function useCreateAgaveLinkedAccount(
  alreadyLinkedSourceSystems: AgeveSourceSystemSlug[],
) {
  const { toasterStore, agaveIntegrationStore } = useStores();
  const { createLinkedAccount, getLinkToken } = useAgave();

  const [linkToken, setLinkToken] = useState('');

  const onSuccess = (token: string) => {
    createLinkedAccount(token)
      .then((response) => {
        if (response) {
          const linkedAccount = AgaveLinkedAccount.parse(response);

          agaveIntegrationStore.createLinkedAccountEnd(linkedAccount);
          refreshToken();
          toasterStore.push(
            alert(
              t('administration.integration.agave.grid.linked_account_created'),
              AlertTypes.success,
            ),
            false,
            true,
          );
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        agaveIntegrationStore.createLinkedAccountEnd();
        refreshToken();
        toasterStore.push(
          alert(
            t('error_messages.agave.failed_to_create_linked_account'),
            AlertTypes.error,
          ),
          false,
          true,
        );
      });
  };

  const onExit = (error?: string) => {
    agaveIntegrationStore.createLinkedAccountEnd();
    refreshToken();

    if (error) {
      console.error('Failed to create Agave Linked Account', error);
      toasterStore.push(
        alert(
          t('error_messages.agave.failed_to_create_linked_account'),
          AlertTypes.error,
        ),
        false,
        true,
      );
    }
  };

  const refreshToken = async () => {
    try {
      const token = await getLinkToken();

      if (token) {
        setLinkToken(token);
      } else {
        throw new Error();
      }
    } catch {
      toasterStore.push(
        alert(t('error_messages.agave.failed_to_fetch_link_token'), AlertTypes.error),
        false,
        true,
      );
    }
  };

  const availableSourceSystems = AGAVE_INITIAL_SOURCE_SYSTEMS.filter((system) => {
    return !alreadyLinkedSourceSystems.includes(system);
  });
  // We only support having one linked account for now
  const allAvailableSourceSystemsLinked = availableSourceSystems.length === 1;

  const { isReady, openLink } = useAgaveLink({
    linkToken,
    onSuccess,
    onExit,
    sourceSystem: availableSourceSystems,
  });

  const createAgaveLinkedAccount = useCallback(() => {
    if (isReady) {
      agaveIntegrationStore.createLinkedAccountStart();
      openLink();
    }
  }, [isReady, openLink]);

  useEffect(() => {
    refreshToken();
  }, []);

  return { createAgaveLinkedAccount, allAvailableSourceSystemsLinked };
}

export default AgaveManagement;
