import type { Subscription } from '@rails/actioncable';
import {
  Order_Read,
  Order_Read_Typeahead,
  Order_Read_Typeahead_Dispatch_Number,
} from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useState } from 'react';

import DispatchSearchParam from '~components/Order/Selectors/DispatchSearchParam';
import { API_VERSION } from '~constants/consts';
import { OrderState } from '~constants/enums';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import { Order } from '~hooks/useOrders/models';
import { OrderFormSchemaInterface } from '~pages/Sales/Orders/orderFormSchema';
import connection from '~services/connectionModule';
import { PaginationLink, PaginationQuery } from '~services/pagination';
import { realTimeChannels } from '~services/realTimeChannels';
import { rootStore, useStores } from '~store';

export type OrderEventType =
  | 'request'
  | 'start'
  | 'accept'
  | 'reject'
  | 'cancel'
  | 'complete';

interface UpdateOrderParams {
  order: OrderFormSchemaInterface;
  callBack?: (order: Order) => void;
}
interface CreateOrderParams {
  order: OrderFormSchemaInterface;
  callBack?: (order: Order) => void;
}
interface DeleteOrderParams {
  id: string;
  callBack?: () => void;
}

interface GetOrdersByCompanyIdProps {
  companyId: string;
  link?: PaginationLink;
  searchQuery?: string;
  filterParams?: Record<string, typeof rootStore.ordersStore.orderFilter>;
}

interface GetOrdersByCompanyIdTypeaheadProps {
  callback?: (project: Order[]) => void;
  companyId: string;
  states?: OrderState[];
  limit?: number;
  query?: string;
  cursor?: string;
}

interface GetOrdersByCompanyIdTypeaheadQueryProps {
  'page[limit]': number;
  'search[query]': string;
  'filter[states]': OrderState[];
  'page[after]': string;
}

export const useOrders = () => {
  const [isLoadingOrders, setIsLoadingOrders] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const { ordersStore, userStore } = useStores();
  const [orderSubscription, setOrderSubscription] = useState<Subscription>();
  const [isSendingSms, setIsSendingSms] = useState(false);

  const { addSearchHeaderParam, SubFilterKey } = useDataGridSearch();
  const companyId = userStore?.currentCompanies?.[0]?.id || userStore?.userCompany?.id;

  const subscribeToOrderUpdates = (orderUpdateCallback?: (order: Order) => void) => {
    const consumer = connection.realTimeConnection;
    const subscription = consumer?.subscriptions?.create?.(
      {
        channel: realTimeChannels.CompanyOrderUpdateChannel,
        // This should be the user's company_id _or_ the company_id scoped in a given
        // Page?
        company_id: companyId,
      },
      {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        connected: () => () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        disconnected: () => () => {},
        received: (resp: { data: Order_Read }) => {
          //This message is broadcast to all client including the one who sent the request, will trigger 2*render
          const order = Order.parse(resp?.data);

          // If not last page
          if (!ordersStore.pagination.after) {
            ordersStore.addOrder(order);
          } else {
            ordersStore.updateOrder(order);
          }

          orderUpdateCallback?.(order);
        },
      },
    );
    setOrderSubscription(subscription);
  };
  const getOrderById = async (id: string) => {
    setIsLoadingOrders(true);
    try {
      const resp = await connection.get<Order_Read>(
        `${API_VERSION}/orders/${id}`,
        {},
        $t('error_messages.orders.failed_to_fetch_order') as string,
      );
      const order = Order.parse(resp);

      return order;
    } finally {
      setIsLoadingOrders(false);
    }
  };

  const getOrdersByCompanyId = async ({
    companyId,
    filterParams,
    link,
    searchQuery,
  }: GetOrdersByCompanyIdProps) => {
    setIsLoadingOrders(true);
    let params: PaginationQuery = {
      'page[limit]': ordersStore.pagination.limit,
    };

    if (link && ordersStore.pagination[link]) {
      params[`page[${link}]`] = ordersStore.pagination[link];
    }

    params = addSearchHeaderParam({
      searchValue: searchQuery,
      filterParams,
      params,
    });

    try {
      const { data, pagination } = await connection.getPaginated<Order_Read>(
        `${API_VERSION}/companies/${companyId}/orders`,
        { params },
        $t('error_messages.orders.failed_to_fetch') as string,
      );
      const formatted = data.map(Order.parse);
      ordersStore.setOrders(formatted);
      ordersStore.setPagination(pagination);
      ordersStore.updatePageNumber(link);
    } finally {
      setIsLoadingOrders(false);
    }
  };

  const getOrdersByCompanyIdTypeahead = async (
    options: GetOrdersByCompanyIdTypeaheadProps,
  ) => {
    const params: Partial<GetOrdersByCompanyIdTypeaheadQueryProps> = {
      'page[limit]': 30,
    };

    if (options?.query) {
      params['search[query]'] = options.query;
    }

    if (options?.states) {
      params['filter[states]'] = options.states;
    }

    if (options?.cursor) {
      params['page[after]'] = options.cursor;
    }

    try {
      setIsLoadingOrders(true);
      const { data, pagination } = await connection.getPaginated<Order_Read_Typeahead>(
        `${API_VERSION}/companies/${options.companyId}/orders/typeahead`,
        { params },
        $t('error_messages.orders.failed_to_fetch') as string,
      );
      const projects = (data as Order_Read[]).map((project) => Order.parse(project));
      options.callback?.(projects);
      return { data: projects, pagination };
    } catch (e: unknown) {
      throw new Error(`Failed to fetch orders: ${e}`);
    } finally {
      setIsLoadingOrders(false);
    }
  };

  const getOrderDispatchNumbersTypeahead = async (
    options: GetOrdersByCompanyIdTypeaheadProps,
  ) => {
    const params: Partial<GetOrdersByCompanyIdTypeaheadQueryProps> = {
      'page[limit]': options.limit ?? 30,
    };

    if (options?.query) {
      params['search[query]'] = options.query;
    }

    if (options?.states) {
      params['filter[states]'] = options.states;
    }

    if (options?.cursor) {
      params['page[after]'] = options.cursor;
    }

    try {
      setIsLoadingOrders(true);
      const { data, pagination } =
        await connection.getPaginated<Order_Read_Typeahead_Dispatch_Number>(
          `${API_VERSION}/orders/dispatch_numbers/typeahead`,
          { params },
          $t('error_messages.orders.failed_to_fetch') as string,
        );
      const projects = (data as Order_Read[]).map((project) => Order.parse(project));
      options.callback?.(projects);
      return { data: projects, pagination };
    } catch (e: unknown) {
      throw new Error(`Failed to fetch orders: ${e}`);
    } finally {
      setIsLoadingOrders(false);
    }
  };

  const createOrder = async ({ order, callBack }: CreateOrderParams) => {
    setIsUpdating(true);
    try {
      await connection
        .post<Order_Read>(
          `${API_VERSION}/orders`,
          Order.deparse(order),
          {},
          $t('error_messages.orders.failed_to_create') as string,
        )
        .then((resp) => {
          const orderSaved = Order.parse(resp);

          // If not last page
          if (!ordersStore.pagination.after) {
            ordersStore.addOrder(orderSaved);
          }

          callBack?.(orderSaved);
        });
    } finally {
      setIsUpdating(false);
    }
  };
  const updateOrder = async ({ order, callBack }: UpdateOrderParams) => {
    setIsUpdating(true);
    try {
      return await connection
        .patch<Order_Read>(
          `${API_VERSION}/orders/${order.id}`,
          Order.deparseUpdate(order),
          {},
          $t('error_messages.orders.failed_to_update') as string,
        )
        .then((resp) => {
          const orderSaved = Order.parse(resp);
          ordersStore.updateOrder(orderSaved);
          callBack?.(orderSaved);
        });
    } finally {
      setIsUpdating(false);
    }
  };
  const getCompanyOrders = (
    link?: PaginationLink,
    searchQuery?: string,
    filterParams?: Record<string, any>,
  ) => {
    setIsLoadingOrders(true);
    let params: PaginationQuery = {
      'page[limit]': ordersStore.pagination.limit,
    };

    if (link && ordersStore.pagination[link]) {
      params[`page[${link}]`] = ordersStore.pagination[link];
    }

    params = addSearchHeaderParam({
      searchValue: searchQuery,
      filterParams,
      params,
    });
    return connection
      .getPaginated<Order_Read>(
        `${API_VERSION}/orders`,
        { params },
        $t('error_messages.orders.failed_to_fetch') as string,
      )
      .then(({ data, pagination }) => {
        const formatted = data.map(Order.parse);
        ordersStore.setOrders(formatted);
        ordersStore.setPagination(pagination);
        ordersStore.updatePageNumber(link);
      })
      .finally(() => {
        setIsLoadingOrders(false);
      });
  };
  const doEvent = (id: string, event: OrderEventType, data?: any) => {
    setIsUpdating(true);

    return connection
      .put<Order_Read>(
        `${API_VERSION}/orders/${id}/${event}`,
        data || undefined,
        {},
        $t('error_messages.orders.failed_to_do_order_event', { event }) as string,
      )
      .then((resp) => {
        const formatted = Order.parse(resp);
        ordersStore.updateOrder(formatted);
        return formatted;
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  /**
   * Retrieves company orders based on the provided parameters under the order's jobs.
   *
   * @param link - The pagination link to fetch the next or previous page of orders.
   * @param searchQuery - The search query to filter the orders.
   * @param filterParams - Additional filter parameters to narrow down the orders.
   */
  const getOrdersDispatch = (
    link?: PaginationLink,
    searchQuery?: string,
    filterParams?: { jobs: Record<string, any>; orders: Record<string, any> },
    limit?: number,
  ) => {
    const { isCurrentCompanyActive, currentCompanies } = userStore;
    setIsLoadingOrders(true);
    let params: PaginationQuery = {
      'page[limit]': limit || ordersStore.pagination.limit,
    };

    if (link && ordersStore.pagination[link]) {
      params[`page[${link}]`] = ordersStore.pagination[link];
    }

    // Add search and filter params
    params = addSearchHeaderParam({
      searchValue: searchQuery,
      filterParams: filterParams?.jobs,
      params,
      subFilterKey: SubFilterKey.Job,
    });

    params = addSearchHeaderParam({
      searchValue: '',
      filterParams: filterParams?.orders,
      params,
    });

    // Status keeps track of the current order or job filter status, but we don't want to send it to api
    delete params['filter[job][status]'];

    let url;
    if (isCurrentCompanyActive) {
      url = `${API_VERSION}/orders/dispatch`;
    } else if (currentCompanies?.length > 0 && currentCompanies[0]?.id) {
      url = `${API_VERSION}/companies/${currentCompanies[0].id}/orders/dispatch`;
    } else {
      console.error($t('error_messages.company.failed_to_fetch_company'));
      throw new Error(`${$t('error_messages.company.failed_to_fetch_company')}`);
    }

    return connection
      .getPaginated<Order_Read>(
        url,
        {
          params,
        },
        $t('error_messages.orders.failed_to_fetch') as string,
      )
      .then(({ data, pagination }) => {
        const formatted = data.map(Order.parse);
        ordersStore.setOrders(formatted);
        ordersStore.setPagination(pagination);
        ordersStore.updatePageNumber(link);

        // Only state filters can allow for new orders flow in the dispatch page
        const jobKeys = Object.keys(filterParams?.jobs || {}).filter(
          (f) => f !== DispatchSearchParam.STATUS,
        );
        const orderKeys = Object.keys(filterParams?.orders || {}).filter(
          (f) => f !== DispatchSearchParam.STATE,
        );

        const rejectNewOrders = searchQuery || jobKeys.length > 0 || orderKeys.length > 0;
        ordersStore.setAcceptNewOrders(!rejectNewOrders);
        ordersStore.setPendingNewOrders(false);

        return formatted;
      })
      .finally(() => {
        setIsLoadingOrders(false);
      });
  };

  const deleteOrder = async ({ id, callBack }: DeleteOrderParams) => {
    setIsUpdating(true);
    try {
      await connection
        .delete(
          `${API_VERSION}/orders/${id}`,
          {},
          $t('error_messages.orders.failed_to_delete') as string,
        )
        .then(() => {
          ordersStore.deleteOrder(id);
          callBack?.();
        });
    } finally {
      setIsUpdating(false);
    }
  };

  const cloneOrder = async (id: string, includeAssignee: boolean = false) => {
    setIsUpdating(true);
    const requestBody = {
      include_assignee: includeAssignee,
    };
    try {
      const resp = await connection.post<Order_Read>(
        `${API_VERSION}/orders/${id}/copy`,
        requestBody,
        {},
        $t('error_messages.orders.failed_to_duplicate') as string,
      );
      const formatted = Order.parse(resp);
      if (!ordersStore.pagination.after?.length) {
        ordersStore.addOrder(formatted);
      }

      ordersStore.addClonedOrder(formatted);
      return formatted;
    } finally {
      setIsUpdating(false);
    }
  };

  const sendSmsToAllDrivers = async (orderId: string, message: string) => {
    // Bottom is commented out to demonstrate the state that a job under the order must be in to receive the SMS
    // SEND_MESSAGE_STATES = [
    //   JobState.definitions[:accepted],
    //   JobState.definitions[:to_pickup],
    //   JobState.definitions[:arrived_pickup],
    //   JobState.definitions[:loaded],
    //   JobState.definitions[:to_dropoff],
    //   JobState.definitions[:arrived_dropoff],
    //   JobState.definitions[:unloaded],
    //   JobState.definitions[:load_completed],
    //   JobState.definitions[:in_review],

    // ]
    try {
      setIsSendingSms(true);
      await connection.put(
        `${API_VERSION}/orders/${orderId}/driver_sms`,
        { message },
        {},
        $t('error_messages.orders.failed_to_send_text') as string,
      );
    } finally {
      setIsSendingSms(false);
    }
  };

  return {
    isLoadingOrders,
    isUpdating,
    // GetProjectById,
    deleteOrder,
    doEvent,
    cloneOrder,
    updateOrder,
    createOrder,
    getCompanyOrders,
    getOrdersByCompanyId,
    getOrdersByCompanyIdTypeahead,
    getOrderDispatchNumbersTypeahead,
    getOrderById,
    subscribeToOrderUpdates,
    orderSubscription,
    getOrdersDispatch,
    sendSmsToAllDrivers,
    isSendingSms,
  } as const;
};
