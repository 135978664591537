import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import { ApprovalStatus } from '~hooks/useApprovalsAssignments/models';
import { useApprovalsAssignments } from '~hooks/useApprovalsAssignments/useApprovalAssignments';
import theme from '~theme/AppTheme';

const ApprovalsRoles = ['driver', 'forman', 'biller', 'companyAdmin'];

const ApprovedBySection = ({ driverDayId }: { driverDayId: string }) => {
  const { getDriverDayApprovalStatus } = useApprovalsAssignments();
  const [status, setStatus] = useState<ApprovalStatus | null>(null);

  const getApprovals = async () => {
    const approvals = await getDriverDayApprovalStatus(driverDayId);
    setStatus(approvals);
  };
  useEffect(() => {
    getApprovals();
  }, [driverDayId]);

  const Approvals = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: 1,
        }}
      >
        {ApprovalsRoles.map((role) => {
          const isRoleApproved = status?.[`${role}Approved` as keyof ApprovalStatus];
          return (
            <Box
              key={role}
              sx={{
                display: 'flex',
                gap: 0.25,
                alignItems: 'center',
              }}
            >
              <CheckCircleOutline
                sx={{
                  color: isRoleApproved
                    ? theme.brandV2.colors.treadGreen
                    : theme.brandV2.colors.treadGray7,
                  fontSize: '16px',
                }}
              />
              <Typography variant="body2">
                {t(`approvals.driver_day.${_.snakeCase(role)}`)}
              </Typography>
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        p: 1.5,
        flexDirection: 'column',
        borderRadius: 2,
        border: `1px solid ${theme.brandV2.colors.treadGray7}`,
        backgroundColor: 'white',
      }}
    >
      <Typography variant="body2" sx={{ fontWeight: 600, mb: 0.75 }}>
        {t('approvals.driver_day.shift_approved_by')}
      </Typography>
      <Approvals />
    </Box>
  );
};
export default ApprovedBySection;
