import {
  Company_Read as CompanyProto,
  Company_Read_Nested,
} from '@treadinc/horizon-api-spec';
import { t as $t, t } from 'i18next';
import { useMemo, useState } from 'react';
import { string } from 'yup';

import { API_VERSION } from '~constants/consts';
import { FormErrorType } from '~formsShared';
import { FormError } from '~formsShared/formSharedModels/models';
import { Company, CompanyBasic } from '~hooks/useCompany/models';
import connection from '~services/connectionModule';
import { PaginationQuery } from '~services/pagination';
import { useStores } from '~store';

const isUUID = (id: string) => string().uuid().isValidSync(id);

type GetCompanyByTreadIdResponseType = CompanyBasic | FormErrorType;

interface UpdateCompanyParams {
  company: Company;
  callBack?: (company: Company) => void;
}
interface UploadLogoParams {
  id: string;
  file: File;
  callBack?: (company: Company) => void;
}
interface GetCompanyByIdProps {
  id: string;
  callBack?: (company: Company) => void;
}
interface GetCompanyByTreadIdParams {
  treadId: string;
}

interface GetAllUserAvailableCompaniesParams {
  id: string;
  callBack?: (company: CompanyBasic[]) => void;
}

interface GetCompanyTypeaheadParams {
  callBack?: (company: CompanyBasic[]) => void;
  limit?: number;
  query?: string;
  cursor?: string;
}

interface GetCompanyTypeaheadQueryProps {
  'page[limit]': number;
  'search[query]': string;
  'page[after]': string;
}

interface GetAllConnectedCompaniesByCompanyIdParams {
  companyId: string;
  limit?: number;
  callback?: (companies: CompanyBasic[]) => void;
}

interface GetAllConnectedCompaniesByCompanyIdTypeaheadParams
  extends GetAllConnectedCompaniesByCompanyIdParams {
  query?: string;
  cursor?: string;
}

interface GetAllConnectedCompaniesByCompanyIdQueryProps {
  'page[limit]'?: number;
}

interface GetAllConnectedCompaniesByCompanyIdTypeaheadQueryProps
  extends GetAllConnectedCompaniesByCompanyIdQueryProps {
  'search[query]'?: string;
  'page[after]'?: string;
}

export const useCompany = () => {
  const [isLoadingCompanies, setIsLoading] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const { userStore } = useStores();
  const getUserCompany = async (id: string) => {
    setIsLoading(true);
    try {
      const resp = await connection.get<CompanyProto>(
        `${API_VERSION}/companies/${id}`,
        {},
        isUUID(id)
          ? ($t('error_messages.company.failed_to_fetch_user_company') as string)
          : ($t('error_messages.company.invalid_company_id') as string),
      );

      const company = Company.parse(resp);
      userStore.setUserCompany(company);

      return company;
    } finally {
      setIsLoading(false);
    }
  };
  const getCompanyById = async ({ id, callBack }: GetCompanyByIdProps) => {
    setIsLoading(true);
    try {
      const resp = await connection.get<CompanyProto>(
        `${API_VERSION}/companies/${id}`,
        {},
        $t('error_messages.company.failed_to_fetch_company') as string,
      );
      const company = Company.parse(resp);
      callBack?.(company);

      return company;
    } finally {
      setIsLoading(false);
    }
  };
  const updateCompany = ({ company, callBack }: UpdateCompanyParams) => {
    setIsUpdating(true);
    return connection
      .patch<CompanyProto>(
        `${API_VERSION}/companies/${company.id}`,
        Company.deparseUpdate(company),
        {},
        $t('error_messages.company.failed_to_update') as string,
      )
      .then((resp) => {
        const company = Company.parse(resp);
        userStore.updateUserCompany(company);
        callBack?.(company);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  // META request to handle case, if company has been created but LOGO POST REQUEST is failed.
  // In this case the safest way is to update just created company instead of creating new one with the same data OR operate with old data
  const postCompanyUpdate = ({ company, callBack }: UpdateCompanyParams) => {
    return company.id;
    updateCompany({ company, callBack });
  };

  const uploadLogo = ({ id, file, callBack }: UploadLogoParams) => {
    setIsUpdating(true);

    const formData = new FormData();
    formData.append('logo', file);

    return connection
      .post<CompanyProto>(
        `${API_VERSION}/companies/${id}/logo`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        $t('error_messages.company.failed_to_upload_logo') as string,
      )
      .then((resp) => {
        const company = Company.parse(resp);
        userStore.updateUserCompany(company);

        callBack?.(company);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const getAllUserAvailableCompanies = ({
    id,
    callBack,
  }: GetAllUserAvailableCompaniesParams) => {
    const url = `${API_VERSION}/companies`;
    const params: PaginationQuery = {
      'page[limit]': 100,
    };
    setIsLoading(true);
    return connection
      .getPaginated<Company_Read_Nested>(
        url,
        { params },
        $t('error_messages.company.failed_to_fetch_companies') as string,
      )
      .then(({ data }) => {
        const companies = data.map((company) => CompanyBasic.parse(company));
        callBack?.(companies);
        if (!userStore.userCompanies.length) {
          userStore.setUserCompanies(companies);
        }
        return companies;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getAllUserAvailableCompaniesTypeahead = async (
    options: GetCompanyTypeaheadParams,
  ) => {
    const params: Partial<GetCompanyTypeaheadQueryProps> = {
      'page[limit]': options?.limit || 20,
    };

    if (options?.query) {
      params['search[query]'] = options.query;
    }

    if (options?.cursor) {
      params['page[after]'] = options.cursor;
    }

    try {
      setIsLoading(true);
      const response = await connection
        .getPaginated<Company_Read_Nested>(
          `${API_VERSION}/companies/typeahead`,
          { params },
          $t('error_messages.company.failed_to_fetch_companies') as string,
        )
        .then(({ data: companies, pagination }) => {
          const formattedCompanies = companies.map(CompanyBasic.parse);
          return { data: formattedCompanies, pagination };
        });

      return response;
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getCompanyByTreadId = async ({
    treadId,
  }: GetCompanyByTreadIdParams): Promise<GetCompanyByTreadIdResponseType> => {
    setIsLoading(true);
    try {
      const company = await connection.get<CompanyProto>(
        `${API_VERSION}/companies/tread_id/${treadId}`,
        {},
        $t('error_messages.company.failed_to_fetch_company_by_tread_id') as string,
      );
      return CompanyBasic.parse(company);
    } catch (error) {
      const errorMessage = FormError.parse({
        message: `${t('errors.no_company_found_with_tread_id')}`,
      });

      return errorMessage;
    } finally {
      setIsLoading(false);
    }
  };

  const getAllConnectedCompaniesByCompanyId = async (
    options: GetAllConnectedCompaniesByCompanyIdParams,
  ) => {
    const params: GetAllConnectedCompaniesByCompanyIdQueryProps = {
      'page[limit]': options?.limit || 25,
    };

    try {
      setIsLoading(true);
      const response = await connection
        .getPaginated<Company_Read_Nested>(
          `${API_VERSION}/companies/${options.companyId}/connected_companies`,
          { params },
          $t('error_messages.company.failed_to_fetch_connected_companies') as string,
        )
        .then(({ data, pagination }) => {
          return data.map(CompanyBasic.parse);
        });

      options.callback?.(response);
      return response;
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllConnectedCompaniesByCompanyIdTypeahead = async (
    options: GetAllConnectedCompaniesByCompanyIdTypeaheadParams,
  ) => {
    const params: GetAllConnectedCompaniesByCompanyIdTypeaheadQueryProps = {
      'page[limit]': options?.limit || 25,
    };
    if (options?.query) {
      params['search[query]'] = options.query;
    }
    if (options?.cursor) {
      params['page[after]'] = options.cursor;
    }

    try {
      setIsLoading(true);
      const response = await connection
        .getPaginated<Company_Read_Nested>(
          `${API_VERSION}/companies/${options.companyId}/connected_companies/typeahead`,
          { params },
          $t('error_messages.company.failed_to_fetch_connected_companies') as string,
        )
        .then(({ data, pagination }) => {
          const formattedCompanies = data.map(CompanyBasic.parse);
          return { data: formattedCompanies, pagination };
        });

      return response;
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getUserCompanyDefaultMapCenter = () => {
    const { defaultLon, defaultLat } = userStore.userCompany;

    return useMemo(() => {
      if (defaultLon && defaultLat) {
        return {
          lng: defaultLon,
          lat: defaultLat,
        };
      }

      return {
        lng: -79.389193,
        lat: 43.648947,
      };
    }, [defaultLon, defaultLat]);
  };

  return {
    isLoadingCompanies,
    getAllUserAvailableCompanies,
    getAllUserAvailableCompaniesTypeahead,
    getAllConnectedCompaniesByCompanyId,
    getAllConnectedCompaniesByCompanyIdTypeahead,
    getCompanyById,
    getCompanyByTreadId,
    getUserCompany,
    getUserCompanyDefaultMapCenter,
    isUpdating,
    postCompanyUpdate,
    updateCompany,
    uploadLogo,
  } as const;
};
