import { makeObservable, observable, runInAction } from 'mobx';

import { AddOn, AddOnCharge } from '~hooks/useAddOns';
import { Pagination, PaginationLink } from '~services/pagination';
import { getPageNumber } from '~utils/pagination';

export default class AddOnsStore {
  addOnChargesByInvoiceId: Record<string, AddOnCharge[]> = {};
  addOnChargesTotalByInvoiceId: Record<string, number> = {};
  addOns: AddOn[] = [];
  pagination: Pagination = {
    limit: 100,
    after: '',
    before: '',
    page: 1,
  };
  selectableAddOnsByInvoiceId: Record<string, AddOn[]> = {};

  constructor() {
    makeObservable(this, {
      addOnChargesByInvoiceId: observable,
      addOnChargesTotalByInvoiceId: observable,
      addOns: observable,
      pagination: observable,
      selectableAddOnsByInvoiceId: observable,
    });
  }

  addAddOn(addOn: AddOn) {
    runInAction(() => {
      this.setAddOns([...this.addOns, addOn]);
    });
  }

  updateAddOn(addOn: AddOn) {
    runInAction(() => {
      const addOnIndex = this.addOns.findIndex(({ id }) => id === addOn.id);

      if (addOnIndex > -1) {
        const newList = this.addOns.slice();

        newList.splice(addOnIndex, 1, addOn);
        this.setAddOns(newList);
      }
    });
  }

  setAddOns(addOns: AddOn[]) {
    runInAction(() => {
      this.addOns = [...addOns];
    });
  }

  setAddOnChargesByInvoiceId(invoiceId: string, addOnCharges: AddOnCharge[]) {
    runInAction(() => {
      this.addOnChargesByInvoiceId[invoiceId] = [...addOnCharges];
    });
  }

  setAddOnChargesTotalByInvoiceId(invoiceId: string, total: number) {
    runInAction(() => {
      this.addOnChargesTotalByInvoiceId[invoiceId] = total;
    });
  }

  setSelectableAddOnsByInvoiceId(invoiceId: string, addOns: AddOn[]) {
    runInAction(() => {
      this.selectableAddOnsByInvoiceId[invoiceId] = [...addOns];
    });
  }

  setPagination(pagination: Pagination) {
    runInAction(() => {
      this.pagination = {
        ...this.pagination,
        before: pagination.before || '',
        after: pagination.after || '',
      };
    });
  }

  updatePageNumber(link: PaginationLink) {
    runInAction(() => {
      this.pagination = {
        ...this.pagination,
        page: getPageNumber(this.pagination.page, link),
      };
    });
  }
}
