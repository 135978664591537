import {
  Job_Read,
  JobEvent_Read,
  JobRoute_Read,
  JobState,
  JobTimeline_Read,
  Ticket_Read,
} from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { JobEventsActions } from '~constants/enums';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import { JobTripEvent } from '~hooks/useJob/models';
import { LatLongItem } from '~hooks/useMaps/models';
import { Ticket } from '~hooks/useTickets/models';
import { JobApprovalSchemaInterface } from '~pages/Approvals/ApprovalsComponents/jobApproveFormSchema';
import connection from '~services/connectionModule';
import { PaginationLink, PaginationQuery } from '~services/pagination';
import { useStores } from '~store';

import { Job, JobTimeline } from '../useJob';
interface UpdateApprovalJobProps {
  id: string;
  data: JobApprovalSchemaInterface;
  callBack?: () => void;
}
interface GetAllActiveJobsWithCallBackProps {
  link?: PaginationLink;
  searchQuery?: string;
  jobState?: JobState[];
  callBack?: (data: { data: Job[]; pagination: any }) => void;
}
interface GetJobApproveEventProps {
  id: string;
  callBack?: (data: JobTripEvent[]) => void;
}
interface GetJobRouteByIdProps {
  id: string;
  callBack?: (data: LatLongItem[]) => void;
}
interface SignOffJobByIdProps {
  id: string;
  callBack?: (data: Job) => void;
}
interface UploadJobTicketParams {
  id: string;
  file: File;
  callBack?: (ticket: Ticket) => void;
}
interface UpdateJobEventsProps {
  id: string;
  jobEvents: JobTripEvent[];
  callBack?: (data: JobTripEvent[]) => void;
}
interface ChangeJobsToPayedProps {
  ids: string[];
  callBack?: (data: Job[]) => void;
}
export const useApprovals = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isLoadingJobTripEvents, setIsLoadingJobTripEvents] = useState<boolean>(false);
  const [isLoadingJobRoute, setIsLoadingJobRoute] = useState<boolean>(false);
  const { approvalsStore } = useStores();
  const { addSearchHeaderParam } = useDataGridSearch();

  // This hook should ideally be depercated in favor of the useJobs hook
  const getAllJobs = (
    link?: PaginationLink,
    searchQuery?: string,
    jobState?: JobState[],
  ) => {
    setIsLoading(true);
    let params: PaginationQuery = {
      'page[limit]': approvalsStore.pagination.limit,
    };
    params = addSearchHeaderParam({ searchValue: searchQuery, params });
    if (link && approvalsStore.pagination[link]) {
      params[`page[${link}]`] = approvalsStore.pagination[link];
    }
    if (jobState) {
      params['filter[states]'] = jobState;
    }
    return connection
      .getPaginated<Job_Read>(
        `${API_VERSION}/jobs`,
        { params },
        $t('error_messages.jobs.failed_to_fetch') as string,
      )
      .then(({ data, pagination }) => {
        const formatted: Job[] = data.map(Job.parse) || [];
        approvalsStore.setJobs(formatted);
        approvalsStore.setPagination(pagination);
        approvalsStore.updatePageNumber(link);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // This hook should ideally be depercated in favor of the useJobs hook
  const getAllActiveJobsWithCallBack = ({
    link,
    searchQuery,
    jobState,
    callBack,
  }: GetAllActiveJobsWithCallBackProps) => {
    setIsLoading(true);
    let params: PaginationQuery = {
      'page[limit]': approvalsStore.pagination.limit,
    };
    params = addSearchHeaderParam({ searchValue: searchQuery, params });
    if (link && approvalsStore.pagination[link]) {
      params[`page[${link}]`] = approvalsStore.pagination[link];
    }
    if (jobState) {
      params['filter[states]'] = jobState;
    }
    return connection
      .getPaginated<Job_Read>(
        `${API_VERSION}/jobs`,
        { params },
        $t('error_messages.jobs.failed_to_fetch') as string,
      )
      .then(({ data, pagination }) => {
        const formatted: Job[] = data.map(Job.parse) || [];
        callBack?.({ data: formatted, pagination });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const updateJob = ({ id, data, callBack }: UpdateApprovalJobProps) => {
    setIsUpdating(true);
    return connection
      .patch<Job_Read>(
        `${API_VERSION}/jobs/${id}`,
        Job.deparseApproveUpdate(data),
        {},
        $t('error_messages.jobs.failed_to_update') as string,
      )
      .then((resp) => {
        const formatted = Job.parse(resp);
        approvalsStore.updateJob(formatted);
        return formatted;
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const removeJob = (id: string) => {
    setIsUpdating(true);

    return connection
      .delete(
        `${API_VERSION}/jobs/${id}`,
        {},
        $t('error_messages.jobs.failed_to_delete') as string,
      )
      .then(() => {
        approvalsStore.removeJob(id);
        return id;
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const doEvent = (id: string, event: JobEventsActions, data?: any) => {
    setIsUpdating(true);
    return connection
      .put<Job_Read>(
        `${API_VERSION}/jobs/${id}/${event}`,
        data || undefined,
        {},
        $t('error_messages.jobs.failed_to_do_event', {
          event,
        }) as string,
      )
      .then((resp) => {
        const formatted = Job.parse(resp);
        approvalsStore.updateJob(formatted);
        return formatted;
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const getJobTimeline = (id: string) => {
    return connection
      .get<
        JobTimeline_Read[]
      >(`${API_VERSION}/jobs/${id}/timeline`, {}, $t('error_messages.jobs.failed_to_fetch_timeline') as string)
      .then((resp) => {
        approvalsStore.setTimeline(
          id,
          resp.map((item) => JobTimeline.parse(item)),
        );
      });
  };

  const getJobApprovalTripEvents = async ({ id, callBack }: GetJobApproveEventProps) => {
    return await connection
      .get<
        JobEvent_Read[]
      >(`${API_VERSION}/jobs/${id}/job_events`, {}, $t('error_messages.jobs.failed_to_fetch_events') as string)
      .then((resp) => {
        const events = resp.map((item) => JobTripEvent.parse(item));
        callBack?.(events);
        return events;
      });
  };
  const getJobRouteById = async ({ id, callBack }: GetJobRouteByIdProps) => {
    setIsLoadingJobRoute(true);
    try {
      const resp = await connection.get<JobRoute_Read[]>(
        `${API_VERSION}/jobs/${id}/route`,
        {},
        $t('error_messages.jobs.failed_to_fetch_route') as string,
      );
      const events = resp.map(LatLongItem.parse);
      callBack?.(events);
      return events;
    } finally {
      setIsLoadingJobRoute(false);
    }
  };
  const uploadJobTicket = ({ id, file, callBack }: UploadJobTicketParams) => {
    setIsUpdating(true);
    const formData = new FormData();
    formData.append('image', file);
    return connection
      .post<Ticket_Read>(
        `${API_VERSION}/tickets/${id}/image`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        $t('error_messages.jobs.failed_to_upload_ticket_image') as string,
      )
      .then((resp) => {
        const ticket = Ticket.parse(resp);
        callBack?.(ticket);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };
  const signOffJobById = ({ id, callBack }: SignOffJobByIdProps) => {
    setIsUpdating(true);
    return connection
      .put<Job_Read>(
        `${API_VERSION}/jobs/${id}/sign_off`,
        {},
        {},
        $t('error_messages.jobs.failed_to_sign_off_job') as string,
      )
      .then((resp) => {
        const formatted = Job.parse(resp);
        approvalsStore.updateJob(formatted);
        callBack?.(formatted);
        return formatted;
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };
  const unSignOffJobById = ({ id, callBack }: SignOffJobByIdProps) => {
    setIsUpdating(true);
    return connection
      .put<Job_Read>(
        `${API_VERSION}/jobs/${id}/remove_signoff`,
        {},
        {},
        $t('error_messages.jobs.failed_to_unsign_off_job') as string,
      )
      .then((resp) => {
        const formatted = Job.parse(resp);
        approvalsStore.updateJob(formatted);
        callBack?.(formatted);
        return formatted;
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };
  const changeJobsToPayed = ({ ids, callBack }: ChangeJobsToPayedProps) => {
    const promises = ids.map((id) => {
      return connection
        .put<Job_Read>(
          `${API_VERSION}/jobs/${id}/pay`,
          {},
          {},
          $t('error_messages.jobs.failed_to_pay') as string,
        )
        .then((resp) => {
          return Job.parse(resp);
        });
    });
    return Promise.all(promises)
      .then((results) => {
        // Callback function for when all promises have resolved.
        // 'results' is an array of resolved values from each promise.
        // Update in map due to the server limitation of end point /jobs/pay
        results?.map((item) => {
          approvalsStore.updateJob(item);
        });

        // You can add your custom callback logic here.
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };
  const flagJobById = ({ id, callBack }: SignOffJobByIdProps) => {
    setIsUpdating(true);
    return connection
      .put<Job_Read>(
        `${API_VERSION}/jobs/${id}/flag`,
        {},
        {},
        $t('error_messages.jobs.failed_to_flag') as string,
      )
      .then((resp) => {
        const formatted = Job.parse(resp);
        approvalsStore.updateJob(formatted);
        callBack?.(formatted);
        return formatted;
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };
  const unFlagJobById = ({ id, callBack }: SignOffJobByIdProps) => {
    setIsUpdating(true);
    return connection
      .put<Job_Read>(
        `${API_VERSION}/jobs/${id}/remove_flag`,
        {},
        {},
        $t('error_messages.jobs.failed_to_unflag') as string,
      )
      .then((resp) => {
        const formatted = Job.parse(resp);
        approvalsStore.updateJob(formatted);
        callBack?.(formatted);
        return formatted;
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };
  const updateJobEvents = async ({ id, jobEvents, callBack }: UpdateJobEventsProps) => {
    setIsUpdating(true);
    const eventsDeparsed = {
      job_events: jobEvents.map(JobTripEvent.deparseUpdate),
    };
    try {
      const resp = await connection.patch<JobEvent_Read[]>(
        `${API_VERSION}/jobs/${id}/job_events`,
        eventsDeparsed,
        {},
        $t('error_messages.jobs.failed_to_update_job_events') as string,
      );
      const formatted = resp.map((item) => JobTripEvent.parse(item));
      // ApprovalsStore.updateJob(formatted);
      callBack?.(formatted);
      return formatted;
    } finally {
      setIsUpdating(false);
    }
  };
  return {
    isLoading,
    isUpdating,
    isLoadingJobTripEvents,
    isLoadingJobRoute,
    getAllJobs,
    updateJob,
    removeJob,
    doEvent,
    getJobTimeline,
    getJobApprovalTripEvents,
    updateJobEvents,
    getJobRouteById,
    uploadJobTicket,
    signOffJobById,
    unSignOffJobById,
    flagJobById,
    unFlagJobById,
    changeJobsToPayed,
    getAllActiveJobsWithCallBack,
  } as const;
};
