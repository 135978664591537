import { countries } from '~constants/enums';

import { AddressItem } from './AddressItem';

export enum SupportedAddressProvider {
  NEXT_BILLION = 'next_billion',
  GOOGLE_MAPS = 'google_maps',
}

type NextBillionSuggestion = {
  place: {
    address: string;
    house: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    geopoint: { lat: number; lng: number };
  };
  dataSource: { refId: string };
};
type GoogleMapsSuggestion = google.maps.GeocoderResult;

export type AddressProviderSuggestion = NextBillionSuggestion | GoogleMapsSuggestion;

interface IAddressProvider {
  decodeSuggestion: (args: AddressProviderSuggestion) => AddressItem;
}

export class AddressProvider {
  constructor(private readonly strategy: IAddressProvider) {}

  decodeSuggestion(args: AddressProviderSuggestion) {
    return this.strategy.decodeSuggestion(args);
  }
}

export class ConcreteNextBillionAddressProvider implements IAddressProvider {
  decodeSuggestion(args: AddressProviderSuggestion) {
    const typedArgs = args as NextBillionSuggestion;

    return new AddressItem(
      typedArgs.place.address,
      typedArgs.place.house,
      typedArgs.place.city,
      typedArgs.place.state,
      typedArgs.place.postalCode,
      countries.values.find((country) => country.alpha3 === typedArgs.place.country) ??
        countries.default,
      String(typedArgs.place.geopoint.lat),
      String(typedArgs.place.geopoint.lng),
      typedArgs.dataSource.refId,
      '',
    );
  }
}

export class ConcreteGoogleGeocodingAddressProvider implements IAddressProvider {
  decodeSuggestion(args: AddressProviderSuggestion) {
    const typedArgs = args as google.maps.GeocoderResult;

    const localityComponent = typedArgs.address_components.find((component) => {
      return component.types.includes('locality');
    });
    const administrativeAreaComponent = typedArgs.address_components.find((component) => {
      return component.types.includes('administrative_area_level_1');
    });
    const postalCodeComponent = typedArgs.address_components.find((component) => {
      return component.types.includes('postal_code');
    });
    const contryComponent = typedArgs.address_components.find((component) => {
      return component.types.includes('country');
    });

    const address = typedArgs.formatted_address ?? '';
    const house = '';
    const city = localityComponent?.short_name ?? '';
    const state = administrativeAreaComponent?.short_name ?? '';
    const postalCode = postalCodeComponent?.short_name ?? '';
    const country = contryComponent?.short_name
      ? countries.values.find((country) => country.code === contryComponent.short_name) ??
        countries.default
      : countries.default;
    const lat = typedArgs.geometry.location.lat();
    const lng = typedArgs.geometry.location.lng();
    const placeId = typedArgs.place_id;

    return new AddressItem(
      address,
      house,
      city,
      state,
      postalCode,
      country,
      String(lat),
      String(lng),
      placeId,
      '',
    );
  }
}

export const concreteAddressProviderBySupportedAddressProvider = {
  [SupportedAddressProvider.NEXT_BILLION]: ConcreteNextBillionAddressProvider,
  [SupportedAddressProvider.GOOGLE_MAPS]: ConcreteGoogleGeocodingAddressProvider,
};
