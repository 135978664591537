import Box from '@mui/material/Box';
import { JobState } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import _ from 'lodash';
import { createContext, useCallback, useRef, useState } from 'react';

import { ModalDialog, ModalDialogHandler } from '~components/Dialog/ModalDialog';
import SendTextDialog from '~components/Job/SendTextDialog';
import { Job } from '~hooks/useJob';
import { useOrdersDispatch } from '~hooks/useOrders';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';

import JobDialog from '../JobDialog';

type JobDialogsContextType = {
  duplicateJob: (job: Job) => void;
  openCancelJobDialog: (job: Job) => void;
  openEditJobFormDialog: (job: Job) => void;
  openSendTextDialog: (job: Job) => void;
};

const JobDialogsContext = createContext<JobDialogsContextType>({
  duplicateJob: _.noop,
  openCancelJobDialog: _.noop,
  openEditJobFormDialog: _.noop,
  openSendTextDialog: _.noop,
});

enum JobActionDialog {
  CANCEL_JOB = 'cancel_job',
  FORM = 'form',
  TEXT_DRIVER = 'text_driver',
}

type JobActionDialogState = {
  dialog?: JobActionDialog;
  isOpen: boolean;
  job?: Job;
};

function JobDialogsContextProvider({ children }: React.PropsWithChildren) {
  const [jobActionDialog, setJobActionDialog] = useState<JobActionDialogState>({
    isOpen: false,
  });
  const cancelJobDialogRef = useRef<ModalDialogHandler>(null);

  const { driverSchedulerStore, toasterStore } = useStores();
  const { cancelJob, duplicateJob } = useOrdersDispatch();

  const closeCurrentDialog = useCallback(() => {
    setJobActionDialog((state) => ({ ...state, isOpen: false }));
    cancelJobDialogRef.current?.close();
  }, []);

  const handleCancelJob = useCallback(async () => {
    const jobId = jobActionDialog.job?.id;

    if (!jobId) {
      return;
    }

    const cancelledJob = await cancelJob(jobId);

    driverSchedulerStore.removeUnassignedJob(cancelledJob);
    toasterStore.push(
      alert(t('dispatch.job.updated', { name: cancelledJob.jobId }), AlertTypes.success),
      false,
      true,
    );
    closeCurrentDialog();
  }, [closeCurrentDialog, jobActionDialog.job?.jobId]);

  const handleDuplicateJob = useCallback(async (job: Job) => {
    const clonedJob = await duplicateJob(job.id);

    if (job.status === JobState.REJECTED) {
      await cancelJob(job.id);
    }

    driverSchedulerStore.addUnassignedJob(clonedJob);

    toasterStore.push(
      alert(t('dispatch.job.copied', { name: clonedJob.jobId }), AlertTypes.success),
      false,
      true,
    );

    return clonedJob;
  }, []);

  const openCancelJobDialog = useCallback((job: Job) => {
    setJobActionDialog({ job, isOpen: true, dialog: JobActionDialog.CANCEL_JOB });
    cancelJobDialogRef.current?.open();
  }, []);

  const openEditJobFormDialog = useCallback((job: Job) => {
    setJobActionDialog({ job, isOpen: true, dialog: JobActionDialog.FORM });
  }, []);

  const openSendTextDialog = useCallback((job: Job) => {
    setJobActionDialog({ job, isOpen: true, dialog: JobActionDialog.TEXT_DRIVER });
  }, []);

  return (
    <JobDialogsContext.Provider
      value={{
        duplicateJob: handleDuplicateJob,
        openCancelJobDialog,
        openEditJobFormDialog,
        openSendTextDialog,
      }}
    >
      {children}

      <Box onClick={(event) => event.stopPropagation()}>
        <JobDialog
          isOpen={
            jobActionDialog.dialog === JobActionDialog.FORM && jobActionDialog.isOpen
          }
          job={jobActionDialog.job}
          onClose={closeCurrentDialog}
        />

        <ModalDialog
          ref={cancelJobDialogRef}
          callBack={handleCancelJob}
          confirmButtonColor="error"
          confirmButtonText={`${t('actions.confirm')}`}
          content={t('dispatch.job.cancel_description')}
          title={t('dispatch.job.cancel_job')}
        />

        <SendTextDialog
          isOpen={
            jobActionDialog.dialog === JobActionDialog.TEXT_DRIVER &&
            jobActionDialog.isOpen
          }
          mode="job_driver"
          source={jobActionDialog.job}
          onClose={closeCurrentDialog}
        />
      </Box>
    </JobDialogsContext.Provider>
  );
}

export { JobDialogsContextProvider };
export default JobDialogsContext;
