import Popover from '@mui/material/Popover';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import dayjs, { Dayjs } from 'dayjs';
import { useMemo, useState } from 'react';

import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';

const getDisplayDate = (startDate: Dayjs | null, endDate: Dayjs | null) => {
  if (!startDate && !endDate) {
    return 'All Dates';
  }
  if (!startDate) {
    return dayjs.tz(endDate?.toISOString()).format('ddd MMM DD YYYY');
  }
  const formattedStartDate = dayjs.tz(startDate.toISOString()).format('ddd MMM DD YYYY');
  if (!endDate) {
    return formattedStartDate;
  }
  const formattedEndDate = dayjs.tz(endDate.toISOString()).format('ddd MMM DD YYYY');

  return formattedStartDate !== formattedEndDate
    ? `${formattedStartDate} - ${formattedEndDate}`
    : formattedStartDate;
};

type Props = {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  onDateSelect: (startDate: Dayjs | null, endDate: Dayjs | null) => void;
  onAnchorClose?: () => void;
};

export function DateFilter({ startDate, endDate, onDateSelect, onAnchorClose }: Props) {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const isOpen = Boolean(anchor);

  const displayDate = useMemo(
    () => getDisplayDate(startDate, endDate),
    [startDate, endDate],
  );

  return (
    <div>
      <SmallButton
        onClick={(event: React.MouseEvent<HTMLElement>) => setAnchor(event.currentTarget)}
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          bgcolor: 'white',
          border: `solid 1px ${theme.brandV2.colors.treadGray7}`,
          borderRadius: theme.brandV2.borderRadius,
          boxShadow: 'none',
          height: '30px',
          p: 1,
        })}
      >
        {displayDate}
      </SmallButton>
      <Popover
        open={isOpen}
        anchorEl={anchor}
        onClose={() => {
          setAnchor(null);
          onAnchorClose?.();
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DateRangeCalendar
          value={[startDate, endDate]}
          onChange={(newValue) => {
            onDateSelect(newValue[0], newValue[1]);
          }}
        />
      </Popover>
    </div>
  );
}
