import _ from 'lodash';
import { useMemo } from 'react';

import { useDriverDays } from '~hooks/useDriverDays/useDriverDays';

import ApprovalActionsMenu from './ApprovalActionsMenu';

export default function DriverDayStatus({
  approved,
  approvable,
  unapprovable,
  driverDayId,
}: {
  approved: boolean;
  approvable: boolean;
  unapprovable: boolean;
  driverDayId: string;
}) {
  const { approveDriverDay, unapproveDriverDay } = useDriverDays();

  const handleApprove = () => {
    if (!approvable) return undefined;
    approveDriverDay(driverDayId);
  };

  const handleUnapprove = () => {
    if (!unapprovable) return undefined;

    unapproveDriverDay(driverDayId);
  };

  return (
    <ApprovalActionsMenu
      onMenuItemClick={approved ? handleUnapprove : handleApprove}
      approved={approved}
    />
  );
}
