import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { CompanyType, ModelError_Item } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import {
  userEmailSchema,
  userPhoneSchema,
} from '~components/UserForm/userFormValidationSchema';
import { AccountTypes } from '~constants/enums';
import { Account } from '~hooks/useAccount';
import { CompanyShare, CompanyShareableBasic } from '~hooks/useCompanyShares';
import { AccountConfiguration } from '~pages/Settings/Accounts/AccountFormParts/AccountConfiguration';
import {
  autoAcceptOrdersOptions,
  connectionTypeOptions,
} from '~pages/Settings/Accounts/AccountFormParts/constants';
import {
  accountFormSchema,
  connectedAccountFormSchema,
  setDefaultAccountValues,
  updateAccountFormSchema,
} from '~pages/Settings/Accounts/accountFormSchema';
import { useStores } from '~store';
import { Nullable } from '~types/Nullable';

import { AccountContact } from './AccountFormParts/AccountContact';
import { AccountGeneralInfo } from './AccountFormParts/AccountGeneralInfo';
import { ConnectedAccountContact } from './AccountFormParts/ConnectedAccountContact';
import { Notes } from './AccountFormParts/Notes';
import { AccountFormStateChangeProps } from './AccountsDataGrid';

interface CreateUpdateAccountFormProps {
  defaultAccount: Account | null | undefined;
  onFormStateChange: ({
    isValid,
    isDirty,
    hasValidEmail,
    hasValidPhone,
  }: AccountFormStateChangeProps) => void;
  isConnected?: boolean;
  sharedDrivers: CompanyShareableBasic[];
  sharedEquipment: CompanyShareableBasic[];
  sharedDriversCompanyShares: CompanyShare[];
  sharedEquipmentCompanyShares: CompanyShare[];
  errors?: ModelError_Item[];
}

const validateEmail = (email: Nullable<string>) => {
  let isValid = true;

  try {
    const input = email || '';

    if (input.length) {
      userEmailSchema.validateSync(input);
    } else {
      isValid = false;
    }
  } catch {
    isValid = false;
  }

  return isValid;
};

const validatePhone = (phone: Nullable<string>) => {
  let isValid = true;

  try {
    const input = phone || '';

    if (input.length) {
      userPhoneSchema.validateSync(input);
    } else {
      isValid = false;
    }
  } catch {
    isValid = false;
  }

  return isValid;
};

const ConnectedAccountForm = forwardRef(function AccountForm(
  {
    defaultAccount,
    onFormStateChange,
    isConnected,
    sharedDrivers,
    sharedEquipment,
    sharedDriversCompanyShares,
    sharedEquipmentCompanyShares,
    errors: errorsProp,
  }: CreateUpdateAccountFormProps,
  ref: Ref<any>,
) {
  const [currentSchema, setCurrentSchema] = React.useState<
    | typeof connectedAccountFormSchema
    | typeof accountFormSchema
    | typeof updateAccountFormSchema
  >(isConnected ? connectedAccountFormSchema : accountFormSchema);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    setError,
    formState: { errors, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(currentSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      ...setDefaultAccountValues(defaultAccount as Account),
      drivers: sharedDrivers,
      equipment: sharedEquipment,
      vendorDrivers: [] as CompanyShareableBasic[],
      vendorEquipment: [] as CompanyShareableBasic[],
      connected:
        isConnected !== undefined
          ? connectionTypeOptions.find((option) => option.value === isConnected)
          : { value: '', label: '' },
    },
  });

  const { userStore } = useStores();
  const isBillingAddressHidden = watch('isBillingAddressSame');
  const isBillingContactHidden = watch('isBillingContactSame');
  const selectedCountry = watch('address.country');
  const selectedBillingCountry = watch('billingAddress.country');
  const accountTypes = watch('accountTypes') as AccountTypes[];
  const companyType = watch('companyType');
  const watchedDrivers = watch('drivers');
  const watchedEquipment = watch('equipment');
  const watchedVendorDrivers = watch('vendorDrivers');
  const watchedVendorEquipment = watch('vendorEquipment');
  const accountCompanyId = defaultAccount?.company?.id;

  const updateDrivers = (newDrivers: CompanyShareableBasic[]) => {
    setValue('drivers', newDrivers, { shouldDirty: true });
  };

  const updateEquipment = (newEquipment: CompanyShareableBasic[]) => {
    setValue('equipment', newEquipment, { shouldDirty: true });
  };

  useEffect(() => {
    updateDrivers(sharedDrivers);
    updateEquipment(sharedEquipment);
  }, [sharedDrivers, sharedEquipment]);

  useEffect(() => {
    if (
      accountTypes.includes(AccountTypes.CUSTOMER) &&
      accountTypes.includes(AccountTypes.VENDOR)
    ) {
      const vendorDrivers: CompanyShareableBasic[] = sharedDriversCompanyShares
        .filter((share) => {
          return share.receiverCompany.id === accountCompanyId;
        })
        .map((share) => share.companyShareable);

      const vendorEquipment: CompanyShareableBasic[] = sharedEquipmentCompanyShares
        .filter((share) => {
          return share.receiverCompany.id === accountCompanyId;
        })
        .map((share) => share.companyShareable);

      setValue('vendorDrivers', vendorDrivers, { shouldDirty: true });
      setValue('vendorEquipment', vendorEquipment, { shouldDirty: true });
    }
  }, [accountTypes, sharedDriversCompanyShares, sharedEquipmentCompanyShares]);

  const watchedEmail = watch('primaryContact.email');
  const hasValidEmail = useMemo(() => {
    return validateEmail(watchedEmail);
  }, [watchedEmail]);

  const watchedPhone = watch('primaryContact.phone');
  const hasValidPhone = useMemo(() => {
    return validatePhone(watchedPhone);
  }, [watchedPhone]);

  useImperativeHandle(ref, () => ({
    submit(callBack: () => void) {
      handleSubmit(callBack)();
    },
    resetForm(callBack?: () => void) {
      reset();
      callBack?.();
    },
  }));

  const connected = watch('connected');
  const isConnectedAccountToggled = !!connected?.value;
  const areConfigurationFieldsEnabled =
    isConnectedAccountToggled &&
    accountTypes.includes(AccountTypes.CUSTOMER) &&
    !accountTypes.includes(AccountTypes.VENDOR);

  const isNewAccount = !defaultAccount?.id;
  const isConnectedAccount = !!(defaultAccount?.id && defaultAccount.connectedCompany);

  useEffect(() => {
    const currentSchema = isConnectedAccountToggled
      ? connectedAccountFormSchema
      : defaultAccount?.id && !isConnectedAccount
        ? updateAccountFormSchema
        : accountFormSchema;

    setCurrentSchema(currentSchema);

    reset({
      ...getValues(), // Get current form values and pass them to reset
      connected,
    });
  }, [isConnectedAccountToggled, defaultAccount?.id, isConnectedAccount, reset]);

  useEffect(() => {
    onFormStateChange({
      isDirty,
      isValid,
      hasValidEmail,
      hasValidPhone,
      inConnectedView: isConnectedAccountToggled,
    });
  }, [isValid, isDirty, hasValidEmail, hasValidPhone]);

  useEffect(() => {
    if (isConnectedAccount) {
      setValue(
        'connected',
        connectionTypeOptions.find((option) => option.value === true),
      );
    }
  }, [isConnectedAccount]);

  // Reset account configuration values if user switches between connected and non-connected account
  // Of if the account does not have the Customer account type
  const resetFormFields = () => {
    setValue('drivers', []);
    setValue('equipment', []);
    setValue(
      'autoAcceptOrders',
      autoAcceptOrdersOptions.find((option) => option.value === false),
    );
    setValue('truckCount', null);
  };

  useEffect(() => {
    if (isNewAccount && connected?.value !== undefined) {
      setValue('companyType', '');
      resetFormFields();
    }
  }, [connected?.value, autoAcceptOrdersOptions, isNewAccount]);

  useEffect(() => {
    if (isNewAccount && !accountTypes.includes(AccountTypes.CUSTOMER)) {
      resetFormFields();
    }
  }, [isNewAccount, accountTypes, autoAcceptOrdersOptions]);

  useEffect(() => {
    if (errorsProp) {
      for (const error of errorsProp) {
        const newErrorField = getErrorMapping(error.field, error.message);
        if (newErrorField.field && newErrorField.message) {
          setError(newErrorField.field as keyof ReturnType<typeof setError>, {
            type: 'manual',
            message: newErrorField.message,
          });
        }
      }
    }
  }, [errorsProp]);

  return (
    <Box component="form" data-test-id="company-form" sx={{ my: 1 }}>
      {!defaultAccount?.id ? (
        // New account
        <Grid container spacing={2}>
          {/*Left side*/}
          <Grid item xs={6}>
            <AccountGeneralInfo
              control={control}
              errors={errors}
              connected={isConnectedAccountToggled}
              isBillingAddressHidden={isBillingAddressHidden}
            />

            <AccountConfiguration
              control={control}
              errors={errors}
              isConnectedAccount={isConnectedAccount}
              isConnectedAccountToggled={isConnectedAccountToggled}
              isConnectedCustomer={areConfigurationFieldsEnabled}
              selectedAccountTypes={(accountTypes as AccountTypes[]) || []}
              drivers={watchedDrivers}
              equipment={watchedEquipment}
              companyType={companyType as CompanyType}
              vendorDrivers={watchedVendorDrivers}
              vendorEquipment={watchedVendorEquipment}
              selectedAccountCompanyId={
                accountCompanyId || userStore?.userCompany?.id || ''
              }
            />
          </Grid>

          {/*Right side*/}
          <Grid item xs={6}>
            {!isConnectedAccountToggled && (
              <AccountContact
                control={control}
                errors={errors}
                selectedCountry={selectedCountry}
                isBillingContactHidden={isBillingContactHidden}
                selectedBillingCountry={selectedBillingCountry}
              />
            )}

            {isConnectedAccountToggled && (
              <ConnectedAccountContact
                control={control}
                errors={errors}
                selectedCountry={selectedCountry}
                isBillingContactHidden={isBillingContactHidden}
                selectedBillingCountry={selectedBillingCountry}
              />
            )}
            <Notes control={control} errors={errors} />
          </Grid>
        </Grid>
      ) : (
        // Update account
        <Grid container spacing={2}>
          {/*Left side*/}
          <Grid item xs={6}>
            <AccountGeneralInfo
              control={control}
              errors={errors}
              connected={isConnectedAccountToggled}
              isBillingAddressHidden={isBillingAddressHidden}
              selectedAccountId={defaultAccount?.id}
            />
            {defaultAccount?.connectedCompany ? (
              <AccountConfiguration
                control={control}
                errors={errors}
                isConnectedAccount={isConnectedAccount}
                isConnectedAccountToggled={isConnectedAccountToggled}
                isConnectedCustomer={areConfigurationFieldsEnabled}
                selectedAccountTypes={(accountTypes as AccountTypes[]) || []}
                drivers={watchedDrivers}
                equipment={watchedEquipment}
                companyType={companyType as CompanyType}
                vendorDrivers={watchedVendorDrivers}
                vendorEquipment={watchedVendorEquipment}
                selectedAccountCompanyId={
                  accountCompanyId || userStore?.userCompany?.id || ''
                }
              />
            ) : null}
          </Grid>
          <Grid item xs={6}>
            {defaultAccount?.connectedCompany ? (
              <ConnectedAccountContact
                control={control}
                errors={errors}
                selectedCountry={selectedCountry}
                isBillingContactHidden={isBillingContactHidden}
                selectedBillingCountry={selectedBillingCountry}
                selectedAccountId={defaultAccount?.id || ''}
              />
            ) : (
              <AccountContact
                control={control}
                errors={errors}
                selectedCountry={selectedCountry}
                isBillingContactHidden={isBillingContactHidden}
                selectedBillingCountry={selectedBillingCountry}
              />
            )}
            <Notes control={control} errors={errors} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
});

const getErrorMapping = (fieldName: string, defaultMessage: string) => {
  switch (fieldName) {
    case 'name':
      return {
        field: fieldName,
        message: t('error_messages.account.account_name_taken'),
      };
    case 'email':
      return {
        field: 'primaryContact.email',
        message: t('error_messages.users.email_taken'),
      };
    case 'phone':
      return {
        field: 'primaryContact.phone',
        message: t('error_messages.users.phone_taken'),
      };
    case 'address.postal_code':
      return {
        field: 'address',
        message: defaultMessage,
      };
    default:
      return {
        field: fieldName,
        message: defaultMessage,
      };
  }
};

export { ConnectedAccountForm };
