import { action, makeObservable, observable, runInAction } from 'mobx';

import DispatchSearchParam from '~components/Order/Selectors/DispatchSearchParam';
import { Order } from '~hooks/useOrders';
import { getOrderStatesPerStatus } from '~pages/Dispatch/utils';
import { Pagination, PaginationLink } from '~services/pagination';
import { RootStore } from '~store/RootStore';
import { getPageNumber } from '~utils/pagination';

class OrdersStore {
  orders: Order[] = [];
  // Keep cloned orders in a separate list, as we need them to be present in the store even if they are not
  // Visible to the user in any grid
  clonedOrders: Order[] = [];
  pagination: Pagination = {
    limit: 100,
    after: '',
    before: '',
    page: 1,
  };
  dispatchFilter: Record<string, any> = {};
  orderFilter: Record<string, any> = {};
  areFilterOptionsLoaded: boolean = false;
  orderUpdatesOccurred: boolean = false;
  acceptNewOrders: boolean = true;
  pendingNewOrders: boolean = false;
  constructor(private readonly rootStore: RootStore) {
    makeObservable(this, {
      orders: observable,
      clonedOrders: observable,
      pagination: observable,
      dispatchFilter: observable,
      orderFilter: observable,
      areFilterOptionsLoaded: observable,
      orderUpdatesOccurred: observable,
      acceptNewOrders: observable,
      pendingNewOrders: observable,
    });
    this.rootStore = rootStore;
  }

  setPagination(pagination: Pagination) {
    this.pagination = {
      ...this.pagination,
      before: pagination.before || '',
      after: pagination.after || '',
    };
  }

  updatePageNumber(link: PaginationLink) {
    runInAction(() => {
      this.pagination = {
        ...this.pagination,
        page: getPageNumber(this.pagination.page, link),
      };
    });
  }

  setOrders(orders: Order[]) {
    this.orders = [...orders];
  }

  deleteOrder(id: string) {
    this.orders = this.orders.filter((o) => o.id !== id);
  }

  updateOrder(order: Order) {
    this.orders = this.orders.map((o) => (o.id === order.id ? order : o));
  }

  updateClonedOrder(order: Order) {
    this.clonedOrders = this.clonedOrders.map((o) => (o.id === order.id ? order : o));
  }

  addOrder(order: Order) {
    const idx = this.orders.findIndex((o) => o.id === order.id);
    if (idx !== -1) {
      this.updateOrder(order);
      // Check if we are accepting new orders, if not (when filters other than states are applied),
      // We should not add the order to the list
    } else if (matchedOnFilter(order, this.dispatchFilter)) {
      if (this.acceptNewOrders) {
        this.orders = [...this.orders, order];
      } else {
        this.setPendingNewOrders(true);
      }
    }
  }

  addClonedOrder = action((order: Order) => {
    const idx = this.clonedOrders.findIndex((o) => o.id === order.id);
    if (idx !== -1) {
      this.updateClonedOrder(order);
    } else {
      this.clonedOrders = [...this.clonedOrders, order];
    }
  });

  setDispatchFilter(filter: Record<string, any>) {
    this.dispatchFilter = filter;
  }

  addDispatchFilter(filter: Record<string, any>) {
    this.dispatchFilter = {
      ...this.dispatchFilter,
      ...filter,
    };
  }

  setOrderFilter(filter: Record<string, any>) {
    this.orderFilter = filter;
  }

  addOrderFilter(filter: Record<string, any>) {
    this.orderFilter = {
      ...this.orderFilter,
      ...filter,
    };
  }

  setFilterOptionsLoaded = (loaded: boolean) => {
    this.areFilterOptionsLoaded = loaded;
  };

  setOrderUpdatesOccurred = (updatesMade: boolean) => {
    this.orderUpdatesOccurred = updatesMade;
  };

  setAcceptNewOrders = (acceptNewOrders: boolean) => {
    this.acceptNewOrders = acceptNewOrders;
  };
  setPendingNewOrders = (pendingNewOrders: boolean) => {
    this.pendingNewOrders = pendingNewOrders;
  };
}

const matchedOnFilter = (order: Order, filter: Record<string, any>) => {
  return Object.keys(filter).every((key: string) => {
    const filterValue = filter[key][0];

    if (key === DispatchSearchParam.STATUS) {
      const orderStates = getOrderStatesPerStatus(filterValue);
      return orderStates.length === 0 || orderStates.includes(order.state);
    } else {
      return true;
    }
  });
};

export default OrdersStore;
