import styled from '@emotion/styled';
import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { WaypointType } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { uniqBy } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useController } from 'react-hook-form';

import {
  AutocompleteAsyncFormField,
  AutocompleteAsyncFormFieldRef,
} from '~components/FormFields/AutocompleteAsyncFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { useGeocode } from '~hooks/useGeocode';
import { Site, useSites } from '~hooks/useSites';
import { Pagination } from '~services/pagination';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { Nullable } from '~types/Nullable';
import { usePrevious } from '~utils/hooks/usePrevious';

import { AddressOption, AddressType } from './types';

interface LocationSelectorProps {
  companyId?: string | null;

  control: any;
  errors: any;
  addressName: string;
  addressLabel: string;

  latLngLabel: string;
  latLngName: string;

  renderCustomLabel?: ({ isRequired }: { isRequired?: boolean }) => Nullable<JSX.Element>;
  isRequired?: boolean;
  allowSuggestions?: boolean;
  projectId?: Nullable<string>;
  orderId?: Nullable<string>;
  waypointType: WaypointType;
  onDefaultSiteAdded?: ({
    waypointType,
    siteId,
  }: {
    waypointType: WaypointType;
    siteId: string;
  }) => void;
  hideAddDefaultSiteAction?: boolean;
}

const AddSiteToDefaultListButton = styled(LoadingButton)`
  /*
    Needed to align with other fields as there's a 4px bottom margin on them.
    Need to remove the bottom margins eventually
  */
  margin-bottom: 4px;
`;

const PlusIcon = styled(Typography)`
  font-size: 24px;
`;

const constructTypeaheadDropdownItem = (site: Site, type: AddressType): AddressOption =>
  ({
    type,
    lat: site.lat,
    lng: site.lon,
    siteId: site.id,
    id: site.id,
    name: site.name,
    geofence: site.nextBillionGeofence,
  }) as AddressOption;

export const SiteSelectorWithDefaultSites = (props: LocationSelectorProps) => {
  const {
    companyId,
    addressLabel,
    addressName,
    latLngLabel,
    latLngName,
    control,
    errors,
    renderCustomLabel,
    isRequired,
    allowSuggestions = true,
    projectId,
    orderId,
    waypointType,
    onDefaultSiteAdded,
    hideAddDefaultSiteAction = false,
  } = props;
  const previousProjectId = usePrevious(projectId);
  const autocompleteRef = useRef<AutocompleteAsyncFormFieldRef>(null);
  const orderSites = useRef<AddressOption[]>([]);
  const projectSites = useRef<AddressOption[]>([]);
  const { toasterStore } = useStores();
  const { field } = useController({ control, name: addressName });
  const [tempNewDefaultSites, setTempNewDefaultSitesState] = useState<AddressOption[]>(
    [],
  );
  const [selectedSiteId, setSelectedSiteId] = useState<Nullable<string>>(
    field?.value?.siteId || null,
  );
  const { getPlaces } = useGeocode({ addressProvider: 'google_maps' });
  const {
    addSiteToOrderDefaultSites,
    addSiteToProjectDefaultSites,
    getCompanySitesTypeahead,
    getOrderDefaultSites,
    getProjectDefaultSites,
    getSiteById,
    isSaving,
  } = useSites();

  const fetchProjectDefaultSites = useCallback(
    async (projectId: string) => {
      const defaultSites: Site[] = await getProjectDefaultSites({
        projectId,
        waypointType,
      });
      let defaultSitesData: AddressOption[] = [];

      if (defaultSites.length > 0) {
        defaultSitesData = await Promise.all(
          defaultSites.map(async (site) => {
            const siteDetails = await getSiteById(site.id || '');
            return constructTypeaheadDropdownItem(siteDetails, AddressType.DefaultSites);
          }),
        );
      }

      return defaultSitesData;
    },
    [constructTypeaheadDropdownItem, getProjectDefaultSites, waypointType],
  );

  const fetchOrderDefaultSites = useCallback(
    async (orderId: string) => {
      const defaultSites: Site[] = await getOrderDefaultSites({
        orderId,
        waypointType,
      });
      let defaultSitesData: AddressOption[] = [];

      if (defaultSites.length > 0) {
        defaultSitesData = await Promise.all(
          defaultSites.map(async (site) => {
            const siteDetails = await getSiteById(site.id || '');
            return constructTypeaheadDropdownItem(siteDetails, AddressType.DefaultSites);
          }),
        );
      }

      return defaultSitesData;
    },
    [constructTypeaheadDropdownItem, getOrderDefaultSites, waypointType],
  );
  const setProjectDefaultSites = useCallback(
    async (projectId: string) => {
      const defaultSites: AddressOption[] = await fetchProjectDefaultSites(projectId);

      projectSites.current = defaultSites;
    },
    [fetchProjectDefaultSites, projectSites.current],
  );
  const setOrderDefaultSites = useCallback(
    async (orderId: string) => {
      const defaultSites: AddressOption[] = await fetchOrderDefaultSites(orderId);

      orderSites.current = defaultSites;
    },
    [fetchOrderDefaultSites, orderSites.current],
  );

  // On project change, re-fetch project default sites
  // And update useRef
  useEffect(() => {
    if (previousProjectId !== projectId) {
      if (projectId) {
        setProjectDefaultSites(projectId);
      } else {
        // If project is removed from order, remove default sites
        // For projects
        projectSites.current = [];
      }
    }
  }, [projectId, previousProjectId, setProjectDefaultSites, projectSites.current]);

  const handleSelect = async (site: AddressOption) => {
    if (site?.type === AddressType.Sites) {
      const siteDetails = await getSiteById(site.id);

      setSelectedSiteId(siteDetails.id);

      const address = {
        type: AddressType.Sites,
        lat: siteDetails.lat,
        lng: siteDetails.lng,
        siteId: siteDetails.id,
        id: siteDetails.id,
        name: siteDetails.name,
        geofence: siteDetails.nextBillionGeofence,
      } as AddressOption;

      return address;
    }

    return site;
  };
  // When user first clicks into the field, fetch default sites
  // If a cursor is present in the call, we are fetching more paginated
  // Results. So only add on additional sites to the list for those
  // Sites that have paginated results
  const fetchDefaultSitesList = useCallback(
    async ({ cursor }: { cursor?: string }) => {
      let defaultSites: { data: AddressOption[]; pagination: Nullable<Pagination> } = {
        data: [],
        pagination: null,
      };

      // Don't re-render default sites if we are scrolling
      // As these don't have paginated results and we want to
      // Prevent duplicating these sites in the dropdown
      if (!cursor) {
        defaultSites.data = [...tempNewDefaultSites];

        if (projectId) {
          const projectDefaultSites = await fetchProjectDefaultSites(projectId);
          projectSites.current = projectDefaultSites;
          defaultSites = {
            ...defaultSites,
            data: [...projectDefaultSites],
          };
        }

        if (orderId) {
          const orderDefaultSites = await fetchOrderDefaultSites(orderId);
          orderSites.current = orderDefaultSites;
          defaultSites = {
            ...defaultSites,
            data: [...uniqBy([...defaultSites.data, ...orderDefaultSites], 'id')],
          };
        }
      }

      if (companyId) {
        const { data: sites = [], pagination } = await getCompanySitesTypeahead({
          companyId,
          cursor,
        });

        defaultSites = {
          pagination,
          data: [
            ...uniqBy(
              [
                ...defaultSites.data,
                ...sites.map((site) =>
                  constructTypeaheadDropdownItem(site, AddressType.Sites),
                ),
              ],
              'id',
            ),
          ],
        };
      }

      return defaultSites;
    },
    [
      companyId,
      constructTypeaheadDropdownItem,
      fetchOrderDefaultSites,
      fetchProjectDefaultSites,
      getCompanySitesTypeahead,
      orderSites.current,
      orderId,
      projectSites.current,
      projectId,
      tempNewDefaultSites,
    ],
  );

  // Filters sites list based on query
  const fetchTypeaheadSitesList = async ({
    query,
    cursor,
  }: {
    query: string;
    cursor?: string;
  }) => {
    let filteredSites: { data: AddressOption[]; pagination: Nullable<Pagination> } = {
      data: [],
      pagination: null,
    };

    // Don't re-render default sites if we are scrolling as
    // These don't have paginated results
    if (!cursor) {
      const defaultSitesList = projectId
        ? uniqBy(
            [...tempNewDefaultSites, ...projectSites.current, ...orderSites.current],
            'id',
          )
        : [...tempNewDefaultSites, ...orderSites.current];

      filteredSites.data = defaultSitesList;

      // Default Project Sites
      if (projectSites.current.length > 0) {
        filteredSites = {
          ...filteredSites,
          data: filteredSites.data.filter(({ name }) => name.includes(query)),
        };
      }
    }

    if (companyId) {
      const { data: sites = [], pagination } = await getCompanySitesTypeahead({
        companyId,
        query,
        cursor,
      });

      filteredSites = {
        pagination,
        data: [
          ...filteredSites.data,
          ...sites.map(
            (site) =>
              ({
                type: AddressType.Sites,
                lat: site.lat,
                lng: site.lon,
                siteId: site.id,
                id: site.id,
                name: site.name,
                geofence: site.nextBillionGeofence,
              }) as AddressOption,
          ),
        ],
      };
    }

    // Suggested addresses
    const places = (await getPlaces({ query })) || [];

    if (places) {
      filteredSites = {
        ...filteredSites,
        data: [
          ...filteredSites.data,
          ...places.slice(0, 10).map((place) => ({
            lat: place.lat,
            lng: place.lng,
            placeId: place.placeId,
            id: place.id,
            name: place.streetAddress,
            siteId: null,
            address: place,
            type: AddressType.Suggestions,
          })),
        ],
      };
    }

    return filteredSites;
  };

  // Update sites dropdown with temp default sites list
  const updateSitesListWithTempDefaultSites = useCallback(
    (newDefaultSitesList: Record<string, any>[]) => {
      if (autocompleteRef.current) {
        const currentSitesList = autocompleteRef.current.getSuggestions();
        const updatedSitesList = [...newDefaultSitesList, ...currentSitesList];

        autocompleteRef.current.updateSuggestions(updatedSitesList);
      }
    },
    [autocompleteRef.current],
  );

  // Overall handler to fetch sites list for initial load and with
  // Typeahead
  const fetchSitesByCompanyId = async (
    additionalProps: { query?: string; cursor?: string } = {},
  ) => {
    if (additionalProps?.query && allowSuggestions) {
      return await fetchTypeaheadSitesList({
        query: additionalProps.query,
      });
    }

    return await fetchDefaultSitesList({
      cursor: additionalProps.cursor,
    });
  };

  const onSuccess = useCallback(
    (resetAutocomplete = true) => {
      resetAutocomplete && autocompleteRef.current?.reset();
      autocompleteRef.current?.resetCursor();
      toasterStore.push(
        alert($t('approvals.site_added_to_default_list'), AlertTypes.success),
      );
    },
    [alert, AlertTypes.success, autocompleteRef.current, toasterStore],
  );

  const onError = useCallback(() => {
    toasterStore.push(
      alert(
        $t('errors.issue_adding_site_to_project_default_sites_list'),
        AlertTypes.error,
      ),
    );
  }, [alert, AlertTypes.error, toasterStore]);

  const handleAddSiteToDefaultList = useCallback(async () => {
    if (selectedSiteId) {
      projectId &&
        (await addSiteToProjectDefaultSites({
          projectId,
          siteId: selectedSiteId,
          waypointType,
        })
          .then((newlyAddedSite: Site) => {
            newlyAddedSite.id && setProjectDefaultSites(projectId);
            onSuccess();
          })
          .catch(onError));
      orderId &&
        (await addSiteToOrderDefaultSites({
          orderId,
          siteId: selectedSiteId,
          waypointType,
        })
          .then((newlyAddedSite: Site) => {
            newlyAddedSite.id && setOrderDefaultSites(orderId);
            onSuccess();
          })
          .catch(onError));
      // For creating a new order, fake the addition of a tempNewDefaultSites
      // Site being added to the default list
      // By adding the site to the a new state call
      if (!orderId) {
        // Fetch the site details and convert
        // To AddressOption
        const newDefaultSite = await getSiteById(selectedSiteId);
        // Transform site to AddressOption
        const transformSiteToAddressOption = constructTypeaheadDropdownItem(
          newDefaultSite,
          AddressType.DefaultSites,
        );

        // Add new site to Default Sites List
        setTempNewDefaultSitesState((tempNewDefaultSites) =>
          uniqBy([...tempNewDefaultSites, transformSiteToAddressOption], 'id'),
        );

        onDefaultSiteAdded?.({ siteId: selectedSiteId, waypointType });

        onSuccess(false);
        updateSitesListWithTempDefaultSites(tempNewDefaultSites);
      }
    }
  }, [selectedSiteId, orderId, projectId, waypointType]);

  return (
    <div className="flex flex-1 items-end space-x-2">
      <div className="w-[1200px]">
        {!addressLabel && renderCustomLabel?.({ isRequired })}

        <AutocompleteAsyncFormField
          ref={autocompleteRef}
          control={control}
          errors={errors}
          name={addressName}
          getValue={(item) => item.id}
          getLabel={(item) => item.name || ''}
          label={addressLabel}
          onSelect={handleSelect}
          isRequired={isRequired}
          asyncCallback={fetchSitesByCompanyId}
          groupBy={(option) => option.type}
          // StartSearchPosition is used by getPlaces hook to center NB search on its lat/lon values
          // ExtraRequestOptions={{ startSearchPosition }}
          clearable={true}
          inputProps={{
            style: {
              fontSize: '12px',
            },
          }}
          debounceTime={500}
        />
      </div>

      <TextFormField
        control={control}
        errors={errors}
        name={latLngName}
        type="text"
        disabled={true}
        placeholder={`${$t('form_fields.lat_lon_placeholder')}`}
        label={latLngLabel}
        InputProps={{ sx: { fontSize: '12px' } }}
      ></TextFormField>

      {!hideAddDefaultSiteAction && (
        <Tooltip title={`${$t('actions.add_site_to_default_list')}`}>
          <AddSiteToDefaultListButton
            onClick={handleAddSiteToDefaultList}
            disabled={!selectedSiteId}
            variant="contained"
            loading={isSaving}
          >
            <PlusIcon align="center">+</PlusIcon>
          </AddSiteToDefaultListButton>
        </Tooltip>
      )}
    </div>
  );
};
