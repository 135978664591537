import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { t } from 'i18next';

import { OverflowAwareText } from '~components/Order/ordersDispatchStyledComponents';
import { Order } from '~hooks/useOrders';
import theme from '~theme/AppTheme';

interface DialogHeaderProps {
  order: Order;
  sx?: SxProps;
}

export default function DialogHeader({ order, sx }: DialogHeaderProps) {
  const fragments = [
    order.jobsCount === 1
      ? t('common.trucks_one', { count: order.jobsCount })
      : t('common.trucks_other', { count: order.jobsCount }),
    order.equipmentType?.name ?? null,
    order.material?.name
      ? `${Number(order.quantity).toFixed(2)} ${order.material.name}`
      : null,
    order.pickUpWayPoints?.[0]?.siteNested?.name ?? null,
  ].filter((fragment) => fragment !== null);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
        <OverflowAwareText sx={{ fontSize: '16px', fontWeight: 600 }}>
          <Typography
            component="span"
            sx={{
              color: theme.brandV2.colors.treadGray3,
              fontSize: 'inherit',
              fontWeight: 'inherit',
            }}
          >
            {`${t('dispatch.job.assign_to')}:`}
          </Typography>

          <Typography
            component="span"
            sx={{
              color: theme.brandV2.colors.treadBlack,
              fontSize: 'inherit',
              fontWeight: 'inherit',
              ml: 0.75,
            }}
          >
            {`${order.dispatchNumber ? `${order.dispatchNumber} • ` : ''}${order.orderId}`}
          </Typography>
        </OverflowAwareText>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', px: 1.5 }}>
        <OverflowAwareText variant="body2">
          <Typography component="span" sx={{ fontSize: 'inherit', fontWeight: 600 }}>
            {`${t('common.order')}:`}
          </Typography>

          <Typography
            component="span"
            sx={{ fontSize: 'inherit', fontWeight: 'inherit', ml: 0.75 }}
          >
            {fragments.join(' • ')}
          </Typography>
        </OverflowAwareText>
      </Box>
    </>
  );
}
