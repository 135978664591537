import { Department_Read } from '@treadinc/horizon-api-spec';
import { t as $t } from 'i18next';
import { useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { Department } from '~hooks/useDepartment';
import connection from '~services/connectionModule';
import { PaginationLink, PaginationQuery } from '~services/pagination';
import { useStores } from '~store';

// API props
interface CreateUpdateDepartmentProps {
  department: Department;
  callBack?: (department: Department) => void;
}

interface DeleteDepartmentProps {
  departmentId: string;
  callBack?: () => void;
}

export const useDepartment = () => {
  const { companyAssetsStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  // GET /v1/departments endpoint
  const getDepartment = (link?: PaginationLink) => {
    setIsLoading(true);
    const params: PaginationQuery = {
      'page[limit]': companyAssetsStore.departmentsPagination.limit,
    };
    if (link && companyAssetsStore.departmentsPagination[link]) {
      params[`page[${link}]`] = companyAssetsStore.departmentsPagination[link];
    }
    return connection
      .getPaginated<Department_Read>(
        `${API_VERSION}/departments`,
        {
          params,
        },
        $t('error_messages.departments.failed_to_fetch') as string,
      )
      .then(({ data, pagination }) => {
        companyAssetsStore.setDepartments(data.map(Department.parse));
        companyAssetsStore.setDepartmentsPagination(pagination);
        companyAssetsStore.updateDepartmentsPageNumber(link);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // GET /v1/companies/{companyId}/departments endpoint
  const getDepartmentByCompanyId = (companyId: string, link?: PaginationLink) => {
    setIsLoading(true);
    const params: PaginationQuery = {
      'page[limit]': companyAssetsStore.departmentsPagination.limit,
    };
    if (link && companyAssetsStore.departmentsPagination[link]) {
      params[`page[${link}]`] = companyAssetsStore.departmentsPagination[link];
    }
    return connection
      .getPaginated<Department_Read>(
        `${API_VERSION}/companies/${companyId}/departments`,
        {
          params,
        },
        $t('error_messages.departments.failed_to_fetch') as string,
      )
      .then(({ data, pagination }) => {
        companyAssetsStore.setDepartments(data.map(Department.parse));
        companyAssetsStore.setDepartmentsPagination(pagination);
        companyAssetsStore.updateDepartmentsPageNumber(link);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // POST /v1/departments endpoint
  const createDepartment = ({ department, callBack }: CreateUpdateDepartmentProps) => {
    setIsLoading(true);
    return connection
      .post<Department_Read>(
        `${API_VERSION}/departments`,
        Department.deparseCreate(department),
        {},
        $t('error_messages.departments.failed_to_create') as string,
      )
      .then((resp) => {
        const department = Department.parse(resp);
        companyAssetsStore.addDepartment(department);
        callBack?.(department);
        return department;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // POST /v1/companies/{companyId}/departments endpoint
  const createDepartmentByCompanyId = ({
    department,
    companyId,
    callBack,
  }: CreateUpdateDepartmentProps & { companyId: string }) => {
    setIsLoading(true);
    return connection
      .post<Department_Read>(
        `${API_VERSION}/companies/${companyId}/departments`,
        Department.deparseCreate(department),
        {},
        $t('error_messages.departments.failed_to_create') as string,
      )
      .then((resp) => {
        const department = Department.parse(resp);
        companyAssetsStore.addDepartment(department);
        callBack?.(department);
        return department;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // PATCH /v1/departments/{departmentId} endpoint
  const updateDepartment = ({ department, callBack }: CreateUpdateDepartmentProps) => {
    setIsUpdating(true);
    return connection
      .patch<Department_Read>(
        `${API_VERSION}/departments/${department.id}`,
        Department.deparseUpdate(department),
        {},
        $t('error_messages.departments.failed_to_update') as string,
      )
      .then((resp) => {
        const department = Department.parse(resp);
        companyAssetsStore.updateDepartment(department);
        callBack?.(department);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  // DELETE /v1/departments/{departmentId} endpoint
  const deleteDepartment = ({ departmentId, callBack }: DeleteDepartmentProps) => {
    setIsLoading(true);
    return connection
      .delete<Department_Read>(
        `${API_VERSION}/departments/${departmentId}`,
        {},
        $t('error_messages.departments.failed_to_delete') as string,
      )
      .then(() => {
        companyAssetsStore.deleteDepartment(departmentId);
        callBack?.();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    isUpdating,
    getDepartment,
    createDepartment,
    updateDepartment,
    deleteDepartment,
    createDepartmentByCompanyId,
    getDepartmentByCompanyId,
  };
};
