import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-premium';
import { t as $t, t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import DataGrid from '~components/DataGrid/DataGrid';
import { HeaderNavigation } from '~components/DataGrid/HeaderNavigation';
import { DialogHeader } from '~components/Dialog/DialogHeader';
import { ModalDialog } from '~components/Dialog/ModalDialog';
import { SimpleMenu } from '~components/Menu/SimpleMenu';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { FormStateChangeProps } from '~formsShared';
import { useUserRolesAdmin } from '~hooks/admin/useUsersAdmin/useUserRolesAdmin';
import { useDataGridSearch } from '~hooks/useDataGridSearch';
import { Material, useMaterials } from '~hooks/useMaterials';
import { MaterialForm } from '~pages/Settings/Administration/MaterialsManagement/MaterialForm';
import { PaginationLink } from '~services/pagination';
import { useStores } from '~store';
import { alert, AlertTypes } from '~types/AlertTypes';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { dateFormat } from '~utils/dateTime';
import { isActionClicked } from '~utils/utilFunctions';
const MaterialsManagement = observer(() => {
  const theme = useTheme();

  const [isDialogOpen, setDialogOpen] = useState(false);

  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const { companyAssetsStore, userStore, toasterStore } = useStores();
  const { getUserRoles } = useUserRolesAdmin();
  const {
    getAllMaterials,
    updateMaterial,
    createMaterial,
    deleteMaterial,
    isLoading,
    isUpdating,
  } = useMaterials();
  const { searchValue, setSearchQueryValue } = useDataGridSearch();

  // Store the current isLoading state in a ref for HeaderNavigation since datagrid headers does not get re-rendered on those updates
  const loadingRef = useRef(isLoading);
  useEffect(() => {
    loadingRef.current = isLoading;
  }, [isLoading]);

  useEffect(() => {
    getAllMaterials();
    getUserRoles();
  }, []);

  const userPermissions = useMemo(() => {
    return userStore.getPermissions();
  }, [userStore]);

  const onChangeQuickFilter = (searchQuery: string) => {
    // Do nothing
    setSearchQueryValue(searchQuery);
    getAllMaterials({} as PaginationLink, searchQuery);
  };
  const modalDialogRef = useRef<any>(null);
  const updateDialogRef = useRef<any>(null);

  const handleClose = () => {
    setDialogOpen(false);
  };
  const editRow = (id: string) => {
    setSelectedId(id);
    setDialogOpen(true);
  };

  const createNew = () => {
    setSelectedId(null);
    setDialogOpen(true);
  };

  const deleteRow = (id: string) => {
    setSelectedId(id);
    modalDialogRef.current?.open();
  };

  const deleteMaterialCallback = () => {
    if (selectedId) {
      deleteMaterial({
        materialId: selectedId,
        callBack: () => {
          modalDialogRef.current?.close();
          toasterStore.push(
            alert($t('administration.material.material_deleted'), AlertTypes.success),
          );
        },
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleErrors = () => {};
  const onSuccess = (mat?: Material) => {
    handleClose();
    const name = `${mat?.name}`;
    toasterStore.push(
      alert(
        selectedId
          ? $t('administration.material.material_updated', { name })
          : $t('administration.material.material_updated', { name }),
        AlertTypes.success,
      ),
    );
  };

  const onSubmitCallback = (data: any) => {
    if (selectedId) {
      updateMaterial({ material: data, callBack: onSuccess }).catch(handleErrors);
    } else {
      createMaterial({ material: data, callBack: onSuccess }).catch(handleErrors);
    }
  };

  const onSubmitForm = () => {
    updateDialogRef.current?.submit(onSubmitCallback);
  };

  const onFormStateChange = ({ isDirty }: FormStateChangeProps) => {
    setIsDirty(isDirty);
  };

  const rows = useMemo(() => {
    return companyAssetsStore.materials || [];
  }, [companyAssetsStore.materials]);

  const selectedMaterial = useMemo(() => {
    return rows.find((material) => material.id === selectedId) || null;
  }, [selectedId, rows]);

  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'name',
          headerName: $t('form_fields.name'),
          flex: 1,
        },
        {
          field: 'typeName',
          headerName: $t('form_fields.material_type'),
          flex: 1,
          groupable: true,
          aggregable: false,
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((row) => row.typeName))],

          renderCell: (params: GridValueGetterParams) => {
            return (
              <BasicTooltip
                key={`material__type__${params.row.id}`}
                title={`${$t('form_fields.material_type')}: ${params.value}`}
              >
                <span>{params.value}</span>
              </BasicTooltip>
            );
          },
        },
        {
          field: 'companyName',
          headerName: $t('form_fields.company'),
          flex: 1,
          groupable: true,
          aggregable: false,
          type: 'singleSelect',
          valueOptions: [...new Set(rows.map((row) => row.companyName))],
        },
        {
          field: 'externalId',
          headerName: $t('form_fields.material_id'),
          flex: 1,
        },
        {
          field: 'isActive',
          headerName: $t('form_fields.status'),
          flex: 1,
          type: 'singleSelect',
          valueOptions: [
            ...new Set(
              rows.map((row) =>
                row.isActive ? $t('common.active') : $t('common.inactive'),
              ),
            ),
          ],
          valueGetter: (params: GridValueGetterParams) => {
            return params.value ? $t('common.active') : $t('common.inactive');
          },
          renderCell: (params: GridValueGetterParams) => {
            return (
              <Chip
                label={params.value}
                color={!params.row.isActive ? 'default' : 'success'}
                variant={'outlined'}
              />
            );
          },
        },
        {
          field: 'createdAt',
          headerName: $t('common.created_on'),
          type: 'date',
          flex: 1,
          valueGetter: (params: GridValueGetterParams) => {
            return new Date(params.value);
          },
          renderCell: (params: GridValueGetterParams) => {
            return (
              <BasicTooltip
                key={`created__${params.row.id}`}
                title={`${$t('common.last_update')}: ${dateFormat(params.row.updatedAt)}`}
              >
                <span>{dateFormat(params.row.createdAt)}</span>
              </BasicTooltip>
            );
          },
        },
        {
          field: 'actions',
          headerName: $t('actions.actions'),
          type: 'actions',
          width: 96,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          hideable: false,
          renderHeader: () => (
            <HeaderNavigation
              count={companyAssetsStore.materials.length || 0}
              loading={loadingRef.current}
              pagination={companyAssetsStore.materialsPagination}
              callback={getAllMaterials}
              altText={`${$t('actions.actions')}`}
              searchQuery={searchValue}
            />
          ),
          renderCell: (params: GridValueGetterParams) => {
            const options = [
              {
                title: t('actions.delete'),
                icon: <Delete />,
                callBack: () => deleteRow(params.row.id),
              },
            ];
            return (
              <>
                <IconButton size="small" onClick={() => editRow(params.row.id)}>
                  <Edit />
                </IconButton>
                <SimpleMenu options={options}>
                  <MoreHoriz />
                </SimpleMenu>
              </>
            );
          },
        },
      ] as GridColDef[],
    [rows],
  );
  const initialState = useMemo(
    () => ({
      columns: {
        columnVisibilityModel: {},
      },
    }),
    [],
  );
  return (
    <>
      <Box sx={{ mt: 3 }} data-test-id={'materials-data-grid'}>
        <DataGrid
          id="material-data-grid-view"
          columns={columns}
          rows={rows as unknown as Record<string, any>[]}
          loading={isLoading}
          onChangeFilter={onChangeQuickFilter}
          initialState={initialState}
          headerActionsComponent={
            <>
              <Box display={'flex'}>
                <Button
                  size="medium"
                  color={'primary'}
                  onClick={createNew}
                  startIcon={<Add />}
                  // Disabled={!selectedCompanyId}
                >
                  {t('administration.material.create_material')}
                </Button>
              </Box>
            </>
          }
        />
      </Box>

      <Dialog
        open={isDialogOpen}
        onClose={(_: any, reason: DialogCloseReasonType) => {
          isActionClicked(reason) && handleClose();
        }}
      >
        <DialogHeader
          closeCallBack={handleClose}
          title={t(
            selectedId
              ? 'administration.material.update_material'
              : 'administration.material.create_material',
          )}
        />
        <DialogContent sx={{ my: 4 }}>
          <MaterialForm
            defaultMaterial={selectedMaterial}
            ref={updateDialogRef}
            onFormStateChange={onFormStateChange}
          />
        </DialogContent>
        <DialogActions
          sx={{
            m: 0,
            p: 2,
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'row-reverse',
            }}
          >
            <LoadingButton
              disabled={isUpdating || !isDirty}
              loading={isUpdating}
              loadingPosition="start"
              startIcon={<></>}
              onClick={onSubmitForm}
              type="button"
              variant="contained"
              color="primary"
              sx={isUpdating ? { pl: 5, pr: 2 } : { pr: 2 }}
            >
              {$t(selectedId ? `actions.update` : 'actions.create')}
            </LoadingButton>
            <Button
              onClick={handleClose}
              sx={{ mr: 2, px: 2 }}
              disabled={isUpdating}
              color="secondary"
              variant="outlined"
            >
              {$t('actions.cancel')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <ModalDialog
        ref={modalDialogRef}
        title={$t('administration.material.delete_material_title')}
        content={$t('administration.material.delete_material_text')}
        confirmButtonText={`${$t('actions.confirm')}`}
        callBack={deleteMaterialCallback}
        loading={isUpdating}
      />
    </>
  );
});

export { MaterialsManagement };
