import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { t } from 'i18next';
import _ from 'lodash';
import React from 'react';

import { FilterMenuItemLoadingReason } from '~components/Filters/FilterMenuItem';
import { SelectedAssignment } from '~components/Job/AssignJobToView/hooks/useAssignDriverMenu';
import { TabValue as AssigneeType } from '~components/Job/AssignJobToView/hooks/useAssignDriverMenu';
import {
  LoadingSpinner,
  OverflowAwareText,
} from '~components/Order/ordersDispatchStyledComponents';
import { JobAssignmentType } from '~constants/enums';
import { DriverBasic } from '~hooks/useDrivers';
import theme from '~theme/AppTheme';
import { getConsolidatedEquipmentName } from '~utils/drivers/driver-utils';
import { hexToRgba } from '~utils/utilFunctions';

import InfiniteScrollLoadingIndicator from '../InfiniteScrollLoadingIndicator';

const ROW_HEIGHT_IN_PX = 56;

interface AssigneesListProps {
  assigneeType: AssigneeType;
  assignees: SelectedAssignment[];
  fetchMore: () => void;
  hasMore?: boolean;
  loadingReason?: FilterMenuItemLoadingReason;
  showNoResultsMessage?: boolean;
  sx?: SxProps;
}

export default function AssigneesList({
  assigneeType,
  assignees,
  fetchMore,
  hasMore,
  loadingReason,
  showNoResultsMessage,
  sx,
}: AssigneesListProps) {
  if (showNoResultsMessage) {
    const entity =
      assigneeType === AssigneeType.VENDOR
        ? t('form_fields.vendor').toLocaleLowerCase()
        : t('form_fields.driver').toLocaleLowerCase();

    return (
      <Box
        sx={{
          alignItems: 'center',
          bgcolor: hexToRgba(theme.brandV2.colors.treadOrangeDark, 0.1),
          borderRadius: theme.brandV2.borderRadius,
          display: 'flex',
          gap: 0.75,
          height: `${ROW_HEIGHT_IN_PX}px`,
          justifyContent: 'center',
          p: 1,
        }}
      >
        <InfoOutlined
          sx={{ color: theme.brandV2.colors.treadOrangeDark, fontSize: '18px' }}
        />

        <Typography variant="body2" color={theme.brandV2.colors.treadOrangeDark}>
          {t('dispatch.order.no_assignees_for_bulk_assign', { entity })}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={sx}>
      <LoadingSpinner
        isVisible={loadingReason === FilterMenuItemLoadingReason.SEARCH_VALUE}
      />

      {assignees.map((assignee, index) => (
        <React.Fragment key={assignee.id}>
          <AssigneeRow assignee={assignee} />

          {index === assignees.length - 1 && hasMore && (
            <InfiniteScrollLoadingIndicator
              alignItems="center"
              display="flex"
              height="50px"
              isLoading={loadingReason === FilterMenuItemLoadingReason.INFINITE_SCROLL}
              justifyContent="center"
              loadingIndicatorSize={30}
              onFetchMore={fetchMore}
            />
          )}
        </React.Fragment>
      ))}
    </Box>
  );
}

interface AssigneeRowProps {
  assignee: SelectedAssignment;
}

function AssigneeRow({ assignee }: AssigneeRowProps) {
  let content: React.ReactNode = null;

  if (assignee.type === JobAssignmentType.DRIVER) {
    const equipmentName = getConsolidatedEquipmentName(
      assignee as unknown as DriverBasic,
    );
    const equipmentType = assignee.equipment?.equipmentType.name;
    const hasJobsCount = _.isNumber(assignee.jobCount);

    content = (
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <OverflowAwareText variant="body2">
          <Typography component="span" sx={{ fontSize: 'inherit', fontWeight: 600 }}>
            {assignee.name}
          </Typography>

          {assignee.companyShare ? (
            <Typography
              component="span"
              sx={{
                fontSize: 'inherit',
                color: theme.brandV2.colors.treadGray3,
              }}
            >
              {` • ${assignee.companyShare.senderCompany.legalName}`}
            </Typography>
          ) : null}
        </OverflowAwareText>

        <OverflowAwareText variant="body2">
          {equipmentName && (
            <>
              <Typography
                component="span"
                sx={{ fontSize: 'inherit', color: theme.brandV2.colors.treadGray3 }}
              >
                {`${t('form_fields.equipment_id')}:`}
              </Typography>

              <Typography
                component="span"
                sx={{ ml: 0.5, fontSize: 'inherit', fontWeight: 600 }}
              >
                {equipmentName}
              </Typography>

              {equipmentType && (
                <Typography
                  component="span"
                  sx={{
                    fontSize: 'inherit',
                    color: theme.brandV2.colors.treadGray3,
                  }}
                >
                  {` • ${equipmentType}${hasJobsCount ? ` • ` : ''}`}
                </Typography>
              )}
            </>
          )}

          {hasJobsCount && (
            <Typography
              component="span"
              sx={{ fontSize: 'inherit', color: theme.brandV2.colors.treadGray3 }}
            >
              {`${assignee.jobCount} ${t('dispatch.job.assignment_popover.jobs')}`}
            </Typography>
          )}
        </OverflowAwareText>
      </Box>
    );
  } else {
    content = (
      <OverflowAwareText variant="body2" sx={{ fontWeight: 600 }}>
        {assignee.name}
      </OverflowAwareText>
    );
  }

  return (
    <Box
      sx={{
        borderRadius: theme.brandV2.borderRadius,
        height: `${ROW_HEIGHT_IN_PX}px`,
        p: 1,
        '&:hover': { bgcolor: theme.brandV2.colors.treadGray8, cursor: 'pointer' },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
        <Box sx={{ width: '20px', flex: 0 }}>
          <Checkbox
            size="small"
            sx={{
              '&.MuiCheckbox-root': {
                color: theme.brandV2.colors.treadGray6,
                mt: '-10px',
                p: 0,
              },
              '&.Mui-checked': { color: theme.brandV2.colors.treadOrange },
              '&.Mui-disabled': { pointerEvents: 'auto' },
            }}
            disabled={Math.random() > 0.5}
            checked={Math.random() > 0.5}
            onClick={(event: React.MouseEvent) => {
              event.stopPropagation();
            }}
          />
        </Box>

        {content}
      </Box>
    </Box>
  );
}
