import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { t } from 'i18next';

import { OverflowAwareText } from '~components/Order/ordersDispatchStyledComponents';
import theme from '~theme/AppTheme';

interface SelectionCountersProps {
  currentSelections: number;
  maxSelections: number;
  sx?: SxProps;
}

export default function SelectionCounters({
  currentSelections,
  maxSelections,
  sx,
}: SelectionCountersProps) {
  return (
    <Box sx={sx}>
      <OverflowAwareText variant="body2">
        <Typography component="span" sx={{ fontSize: 'inherit', fontWeight: 600 }}>
          {`${currentSelections} ${t('common.of')} ${maxSelections} `}
        </Typography>

        <Typography component="span" sx={{ fontSize: 'inherit' }}>
          {t('dispatch.order.trucks_selected')}
        </Typography>

        <Typography
          component="span"
          sx={{ fontSize: 'inherit', color: theme.brandV2.colors.treadGray3 }}
        >
          {` • `}
        </Typography>

        <Typography
          component="span"
          sx={{ fontSize: 'inherit', color: theme.brandV2.colors.treadOrangeDark }}
        >
          {t('dispatch.order.selected_trucks_count', {
            count: currentSelections,
            plural: currentSelections > 1 ? 's' : '',
          })}
        </Typography>
      </OverflowAwareText>
    </Box>
  );
}
