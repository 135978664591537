import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Skeleton from '@mui/material/Skeleton';
import Switch from '@mui/material/Switch';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { InvoiceCategoryFilter } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';

import InvoicesTabContent from '~components/Invoice/InvoicesTabContent';
import { Loads } from '~components/Job/Loads';
import { RouteMap } from '~components/Job/RouteMap';
import { SmallTabs } from '~components/Tabs/SmallTabs';
import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import { useDriverDays } from '~hooks/useDriverDays/useDriverDays';
import { Invoice, useInvoices } from '~hooks/useInvoices';
import { useJob } from '~hooks/useJob';
import { Job } from '~hooks/useJob/models';
import { SubtitlesOff } from '~icons/SubtitlesOffIcon';
import { useStores } from '~store/RootStore';
import theme from '~theme/AppTheme';

import ApprovalActionsMenu from './ApprovalActionsMenu';
import ApprovedBySection from './ApprovedBySection';
import { ShiftDetails } from './ShiftDetails';

const emDash = '-';
const getFromToLocationString = (from: string, to: string) => `${from} to: ${to}`;
const formatMinutesToHoursString = (minutes: number): string =>
  `${(minutes / 60).toFixed(2)}h`;

export const DriverDayDetails = observer(({ driverDayId }: { driverDayId: string }) => {
  const [toggledJob, setToggledJob] = useState<Job | null>(null);
  const { driverDayStore } = useStores();
  const { fetchJobsForDriverDay, isLoadingDriverDayJobs } = useDriverDays();
  const { approveJob, unapproveJob } = useJob();
  const { approveInvoice, getInvoicesByCategory } = useInvoices();
  const [currentCategory, setCurrentCategory] = useState<InvoiceCategoryFilter>(
    InvoiceCategoryFilter.PAYABLES,
  );
  const [invoices, setInvoices] = useState<Invoice[]>([]);

  const getInvoices = async () => {
    const invoices = await getInvoicesByCategory(currentCategory);
    setInvoices(invoices);
  };
  useEffect(() => {
    getInvoices();
  }, [currentCategory]);

  const jobIdsForDay = driverDayStore.jobIdsByDriverDayId[driverDayId];
  const jobsForDay = jobIdsForDay?.map((jobId) => driverDayStore.jobByJobId[jobId]);

  const driverDay = driverDayStore.driverDays.find((dDay) => dDay.id === driverDayId);
  const { currencyFormatter } = useCompanyCurrency();

  const getInvoicesForJob = useCallback(
    (jobId: string) => {
      return invoices.filter((invoice) => invoice.job?.id === jobId);
    },
    [invoices],
  );

  const JobHeader = ({
    job,
    hideMapToggle = false,
  }: {
    job: Job;
    hideMapToggle?: boolean;
  }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          bgcolor: theme.brandV2.colors.treadGray10,
          py: 1,
          px: 1.5,
          borderRadius: 2,
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      >
        <Typography noWrap paragraph variant="subtitle1" sx={{ m: 0 }}>
          {' '}
          {job.jobId}
        </Typography>
        <div>•</div>
        <Typography noWrap sx={{ maxWidth: '300px' }}>
          {getFromToLocationString(
            job.waypoints[0].site?.name ?? emDash,
            job.waypoints[1].site?.name ?? emDash,
          )}
        </Typography>
        {job.driverRateType && (
          <>
            <div>•</div>
            <Typography>{job.driverRateType}</Typography>
          </>
        )}
        {!hideMapToggle && (
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={toggledJob?.id === job.id}
                onChange={() => {
                  setToggledJob(job);
                }}
              />
            }
            label={t('approvals.driver_day.show_map_toggle')}
            sx={{ ml: 'auto' }}
          />
        )}
      </Box>
    );
  };

  const JobsTabContent = () => {
    return (
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '3fr 2fr',
          gap: 2,
        }}
      >
        <Box
          aria-label={`job list for ${driverDayId}`}
          component="ul"
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          {driverDay && <ShiftDetails driverDay={driverDay} />}

          {jobsForDay.map((job, ind) => (
            <Box
              key={job.id}
              component="li"
              aria-label="job details"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,

                backgroundColor: 'white',
                border: `1px solid ${theme.brandV2.colors.treadGray7}`,
                borderRadius: 2,
                pb: 1,
              }}
            >
              <JobHeader job={job} />

              {/* Job summary */}
              <Box
                sx={{
                  px: 1.5,
                }}
              >
                {/* header */}

                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 600,
                    }}
                  >
                    {t('approvals.summary')}
                  </Typography>

                  {job.approved ? (
                    <ApprovalActionsMenu
                      onMenuItemClick={
                        job.unapprovable
                          ? () => {
                              unapproveJob(job.id);
                              fetchJobsForDriverDay(driverDayId, true);
                            }
                          : undefined
                      }
                      approved={job.approved}
                    />
                  ) : job.approvable ? (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        fontSize: '12px',
                      }}
                      onClick={() => {
                        approveJob(job.id);
                        fetchJobsForDriverDay(driverDayId, true);
                      }}
                    >
                      {t('approvals.driver_day.approve_job')}
                    </Button>
                  ) : null}
                </Box>
              </Box>

              <Loads
                key={job.id}
                details={job}
                reload={() => {
                  fetchJobsForDriverDay(driverDayId, true);
                }}
              />
            </Box>
          ))}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <ApprovedBySection driverDayId={driverDayId} />

          {toggledJob && (
            <Box
              sx={{
                backgroundColor: 'white',
                pb: 1.5,
                borderRadius: 2,
                border: `1px solid ${theme.brandV2.colors.treadGray7}`,
              }}
            >
              <Tabs
                value={'map'}
                aria-label="basic tabs example"
                TabIndicatorProps={{
                  style: {
                    display: 'none',
                  },
                }}
              >
                <Tab
                  label="Map"
                  value={'map'}
                  sx={{
                    color: theme.brandV2.colors.treadBlack,
                    pb: 1,
                    mb: 1,
                    ml: 1,
                    fontSize: '12px',
                    width: 'fit-content',
                    '&.Mui-selected': {
                      borderBottom: `4px solid ${theme.brandV2.colors.treadYellow}`,
                    },
                  }}
                />
              </Tabs>
              <div
                role="tabpanel"
                id={`simple-tabpanel-0`}
                aria-labelledby={`simple-tab-0`}
              >
                <RouteMap job={toggledJob} />
              </div>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const DriverInvoices = () => {
    return jobsForDay.map((job, ind) => {
      const filteredInvoices = getInvoicesForJob(job.id);
      if (!filteredInvoices.length) {
        return (
          <Box
            display="flex"
            gap={2}
            key={job.id}
            sx={{
              width: '100%',
            }}
          >
            {' '}
            <Box
              key={job.id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                mb: 2,
                width: '100%',

                gap: 1.5,

                backgroundColor: 'white',
                border: `1px solid ${theme.brandV2.colors.treadGray7}`,
                borderRadius: 2,
                pb: 1,
              }}
            >
              <JobHeader job={job} hideMapToggle />
              <SubtitlesOff
                style={{
                  marginInline: 'auto',
                }}
              />
              <Typography
                color={theme.brandV2.colors.treadBlack}
                variant="body1"
                sx={{ textAlign: 'center', fontWeight: 600 }}
              >
                {t('approvals.driver_day.no_invoices', {
                  category: currentCategory,
                })}
              </Typography>
            </Box>
          </Box>
        );
      }
      return filteredInvoices.map((invoice) => {
        return (
          <InvoicesTabContent
            invoice={invoice}
            job={job}
            utils={{
              currencyFormatter,
              approveInvoice: async (invoice: Invoice) => {
                await approveInvoice(invoice);
              },
            }}
            isDayDetailsView={true}
            customHeader={<JobHeader job={job} />}
            key={invoice.id}
            customCategory={currentCategory}
          />
        );
      });
    });
  };

  const tabs = useMemo(() => {
    return [
      {
        id: 'jobs',
        headerClassName: 'jobs-tab',
        label: t('page_headings.driver_day_jobs'),
        content: <JobsTabContent />,
      },
      {
        id: 'payables',
        headerClassName: 'jobs-tab',
        label: t('page_headings.driver_day_payables'),
        content: <DriverInvoices />,
        onSelected: () => {
          setCurrentCategory(InvoiceCategoryFilter.PAYABLES);
        },
      },
      {
        id: 'recievables',
        headerClassName: 'jobs-tab',
        label: t('page_headings.driver_day_receivables'),
        content: <DriverInvoices />,
        onSelected: () => {
          setCurrentCategory(InvoiceCategoryFilter.RECEIVABLES);
        },
      },
    ];
  }, [jobsForDay, invoices]);

  useEffect(() => {
    fetchJobsForDriverDay(driverDayId);
  }, [driverDayId]);

  useEffect(() => {
    if (!toggledJob && jobsForDay && jobsForDay.length > 0) {
      setToggledJob(jobsForDay?.[0]);
    }
  }, [toggledJob, jobsForDay]);

  if (isLoadingDriverDayJobs || !jobsForDay) {
    return <Skeleton height={300} />;
  }

  return (
    <Box sx={{ p: 1.5, pb: 2, borderTopWidth: '0px' }}>
      {jobIdsForDay.length ? (
        <SmallTabs
          tabs={tabs}
          sx={{
            background: 'transparent',
          }}
          tabPanelStyle={{
            backgroundColor: theme.brandV2.colors.treadGray8,
            p: 1.5,
            pb: 2,
          }}
          tabStyle={{
            '&.MuiTab-root': {
              borderRadius: '8px 8px 0 0',
              px: 1.5,
              width: 'fit-content',
              fontSize: '12px',
            },

            background: theme.brandV2.colors.treadGray7,
            border: `1px solid ${theme.brandV2.colors.treadGray7}`,
            color: theme.brandV2.colors.treadGray2,
            '&.Mui-selected': {
              color: theme.palette.primary.contrastText,
              background: theme.brandV2.colors.treadGray8,
              borderColor: theme.brandV2.colors.treadGray7,
            },
          }}
        />
      ) : null}
    </Box>
  );
});
