/*
 * Each of these exported consts is a repeat of the enums below. Consider consolidating
 * into the enums below.
 */
export const companyConnectAccountByTreadId = 'company_connect_account_by_tread_id';
export const haulerRateTypeField = 'hauler_rate_type_field';
export const newOrderForm = 'new_order_form';

export enum FeatureFlags {
  agaveIntegrationEnabled = 'agave_integration_enabled',
  assignJobModal = 'assign_job_modal',
  bulkAssignMultiple = 'bulk_assign_multiple',
  companyConnectAccountByTreadId = 'company_connect_account_by_tread_id',
  driverAssignmentPopover = 'driver_assignment_popover_enabled',
  driverDay = 'driver-day',
  driverPanelEnabled = 'driver_panel_enabled',
  driverPay = 'driver-pay',
  extendedDispatchFilters = 'extended_dispatch_filters',
  haulerRateTypeField = 'hauler_rate_type_field',
  hybridBaseMap = 'hybrid_base_map',
  manageEquipmentTypes = 'manage_equipment_types',
  manageTelematicsIntegrations = 'manage_telematics_integrations',
  newLiveMapScrubber = 'live_map_scrubber_v2',
  newOrderForm = 'new_order_form',
  nonRoutableAlerts = 'non_routable_alerts',
  omniReportFeatureFlag = 'omni_reports',
  persistDispatchFilters = 'persist_dispatch_filters',
  projectPhase = 'project_phase_enabled',
  serviceClass = 'service_class',
  settlements = 'settlements',
  sharedLiveMapComponent = 'shared_live_map_component',
  treadLivePage = 'tread_live_page',
}
