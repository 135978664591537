const realTimeChannels = {
  CompanyJobUpdateChannel: 'CompanyJobUpdateChannel',
  CompanyOrderUpdateChannel: 'CompanyOrderUpdateChannel',
  InvoiceUpdateChannel: 'InvoiceUpdateChannel',
  NextBillionLastLocationChannel: 'NextBillionLastLocationChannel',
  SettlementUpdateChannel: 'SettlementUpdateChannel',
  UserBulkAssignJobsChannel: 'UserBulkAssignJobsChannel',
  UserBulkCopyAssignmentsChannel: 'UserBulkCopyAssignmentsChannel',
  UserBulkSendJobsChannel: 'UserBulkSendJobsChannel',
  DriverDayUpdateChannel: 'DriverDayUpdateChannel',
};
export { realTimeChannels };
