import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import { t } from 'i18next';
import _ from 'lodash';
import { ReactElement } from 'react';
import React from 'react';

import { JobAssignmentType } from '~constants/enums';
import InfiniteScrollLoadingIndicator from '~pages/Dispatch/components/InfiniteScrollLoadingIndicator';

import {
  FILTER_MENU_ITEM_HEIGHT_IN_PX,
  FilterMenuItemLoadingReason,
} from '../../Filters/FilterMenuItem';
import Menu from '../../Menu/Menu';
import { SearchBar } from '../../Search/SearchBar';
import Tabs from '../../Tabs/Tabs';
import { DriverMenuItem } from './DriverMenuItem';
import {
  SelectedAssignment,
  TabValue,
  useAssignDriverMenu,
} from './hooks/useAssignDriverMenu';

interface AssignDriverMenuProps {
  companyId: string;
  customAssignCTA?: ReactElement;
  filterDate?: string;
  onChange: (assignment: SelectedAssignment) => void;
  onOpenStateChanged: (open: boolean) => void;
}

const AssignDriverMenu = ({
  companyId,
  customAssignCTA,
  filterDate,
  onOpenStateChanged,
  onChange,
}: AssignDriverMenuProps) => {
  const theme = useTheme();

  const {
    searchBarRef,
    selectedTab,
    setSelectedTab,
    setSearchFieldValue,
    open,
    setOpen,
    data,
    loadingReason,
    hasMore,
    fetchMore,
    applyDebouncedFilter,
  } = useAssignDriverMenu(companyId, filterDate);

  const AssignCTA = customAssignCTA ? (
    React.cloneElement(customAssignCTA, {
      onClick(event: React.MouseEvent<HTMLElement>) {
        event?.stopPropagation();
      },
      sx: () => ({
        ...(customAssignCTA.props.sx || {}),
        backgroundColor: open ? theme.brandV2.colors.treadOrange : 'inherit',
        '& > p': {
          color: open ? '#FFFFFF' : 'inherit',
        },
      }),
    })
  ) : (
    <Button color="primary">{t('dispatch.job.assign_to')}</Button>
  );

  return (
    <Menu
      menuTrigger={AssignCTA}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClick={(event: React.MouseEvent) => {
        event.stopPropagation();
      }}
      onOpenStateChanged={(open) => {
        onOpenStateChanged(open);
        setOpen(open);
      }}
      sx={{
        '& .MuiList-root': {
          padding: '12px',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Tabs
        selected={selectedTab}
        onChange={(tab) => setSelectedTab(tab)}
        sx={{
          paddingBottom: '12px',
        }}
        tabs={[
          {
            label: t('dispatch.job.assignment_popover.internal'),
            value: TabValue.INTERNAL,
          },
          {
            label: t('dispatch.job.assignment_popover.external'),
            value: TabValue.EXTERNAL,
          },
          {
            label: t('dispatch.job.assignment_popover.vendor'),
            value: TabValue.VENDOR,
          },
        ]}
      />

      <SearchBar
        ref={searchBarRef}
        focusOnMount={true}
        placeHolder={
          selectedTab === TabValue.VENDOR
            ? t('dispatch.job.assignment_popover.search_for_vendor') || ''
            : t('dispatch.job.assignment_popover.search_for_driver') || ''
        }
        alwaysExpanded
        isLoading={loadingReason === FilterMenuItemLoadingReason.SEARCH_VALUE}
        setFilter={(value) => {
          applyDebouncedFilter(() => setSearchFieldValue(value));
        }}
      />

      <Divider sx={{ '&.MuiDivider-root': { mt: '8px', mb: 0 } }} />

      {data.length > 0 && (
        <Box maxHeight={FILTER_MENU_ITEM_HEIGHT_IN_PX * 5} overflow="auto">
          {data.map((driver, index) => (
            <React.Fragment key={driver.id}>
              <MenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  onChange(driver);
                }}
                sx={{
                  '&.MuiMenuItem-root': {
                    padding: '0px',
                  },
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <Box display="flex" width="100%">
                  <DriverMenuItem
                    name={driver.name}
                    company={driver.companyShare?.senderCompany?.legalName}
                    equipmentId={
                      driver.equipment?.companyShare?.externalId ||
                      driver.equipment?.externalId
                    }
                    equipmentType={driver.equipment?.equipmentType.name}
                    jobCount={
                      driver.type === JobAssignmentType.VENDOR ? null : driver.jobCount
                    }
                  />
                </Box>
              </MenuItem>
              {index === data.length - 1 && hasMore && (
                <InfiniteScrollLoadingIndicator
                  alignItems="center"
                  display="flex"
                  height={FILTER_MENU_ITEM_HEIGHT_IN_PX}
                  isLoading={
                    loadingReason === FilterMenuItemLoadingReason.INFINITE_SCROLL
                  }
                  justifyContent="center"
                  onFetchMore={fetchMore}
                />
              )}
            </React.Fragment>
          ))}
        </Box>
      )}
    </Menu>
  );
};

export { AssignDriverMenu };
