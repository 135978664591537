import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';

import { AddOnCharge } from '~hooks/useAddOns';

import { COMMON_GRID_CONFIG } from './constants';

export function SettlementAddOnCharges({
  settlementAddOnCharges,
  currencyFormatter,
}: {
  settlementAddOnCharges: AddOnCharge[];
  currencyFormatter: (value: number) => string;
}) {
  if (!settlementAddOnCharges.length) {
    return null;
  }
  return settlementAddOnCharges.map((addOnCharge) => (
    <Box
      key={addOnCharge.id}
      sx={{
        ...COMMON_GRID_CONFIG,
        borderBottom: '1px solid #E2E2E2',
      }}
    >
      <Typography variant="body2" sx={{ gridColumn: '1 / 4' }}>
        {`${t('approvals.driver_pay.settlements.add_on_name_prefixed', { name: addOnCharge.name })}`}
      </Typography>
      {!!addOnCharge.quantity && (
        <Typography variant="body2" sx={{ gridArea: 'qty' }}>
          {addOnCharge.quantity}
        </Typography>
      )}
      {addOnCharge.percentage ? (
        <Typography variant="body2" sx={{ gridArea: 'rate' }}>
          {addOnCharge.percentage >= 0
            ? `%${addOnCharge.percentage}`
            : `(%${-addOnCharge.percentage})`}
        </Typography>
      ) : (
        <Typography variant="body2" sx={{ gridArea: 'rate' }}>
          {addOnCharge.rate >= 0
            ? currencyFormatter(addOnCharge.rate)
            : `(${currencyFormatter(-addOnCharge.rate)})`}
        </Typography>
      )}
      <Typography variant="body2" sx={{ gridArea: 'total' }}>
        {addOnCharge.totalAmount}
      </Typography>
    </Box>
  ));
}
