// Todo: cleanup store, remove unused methods and flow
// From now, there is no separate list of all jobs, just jobs per order.
// That means, all stuff, i.e. pagination, search, etc. should be moved to context of orderID, or to order store itself

import { AccountType } from '@treadinc/horizon-api-spec';
import { cloneDeep, get, uniqBy } from 'lodash';
import { makeObservable, observable, runInAction } from 'mobx';

import { Account } from '~hooks/useAccount';
import {
  Job,
  JobAssignment,
  JobTimeline,
  JobTripEvent,
  onlyTimeLineOptions,
} from '~hooks/useJob';
import { User } from '~hooks/useUsers';
import { Pagination, PaginationLink } from '~services/pagination';
import { RootStore } from '~store/RootStore';
import { getPageNumber } from '~utils/pagination';
class JobStore {
  jobs: Job[] = [];
  batchedJobs: Job[] = [];
  timeline: Record<string, JobTimeline[]> = {};
  tripEvents: Record<string, JobTripEvent[]> = {};
  jobAccounts: Record<string, Account[]> = {};
  companyDrivers: Record<string, User[]> = {};
  accountDrivers: Record<string, User[]> = {};
  jobAssignments: Record<string, JobAssignment[]> = {};
  jobFilter: Record<string, any> = {};
  dispatchFilter: Record<string, any> = {};
  areFilterOptionsLoaded: boolean = false;

  // Todo: move to order store ???
  pagination: Pagination = {
    limit: 100,
    after: '',
    before: '',
    page: 1,
  };

  jobEventsPagination: Pagination = {
    limit: 100,
    after: '',
    before: '',
    page: 1,
  };

  constructor(private readonly rootStore: RootStore) {
    makeObservable(this, {
      jobs: observable,
      batchedJobs: observable,
      timeline: observable,
      tripEvents: observable,
      pagination: observable,
      jobAccounts: observable,
      companyDrivers: observable,
      accountDrivers: observable,
      jobAssignments: observable,
      jobFilter: observable,
      dispatchFilter: observable,
      areFilterOptionsLoaded: observable,
      jobEventsPagination: observable,
    });
    this.rootStore = rootStore;
  }

  public get accountsByCompany() {
    return (companyId: string, role?: AccountType) => {
      const list = this.jobAccounts[companyId];
      if (role && list?.length) {
        return list.filter((account) => account.accountTypes.includes(role));
      }

      return list || undefined;
    };
  }

  public get driversByCompany() {
    return (companyId: string) => {
      const list = uniqBy(
        [
          ...(this.companyDrivers[companyId] || []),
          ...(this.accountDrivers[companyId] || []),
        ],
        'id',
      );
      return list.length ? list : undefined;
    };
  }

  public get jobsByOrder() {
    return (orderId: string) => {
      return this.jobs.filter((job) => job.order?.id === orderId) || [];
    };
  }
  public get jobById() {
    return (jobId: string) => this.jobs.find((job) => job.id === jobId) || null;
  }

  public get getAllCompanyDrivers() {
    return () => {
      const list = uniqBy([...(Object.values(this.companyDrivers) || [])], 'id');
      return list.length ? list : null;
    };
  }

  setPagination(pagination: Pagination) {
    this.pagination = {
      ...this.pagination,
      before: pagination.before || '',
      after: pagination.after || '',
    };
  }

  updatePageNumber(link: PaginationLink) {
    runInAction(() => {
      this.pagination = {
        ...this.pagination,
        page: getPageNumber(this.pagination.page, link),
      };
    });
  }

  setJobs(jobs: Job[]) {
    this.jobs = uniqBy(jobs, 'id');
  }

  setBatchedJobs(jobs: Job[]) {
    const allJobs = [...this.batchedJobs, ...jobs];

    this.batchedJobs = uniqBy(allJobs, 'id');
  }

  /**
   * Sets the jobs for a specific order ID.
   * Filters out the jobs that are not part of the order so that the list is updated
   * based on any changes made by searching or filtering.
   *
   * @param orderId - The ID of the order.
   * @param jobs - An array of Job objects.
   */
  setJobsByOrder(orderId: string, jobs: Job[]) {
    const prevJobs = this.jobs.filter((job) => job.order?.id !== orderId);
    this.jobs = uniqBy([...prevJobs, ...jobs], 'id');
  }

  addJob(job: Job) {
    const idx = this.jobs.findIndex((j) => j.id === job.id);
    if (idx !== -1) {
      this.updateJob(job);
    } else {
      this.jobs = uniqBy([...this.jobs, job], 'id');
    }
  }

  addJobs(jobs: Job[]) {
    this.jobs = uniqBy([...jobs, ...this.jobs], 'id');
  }

  removeJob(id: string) {
    this.jobs = this.jobs.filter((job) => job.id !== id);
  }

  updateJob(job: Job) {
    this.jobs = this.jobs.map((j) => (j.id === job.id ? job : j));
  }
  setTimeline(jobId: string, timeline: JobTimeline[]) {
    this.timeline[jobId] = timeline.filter((t) =>
      onlyTimeLineOptions.includes(get(t, 'changes.state[1]')),
    );

    this.timeline = cloneDeep(this.timeline);
  }
  setJobTripEvents(jobId: string, events: JobTripEvent[]) {
    this.tripEvents[jobId] = events;
    this.tripEvents = cloneDeep(this.tripEvents);
  }

  setCompanyAccounts(companyId: string, accounts: Account[]) {
    this.jobAccounts[companyId] = accounts;
  }

  setAccountDrivers = (companyId: string, drivers: User[]) => {
    this.accountDrivers[companyId] = drivers;
  };

  setDrivers = (companyId: string, drivers: User[]) => {
    this.companyDrivers[companyId] = drivers;
  };

  setJobAssignments = (jobId: string, jobAssignments: JobAssignment[]) => {
    this.jobAssignments[jobId] = jobAssignments;
  };

  setJobFilter(filter: Record<string, any>) {
    this.jobFilter = filter;
  }

  addJobFilter(filter: Record<string, any>) {
    this.jobFilter = {
      ...this.jobFilter,
      ...filter,
    };
  }

  setDispatchFilter(filter: Record<string, any>) {
    this.dispatchFilter = filter;
  }

  addDispatchFilter(filter: Record<string, any>) {
    this.dispatchFilter = {
      ...this.dispatchFilter,
      ...filter,
    };
  }

  setFilterOptionsLoaded = (loaded: boolean) => {
    this.areFilterOptionsLoaded = loaded;
  };

  setJobsEventsPagination(pagination: Pagination) {
    this.jobEventsPagination = {
      ...this.jobEventsPagination,
      before: pagination.before || '',
      after: pagination.after || '',
    };
  }

  updateJobEventsPageNumber(link: PaginationLink) {
    runInAction(() => {
      this.jobEventsPagination = {
        ...this.jobEventsPagination,
        page: getPageNumber(this.jobEventsPagination.page, link),
      };
    });
  }
}

export default JobStore;
