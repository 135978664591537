import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { t as $t } from 'i18next';
import { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';

import { AutocompleteFormField } from '~components/FormFields/AutocompleteFormField';
import { CheckBoxFormField } from '~components/FormFields/CheckBoxFormField';
import { CompanySelectorFormField } from '~components/FormFields/CompanySelectorFormField';
import { TextFormField } from '~components/FormFields/TextFormField';
import { FormStateChangeProps } from '~formsShared';
import { Material, MaterialTypeItem, useMaterials } from '~hooks/useMaterials';
import {
  materialFormValidationSchema,
  setMaterialDefaults,
} from '~pages/Settings/Administration/MaterialsManagement/materialFormValidationSchema';
import { useStores } from '~store';
import { Nullable } from '~types/Nullable';

interface MaterialFormProps {
  defaultMaterial: Nullable<Material>;
  onFormStateChange: ({ isValid, isDirty }: FormStateChangeProps) => void;
  onFormDirty?: (isDirty: boolean) => void;
}

const MaterialForm = forwardRef(function MaterialForm(
  { defaultMaterial, onFormStateChange }: MaterialFormProps,
  ref: Ref<any>,
) {
  const [materialTypesOptions, setMaterialTypesOptions] = useState<MaterialTypeItem[]>(
    [],
  );
  const { userStore } = useStores();
  const { getMaterialTypesByCompanyId } = useMaterials();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid, isDirty },
    reset,
  } = useForm({
    resolver: yupResolver(materialFormValidationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: setMaterialDefaults(defaultMaterial, userStore.user.company),
  });
  const selectedCompany = watch('company');
  useImperativeHandle(ref, () => ({
    submit(callBack: () => void) {
      handleSubmit(callBack)();
    },
    resetForm(callBack?: () => void) {
      reset();
      callBack?.();
    },
  }));

  useEffect(() => {
    if (selectedCompany?.id) {
      getMaterialTypesByCompanyId({
        companyId: selectedCompany?.id,
        callBack: setMaterialTypesOptions,
      });
    }
  }, [selectedCompany?.id]);

  useEffect(() => {
    onFormStateChange({ isValid, isDirty });
  }, [isValid, isDirty]);

  return (
    <form>
      <Box display={'flex'} flexDirection={'column'} sx={{ width: '100%', flexGrow: 1 }}>
        <Box sx={{ width: '100%' }}>
          <CompanySelectorFormField
            sx={{ width: '100%', minWidth: '100%' }}
            name={'company'}
            isRequired={true}
            disabled={!!defaultMaterial?.company?.id}
            errors={errors}
            control={control}
          />
        </Box>
        <Box display={'flex'} sx={{ mt: 2 }}>
          <Box sx={{ width: '50%', mr: 2 }}>
            <TextFormField
              control={control}
              errors={errors}
              name="name"
              label={`${$t('form_fields.name')}`}
              isRequired={true}
            />
          </Box>
          <Box sx={{ width: '50%' }}>
            <AutocompleteFormField
              control={control}
              name="type"
              disabled={!selectedCompany?.id}
              errors={errors}
              list={materialTypesOptions || []}
              label={`${$t('form_fields.material_type')}`}
              isRequired={false}
              getValue={(item) => item?.id}
              getLabel={(item) => item.name}
            />
          </Box>
        </Box>
        <Box display={'flex'} sx={{ mt: 2 }}>
          <Box display={'flex'} sx={{ width: '50%', mr: 2 }}>
            <TextFormField
              control={control}
              errors={errors}
              name="externalId"
              label={`${$t('form_fields.material_id')}`}
              isRequired={false}
            />
          </Box>
          <Box display={'flex'} sx={{ width: '50%' }} alignItems={'center'}>
            <CheckBoxFormField
              sx={{ mt: 3 }}
              control={control}
              errors={errors}
              value={true}
              name="isNotActive"
              isRequired={true}
              label={`${$t('common.inactive')}`}
            />
          </Box>
        </Box>
      </Box>
    </form>
  );
});
export { MaterialForm };
