import { Subscription } from '@rails/actioncable';
import type {
  DriverDay_Read,
  Job_Read,
  JobSummary_Read,
  ShiftAdjustmentType,
} from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import { useCallback, useState } from 'react';

import { API_VERSION } from '~constants/consts';
import connection from '~services/connectionModule';
import { PaginationLink, PaginationQuery } from '~services/pagination';
import { realTimeChannels } from '~services/realTimeChannels';
import { useStores } from '~store/RootStore';
import { subscribeToChannel } from '~utils/rtu/rtu-utils';

import { Job, JobSummary } from '../useJob';
import { DriverDay } from './models';

export const useDriverDays = () => {
  const { driverDayStore, userStore } = useStores();

  const [isLoadingDriverDays, setIsLoadingDriverDays] = useState(false);
  const [isLoadingDriverDayJobs, setIsLoadingDriverDayJobs] = useState(false);
  const [isUpdatingDriverDay, setIsUpdatingDriverDay] = useState(false);
  const [error, setError] = useState<Error>();

  const [driverDaySubscription, setDriverDaySubscription] = useState<Subscription>();
  const [jobSubscription, setJobSubscription] = useState<Subscription>();

  const subscribeToDriverDayUpdates = useCallback(() => {
    const companyId = userStore.userCompany?.id;
    subscribeToChannel(
      realTimeChannels.DriverDayUpdateChannel,
      companyId,
      setDriverDaySubscription,
      (response: { data: DriverDay_Read }) => {
        driverDayStore.updateDriverDay(DriverDay.parse(response.data));
      },
    );
  }, [userStore.userCompany?.id, setDriverDaySubscription]);

  const subscribeToJobUpdates = useCallback(() => {
    const companyId = userStore.userCompany?.id;
    subscribeToChannel(
      realTimeChannels.CompanyJobUpdateChannel,
      companyId,
      setJobSubscription,
      (response: { data: Job_Read }) => {
        const job = Job.parse(response?.data);
        driverDayStore.setJobByJobId(job.id, job);
      },
    );
  }, [userStore.userCompany?.id, setJobSubscription]);

  const fetchDriverDays = useCallback(
    async (link?: PaginationLink, searchQuery?: string) => {
      setIsLoadingDriverDays(true);
      try {
        const params: PaginationQuery = {
          'page[limit]': driverDayStore.pagination.limit,
        };

        if (link && driverDayStore.pagination[link]) {
          params[`page[${link}]`] = driverDayStore.pagination[link];
        }

        if (driverDayStore.filters.customerAccountIds) {
          params['filter[customer_account_ids]'] = Array.from(
            driverDayStore.filters.customerAccountIds,
          );
        }
        if (driverDayStore.filters.driverIds) {
          params['filter[driver_ids]'] = Array.from(driverDayStore.filters.driverIds);
        }
        if (driverDayStore.filters.vendorAccountIds) {
          params['filter[vendor_account_ids]'] = Array.from(
            driverDayStore.filters.vendorAccountIds,
          );
        }
        if (searchQuery) {
          params['search[datagrid]'] = searchQuery;
        }
        if (driverDayStore.dateFilters.startDate) {
          params['filter[start_date]'] =
            driverDayStore.dateFilters.startDate.toISOString();
          // To account for a single date selection
          if (!driverDayStore.dateFilters.endDate) {
            params['filter[end_date]'] =
              driverDayStore.dateFilters.startDate.toISOString();
          }
        }
        if (driverDayStore.dateFilters.endDate) {
          params['filter[end_date]'] = driverDayStore.dateFilters.endDate.toISOString();
        }

        const response = await connection.getPaginated<DriverDay_Read>(
          `${API_VERSION}/driver_days`,
          { params },
          t('error_messages.driver_days.failed_to_fetch_driver_days') as string,
        );

        const { data, pagination } = response;

        driverDayStore.setDriverDays(data.map((dDayJson) => DriverDay.parse(dDayJson)));
        driverDayStore.setPagination(pagination);
        driverDayStore.updatePageNumber(link);
      } catch (err) {
        setError(err as Error);
        throw new Error(
          t('error_messages.driver_days.failed_to_fetch_driver_days') as string,
        );
      } finally {
        setIsLoadingDriverDays(false);
      }
    },
    [setIsLoadingDriverDays, setError],
  );

  const fetchJobsForDriverDay = useCallback(
    async (driverDayId: string, silent?: boolean) => {
      !silent && setIsLoadingDriverDayJobs(true);
      try {
        const jobsResponse = await connection.get<Job_Read[]>(
          `${API_VERSION}/driver_days/${driverDayId}/jobs`,
          {},
          t('error_messages.driver_days.failed_to_fetch_driver_day_jobs') as string,
        );
        const detailsPromises = jobsResponse.map((jobJson) => {
          return connection.get<Job_Read>(
            `${API_VERSION}/jobs/${jobJson.id}`,
            {},
            t('error_messages.jobs.failed_to_fetch_individual_job') as string,
          );
        });
        // Hydrate job summaries in parallel
        jobsResponse.forEach((jobJson) => {
          connection
            .get<JobSummary_Read>(
              `${API_VERSION}/jobs/${jobJson.id}/summary`,
              {},
              t('error_messages.jobs.failed_to_fetch_individual_job') as string,
            )
            .then((summaryJson) => {
              driverDayStore.setJobSummaryByJobId(
                jobJson.id,
                JobSummary.parse(summaryJson),
              );
            });
        });

        await Promise.all(detailsPromises).then((details) => {
          driverDayStore.setJobIdsByDriverDayId(
            driverDayId,
            details.map((jobJson) => jobJson.id),
          );
          const jobs = details.map((jobJson) => Job.parse(jobJson));
          driverDayStore.setJobs(jobs);
        });
      } catch (err) {
        setError(err as Error);
        throw new Error(
          t('error_messages.driver_days.failed_to_fetch_driver_day_jobs') as string,
        );
      } finally {
        !silent && setIsLoadingDriverDayJobs(false);
      }
    },
    [driverDayStore, setIsLoadingDriverDayJobs, setError],
  );

  const updateDriverDay = useCallback(
    async (
      id: string,
      adjustmentMinutes: number,
      adjustmentType: ShiftAdjustmentType,
    ) => {
      setIsUpdatingDriverDay(true);
      try {
        const response = await connection.patch<DriverDay_Read>(
          `${API_VERSION}/driver_days/${id}`,
          {
            shift_adjustment_minutes: adjustmentMinutes,
            shift_adjustment_type: adjustmentType,
          },
          {},
          t('error_messages.driver_days.failed_to_fetch_driver_days') as string,
        );
        driverDayStore.updateDriverDay(DriverDay.parse(response));
      } catch (err) {
        setError(err as Error);
        throw new Error(
          t('error_messages.driver_days.failed_to_fetch_driver_days') as string,
        );
      } finally {
        setIsUpdatingDriverDay(false);
      }
    },
    [setIsLoadingDriverDays, setError],
  );

  const approveDriverDay = useCallback(
    async (driverDayId: string) => {
      setIsUpdatingDriverDay(true);
      try {
        const response = await connection.put<DriverDay_Read>(
          `${API_VERSION}/driver_days/${driverDayId}/approve`,
          {},
          {},
          t('error_messages.driver_days.failed_to_approve_driver_day') as string,
        );
        driverDayStore.updateDriverDay(DriverDay.parse(response));
      } catch (err) {
        setError(err as Error);
        throw new Error(
          t('error_messages.driver_days.failed_to_approve_driver_day') as string,
        );
      } finally {
        setIsUpdatingDriverDay(false);
      }
    },
    [setIsUpdatingDriverDay, setError],
  );

  const unapproveDriverDay = useCallback(async (driverDayId: string) => {
    setIsUpdatingDriverDay(true);
    try {
      const response = await connection.put<DriverDay_Read>(
        `${API_VERSION}/driver_days/${driverDayId}/unapprove`,
        {},
        {},
        t('error_messages.driver_days.failed_to_unapprove_driver_day') as string,
      );
      driverDayStore.updateDriverDay(DriverDay.parse(response));
    } catch (err) {
      setError(err as Error);
      throw new Error(
        t('error_messages.driver_days.failed_to_unapprove_driver_day') as string,
      );
    } finally {
      setIsUpdatingDriverDay(false);
    }
  }, []);
  return {
    approveDriverDay,
    error,
    fetchDriverDays,
    fetchJobsForDriverDay,
    isLoadingDriverDays,
    isLoadingDriverDayJobs,
    isUpdatingDriverDay,
    subscribeToDriverDayUpdates,
    subscribeToJobUpdates,
    driverDaySubscription,
    jobSubscription,
    unapproveDriverDay,
    updateDriverDay,
  };
};
