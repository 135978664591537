import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import type { Consumer } from '@rails/actioncable';
import { createConsumer } from '@rails/actioncable';
import * as Sentry from '@sentry/react';
import { client } from '@treadinc/horizon-api-spec';
import type { RequestOptions } from '@treadinc/horizon-api-spec/types/client/types.d.ts';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { t as $t } from 'i18next';
import { cloneDeep, get } from 'lodash';
import { runInAction } from 'mobx';
import { v4 as UUID } from 'uuid';

import { PRIMARY_TOKEN } from '~constants/consts';
import { STATUS_MESSAGES } from '~constants/errorMessagesConsts';
import { ERROR_TYPES } from '~constants/errorTypes';
import { routes } from '~router';
import { extractPagination, Paginated, Pagination } from '~services/pagination';
import { rootStore } from '~store';
import { alert } from '~types/AlertTypes';

interface ArrayResponse<T> {
  data: Array<T>;
}

const emitError = (
  message: string | React.ReactNode,
  includeTreadAlertedText = false,
) => {
  runInAction(() => {
    const errorMessage = includeTreadAlertedText ? (
      <Box>
        <Typography variant="body1">{message}</Typography>
        <Typography variant="body2">{$t('error_messages.tread_alerted')}</Typography>
      </Box>
    ) : (
      message
    );

    rootStore.toasterStore.push(alert(errorMessage, 'error'), true);
  });
};
const REAL_TIME_URL = import.meta.env.TREAD__RTU_URL || 'ws://localhost:8080';
const BASE_URL = import.meta.env.TREAD__BASE_URL || 'http://localhost:8000';

// configure our generated api spec client, see https://heyapi.vercel.app/openapi-ts/clients/axios.html
client.setConfig({
  baseURL: BASE_URL,
  throwOnError: true,
});

function cloneFormData(formData: FormData): FormData {
  const clonedFormData = new FormData();
  for (const [key, value] of formData.entries()) {
    clonedFormData.append(key, value);
  }
  return clonedFormData;
}

// the @heyapi client uses a slightly different interface than axios so we have to
// convert our axois options to the @heyapi options. note we only use this in our
// homegrown http methods below (which are essentially deprecated in favor of using
// the generated api spec client code)
function convertedOptions(options: Record<string, unknown>): RequestOptions {
  // contents for FormData objects are stored differently than normal objects, so we cannot use deepClone to make a copy.
  const clonedData =
    options.data instanceof FormData
      ? cloneFormData(options.data)
      : cloneDeep(options.data);

  return {
    url: options.url,
    headers: cloneDeep(options.headers),
    body: clonedData,
    query: cloneDeep(options.params),
  } as RequestOptions;
}
class Connection {
  private realTimeConnectionInstance: Consumer;
  private impersonationJwt: string | null = null;

  public get realTimeConnection(): Consumer {
    return this.realTimeConnectionInstance;
  }

  constructor() {
    this.realTimeConnectionInstance = createConsumer(REAL_TIME_URL);

    const token = localStorage.getItem(PRIMARY_TOKEN) || '';
    if (token?.length) {
      this.realTimeConnectionInstance.addSubProtocol(token);
    }

    this.initializeInterceptors();
  }

  private initializeInterceptors(): void {
    client.instance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem(PRIMARY_TOKEN);
        if (this.impersonationJwt) {
          config.headers.Authorization = `Bearer ${this.impersonationJwt}`;
        } else if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }

        config.headers.Accept = 'application/json';

        const requestId = UUID();
        config.headers['X-Request-Id'] = requestId;
        config.headers['X-Amzn-Trace-Id'] = `RequestId=${requestId}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    // Ensure that the handler has access to object methods and context
    const boundHandler = this.handleSuccessMiddleware.bind(this);
    client.instance.interceptors.response.use(boundHandler);
  }

  private reconnectRealTimeConnection(token: string): void {
    this.realTimeConnectionInstance.disconnect();
    this.realTimeConnectionInstance.subprotocols = [];
    this.realTimeConnectionInstance.addSubProtocol(token);
    this.realTimeConnectionInstance.connect();
  }

  private handleSuccessMiddleware(response: AxiosResponse): AxiosResponse {
    const token = response?.headers?.['x-horizon-api-session-jwt'] ?? '';
    // If the token provided is different from the one we store, and the impersonationJwt is not already set
    // Alternatively, we can check for the jwt in the response body.
    if (
      !!token &&
      token !== localStorage.getItem(PRIMARY_TOKEN) &&
      !this.impersonationJwt
    ) {
      // If this was an impersonation request, we store the token in impersonationJwt and connect with that
      if (response.config.url?.includes('impersonations')) {
        this.impersonationJwt = token;
        this.reconnectRealTimeConnection(token);
      } else {
        // Otherwise, this is from a login or a refresh and we update the token in storage and connect with that
        localStorage.setItem(PRIMARY_TOKEN, token);
        this.reconnectRealTimeConnection(token);
      }
    }
    return response;
  }

  public handleRequestError(
    e: unknown,
    customErrorMessage?: string,
    overrideErrorCodes?: [number],
  ): never {
    const error = customErrorMessage
      ? new AxiosError(
          `${(e as AxiosError)?.response?.status} error: ${customErrorMessage}`,
          (e as AxiosError).code,
          (e as AxiosError).config,
          (e as AxiosError).request,
          (e as AxiosError).response,
        )
      : e;

    const errorMessage = (error as Error)?.message || 'Unknown error';
    let errorRequest = 'No request data';
    let errorResponse = 'No response data';
    let statusCode: number | undefined;

    if (axios.isAxiosError(error)) {
      if (error.request) {
        errorRequest = JSON.stringify(error.request);
      }

      if (error.response) {
        errorResponse =
          typeof error.response.data === 'string'
            ? error.response.data
            : JSON.stringify(error.response.data);
        statusCode = Number(error.response.status);
      }
    }

    const overrideError = shouldOverrideError(statusCode, overrideErrorCodes);
    // We want to continue passing 500 errors to Sentry, even if we don't want to display the error toasts for them
    if (!overrideError || String(statusCode)?.startsWith('5')) {
      Sentry.withScope((scope) => {
        scope.setTag('axios_error', axios.isAxiosError(error));
        scope.setExtra('error_message', errorMessage);
        scope.setExtra('error_request', errorRequest);
        scope.setExtra('error_response', errorResponse);
        if (statusCode !== undefined) {
          scope.setExtra('error_status', statusCode);
        }
        // Set the fingerprint to group by error message
        scope.setFingerprint([errorMessage]);
        Sentry.captureException(error);
      });
    }

    // Handling the error response here allows us to display the custom error message in the error toast
    this.handleErrorResponse(
      error as AxiosError,
      !!customErrorMessage,
      overrideErrorCodes,
    );

    throw error;
  }

  // @ts-ignore
  private handleErrorResponse(
    error: AxiosError,
    includeTreadAlertedText: boolean,
    overrideErrorCodes?: number[],
  ): Promise<never> {
    if (error) {
      const code = String(error.code || '').toUpperCase();
      const status = Number(get(error, 'response.status', 0));
      const message =
        error.message ||
        get(error, 'response.error.message') ||
        get(error, 'response.data.message') ||
        STATUS_MESSAGES[500];
      const overrideError = shouldOverrideError(status, overrideErrorCodes);
      if (overrideError) {
        return Promise.reject(error);
      }

      const errorType = String(get(error, 'response.data.error.error_type', ''));

      if ([400, 422, 404].includes(status)) {
        const errorList = get(error, 'response.data.error.errors', [])
          // @ts-ignore
          .map((err: { message: string }) => err.message)
          .join(', ');

        // @ts-ignore
        emitError(
          errorList || message || STATUS_MESSAGES[status as keyof typeof STATUS_MESSAGES],
          includeTreadAlertedText,
        );
      } else if (status === 401) {
        // Auth required
        // RedirectToLogin
        switch (errorType) {
          case 'member_reset_password':
            emitError(ERROR_TYPES.member_reset_password);
            break;
          case 'breached_password':
            emitError(ERROR_TYPES.breached_password);
            break;
          case 'unable_to_auth_magic_link':
            emitError(ERROR_TYPES.unable_to_auth_magic_link);
            break;
          case 'member_password_not_found':
            emitError(ERROR_TYPES.member_password_not_found);
            break;
          case 'weak_password':
            emitError(ERROR_TYPES.weak_password);
            break;
          default:
            emitError(STATUS_MESSAGES[401]);
            localStorage.removeItem(PRIMARY_TOKEN);
            // //keep here, do no reload of current login page if wrong credentials
            if (window.location.pathname !== `/${routes.signIn}`) {
              window.location.replace(`/${routes.signIn}`);
            }
            break;
        }
      } else if (status === 403) {
        // Unauthorized
        emitError(message || STATUS_MESSAGES[403], includeTreadAlertedText);
      } else if (Math.floor(status / 100) === 5) {
        // Any 500 error
        emitError(message || STATUS_MESSAGES[500], includeTreadAlertedText);
      } else if (!status && !code.includes('ERR_CANCEL')) {
        // Any not-recognized except CANCEL
        emitError(message);
      } else if (message || STATUS_MESSAGES[409]) {
        emitError(message || STATUS_MESSAGES[401], includeTreadAlertedText);
      }
      console.error(message || status || 'Unknown error');
      return Promise.reject(error);
    }
    return Promise.reject('Unknown error');
  }
  public async get<T>(
    url: string,
    config?: Record<string, unknown>,
    customErrorMessage?: string,
  ): Promise<T> {
    try {
      const response = await client.get<T>(convertedOptions({ url, ...config }));
      // @ts-ignore
      return response.data.data;
    } catch (e) {
      this.handleRequestError(e, customErrorMessage);
    }
  }

  public async getPaginated<T>(
    url: string,
    config?: Record<string, unknown>,
    customErrorMessage?: string,
  ): Promise<Paginated<T>> {
    try {
      const response = await client.get<ArrayResponse<T>>(
        convertedOptions({ url, ...config }),
      );
      const pagination = extractPagination(response);
      return {
        data: response.data.data,
        pagination,
      };
    } catch (e) {
      this.handleRequestError(e, customErrorMessage);
    }
  }

  public async post<T>(
    url: string,
    data?: unknown,
    config?: Record<string, unknown>,
    customErrorMessage?: string,
    overrideErrorCodes?: [number],
  ): Promise<T> {
    try {
      const response = await client.post<T>(convertedOptions({ url, data, ...config }));
      // @ts-ignore
      return response.data.data;
    } catch (e) {
      this.handleRequestError(e, customErrorMessage, overrideErrorCodes);
    }
  }

  public async put<T>(
    url: string,
    data?: unknown,
    config?: Record<string, unknown>,
    customErrorMessage?: string,
  ): Promise<T> {
    try {
      const response = await client.put<T>(convertedOptions({ url, data, ...config }));
      // @ts-ignore
      return response.data.data;
    } catch (e) {
      this.handleRequestError(e, customErrorMessage);
    }
  }

  public async patch<T>(
    url: string,
    data?: unknown,
    config?: Record<string, unknown>,
    customErrorMessage?: string,
    overrideErrorCodes?: [number],
  ): Promise<T> {
    try {
      const response = await client.patch<T>(convertedOptions({ url, data, ...config }));
      // @ts-ignore
      return response.data.data;
    } catch (e) {
      this.handleRequestError(e, customErrorMessage, overrideErrorCodes);
    }
  }

  public async delete<T>(
    url: string,
    config?: Record<string, unknown>,
    customErrorMessage?: string,
    overrideErrorCodes?: [number],
  ): Promise<T> {
    try {
      const response = await client.delete<T>(convertedOptions({ url, ...config }));
      // @ts-ignore
      return response.data.data;
    } catch (e) {
      this.handleRequestError(e, customErrorMessage, overrideErrorCodes);
    }
  }
}

/**
 * Determines whether the given error code should be overridden based on the provided error codes.
 *
 * @param {number | undefined} errorCode - The error code to check.
 * @param {number[] | undefined} overrideErrorCodes - The list of error codes to compare against.
 * @return {boolean} Returns true if the error code should be overridden, false otherwise.
 */
const shouldOverrideError = (
  errorCode: number | undefined,
  overrideErrorCodes: number[] | undefined,
) => {
  if (!overrideErrorCodes || !errorCode) {
    return false;
  }

  const staticSet = new Set([400, 422, 404, 401, 403]);
  if (overrideErrorCodes.some((code) => staticSet.has(code))) {
    return overrideErrorCodes.some((code) => code === errorCode);
  }

  // check if the error code starts with 5
  if (overrideErrorCodes.some((code) => code.toString().startsWith('5'))) {
    return errorCode.toString().startsWith('5');
  }

  return false;
};

const connection = new Connection();
export default connection;
