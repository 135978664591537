import { AccountType, Job_Read, JobState } from '@treadinc/horizon-api-spec';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

import { API_VERSION } from '~constants/consts';
import { JobAssignmentType } from '~constants/enums';
import { DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS } from '~constants/filters';
import { useAccount } from '~hooks/useAccount';
import { CompanyShareBasic } from '~hooks/useCompanyShares';
import { useDrivers } from '~hooks/useDrivers';
import { BasicEquipment } from '~hooks/useEquipment';
import { Job } from '~hooks/useJob';
import connection from '~services/connectionModule';
import { Paginated } from '~services/pagination';
import { Nullable } from '~types/Nullable';
import { useFetchMore } from '~utils/hooks/useFetchMore';

// Defines the maximum number of items to display in the list at a time
const LIST_LIMIT = 10;

// Defines the states of jobs used to filter the jobs that are assigned to the driver
const DRIVER_JOB_STATES = [
  JobState.ASSIGNED,
  JobState.ACCEPTED,
  JobState.TO_PICKUP,
  JobState.ARRIVED_PICKUP,
  JobState.LOADED,
  JobState.TO_DROPOFF,
  JobState.ARRIVED_DROPOFF,
  JobState.UNLOADED,
  JobState.LOAD_COMPLETED,
  JobState.SENT,
];

export interface SelectedAssignment {
  type: JobAssignmentType;
  id: string;
  name: string;
  companyShare: Nullable<CompanyShareBasic>;
  equipment: Nullable<BasicEquipment>;
  jobCount: Nullable<number>;
}

export enum TabValue {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
  VENDOR = 'vendor',
}

interface FetchJobsOptions {
  driverIds: string[];
  startDate?: string;
  endDate?: string;
  companyId: string;
  states?: JobState[];
}

interface FetchJobsResponse {
  data: Record<string, number>;
  hasMore: Nullable<boolean>;
}

export const useAssignDriverMenu = (companyId: string, filterDate?: string) => {
  const [open, setOpen] = useState<boolean>(false);
  const searchBarRef = useRef<HTMLInputElement>(null);
  const [selectedTab, setSelectedTab] = useState(TabValue.INTERNAL);
  const { getAccountsByCompanyIdTypeahead } = useAccount();
  const { getDriversByCompanyIdTypeahead } = useDrivers();
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const applyDebouncedFilter = _.debounce((callback: () => void) => {
    callback();
  }, DISPATCH_FILTERS_DEBOUNCE_DELAY_IN_MS);

  useEffect(() => {
    if (open && searchBarRef.current) {
      searchBarRef.current.focus();
    }
  }, [open, selectedTab]);

  const fetchInternalDrivers = useCallback(
    async (nextPageCursor?: string) => {
      if (!open) {
        return { data: [], hasMore: undefined };
      }
      const response = await getDriversByCompanyIdTypeahead({
        companyId,
        shared: false,
        searchParams: {
          limit: LIST_LIMIT,
          query: searchFieldValue,
          link: {
            type: 'after',
            cursor: nextPageCursor || '',
          },
        },
      });

      const data = (response?.data || []).map((driver) => ({
        type: JobAssignmentType.DRIVER,
        id: driver.id,
        name: `${driver.fullName}`,
        companyShare: driver.companyShare,
        equipment: driver.equipment,
        jobCount: 0,
      })) as SelectedAssignment[];

      const jobs = await fetchJobsForDriver(
        getFetchJobsOptions(companyId, data, filterDate),
      );

      return {
        data: data.map((driver) => ({
          ...driver,
          jobCount: jobs.data[driver.id] || 0,
        })),
        hasMore: response.pagination.after || '',
      };
    },
    [open, companyId, searchFieldValue],
  );
  const fetchExternalDrivers = useCallback(
    async (nextPageCursor?: string) => {
      if (!open) {
        return { data: [], hasMore: undefined };
      }
      const response = await getDriversByCompanyIdTypeahead({
        companyId,
        shared: true,
        searchParams: {
          limit: LIST_LIMIT,
          query: searchFieldValue,
          link: {
            type: 'after',
            cursor: nextPageCursor || '',
          },
        },
      });
      const data = (response?.data || []).map((driver) => ({
        type: JobAssignmentType.DRIVER,
        id: driver.id,
        name: `${driver.fullName}`,
        companyShare: driver.companyShare,
        equipment: driver.equipment,
        jobCount: 0,
      })) as SelectedAssignment[];

      const jobs = await fetchJobsForDriver(
        getFetchJobsOptions(companyId, data, filterDate),
      );

      return {
        data: data.map((driver) => ({
          ...driver,
          jobCount: jobs.data[driver.id] || 0,
        })),
        hasMore: response?.pagination.after || '',
      };
    },
    [open, companyId, searchFieldValue],
  );
  const fetchVendorDrivers = useCallback(
    async (nextPageCursor?: string) => {
      if (!open) {
        return { data: [], hasMore: undefined };
      }
      const response = await getAccountsByCompanyIdTypeahead({
        companyId,
        searchParams: {
          accountTypes: [AccountType.VENDOR],
          limit: LIST_LIMIT,
          query: searchFieldValue,
          link: {
            type: 'after',
            cursor: nextPageCursor || '',
          },
        },
      });
      return {
        data: (response?.data || []).map((vendor) => ({
          type: JobAssignmentType.VENDOR,
          id: vendor.id,
          name: vendor.name,
          companyShare: null,
          jobCount: null,
        })) as SelectedAssignment[],
        hasMore: response?.pagination.after || '',
      };
    },
    [open, companyId, searchFieldValue],
  );

  const fetchDrivers = useCallback(
    (cursor?: string) => {
      if (selectedTab === TabValue.INTERNAL) {
        return fetchInternalDrivers(cursor);
      } else if (selectedTab === TabValue.EXTERNAL) {
        return fetchExternalDrivers(cursor);
      } else if (selectedTab === TabValue.VENDOR) {
        return fetchVendorDrivers(cursor);
      }

      return Promise.resolve({ data: [], hasMore: undefined });
    },
    [selectedTab, fetchVendorDrivers, fetchInternalDrivers, fetchExternalDrivers],
  );
  const { data, loadingReason, hasMore, fetchMore } = useFetchMore({
    dataFetcher: fetchDrivers,
  });

  return {
    open,
    setOpen,
    searchBarRef,
    selectedTab,
    setSelectedTab,
    searchFieldValue,
    setSearchFieldValue,
    applyDebouncedFilter,
    data,
    loadingReason,
    hasMore,
    fetchMore,
  };
};

const fetchJobsForDriver = async (options: FetchJobsOptions) => {
  if (!options.driverIds || options.driverIds.length === 0) {
    return { data: {}, hasMore: undefined } as FetchJobsResponse;
  }

  const params: Record<string, any> = {
    'filter[driver_ids]': options.driverIds,
    'filter[company_id]': options.companyId,
  };

  if (options.startDate) {
    params['filter[start_date]'] = options.startDate;
  }

  if (options.endDate) {
    params['filter[end_date]'] = options.endDate;
  }

  if (options.states) {
    params['filter[states]'] = options.states;
  }

  const driverJobCount: Record<string, number> = {};

  try {
    let hasMore = true;
    let cursor = undefined;

    while (hasMore) {
      const response: Paginated<Job_Read> = await connection.getPaginated<Job_Read>(
        `${API_VERSION}/jobs`,
        {
          params: {
            ...params,
            'page[limit]': 100,
            'page[after]': cursor,
          },
        },
      );

      const parsedJobs = response.data.map(Job.parse);

      parsedJobs.forEach((job) => {
        const driverId = job.driver?.id;
        if (driverId) {
          if (driverJobCount[driverId]) {
            driverJobCount[driverId]++;
          } else {
            driverJobCount[driverId] = 1;
          }
        }
      });

      hasMore = !!response.pagination.after;
      cursor = response.pagination.after;
    }
  } catch (error) {
    console.error('Failed to fetch jobs:', error);
  }

  return { data: driverJobCount, hasMore: undefined } as FetchJobsResponse;
};

const getFetchJobsOptions = (
  companyId: string,
  driverData: SelectedAssignment[],
  filterDate?: string,
) => ({
  driverIds: driverData.map((driver) => driver.id),
  startDate: filterDate ? dayjs.tz(filterDate).startOf('day').toISOString() : undefined,
  endDate: filterDate ? dayjs.tz(filterDate).endOf('day').toISOString() : undefined,
  states: DRIVER_JOB_STATES,
  companyId,
});
