import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { useAssignDriverMenu } from '~components/Job/AssignJobToView/hooks/useAssignDriverMenu';
import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';
import { Order } from '~hooks/useOrders';
import { useStores } from '~store';
import theme from '~theme/AppTheme';
import { DialogCloseReasonType } from '~types/DialogCloseReasonType';
import { isActionClicked } from '~utils/utilFunctions';

import AssigneesList from './bulk-assign-multiple-jobs-dialog/AssigneesList';
import AssigneeTabs from './bulk-assign-multiple-jobs-dialog/AssigneeTabs';
import DialogHeader from './bulk-assign-multiple-jobs-dialog/DialogHeader';
import Search from './bulk-assign-multiple-jobs-dialog/Search';
import SelectionCounters from './bulk-assign-multiple-jobs-dialog/SelectionCounters';

const DIALOG_CONTENT_INNER_GUTTER_SIZE = 1.5;
const DIALOG_CONTENT_FLEX_GAP_SIZE = 1.5;

interface BulkAssignMultipleJobsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  order?: Order;
}

export default function BulkAssignMultipleJobsDialog({
  isOpen,
  onClose,
  onConfirm,
  order,
}: BulkAssignMultipleJobsDialogProps) {
  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {
          height: '720px',
          maxWidth: '649px',
          '.MuiDialogContent-root': { p: 0, overflow: 'hidden' },
        },
      }}
      open={isOpen}
      onClose={(__: never, reason: DialogCloseReasonType) => {
        if (isActionClicked(reason)) {
          onClose();
        }
      }}
    >
      <MuiDialogContent>{order && <DialogContent order={order} />}</MuiDialogContent>

      <DialogActions
        sx={{
          alignItems: 'center',
          borderTop: `1px solid ${theme.palette.divider}`,
          display: 'flex',
          justifyContent: 'space-between',
          m: 0,
          p: 1.5,
        }}
      >
        <SmallButton variant="text" color="brandV2OrangeDark" onClick={() => {}}>
          {t('common.clear_all')}
        </SmallButton>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <SmallButton color="secondary" variant="outlined" onClick={onClose}>
            {t('actions.cancel')}
          </SmallButton>

          <SmallButton color="brandV2Yellow" onClick={onConfirm}>
            {t('dispatch.order.assign_all')}
          </SmallButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

interface DialogContentProps {
  order: Order;
}

const DialogContent = observer(({ order }: DialogContentProps) => {
  const { userStore, ordersDispatchStore } = useStores();

  const companyId = userStore.userCompany.id;
  const filterDate = ordersDispatchStore.filters.startDate;

  const {
    data,
    fetchMore,
    hasMore,
    loadingReason,
    searchFieldValue,
    selectedTab,
    setOpen,
    setSearchFieldValue,
    setSelectedTab,
  } = useAssignDriverMenu(companyId, filterDate);

  const hasEmptyResults = searchFieldValue.length > 0 && !loadingReason && !data.length;

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: DIALOG_CONTENT_FLEX_GAP_SIZE,
        py: 1.5,
      }}
    >
      <DialogHeader order={order} sx={{ px: DIALOG_CONTENT_INNER_GUTTER_SIZE }} />

      <AssigneeTabs
        onChange={setSelectedTab}
        selectedTab={selectedTab}
        sx={{ px: DIALOG_CONTENT_INNER_GUTTER_SIZE }}
      />

      <Search
        assigneeType={selectedTab}
        onChange={setSearchFieldValue}
        sx={{ px: DIALOG_CONTENT_INNER_GUTTER_SIZE }}
      />

      <SelectionCounters
        currentSelections={Math.ceil(Math.random() * order.jobsCount)}
        maxSelections={order.jobsCount}
        sx={{ px: DIALOG_CONTENT_INNER_GUTTER_SIZE }}
      />

      <Divider />

      <Box
        sx={{
          flex: 1,
          my: DIALOG_CONTENT_FLEX_GAP_SIZE * -1,
          py: DIALOG_CONTENT_FLEX_GAP_SIZE,
          px: DIALOG_CONTENT_INNER_GUTTER_SIZE,
          overflow: 'auto',
        }}
      >
        <AssigneesList
          assigneeType={selectedTab}
          assignees={data}
          fetchMore={fetchMore}
          hasMore={hasMore}
          loadingReason={loadingReason ?? undefined}
          showNoResultsMessage={hasEmptyResults}
        />
      </Box>
    </Box>
  );
});
