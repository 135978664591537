import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { ReactNode, useCallback, useEffect, useRef } from 'react';

import Menu, { MenuHandler } from '~components/Menu/Menu';
import { SmallButton } from '~components/Order/ordersDispatchStyledComponents';
import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '~constants/filters';
import { useStores } from '~store';
import theme from '~theme/AppTheme';

dayjs.extend(isToday);

const formatDate = (date: Dayjs) => {
  const isSameYear = dayjs.tz().year() === date.year();
  const format = `ddd, MMM DD${isSameYear ? '' : " 'YY"}`;

  return date.format(format);
};

export const getDateString = (dateAsString?: string) => {
  if (dateAsString) {
    const date = dayjs.tz(dateAsString);

    if (date.isToday()) {
      return t('date.today');
    }

    return formatDate(date);
  }

  return null;
};

interface DateFilterProps {
  allowAllDates?: boolean;
}

const LiveMapDateFilter = observer(({ allowAllDates = true }: DateFilterProps) => {
  const { liveMapStoreNew } = useStores();
  const isMounted = useRef(false);

  const dateAsString = getDateString(liveMapStoreNew.filters.startDate);
  const isAllDates = !dateAsString;

  const applyDateFilter = (date?: Dayjs) => {
    const receivedDate = date?.toISOString();
    const storedDate = liveMapStoreNew.filters.startDate;
    const dateDidChange = receivedDate !== storedDate;

    if (date) {
      if (dateDidChange) {
        const startDate = date.startOf('day').toISOString();
        const endDate = date.endOf('day').toISOString();

        liveMapStoreNew.setFilters({ startDate, endDate }, true);
      }
    } else if (allowAllDates) {
      liveMapStoreNew.setFilters({ startDate: undefined, endDate: undefined }, true);
    } else {
      const startDate = dayjs.tz().startOf('day').toISOString();
      const endDate = dayjs.tz().endOf('day').toISOString();

      liveMapStoreNew.setFilters({ startDate, endDate }, true);
    }
  };

  const handlePreviousClick = useCallback(() => {
    applyDateFilter(dayjs.tz(liveMapStoreNew.filters.startDate).add(-1, 'day'));
  }, [liveMapStoreNew.filters.startDate]);

  const handleNextClick = useCallback(() => {
    applyDateFilter(dayjs.tz(liveMapStoreNew.filters.startDate).add(1, 'day'));
  }, [liveMapStoreNew.filters.startDate]);

  const handleTodayClick = useCallback(() => {
    const date = dayjs.tz(liveMapStoreNew.filters.startDate);

    if (!liveMapStoreNew.filters.startDate || !date.isToday()) {
      applyDateFilter(dayjs.tz());
    }
  }, [liveMapStoreNew.filters.startDate]);

  const handleAllDatesClick = useCallback(() => {
    if (!isAllDates) {
      applyDateFilter();
    }
  }, [isAllDates]);

  useEffect(() => {
    const isMissingInitialDate = liveMapStoreNew.filters.startDate;

    if (isMissingInitialDate && !isMounted.current) {
      const storedFilters = liveMapStoreNew.retrieveStoredFilters();

      if (storedFilters.startDate) {
        applyDateFilter(dayjs.tz(storedFilters.startDate));
      } else {
        applyDateFilter();
      }
    }
  }, [liveMapStoreNew.filters.startDate]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    }
  }, []);

  return (
    <Box flex={0}>
      <Box
        alignItems="center"
        bgcolor="white"
        border={`solid 1px ${theme.brandV2.colors.treadGray7}`}
        borderRadius={theme.brandV2.borderRadius}
        boxShadow="none"
        display="flex"
        height={NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX}
      >
        <DateFilterButton
          disabled={isAllDates}
          icon={<ChevronLeft />}
          onClick={handlePreviousClick}
          ariaLabel={t('actions.previous')}
        />

        <DateFilterMenu
          date={dateAsString ?? undefined}
          onAllDatesClick={handleAllDatesClick}
          onTodayClick={handleTodayClick}
          allowAllDates={allowAllDates}
        />

        <DateFilterButton
          disabled={isAllDates}
          icon={<ChevronRight />}
          onClick={handleNextClick}
          ariaLabel={t('actions.next')}
        />
      </Box>
    </Box>
  );
});

interface DateFilterButtonProps {
  disabled?: boolean;
  icon: ReactNode;
  onClick: () => void;
  ariaLabel: string;
}

function DateFilterButton({ disabled, icon, onClick, ariaLabel }: DateFilterButtonProps) {
  return (
    <SmallButton
      disabled={disabled}
      onClick={onClick}
      startIcon={icon}
      aria-label={ariaLabel}
      sx={{
        '&.MuiButton-root': {
          backgroundColor: 'transparent',
          border: 0,
          boxShadow: 'none',
          height: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
          width: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
          ...(disabled ? { pointerEvents: 'auto' } : {}),
          '.MuiButton-startIcon': { mr: 0 },
        },
      }}
    />
  );
}

interface DateFilterMenuProps {
  date?: string;
  onAllDatesClick: () => void;
  onTodayClick: () => void;
  allowAllDates?: boolean;
}

function DateFilterMenu({
  allowAllDates = true,
  date,
  onAllDatesClick,
  onTodayClick,
}: DateFilterMenuProps) {
  const menuHandler = useRef<MenuHandler>(null);
  const isAllDates = !date;

  return (
    <Menu
      ref={menuHandler}
      sx={{ '& .MuiPaper-root': { ml: '-31px' } }}
      menuTrigger={
        <ButtonBase
          sx={{ height: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX, minWidth: 75 }}
        >
          <Typography variant="subtitle2" whiteSpace="nowrap">
            {date ?? (allowAllDates ? t('dispatch.dispatch_v2.filters.all_dates') : '')}
          </Typography>
        </ButtonBase>
      }
    >
      <MenuItem
        onClick={() => {
          menuHandler.current?.onClose?.();
          onTodayClick();
        }}
      >
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          width="100%"
        >
          <Typography color={theme.brandV2.colors.treadBlack} variant="subtitle2">
            {t('date.today')}
          </Typography>
        </Box>
      </MenuItem>

      {allowAllDates && (
        <MenuItem
          onClick={() => {
            menuHandler.current?.onClose?.();
            onAllDatesClick();
          }}
        >
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            width="100%"
          >
            <Typography color={theme.brandV2.colors.treadBlack} variant="subtitle2">
              {t('dispatch.dispatch_v2.filters.all_dates')}
            </Typography>

            {isAllDates && <Check sx={{ fontSize: '16px' }} />}
          </Box>
        </MenuItem>
      )}
    </Menu>
  );
}

export default LiveMapDateFilter;
