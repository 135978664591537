import {
  Agave_LinkedAccount_Read,
  Agave_SourceSystem_Read,
} from '@treadinc/horizon-api-spec';
import dayjs, { Dayjs } from 'dayjs';

import { Nullable } from '~types/Nullable';

export class AgaveLinkedAccount {
  constructor(
    private _id: string,
    private _status: Agave_LinkedAccount_Read.status,
    private _synchronized_at: Nullable<Dayjs> | null,
    private _agave_source_system: Agave_SourceSystem_Read,
  ) {}

  public get id() {
    return this._id;
  }

  public get status(): Agave_LinkedAccount_Read.status {
    return this._status;
  }

  public get synchronizedAt(): Nullable<Dayjs> | null {
    return this._synchronized_at;
  }

  public get agaveSourceSystem(): Agave_SourceSystem_Read {
    return this._agave_source_system;
  }

  public static parse(proto: Agave_LinkedAccount_Read) {
    return new AgaveLinkedAccount(
      proto.id,
      proto.status as Agave_LinkedAccount_Read.status,
      proto.synchronized_at ? dayjs(proto.synchronized_at) : null,
      {
        name: proto.agave_source_system.name,
        slug: proto.agave_source_system.slug,
        theme: proto.agave_source_system.theme,
      },
    );
  }
}
