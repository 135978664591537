import CancelOutlined from '@mui/icons-material/CancelOutlined';
import Delete from '@mui/icons-material/Delete';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid-premium';
import dayjs from 'dayjs';
import { t } from 'i18next';
import React, { useRef } from 'react';

import Menu, { MenuHandler } from '~components/Menu/Menu';
import {
  LoadingSpinner,
  SmallButton,
} from '~components/Order/ordersDispatchStyledComponents';
import { NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX } from '~constants/filters';
import { AgaveLinkedAccount } from '~hooks/useAgave/models';
import theme from '~theme/AppTheme';

export type AgaveManagementRowDef = {
  row: {
    id: string;
    isDeleting?: boolean;
    linkedAccount: AgaveLinkedAccount;
    onDelete: () => void;
  };
};

export const logoColDef: GridColDef = {
  flex: 0,
  maxWidth: 25,
  field: 'Icon',
  headerName: '',
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  renderCell: ({ row }: AgaveManagementRowDef) => {
    return (
      <img
        src={row.linkedAccount.agaveSourceSystem.theme.square_image}
        alt="logo"
        width={25}
      />
    );
  },
};

export const sourceSystemNameColDef: GridColDef = {
  flex: 1,
  field: 'sourceSystemName',
  headerName: `${t('administration.integration.agave.grid.source_system_name')}`,
  valueGetter: ({ row }: AgaveManagementRowDef) =>
    row.linkedAccount.agaveSourceSystem.name,
};

export const statusColDef: GridColDef = {
  flex: 1,
  field: 'status',
  headerName: `${t('administration.integration.agave.grid.status')}`,
  valueGetter: ({ row }: AgaveManagementRowDef) => row.linkedAccount.status,
  renderCell: ({ row }: AgaveManagementRowDef) => {
    return (
      <Chip
        size="small"
        color="success"
        label={t(`administration.integration.agave.status.${row.linkedAccount.status}`)}
        variant="outlined"
      />
    );
  },
};

export const lastSyncColDef: GridColDef = {
  flex: 1,
  field: 'lastSync',
  headerName: `${t('administration.integration.agave.grid.last_sync')}`,
  valueGetter: ({ row }: AgaveManagementRowDef) =>
    row.linkedAccount.synchronizedAt
      ? dayjs.tz(row.linkedAccount.synchronizedAt).format("h:mm A ddd MMM-DD 'YY")
      : '-',
};

export const actionsColDef = (renderHeader: () => React.ReactNode): GridColDef => ({
  width: 96,
  field: 'actions',
  headerName: `${t('actions.actions')}`,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  hideable: false,
  renderHeader,
  renderCell: ({ row }: AgaveManagementRowDef) => {
    const menuHandler = useRef<MenuHandler>(null);
    return (
      <Box display="flex" alignItems="center" justifyContent="center" width="100%">
        <Menu
          ref={menuHandler}
          menuTrigger={
            <SmallButton
              color="brandV2TreadGray3"
              variant="text"
              startIcon={<MoreHoriz />}
              sx={{
                '&.MuiButtonBase-root': {
                  border: 'solid 1px transparent',
                  borderRadius: '50%',
                  height: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                  minWidth: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                  p: 0,
                  width: NEW_DISPATCH_TOPBAR_CONTROLS_HEIGHT_IN_PX,
                  '&:hover': { borderColor: theme.brandV2.colors.treadGray3 },
                  '.MuiButton-startIcon': { mx: 0 },
                },
              }}
            />
          }
        >
          <MenuItem
            key={'Delete'}
            onClick={(event) => {
              event.stopPropagation();
              row.onDelete();
            }}
          >
            <Box alignItems="center" display="flex" width="100%" gap={1}>
              <CancelOutlined
                sx={{ color: theme.brandV2.colors.treadBlack, fontSize: '16px' }}
              />

              <Typography color={theme.brandV2.colors.treadBlack} variant={'body2'}>
                {t('administration.integration.agave.cta.disconnect')}
              </Typography>
            </Box>
          </MenuItem>
        </Menu>
      </Box>
    );
  },
});
