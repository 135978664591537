import CheckCircleOutlineOutlined from '@mui/icons-material/CheckCircleOutlineOutlined';
import Clear from '@mui/icons-material/Clear';
import ControlPointOutlined from '@mui/icons-material/ControlPointOutlined';
import FlagOutlined from '@mui/icons-material/FlagOutlined';
import InsertDriveFileOutlined from '@mui/icons-material/InsertDriveFileOutlined';
import WarningAmber from '@mui/icons-material/WarningAmber';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { LoadState } from '@treadinc/horizon-api-spec';
import { t } from 'i18next';
import _ from 'lodash';
import React, { useCallback } from 'react';

import { Status as LoadStatus } from '~components/Job/LoadStatus';
import { BasicTooltip } from '~components/Tooltip/BasicTooltip';
import { JobLoadSummary, useJob } from '~hooks/useJob';
import { Ticket } from '~hooks/useTickets/models';
import { TicketEventType, useTickets } from '~hooks/useTickets/useTickets';
import ApprovalActionsMenu from '~pages/Approvals/DriverDay/ApprovalActionsMenu';
import { RemoveItemButton } from '~pages/Sales/sharedSales/RemoveItemButton';
import { useStores } from '~store/RootStore';
import { alert, AlertTypes } from '~types/AlertTypes';
import { Nullable } from '~types/Nullable';
import { dateFormat, formatDuration, minutesToHoursMins } from '~utils/dateTime';

interface Props {
  cycleTime: number;
  deletable: boolean;
  index: number;
  load: JobLoadSummary;
  onClick?: () => void;
  onRemoveLoad: (id: string) => void;
  reload: () => void;
  sx?: SxProps<Theme>;
  ticket: Nullable<Ticket>;
  isLast?: boolean;
}
const LoadCard = ({
  load,
  ticket,
  cycleTime,
  index,
  deletable,
  onRemoveLoad,
  onClick,
  reload,
  sx = {},
  isLast = false,
}: Props) => {
  const theme = useTheme();

  const { toasterStore } = useStores();
  const { updateState } = useTickets();

  // The load data currently does not display the dropoff and pickup arrival times
  // Once this is added, we can uncomment out the below

  // Const [loadData, setLoadData] = React.useState<JobLoad | null>(null);
  // Const { getLoadByLoadId } = useJob();
  // UseEffect(() => {
  //   If (!load.id) return;
  //
  //   GetLoadByLoadId({ loadId: load.id, callback: setLoadData });
  // }, [load.id]);

  const styles = {
    background: '#fff',
  };

  const gridStyles = {
    display: 'grid',
    alignContent: 'center',
    py: 0.75,
    gridTemplateRows: '1fr 1fr',
  };
  const hasCycleTime = cycleTime > 0;
  const hasTicket = !!ticket;
  const hasAttachment = !!ticket?.imageUrl;
  const ticketIsMissingData =
    !ticket?.ticketNumber ||
    !ticket?.material?.name ||
    !ticket?.quantity ||
    !ticket?.unitOfMeasure?.name ||
    !ticket?.imageUrl;
  const formattedTicketTime = ticket?.createdAt
    ? dateFormat(new Date(ticket.createdAt), 'h:mm A')
    : '-';
  const formattedTicketQuantity =
    ticket?.quantity && ticket?.unitOfMeasure?.name
      ? `${ticket.quantity} ${ticket.unitOfMeasure.name}`
      : '-';
  const ticketIsFlagged = ticket?.flagged || false;
  const loadIsApproved = load.approved;
  const loadIsApprovable = load.approvable;
  let ticketMetadataText: string;

  if (formattedTicketTime !== '-' && formattedTicketQuantity !== '-') {
    ticketMetadataText = `${formattedTicketQuantity} • ${formattedTicketTime}`;
  } else if (formattedTicketTime !== '-') {
    ticketMetadataText = formattedTicketTime;
  } else if (formattedTicketQuantity !== '-') {
    ticketMetadataText = formattedTicketQuantity;
  } else {
    ticketMetadataText = '-';
  }
  const { approveLoad, unapproveLoad } = useJob();

  const handleRemoveLoad = useCallback(
    (event?: React.MouseEvent) => {
      event?.stopPropagation();
      onRemoveLoad(load.id);
    },
    [onRemoveLoad],
  );

  const doAction = async (id: string, action: TicketEventType) => {
    const res = await updateState(id, action);
    toasterStore.push(alert(t('approvals.ticket_updated'), AlertTypes.success));
    reload();
    return res;
  };

  return (
    <>
      <Grid
        aria-label={`load job ${load.id}`}
        container
        columnSpacing={1}
        rowSpacing={0}
        alignItems={'center'}
        sx={{
          flexWrap: 'nowrap',
          cursor: 'pointer',
          borderBottom: isLast ? '0px' : `1px solid ${theme.brandV2.colors.treadGray6}`,
          position: 'relative',
          ...styles,
          ...sx,
        }}
        onClick={onClick}
      >
        {/* Load number */}
        <Grid item xs={1} sx={{ ...gridStyles }}>
          <Typography sx={{ pl: 1, gridRow: 'span 2' }}>{index + 1}</Typography>
          {ticketIsFlagged && (
            <Box pl={0.5}>
              <BasicTooltip title={t('loads.ticket_flagged')}>
                <FlagOutlined
                  sx={{
                    width: '16px',
                    height: '16px',
                    color: theme.brandV2.colors.treadGray2,
                  }}
                  aria-label={t('aria_label.ticket.flagged')}
                />
              </BasicTooltip>
            </Box>
          )}
        </Grid>

        {/* Status and cycle */}
        <Grid item xs={2} sx={{ ...gridStyles }}>
          <Box>
            <LoadStatus status={load.state} />
          </Box>
          <Typography variant={'body2'} mt={0.5}>
            {hasCycleTime ? minutesToHoursMins(cycleTime) : '-'}
          </Typography>
        </Grid>

        {/* Attachments and alerts */}
        <Grid item xs={1} sx={{ ...gridStyles, '&.MuiGrid-item': { pl: 0.5 } }}>
          <Box display="flex" alignItems="center" gap={0.25}>
            {hasAttachment && (
              <BasicTooltip title={t('loads.ticket_attached')}>
                <InsertDriveFileOutlined
                  sx={{
                    width: '16px',
                    height: '16px',
                    color: theme.brandV2.colors.treadGray2,
                  }}
                  aria-label={t('aria_label.ticket.has_attachment')}
                />
              </BasicTooltip>
            )}

            {loadIsApproved && (
              <BasicTooltip title={t('loads.ticket_approved')}>
                <CheckCircleOutlineOutlined
                  sx={{
                    width: '16px',
                    height: '16px',
                    color: theme.brandV2.colors.treadGreen,
                  }}
                  aria-label={t('aria_label.ticket.approved')}
                />
              </BasicTooltip>
            )}

            {hasTicket && ticketIsMissingData && (
              <BasicTooltip title={t('loads.missing_info')}>
                <WarningAmber
                  sx={{
                    display: 'inline-block',
                    width: '16px',
                    height: '16px',
                    color: theme.brandV2.colors.treadOrangeDark,
                  }}
                  aria-label={t('aria_label.ticket.missing_info')}
                />
              </BasicTooltip>
            )}
          </Box>
        </Grid>

        {/* Ticket */}
        <Grid
          item
          xs={3}
          sx={{ ...gridStyles, whiteSpace: 'nowrap', '&.MuiGrid-item': { pl: 0.5 } }}
        >
          {ticket ? (
            <Typography variant={'body2'}>{ticket.ticketNumber || '-'} </Typography>
          ) : (
            <Typography
              variant={'body2'}
              sx={{ color: theme.brandV2.colors.treadOrangeDark }}
              display={'flex'}
              alignItems={'center'}
            >
              <ControlPointOutlined sx={{ mr: 0.5, width: '16px', height: '16px;' }} />
              {t('loads.add_ticket')}
            </Typography>
          )}

          <Typography variant={'body2'} mt={0.5}>
            {ticketMetadataText}
          </Typography>
        </Grid>

        <Grid item xs={1.5} sx={{ ...gridStyles, whiteSpace: 'nowrap' }}>
          {load.loadSummary?.arrivedPickupAt && (
            <>
              <Typography variant="body2">
                {load.loadSummary.arrivedPickupAt.format('hh:mm A')}
              </Typography>

              <Typography variant="body2" color={theme.brandV2.colors.treadGray2}>
                {load.loadSummary.pickupTimeMinutes
                  ? formatDuration(load.loadSummary.pickupTimeMinutes * 60)
                  : '0m'}
              </Typography>
            </>
          )}
        </Grid>

        <Grid item xs={1.5} sx={{ ...gridStyles, whiteSpace: 'nowrap' }}>
          {load.loadSummary?.arrivedDropoffAt && (
            <>
              <Typography variant="body2">
                {load.loadSummary.arrivedDropoffAt.format('hh:mm A')}
              </Typography>

              <Typography variant="body2" color={theme.brandV2.colors.treadGray2}>
                {load.loadSummary.dropoffTimeMinutes
                  ? formatDuration(load.loadSummary.dropoffTimeMinutes * 60)
                  : '0m'}
              </Typography>
            </>
          )}
        </Grid>

        <Grid item xs={1.5} sx={{ '&.MuiGrid-item': { pl: 0.5, textAlign: 'right' } }}>
          {loadIsApproved ? (
            <ApprovalActionsMenu
              onMenuItemClick={() => {
                unapproveLoad(load.id);
                reload();
              }}
              approved={loadIsApproved}
            />
          ) : loadIsApprovable ? (
            <Button
              variant="outlined"
              color="secondary"
              onClick={(e) => {
                e.stopPropagation();
                approveLoad(load.id);
                reload();
              }}
              sx={{ fontSize: '12px', minWidth: 0, px: '4px', height: 'auto' }}
            >
              {t('approvals.approve')}
            </Button>
          ) : null}
        </Grid>

        {/* Remove row */}
        <Grid item xs={0.5} sx={{ '&.MuiGrid-item': { pl: 0 } }}>
          {deletable && [LoadState.CREATED].includes(load?.state) && (
            <RemoveItemButton
              action={handleRemoveLoad}
              sx={{
                cursor: 'pointer',
                backgroundColor: 'transparent',
                width: '16px',
                height: '16px',
                ml: 'auto',
              }}
              icon={Clear}
              iconStyles={{
                color: theme.brandV2.colors.treadGray3,
                width: '16px',
                height: '16px',
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export { LoadCard };
