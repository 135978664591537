import SubtitlesOff from '@mui/icons-material/SubtitlesOff';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { InvoiceCategoryFilter } from '@treadinc/horizon-api-spec';
import { t as $t, t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import InvoicesTabContent from '~components/Invoice/InvoicesTabContent';
import { SmallTabs } from '~components/Tabs/SmallTabs';
import { useCompanyCurrency } from '~hooks/useCompanyCurrency';
import { Invoice, useInvoices } from '~hooks/useInvoices';
import { Job, useJob } from '~hooks/useJob';
import theme from '~theme/AppTheme';

import { JobDetails } from './JobDetails';
import { LoadDetails } from './LoadDetails';

interface Props {
  details: Job;
}

const JobDetailsContainer = ({ details }: Props) => {
  const { getJobById } = useJob();
  const [detailedJob, setDetailedJob] = useState<Job>(details);
  const { approveInvoice, getInvoicesByCategory } = useInvoices();
  const [currentCategory, setCurrentCategory] = useState<InvoiceCategoryFilter>(
    InvoiceCategoryFilter.PAYABLES,
  );
  const [invoices, setInvoices] = useState<Invoice[]>([]);

  const getInvoices = async () => {
    const invoices = await getInvoicesByCategory(currentCategory);
    setInvoices(invoices);
  };
  useEffect(() => {
    getInvoices();
  }, [currentCategory]);

  const getInvoicesForJob = useCallback(
    (jobId: string) => {
      return invoices.filter((invoice) => invoice.job?.id === jobId);
    },
    [invoices],
  );

  const { currencyFormatter } = useCompanyCurrency();

  const JobInvoices = () => {
    const filteredInvoices = getInvoicesForJob(details.id);

    if (!filteredInvoices.length) {
      return (
        <Box display="flex" gap={2} key={details.id}>
          {' '}
          <Box
            key={details.id}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1,
              mb: 2,
              width: '100%',
            }}
          >
            <SubtitlesOff />
            <Typography
              color={theme.brandV2.colors.treadBlack}
              variant="body1"
              sx={{ textAlign: 'center', fontWeight: 600 }}
            >
              {t('approvals.driver_day.no_invoices', {
                category: currentCategory,
              })}
            </Typography>
          </Box>
        </Box>
      );
    }
    return filteredInvoices.map((invoice) => {
      return (
        <InvoicesTabContent
          invoice={invoice}
          job={details}
          utils={{
            currencyFormatter,
            approveInvoice: async (invoice: Invoice) => {
              await approveInvoice(invoice);
            },
          }}
          isDayDetailsView={true}
          key={invoice.id}
          customCategory={currentCategory}
        />
      );
    });
  };

  // Set detailedJob if the details passed in changes
  // We shouldn't need to store the state at this level, but see note below
  useEffect(() => {
    setDetailedJob(details);
  }, [details]);

  // Not sure what case we would have to reload the job details given that the parentshould
  // Be responsible for providing the updated details
  // However, will keep this here for now until there is more time to investigate
  useEffect(() => {
    if (!details.id) return;
    getJobById(details.id).then((job) => {
      setDetailedJob(job);
    });
  }, [details.id]);

  const tabs = useMemo(() => {
    return [
      {
        label: $t('order.form.loads'),
        id: `loads_${details.id}`,
        content: (
          <LoadDetails
            details={detailedJob}
            reload={() => {
              getJobById(details.id).then((job) => {
                setDetailedJob(job);
              });
            }}
          />
        ),
      },
      {
        label: $t('dispatch.job.details'),
        id: `details_${details.id}`,
        content: <JobDetails details={detailedJob} />,
      },
      {
        label: $t('dispatch.job.payables'),
        id: `payables_${details.id}`,
        content: <JobInvoices />,
        onSelected: () => {
          setCurrentCategory(InvoiceCategoryFilter.PAYABLES);
        },
      },
      {
        label: $t('dispatch.job.receivables'),
        id: `receivables_${details.id}`,
        content: <JobInvoices />,
        onSelected: () => {
          setCurrentCategory(InvoiceCategoryFilter.RECEIVABLES);
        },
      },
    ];
  }, [details.id, invoices, detailedJob]);

  return <SmallTabs sx={{ p: 2 }} tabs={tabs} />;
};

export { JobDetailsContainer };
